<template>
  <div class="modals-container">
    <component v-for="modal in modalInstanceNames" :key="modal.uuid" :ref="modal.uuid"  :modalId="modal.uuid" :is="modal.component" :params="modal.params" />
  </div>
</template>

<script>
  import { v4 as uuid } from 'uuid'
  import EventBus from '@services/GlobalEventBus'

  /**
   * NOTE: In order for the automatic systems and garbage collection in this component
   * to work, all modal components must contain the following items:
   *
   * ```
   * <modal @closed="destroyModal"> ... </modal>
   *
   * // ...
   *
   * methods: {
   *   $show () {
   *     this.$modal.show('MODAL-NAME')
   *   },
   *   $close () {
   *     this.$modal.hide('MODAL-NAME')
   *   },
   *   destroyModal () {
   *     ModalService.close(this.modalId)
   *   },
   * },
   * props: {
   *   params: {
   *     type: Object,
   *     required: true,
   *   },
   *   modalId: {
   *     type: String,
   *     required: true,
   *   }
   * }
   * ```
   */

  // Import all possible modal components in this section.
  import AssignmentGraderDialog from '@components/AssignmentGraderDialog.vue'
  import StarterTrackingGraderDialog from '@components/starterTracking/GraderDialog.vue'
  import InteractionTimelineModal from '@components/interactionTimeline/InteractionTimelineModal.vue'
  import MentorshipModal from '@components/MentorshipModal.vue'
  import GroupMembershipActionsModal from '@components/GroupMembershipActionsModal.vue'
  import GroupMembershipActionHistoryModal from '@components/GroupMembershipActionHistoryModal.vue'
  import ActionStepUploadErrorModal from '@components/ActionStepUploadErrorModal.vue'
  import CourseStepContentModal from '@components/CourseStepContentModal.vue'
  import StarterTrackingModal from '@components/StarterTrackingModal.vue'
  import TasksPerStudentModal from "@components/tasks/TasksPerStudentModal.vue";
  import CreateManualEngagementTaskModal from '@components/CreateManualEngagementTaskModal.vue'
  import UserEditTagsModal from '@components/UserEditTagsModal.vue'
  import BulkActionModal from '@components/BulkActionModal.vue'
  import CourseManagementReorderModal from "./components/backoffice/course_management/CourseManagementReorderModal.vue"
  import CourseManagementUpdateCourseModal from "./components/backoffice/course_management/CourseManagementUpdateCourseModal.vue"
  import CourseManagementUpdateModuleModal from "./components/backoffice/course_management/CourseManagementUpdateModuleModal.vue"
  import CourseManagementUpdateStepModal from "./components/backoffice/course_management/CourseManagementUpdateStepModal.vue"
  import CourseManagementBatchActionsMenuModal from "./components/backoffice/course_management/CourseManagementBatchActionsMenuModal.vue"
  import CourseManagementUpdateElementModal from "./components/backoffice/course_management/CourseManagementUpdateElementModal.vue";
  import CourseManagementSetAssignmentModal from "./components/backoffice/course_management/CourseManagementSetAssignmentModal.vue";
  import DirectMessagingGroupChatInfoModal from "@components/directMessaging/DirectMessagingGroupChatInfoModal.vue";
  import TriageEngagementRequestModal from "@components/TriageEngagementRequestModal.vue";
  import MeetingDetailsModal from "@components/backoffice/meeting_management/MeetingDetailsModal.vue";
  import MeetingUpsertModal from "@components/backoffice/meeting_management/MeetingUpsertModal.vue";


  export default {
    name: 'modals-app',
    components: {
      AssignmentGraderDialog,
      StarterTrackingGraderDialog,
      InteractionTimelineModal,
      MentorshipModal,
      GroupMembershipActionsModal,
      GroupMembershipActionHistoryModal,
      ActionStepUploadErrorModal,
      CourseStepContentModal,
      StarterTrackingModal,
      TasksPerStudentModal,
      CreateManualEngagementTaskModal,
      UserEditTagsModal,
      BulkActionModal,
      CourseManagementReorderModal,
      CourseManagementUpdateCourseModal,
      CourseManagementUpdateModuleModal,
      CourseManagementUpdateStepModal,
      CourseManagementBatchActionsMenuModal,
      CourseManagementUpdateElementModal,
      CourseManagementSetAssignmentModal,
      DirectMessagingGroupChatInfoModal,
      TriageEngagementRequestModal,
      MeetingDetailsModal,
      MeetingUpsertModal,
    },
    data () {
      return {
        modalInstanceNames: [],
      }
    },
    methods: {
      /**
       * Spawn a new instance of a modal. Must be the name of a component that has been imported
       * into this component in snake case. For example, to spawn the `JobAssignmentEditorDialog` modal,
       * the componentName should be `job-assignment-editor-dialog`.
       * @param {Object} modal Contains the `componentName` to instantiate and the params to pass in
       */
      spawnModal (modal) {
        const u = uuid()
        if (!modal.params) modal.params = {}

        this.modalInstanceNames.push({
          uuid: u,
          component: modal.componentName,
          params: modal.params || {},
        })


        const intv = setInterval(() => {
          if (this.$refs[u]) {
            this.$refs[u][0].$show() // tip: if you are having errors throwing from this line, it is probably because the modal component file and modal name are not the same
            clearInterval(intv)
          }
        }, 50)
      },
      /**
       * Destroys an instance of a modal. Lookup is done by modal ID, which is the UUID
       * that the instance is mapped to.
       * @param {String} modalId UUID of the modal instance to destroy
       */
      destroyModal (modalId) {
        let idx = this.modalInstanceNames.findIndex(modal => modal.uuid === modalId)
        let modalInst = this.modalInstanceNames[idx]
        if (modalInst.params.beforeCloseFn && typeof modalInst.params.beforeCloseFn === 'function') {
          modalInst.params.beforeCloseFn()
        }
        this.modalInstanceNames.splice(idx, 1)
      },
    },
    created () {
      // Attach the spawn and destroy modal events.
      EventBus.subscribe('spawning-modal', this.spawnModal)
      EventBus.subscribe('destroying-modal', this.destroyModal)
    },
  }
</script>
