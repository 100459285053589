<template>
  <div class="interaction-eventReview">
    <div class="interaction-event-row" v-if="vm.reviewerUser">
      <UserThumbnail :userId="event.value.created_by_id"/>
      <div class="content">
        <div class="sent-info">
          <span class="sent-by">{{ vm.reviewerUser.firstLastName }}</span>
          <span class="sent-at">{{ event.value.sentTimeFormatted }}</span>
        </div>
        <div class="review-content">
          <span class="badge badge-pill badge-secondary">{{ statusText }}</span> {{ event.value.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getUsersStoreFor } from '@stores/UsersStore'
  import UserThumbnail from '@components/general/UserThumbnail.vue'

  import { makeObservable, observable } from 'mobx'
  import { observer } from "mobx-vue"

  class ViewModel {
    user = null
    usersStore = null
    reviewerUserId = null

    constructor(params) {
      this.reviewerUserId = params.reviewerUserId
      makeObservable(this, {
        user: observable,
      })

      this.usersStore = getUsersStoreFor({ id: params.reviewerUserId })
    }

    get reviewerUser() {
      return this.usersStore.allRecords.find(u => u.id === this.reviewerUserId)
    }
  }

  export default observer({
    name: 'interaction-eventReview',
    components: {
      UserThumbnail,
    },
    data () {
      return {
        vm: new ViewModel({
          reviewerUserId: this.event.value.created_by_id,
        }),
      }
    },
    computed: {
      statusText () {
        if (this.studentStepProgress.courseStep.isEvent) {
          if (this.event.value.isApproved) {
            return this.$t('application.common.attended')
          } else if (this.event.value.isUnderReview) {
            return this.$t('application.common.under_review')
          } else if (this.event.value.isQuestionsRemaining) {
            return this.$t('application.common.questions_remaining')
          } else {
            return ''
          }
        } else if (this.studentStepProgress.courseStep.isBenchmark &&
          this.studentStepProgress.latestCourseStepReview) {
          if (this.event.value.isApproved) {
            return this.$t('application.common.achieved')
          } else if (this.event.value.isUnderReview) {
            return this.$t('application.common.under_review')
          } else {
            return this.$t('application.common.did_not_achieve')
          }
        } else if (this.studentStepProgress.courseStep.isStep &&
          this.studentStepProgress.latestCourseStepReview) {
          if (this.event.value.isApproved) {
            return this.$t('application.common.completed')
          } else if (this.event.value.isUnderReview) {
            return this.$t('application.common.under_review')
          } else {
            return this.$t('application.common.questions_remaining')
          }
        }
      }
    },
    props: {
      event: {
        type: Object,
        required: true,
      },
      studentStepProgress: {
        type: Object,
        required: true,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .interaction-eventReview {
    font-size: .875rem;
    font-weight: 500;
    color: #333;
    border-radius: 5px;
    margin-bottom: 16px;

    .content {
      color: #221932;
      background-color: #EEEBF2;
      border-radius: 0px 16px 16px 16px;
      padding: 12px;
      display: flex;
      flex-wrap: wrap;
      max-width: 269px;
      min-width: 64px;
      margin-left: 8px;
      font-size: 1rem;
      line-height: 24px;
      text-align: left;
    }
  }

  .interaction-event-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .sent-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    margin-bottom: 4px;
  }

  .sent-by {
    font-weight: 600;
    font-size: .875rem;
    color: #1A1225;
    line-height: 16px;
    display: inline-block;
    text-align: left;
  }

  .sent-at {
    color: #756292;
    font-size: .75rem;
    text-align: right;
  }
</style>
