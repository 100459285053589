import { cloneDeep, filter, map, some, uniqBy } from 'lodash-es'
import { observable, computed, reaction, makeObservable, action } from 'mobx'
import DataStore from '@services/timelineService/DataStore'
import StudentProgress from '@services/timelineService/StudentProgress'
import StudentPagination from '@services/timelineService/StudentPagination'

class TimelineDataManagerService {
  _dataReady = false;
  _studentProgressesArray = []
  _courseStepsArray = []
  _hideCourseStepIds = new Set()
  _onlyShowCourseStepIds = new Set()
  _stopFunctions = []
  _pagination = new StudentPagination()
  _dataService = null
  _dataReadyFns = []
  _statsByUserId = {}
  _presetFilter = null

  constructor (opts = {}) {
    makeObservable(this, {
      _dataReady: observable,
      _studentProgressesArray: observable,
      _statsByUserId: observable,
      _initStudentProgress: action,
      allUsers: computed,
      courseStepsToShow: computed,
      courseStepCategories: computed,
      courseStepCategoriesToShow: computed,
      courseStepsByCategory: computed,
      courseStepsToShowByCategory: computed,
      courseStepProgress: computed,
      allCourseSteps: computed,
      allCourseStepReviews: computed,
      allAttachments: computed,
      allConversations: computed,
      allAssignmentResponses: computed,
      groupId: computed,
      attachmentsStore: computed,
      assignmentResponsesStore: computed,
      courseStepReviewsStore: computed,
      courseStepsStore: computed,
      conversationsStore: computed,
      loading: computed,
      dataReady: computed,
      studentProgressRecords: computed,
      statsByUserId: computed,
      pagination: computed,
    })

    this._presetFilter = opts
    this._dataService = new DataStore({
      ...opts,
      pagination: this.pagination,
    })
    this._stopFunctions.push(this._registerUsersReaction())
    this._stopFunctions.push(this._registerDataReadyReaction())
  }

  registerDataReadyFn (fn) {
    if (this.dataReady) {
      fn()
    } else {
      this._dataReadyFns.push(fn)
    }
  }

  get presetFilter () {
    return this._presetFilter
  }

  unwatch() {
    this._dataService.dispose()
    this._dataService = null

    // Call all `stop` functions
    this._stopFunctions.forEach((stop) => stop())

    // Reset to initial state
    this._dataReady = false
    this._stopFunctions = []
    this._dataReadyFns = []
  }

  dispose () {
    this.unwatch()
  }

  addStopFunction (fn) {
    if (fn && typeof fn === 'function') {
      this._stopFunctions.push(fn)
    }
  }

  get dataStore () {
    return this._dataService
  }

  get allUsers() {
    return this._dataService.allUsers
  }

  get courseStepsToShow() {
    return filter(this._dataService.allCourseSteps, cs => {
      if (this._onlyShowCourseStepIds.size > 0) {
        return this._onlyShowCourseStepIds.has(cs.id)
      }

      return !this._hideCourseStepIds.has(cs.id)
      // don't hide step's w/o assignment_id
      // return cs.isStep && !!cs.assignment_id || cs.isEvent || cs.isBenchmark
    })
  }

  get courseStepCategories() {
    return uniqBy(map(this._dataService.allCourseSteps, 'category'), 'id')
  }

  get courseStepCategoriesToShow() {
    return uniqBy(map(this.courseStepsToShow, 'category'), 'id')
  }

  get courseStepsByCategory() {
    return map(this.courseStepCategories, cat => ({
      category: cat,
      items: filter(this.allCourseSteps, cs => cs.category.id === cat.id)
    }))
  }

  get courseStepsToShowByCategory() {
    return map(this.courseStepCategoriesToShow, cat => ({
      category: cat,
      items: filter(this.courseStepsToShow, cs => cs.category.id === cat.id)
    }))
  }

  get courseStepProgress() {
    const rec = this._dataService.allCourseStepProgresses
    if (rec.length > 0) {
      return rec[0]
    } else {
      return {}
    }
  }

  get allCourseSteps() {
    return this._dataService.allCourseSteps
  }

  get allCourseStepReviews() {
    return this._dataService.allCourseStepReviews
  }

  get allAttachments() {
    return this._dataService.allAttachments
  }

  get allConversations() {
    return this._dataService.allConversations
  }

  get allAssignmentResponses() {
    return this._dataService.allAssignmentResponses
  }

  get groupId() {
    return this._dataService.groupId
  }

  get attachmentsStore() {
    return this._dataService.attachmentsStore
  }

  get assignmentResponsesStore() {
    return this._dataService.assignmentResponsesStore
  }

  get courseStepReviewsStore() {
    return this._dataService.courseStepReviewsStore
  }

  get courseStepsStore() {
    return this._dataService.courseStepsStore
  }

  get conversationsStore() {
    return this._dataService.conversationsStore
  }

  get loading() {
    return this._dataService.loading
  }

  get dataReady() {
    return !this.loading && this._dataReady
  }

  get studentProgressRecords() {
    return this._studentProgressesArray
  }

  get statsByUserId() {
    return this._studentProgressesArray.reduce((accum, studProg) => {
      if (studProg && studProg.stepProgresses && studProg.openConversations) {
        accum[studProg.userId] = {
          numOpenConversationsNeedingReply: studProg.openConversations.filter(c => c.conversationWaitingForCurrentUser).length,
          numItemsNeedingReview: studProg.stepProgresses.filter(ssp => {
            return (ssp.latestAssignmentResponse && !ssp.latestCourseStepReview) ||
              (ssp.latestAssignmentResponse && ssp.latestAssignmentResponse.isSubmitted && ssp.latestCourseStepReview && ssp.latestCourseStepReview.created_at.unix() < ssp.latestAssignmentResponse.submitted_at.unix())
          }).length
        }
      }
      return accum
    }, {})
  }

  get pagination() {
    return this._pagination
  }

  getStudentProgress (userId) {
    return this._studentProgressesArray.find(studentProgressRecord => userId === studentProgressRecord._userId)
  }

  getStepProgress(userId, courseStepId) {
    let progResult = this.getStudentProgress(userId)

    if (!progResult) {
      progResult = new StudentProgress(this, userId, true)
      this._studentProgressesArray.push(progResult)
    }

    // Assume `_studentStepProgresses` is always set due to `this.allCourseSteps` always being present
    // If `this.allCourseSteps` is ever undefined, then this method will return an undefined value and break the app.
    return progResult._studentStepProgresses.find(courseStep => courseStepId === courseStep._courseStepId)
  }

  /**
   * @param {Iterable<number>} courseStepIds
   */
  set hideCourseStepIds(courseStepIds) {
    this._hideCourseStepIds = new Set(courseStepIds)
  }

  /**
   * @param {Iterable<number>} courseStepIds
   */
  set onlyShowCourseStepIds(courseStepIds) {
    this._onlyShowCourseStepIds = new Set(courseStepIds)
  }

  _registerUsersReaction() {
    return reaction(
      () => this.allUsers && this.allUsers.length > 0,
      () => this._initStudentProgress()
    )
  }

  _registerDataReadyReaction() {
    function runsFnsWithDelay(fns) {
      setTimeout(() => {
        const results = fns.map(fn => fn())
        const anyNotRun = some(results, x => !x)

        if (anyNotRun) {
          runsFnsWithDelay(fns)
        }
      }, 250)
    }

    return reaction(() => {
      return !!this.dataReady
    }, () => {
      runsFnsWithDelay(this._dataReadyFns)
    })
  }

  _initStudentProgress() {
    window.pagination = this.pagination

    const stop = reaction(() => this.pagination.filteredItems, (e) => {
      console.log('OBSERVER RUNNING', cloneDeep(e.object))
      this._studentProgressesArray = e.object.map(
        user => { return new StudentProgress(this, user.id) }
      )
    })
    // const stop = this.pagination.filteredItems.observe((e) => {
    //   console.log('OBSERVER RUNNING', cloneDeep(e.object))
    //   this._studentProgressesArray = e.object.map(
    //     user => { return new StudentProgress(this, user.id) }
    //   )
    // })

    this._stopFunctions.push(stop)

    this.pagination.allItems = this.allUsers
    this._dataReady = true
  }
}

export default TimelineDataManagerService