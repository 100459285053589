<template>
  <modal
        :name="modalName"
        class="course-management-batch-actions-menu-modal"
        :scrollable="true"
        height="auto"
        @closed="close">
      <div class="modal-header">
        <h2 class="modal-title">{{ name }}</h2>
        <div class="close" @click="close">
          <i class="fa fa-times"></i>
        </div>
      </div>

      <div class="modal-content">

        <v-alert v-if="alertVisible" class="alert" type="error" dismissible>
          {{ errorMessage }}
        </v-alert>

        <section class="entity-section">
          <h3 class="entity-title">Parent Entity - {{formatEntityType(parentEntity.entityType)}}</h3>
          <div class="entity-content">
            <span class="entity-label">ID:</span>
            <span class="entity-id">{{ parentEntity.id }}</span>
            <span class="entity-label">Name:</span>
            <span class="entity-name">{{ parentEntity.name }}</span>
          </div>
        </section>


        <section class="entity-section">
          <h3 class="entity-title">Children Entities - {{formatEntityType(childrenEntityType)}}</h3>
          <div v-for="childEntity in childrenEntities" class="entity-content">
            <span class="entity-label">ID:</span>
            <span class="entity-id">{{ childEntity.id }}</span>
            <span class="entity-label">Name:</span>
            <span class="entity-name">{{ childEntity.name }}</span>
          </div>
        </section>

        <button @click="onSubmit" class="submit-button">Submit</button>

      </div>
    </modal>
</template>
<script>
  import Multiselect from 'vue-multiselect'
  import ModalMixin from '@mixins/ModalMixin.vue'
  import axios from '@dataServices/axios'
  import EventBus from '@services/GlobalEventBus'

  import { VAlert } from 'vuetify/lib'

  const baseUrl = window.location.origin
  const NAME = 'course-management-batch-actions-menu-modal'

  export default {
    name: NAME,
    mixins: [ModalMixin],
    components: {
      Multiselect,
      VAlert,
    },
    data() {
      return {
        modalName: NAME,
        name: this.params.name,
        parentEntity: this.params.parentEntity,
        childrenEntities: this.params.childrenEntities,
        submitCallback: this.params.submitCallback,
        updated: false,
        alertVisible: false,
        errorMessage: '',
      }
    },
    computed: {
      childrenEntityType() {
        return this.childrenEntities.length > 0 ? this.childrenEntities[0].entityType : '';
      },
    },
    mounted() {
    },
    methods: {
      formatEntityType(entityType){
        switch(entityType) {
          case 'COURSE': return 'Courses';
          case 'MODULE': return 'Modules';
          case 'STEP': return 'Steps';
          case 'ELEMENT': return 'Elements';
        }
      },
      close() {
        const eventPayload = { type: 'closed', updated: this.updated, parentEntityType: this.parentEntity.entityType, childrenEntityType: this.childrenEntityType}
        EventBus.dispatch(NAME, eventPayload)
        this.destroyModal();
      },
      onSubmit() {
        this.submitCallback(this.parentEntity, this.childrenEntities)
            .then(response => this.processSubmitCallbackResponse(response))
            .catch(error => this.processResponseError(error))
      },
      processSubmitCallbackResponse (response) {
        this.updated = true;
        this.close();
      },
      processResponseError (error) {
        this.alertVisible = true;
        this.errorMessage = error.response? error.response.data.message : error.message;
        console.error('Backend Error:', this.errorMessage, error.response?.data, error.response?.status);
      },
    },
  }
</script>

<style lang="scss">
.course-management-batch-actions-menu-modal {

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #e0e0e0;
  }

  .close {
    color: #767676;
    font-size: 22px;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  .modal-content {
    max-height: 600px;
    overflow-y: auto;
    padding: 20px;
  }

  .modal-title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .entity-section {
    margin-bottom: 20px;
  }

  .entity-title {
    font-weight: 500;
    margin-bottom: 10px;
  }

  .entity-content {
    background: #f7f7f7;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;

    .entity-id, .entity-name {
      display: block;
      margin-right: 10px;
    }
  }

  .entity-label {
    font-weight: bold;
    margin-right: 5px;
  }

  .entity-type {
    font-style: italic;
    margin-bottom: 8px;
    display: block;
  }

  .alert {
    margin-bottom: 15px;
  }

  .submit-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }

}

</style>
