import { v4 as uuid } from 'uuid'
import { action, computed, observable, makeObservable, runInAction } from 'mobx'
import { each, find, isArray, isEmpty, mapKeys, reject, snakeCase } from 'lodash-es'
import defer from '@utils/defer'
import StoreInstances from '@stores/StoreInstances'

class BaseArrayStore {

  _records = []
  _pageMetadata = {}
  _type = ''
  _promises = []
  _filter = {}
  _paginate = false
  _keepDiscarded = false
  _currentPage = 1

  constructor (type, opts = {}) {
    makeObservable(this, {
      _records: observable,
      _pageMetadata: observable,
      loadPageNumber: action,
      nextPage: action,
      prevPage: action,
      setFilter: action,
      inject: action,
      rejectByFilter: action,
      allRecords: computed,
      loading: computed,
      pageMetadata: computed,
      // _fetchOne: action,
      // _fetchAll: action,
      _fetchPage: action,
      _fetchData: action,
      _propagateChanges: action,
      _performChanges: action,
      _performRejectByFilter: action,
      _requestInFlight: observable,
    })

    this._type = type
    this._paginate = opts.paginate || false
    this._keepDiscarded = opts.keepDiscarded || false

    if (opts.data) {
      this._filter = opts.filter
      this._records = isArray(opts.data) ? opts.data : [opts.data]
      this._cacheLastFetched = Date.now()
    }

    this._rejectionFilterFn = opts.rejectionFilterFn || function(x) { return false }

    this.uuid = uuid()
    StoreInstances.registerStore(this)
  }

  loadPageNumber(pageNum) {
    this._cacheLastFetched = null
    this._currentPage = pageNum
    return this._fetchData()
  }

  nextPage() {
    return this.loadPageNumber(this._currentPage + 1)
  }

  prevPage() {
    return this.loadPageNumber(this._currentPage - 1)
  }

  setFilter(params) {
    params = mapKeys(params, (value, key) => snakeCase(key))
    let deferred = defer()
    if (params === this._filter && this.cacheIsValid()) {
      return deferred.resolve(this._records)
    }

    if (this._paginate) {
      this._currentPage = 1
    }

    this._filter = params
    this._promises.push(deferred)

    if (!this._requestInFlight) {
      this._requestInFlight = true
      this._fetchData()
    }

    return deferred.promise
  }

  inject(records) {
    StoreInstances._propagateChanges(this._type, records)
  }

  rejectByFilter(filter) {
    StoreInstances._propagateChanges(this._type, filter)
  }

  get allRecords() {
    return this._records //.peek()
  }

  get loading() {
    return this._requestInFlight
  }

  get pageMetadata() {
    return this._pageMetadata
  }

  cacheIsValid () {
    return this._cacheLastFetched && Date.now() - this._cacheLastFetched <= this._maxCacheAge
  }

  reloadIfCacheInvalid () {
    if (!isEmpty(this._filter) && !this.cacheIsValid() && !this._requestInFlight) {
      this._reload()
    }
  }

  getRecord (id) {
    return find(this.allRecords, r => r.id === id)
  }

  dispose () {
    this._records.clear()
  }

  /**
   * Private Functions
   */

  _fetchOne() {
    throw new Error('Virtual Function: Must override function `BaseArrayStore._fetchOne`.')
  }

  _fetchAll(overridePayload) {
    throw new Error('Virtual Function: Must override function `BaseArrayStore._fetchAll`.')
  }

  _fetchPage() {
    let payload = {
      ...this._filter,
      page: this._currentPage,
      // ...this._paginate ? this._paginationData(++this._lastPageFetched, '') : {},
    }
    return this._fetchAll(payload)
  }

  _fetchData () {
    let fetch = this._fetchAll
    if (this._filter.hasOwnProperty('id')) {
      fetch = this._fetchOne
    } else if (this._paginate) {
      fetch = this._fetchPage
    }
    return fetch.bind(this)()
      .then(resp => {
        runInAction(() => {
          if (this._paginate) {
            this._records = resp.records
            this._pageMetadata = resp.pagination
          } else if (isArray(resp)) {
            this._records = resp
          } else {
            this._records = [resp]
          }
          while (this._promises.length > 0) {
            this._promises.shift().resolve(this._records)
          }
          this._cacheLastFetched = Date.now()
          this._requestInFlight = false
        })
      })
      .catch(resp => {
        while (this._promises.length > 0) {
          this._promises.shift().reject(resp)
        }
        this._requestInFlight = false
      })
  }

  _paginationData (page = 1, sortOrder) {
    return {
      page, sort: sortOrder ? sortOrder.map(sort => `${sort.sortField}|${sort.direction}`).join(',') : '',
    }
  }

  // @action
  // _propagateChanges (changedRecords) {
  //   let temp = this._records.peek()
  //   each(changedRecords, rec => {
  //     let idx = temp.findIndex(r => r.id === rec.id)
  //     const shouldDiscard = rec.deleted_at || rec.discarded_at

  //     if (shouldDiscard && idx >= 0) {
  //       temp.splice(idx, 1)
  //     } else if (!shouldDiscard && idx >= 0) {
  //       temp[idx] = rec
  //     } else {
  //       temp.push(rec)
  //     }
  //   })
  //   this._records = temp
  //   return temp
  // }

  _propagateChanges(changes, action) {
    console.log('propagatechanges', changes, action)
    if (action === 'reject') {
      return this._performRejectByFilter(changes)
    } else {
      return this._performChanges(changes)
    }
  }

  _performChanges(changedRecords) {
    // console.log('performChanges', changedRecords)
    let temp = this._records
    each(changedRecords, rec => {
      const shouldReject = this._rejectionFilterFn(rec)

      let idx = temp.findIndex(r => r.id === rec.id)
      const shouldDiscard = !this._keepDiscarded && (rec.deleted_at || rec.discarded_at)

      if ((shouldDiscard || shouldReject) && idx >= 0) {
        temp.splice(idx, 1)
      } else if (!(shouldDiscard || shouldReject) && idx >= 0) {
        // console.log(this.uuid, 'matching replacing', idx, rec)
        temp[idx] = rec
      } else {
        if (!shouldReject) {
          temp.push(rec)
        }
      }
    })
    this._records = temp
    return temp
  }

  _performRejectByFilter(records) {
    const temp = reject(this._records, filter)
    this._records = temp
    return temp
  }

  _reload () {
    let fetch = this._fetchAll
    if (this._filter.hasOwnProperty('id')) {
      fetch = this._fetchOne
    } else if (this._paginate) {
      fetch = this._fetchPage
    }
    return fetch.bind(this)()
      .then(recs => {
        if (isArray(recs)) {
          this._records = recs
        } else {
          this._records = [recs]
        }
        this._cacheLastFetched = Date.now()
        this._requestInFlight = false
      })
  }

  // Maximum amount of time that the cache is valid for (milliseconds)
  _maxCacheAge = 60000
  // Last time that the cache was filled
  _cacheLastFetched = null

  _requestInFlight = false;
}

export default BaseArrayStore
