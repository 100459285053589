<template>
  <modal :name="modalName" height="90%" width="90%" :scrollable="true">
    <assignment-grader :groupId="params.groupId"></assignment-grader>
  </modal>
</template>

<script>

const NAME = 'assignment-grader-dialog'

import AssignmentGrader from '@components/AssignmentGrader.vue'
import ModalMixin from '@mixins/ModalMixin.vue'

export default {
  name: NAME,
  mixins: [ ModalMixin ],
  components: {
    AssignmentGrader,
  },
  data () {
    return {
      modalName: NAME,
    }
  },
}
</script>