import { observable, computed, makeObservable } from 'mobx'
import { getAssignmentResponseGradesStoreFor } from '@stores/AssignmentResponseGradesStore'
import { getUsersStoreFor } from '@stores/UsersStore'
import { getAssignmentsStoreFor } from '@stores/AssignmentsStore'
import { getAssignmentResponsesStoreFor } from '@stores/AssignmentResponsesStore'

class GradebookManagerDataStore {
  assignmentsStore = undefined
  assignmentResponsesStore = undefined
  assignmentResponseGradesStore = undefined
  studentsStore = undefined

  _userId = null
  _groupId = null

  constructor() {
    makeObservable(this, {
      assignmentsStore: observable,
      assignmentResponsesStore: observable,
      assignmentResponseGradesStore: observable,
      studentsStore: observable,
      _userId: observable,
      _groupId: observable,
      allStudents: computed,
      allAssignments: computed,
      allAssignmentResponses: computed,
      allAssignmentResponseGrades: computed
    });
  }

  set setFilter ({ groupId, userId }) {
    this._groupId = groupId
    this._userId = userId || null
    this.refresh()
  }

  get allStudents() {
    return this.studentsStore && this.studentsStore.allRecords
  }

  get allAssignments() {
    return this.assignmentsStore && this.assignmentsStore.allRecords
  }

  get allAssignmentResponses() {
    return this.assignmentResponsesStore && this.assignmentResponsesStore.allRecords
  }

  get allAssignmentResponseGrades() {
    return this.assignmentResponseGradesStore && this.assignmentResponseGradesStore.allRecords
  }

  refreshAssignmentsStore() {
    const filter = { groupId: this._groupId }
    if (this.assignmentsStore) {
      this.assignmentsStore.setFilter(filter)
    } else {
      this.assignmentsStore = getAssignmentsStoreFor(filter)
    }
  }

  refreshAssignmentResponsesStore () {
    const filter = {
      groupId: this._groupId,
      ...(this._userId && { userId: this._userId })
     }

    if (this.assignmentResponsesStore) {
      this.assignmentResponsesStore.setFilter(filter)
    } else {
      this.assignmentResponsesStore = getAssignmentResponsesStoreFor(filter)
    }

    window.ars = this.assignmentResponsesStore
  }

  refreshAssignmentResponseGradesStore () {
    const filter = {
      groupId: this._groupId,
      ...(this._userId && { userId: this._userId })
     }

    if (this.assignmentResponseGradesStore) {
      this.assignmentResponseGradesStore.setFilter(filter)
    } else {
      this.assignmentResponseGradesStore = getAssignmentResponseGradesStoreFor(filter)
    }
  }

  refreshStudentsStore () {
    const filter = { groupId: this._groupId, role: 'student', state: 1 }
    if (this.studentsStore) {
      this.studentsStore.setFilter(filter)
    } else {
      this.studentsStore = getUsersStoreFor(filter)
    }
  }

  refresh () {
    this.refreshAssignmentsStore()
    this.refreshAssignmentResponsesStore()
    this.refreshAssignmentResponseGradesStore()
    this.refreshStudentsStore()
  }
}

export default GradebookManagerDataStore