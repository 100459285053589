<template>
  <modal
    :name="modalName"
    :class="modalName"
    :scrollable="true"
    height="500"
    @closed="destroyModal">
    <div class="close" @click="close" style="position: absolute; right: 18px; top: 8px; z-index: 999; background-color: white; padding: 4px; color: #767676; font-size: 22px; opacity: .75; border-radius: 4px;">
      <i class="fa fa-times"></i>
    </div>
    <div class="container">
      <h2 class="title">{{ $t('application.action_step_upload_error_modal.title') }}</h2>
      <p style="margin-bottom: 4px;">{{ $t('application.action_step_upload_error_modal.description') }}</p>

      <div class="resolutions-list">
        <div class="resolution-item">
          <div class="resolution-title">{{ $t('application.action_step_upload_error_modal.resolution_title_no_adobe') }}</div>
          <div class="resolution-text">
            {{ $t('application.action_step_upload_error_modal.resolution_no_adobe_install') }}
            <a href="https://course-pages.howtostartsomething.com/posts/set-adobe-acrobat-reader-as-default-pdf-reader-for-your-device">{{ $t('application.action_step_upload_error_modal.link') }}</a><br>
            {{ $t('application.action_step_upload_error_modal.resolution_no_adobe_after') }}
          </div>
        </div>

        <div class="resolution-item">
          <div class="resolution-title">{{ $t('application.action_step_upload_error_modal.resolution_title_saved_multiple') }}</div>
          <div class="resolution-text">
            {{ $t('application.action_step_upload_error_modal.resolution_description_saved_multiple') }}
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import ModalMixin from '@mixins/ModalMixin.vue'

  const MODAL_NAME = "action-step-upload-error-modal"

  export default {
    name: MODAL_NAME,
    mixins: [ ModalMixin ],
    data() {
      return {
        modalName: MODAL_NAME,
      }
    },
    methods: {
      close () {
        this.destroyModal()
      },
    },
  }
</script>

<style lang="scss">
  .action-step-upload-error-modal .title {
    padding-top: 20px;
    margin-bottom: 0.5rem;
  }

  .action-step-upload-error-modal .v--modal {
    height: auto;
    width: 75%;
  }

  .resolutions-list {
    overflow-y: auto;
    height: 320px;
  }

  .resolution-item {
    padding: 0.5rem 1rem;
    background-color: #F2F2F2;
    margin-bottom: 0.5rem;

    .resolution-title {
      font-weight: 600;
      font-size: 1.2rem;
      border-bottom: 2px solid $htss-purple;
    }

    .resolution-text {
      padding: 0.25rem;

    }
  }
</style>
