<template>
  <modal
    :name="modalName"
    class="group-membership-actions-modal"
    :scrollable="true"
    height="auto"
    @closed="destroyModal">
    <div class="close" @click="destroyModal" style="position: absolute; right: 18px; top: 8px; z-index: 999; background-color: white; padding: 4px; color: #767676; font-size: 22px; opacity: .75; border-radius: 4px;">
      <i class="fa fa-times"></i>
    </div>

    <div class="container pt-8">
      <template v-if="!givenUserIdOnly">
        <h2 class="pb-2 text-xl font-semibold text-center">
          <a :href="`/users/${user.id}`" target="_blank">{{ user.firstLastName }}</a>
        </h2>
        <div class="text-center border-b-2 border-gray-200 pb-2 mb-2">
          <span class="" v-if="user.phone_number">
            <i class="fa fa-phone"></i>
            <a :href="'tel:' + user.phone_number.replace(/[^0-9.]/g, '')">{{ user.formattedPhoneNumber }}</a>
          </span>

          <span class="pl-4">
            <i class="fa fa-envelope"></i>
            <a :href="'mailto:' + user.email">{{ user.email }}</a>
          </span>
        </div>
        <FormulateForm
          @submit="submitForm"
          ref="form"
          class="max-w-lg mx-auto"
          v-model="values"
          :schema="currentSchema"
        />
      </template>
    </div>
  </modal>
</template>

<script>

import EventBus from "@services/GlobalEventBus";

function generateHeaderSchema (title) {
  return {
    component: 'h3',
    class: 'font-bold text-center text-xl pb-4',
    children: title,
  }
}

// Action: Add Launcher Update
//   Note: Depending on a permission flag on the current user that does not yet exist, one of these fields will be shown
//   add_column :group_memberships, :internal_user_update, :string
//   add_column :group_memberships, :partner_user_update, :string

const addLauncherUpdateInternalSchema = [
  {
    component: 'div',
    class: '',
    children: [
      generateHeaderSchema('Add Launcher Update'),
      {
        name: 'internal_user_update',
        label: 'Launcher Update',
        class: 'input',
        type: 'textarea',
        rows: 5,
        "help-position": 'before',
        help: "What's going on with this launcher?"
      },
    ]
  }
]

const addLauncherUpdatePartnerSchema = [
  {
    component: 'div',
    class: '',
    children: [
      generateHeaderSchema('Add Launcher Update'),
      {
        name: 'partner_user_update',
        label: 'Launcher Update',
        class: 'input',
        type: 'textarea',
        "help-position": 'before',
        help: "What's going on with this launcher?"
      },
    ]
  },
]

const addPhoneCallSchema = [
  {
    component: 'div',
    class: '',
    children: [
      generateHeaderSchema('Add Call Notes'),
      {
        name: 'phone_call_type',
        label: 'Phone Call Type',
        class: 'input',
        type: 'select',
        options: [
          'General',
          'Coaching Call',
          'Goal Setting Call'
        ],
        validation: 'required',
        "validation-name": 'Phone Call Type'
      },
      {
        name: 'phone_call_course_phase',
        label: 'Which Phase?',
        class: 'input',
        type: 'select',
        // Options are overwritten in the data() method below
        options: [
          'Getting Started',
          'Phase 1',
          'Phase 2',
          'Phase 3',
          'Phase 4',
          'Phase 5',
          'Phase 6',
          'Phase 7',
        ],
        validation: 'required',
        "validation-name": 'Phone Call Phase'
      },
      {
        name: 'phone_call_date',
        label: 'Phone Call Date',
        class: 'input',
        type: 'text',
        id: 'phone_call_date_field',
        "help-position": 'before',
        help: "What date was the phone call made?",
        validation: 'required',
        'validation-name': 'Phone Call Date',
      },
      {
        name: 'phone_call_staff_name',
        label: 'Mentor/Coach/Staff Name',
        class: 'input',
        type: 'text',
        "help-position": 'before',
        help: "Who was the staff person, mentor or coach involved?",
        validation: 'required',
        'validation-name': 'Staff Name',
      },
      {
        name: 'phone_call_notes',
        label: 'Notes',
        class: 'input',
        type: 'textarea',
        rows: 5,
        validation: 'required',
        'validation-name': 'Phone Call Notes',
      },
    ]
  }
]

// Action: Mark Discontinued
//   add_column :group_memberships, :disengagement_category, :string
//   add_column :group_memberships, :disengagement_note, :string

const markDiscontinuedSchema = [
  {
    component: 'div',
    class: '',
    children: [
      generateHeaderSchema('Mark Discontinued'),
      {
        name: 'disengagement_category',
        label: 'Disengagement Category',
        class: 'input',
        type: 'select',
        options: [ //TODO: need to refactor this to fetch the options from /api/group_memberships_disengagement_categories so we have a single source of truth
          'Did Not Finish Getting Started',
          'Did Not Finish On Time',
          'Got a job/work',
          'Not enough time',
          'My idea is too far ahead',
          'The program made me realize my idea isn’t going to work',
          'Other',
        ],
        validation: 'required',
        "validation-name": 'Disengagement Category'
      },
      {
        name: 'disengagement_note',
        label: 'Disengagement Note',
        class: 'input',
        type: 'textarea',
        rows: 5,
        "help-position": 'before',
        help: "Why is this launcher being marked discontinued?",
        validation: 'required',
        "validation-name": 'Disengagement Note',
      },
    ]
  }
]

// Action: Record Assigned Mentor Group
//   add_column :group_memberships, :assigned_mentor_group, :string
//   add_column :group_memberships, :assigned_mentor_group_date, :date

const recordAssignedMentorGroupSchema = [
  {
    component: 'div',
    class: '',
    children: [
      generateHeaderSchema('Record Assigned Mentor Group'),
      {
        name: 'assigned_mentor_group',
        label: 'Assigned Mentor Group',
        class: 'input',
        type: 'text',
        "help-position": 'before',
        help: "Which mentor group was the launcher assigned to?",
        validation: 'required',
        'validation-name': 'Assigned Mentor Group',
      },
      {
        name: 'assigned_mentor_group_date',
        label: 'Assigned Mentor Group Date',
        class: 'input',
        type: 'text',
        id: 'assigned_mentor_group_date_field',
        // "input-class": 'datepicker border border-gray-400 rounded px-3 py-2 leading-none focus:border-blue-500 outline-none border-box w-full mb-1',
        "help-position": 'before',
        help: "What date was the assignment made?",
        validation: 'required',
        'validation-name': 'Assigned Mentor Group Date',
      },
    ]
  }
]

// Action: Add Disengagement Notes
//   add_column :group_memberships, :disengagement_status, :string
//   add_column :group_memberships, :disengagement_note_set_at, :timestamp (set by the controller action if disengagement_status changes)

const addDisengagementNotesSchema = [
  {
    component: 'div',
    class: '',
    children: [
      generateHeaderSchema('Add Disengagement Notes'),
      {
        name: 'disengagement_status',
        label: 'Disengagement Status',
        class: 'input',
        type: 'textarea',
        rows: 5,
        "help-position": 'before',
        help: "What did you learn about their possible disengagement?",
        validation: 'required',
        'validation-name': 'Disengagement Notes',
      },
    ]
  }
]

const updateUserPhoneNumberSchema = [
  {
    component: 'div',
    class: '',
    children: [
      generateHeaderSchema('Update Phone Number'),
      {
        name: 'phone_number',
        label: 'Starter Phone Number',
        class: 'input',
        type: 'text',
      },
    ]
  }
]

const commonSubmit = [
  {
    component: 'div',
    class: '',
    disabled: 'isLoading',
    children: [
      {
        "type": "submit",
        "label": "Submit",
        class: 'w-full'
      }
    ]
  },
]


  import { cloneDeep } from 'lodash-es'
  import { WindowVariables } from '@utils'
  import ModalMixin from '@mixins/ModalMixin.vue'
  import FlashMessages from '@services/FlashMessageService'
  import GroupMembership from '@dataServices/GroupMembership'
  import Users from '@dataServices/Users'
  import User from '@models/User'

  import flatpickr from 'flatpickr'

  const NAME = "group-membership-actions-modal"

  export default {
    name: NAME,
    mixins: [ ModalMixin ],
    components: {
    },
    data () {
      let schema = null
      let apiActionType = null
      if (this.params.actionType === 'addLauncherUpdateInternal') {
        schema = addLauncherUpdateInternalSchema
        apiActionType = 'add_internal_user_update'
      } else if (this.params.actionType === 'addLauncherUpdatePartner') {
        schema = addLauncherUpdatePartnerSchema
        apiActionType = 'add_partner_user_update'
      } else if (this.params.actionType === 'addCallNotes') {
        schema = addCallNotesSchema
        apiActionType = 'add_internal_phone_call_notes'
      } else if (this.params.actionType === 'markDiscontinued') {
        schema = markDiscontinuedSchema
        apiActionType = 'mark_discontinued'
      } else if (this.params.actionType === 'recordAssignedMentorGroup') {
        schema = recordAssignedMentorGroupSchema
        apiActionType = 'record_assigned_mentor_group'
      } else if (this.params.actionType === 'addDisengagementNotes') {
        schema = addDisengagementNotesSchema
        apiActionType = 'add_disengagement_notes'
      } else if (this.params.actionType === 'addPhoneCall') {
        schema = addPhoneCallSchema
        let toEdit = schema[0].children.find(c => c.name === 'phone_call_course_phase')
        if (toEdit && window.courseVars.group_sequences[this.params.data.groupId]) {
          toEdit.options = window.courseVars.group_sequences[this.params.data.groupId].map(s => s.sequence_name)
        }
        apiActionType = 'add_phone_call'
      } else if (this.params.actionType === 'updateUserPhoneNumber') {
        schema = updateUserPhoneNumberSchema
      }

      const combinedSchema = cloneDeep(schema)
      combinedSchema[0].children.push(commonSubmit[0])

      return {
        actionType: this.params.actionType,
        apiActionType: apiActionType,
        values: {
          ...this.params.actionType === 'updateUserPhoneNumber' && { phone_number: this.params.data.user.phone_number },
        },
        modalName: NAME,
        currentSchema: combinedSchema,
        gmUpdateInProgress: false,
        userUpdateInProgress: false,
        user: this.params.data.user,
        groupMembershipId: this.params.data.groupMembershipId,
      }
    },
    methods: {
      submitForm () {
        let prom = null
        if (this.actionType === 'updateUserPhoneNumber') {
          prom = this.updateUser()
        } else {
          prom = this.updateGm()
        }
        return prom
      },
      updateUser () {
        if (this.userUpdateInProgress) { return }
        this.userUpdateInProgress = true
        Users.update({
          id: this.user.id,
          phone_number: this.values.phone_number,
        })
        .then(u => {
          this.userUpdateInProgress = false
          FlashMessages.success('Update successful!')
          EventBus.dispatch(NAME, { update: { model: 'user', model_id: this.user.id }})

          this.destroyModal()
        })
        .catch(resp => {
          this.userUpdateInProgress = false
          FlashMessages.error('Had an issue updating...')
        })
      },
      updateGm () {
        if (this.gmUpdateInProgress) { return }

        this.gmUpdateInProgress = true
        GroupMembership.update({
          id: this.groupMembershipId,
          action_type: `group_membership:${this.apiActionType}`,
          ...this.values
        })
          .then(gm => {
            this.gmUpdateInProgress = false
            FlashMessages.success('Update successful!')
            EventBus.dispatch(NAME, { update: { model: 'group_membership', model_id: this.groupMembershipId }})
            this.destroyModal()
          })
          .catch(resp => {
            this.gmUpdateInProgress = false
            FlashMessages.error('Had an issue updating...')
          })
      },
    },
    computed: {
      givenUserIdOnly () {
        return typeof this.user !== 'object'
      },
    },
    created () {
      if (this.givenUserIdOnly) {
        Users.getById(this.user).then(u => { this.user = u })
      } else {
        if (!this.user._isModel) {
          this.user = new User(this.user)
        }
      }
    },
    mounted () {
      const intv1 = setInterval(() => {
        if (document.getElementById('assigned_mentor_group_date_field')) {
          flatpickr('#assigned_mentor_group_date_field')
          clearInterval(intv1)
        }
      }, 100)

      const intv2 = setInterval(() => {
        if (document.getElementById('phone_call_date_field')) {
          flatpickr('#phone_call_date_field')
          clearInterval(intv2)
        }
      }, 100)
    }
  }
</script>

<style lang="scss">
  .group-membership-actions-modal .v--modal {
    height: auto;
    width: 75%;
  }

  .input :is(textarea,select,input) {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding:  12px;
    width: full;
    margin-top: 4px;
  }
</style>
