<template>
  <v-container>
    <v-dialog v-model="showModal" max-width="500">
      <v-card>
        <v-card-title class="text-h5">Are you sure?</v-card-title>

        <v-card-text>
          Do you really want to remove this meeting?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="$emit('removeMeetingClick')">Remove Meeting</v-btn>
          <v-btn color="red darken-1" text @click="$emit('cancelClick')">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { VContainer, VSpacer, VDialog, VCard, VCardTitle, VCardText, VCardActions, VBtn} from 'vuetify/lib'


export default {
  components: {
    VContainer,
    VDialog,
    VSpacer,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VBtn,
  },
  props: {
    showModal: { type: Boolean, default: false }
  },
  data() {
    return {

    };
  },
  methods: {

  }
};
</script>

<style scoped lang="scss">

</style>