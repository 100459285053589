<template>
  <modal
      :name="modalName"
      class="course-management-update-module-modal"
      :scrollable="true"
      height="auto"
      @closed="close">
    <div class="close" @click="close">
      <i class="fa fa-times"></i>
    </div>

    <div class="modal-content">
      <h2 v-if="isUpdateMode">Update Module</h2>
      <h2 v-if="isCreateMode">Create Module</h2>
      <div>
        <v-alert v-model="alertVisible" type="error" dismissible>
          {{ errorMessage }}
        </v-alert>
      </div>

      <form @submit.prevent="submit" class="form">
        <div class="form-group">
          <label for="id">Id:</label>
          <input type="text" id="id" v-model="module.id" :disabled="true" class="disabled-input" />
        </div>

        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" id="name" v-model="module.name" />
        </div>

        <div class="form-group">
          <label for="analytics">Analytics Name:</label>
          <input type="text" id="analytics" v-model="module.analytics_name" :disabled="isUpdateMode" :class="{'disabled-input': isUpdateMode }" />
        </div>

        <button type="submit" class="submit-button">Submit</button>
      </form>
    </div>
  </modal>
</template>
<script>
  import ModalMixin from '@mixins/ModalMixin.vue'
  import axios from '@dataServices/axios'
  import EventBus from '@services/GlobalEventBus'

  import { VAlert } from 'vuetify/lib'

  const baseUrl = window.location.origin
  const NAME = 'course-management-update-module-modal'

  export default {
    name: NAME,
    mixins: [ModalMixin],
    components: {
      VAlert,
    },
    data() {
      return {
        modalName: NAME,
        courseId: this.params.course_id,
        moduleId: this.params.module_id,
        module: {
          id: null,
          name: '',
          analytics_name: '',
        },
        updated: false,
        alertVisible: false,
        errorMessage: '',
      }
    },
    computed: {
      isCreateMode () {
        return this.moduleId ? false : true
      },
      isUpdateMode () {
        return this.moduleId ? true : false
      },
    },
    mounted() {
      if (this.isUpdateMode){
        this.fetchModuleData()
      }
    },
    methods: {
      close() {
        const eventPayload = { type: 'closed', updated: this.updated}
        EventBus.dispatch(NAME, eventPayload)
        this.destroyModal()
      },
      fetchModuleData(){
        return axios
              .get(baseUrl + `/backoffice/api/modules/${this.moduleId}`)
              .then(response => this.processFetchModuleDataResponse(response))
              .catch(error => this.processResponseError(error))
              ;
      },
      createModule(){
        this.module.course_id = this.courseId;
        const request = new UpdateModuleRequest(this.module);
        return axios
            .post(baseUrl + `/backoffice/api/modules`, request)
            .then(response => this.processUpdateResponse(response))
            .catch(error => this.processResponseError(error))
            ;
      },
      updateModule(){
        const request = new UpdateModuleRequest(this.module)
        return axios
              .put(baseUrl + `/backoffice/api/modules/${this.moduleId}`, request)
              .then(response => this.processUpdateResponse(response))
              .catch(error => this.processResponseError(error))
              ;
      },
      processFetchModuleDataResponse (response) {
        this.module = response.data;
      },
      processUpdateResponse (response) {
        this.updated = true;
        this.module = response.data;
        this.close();
      },
      processResponseError (error) {
        this.errorMessage = error.response? error.response.data.message : error.message;
        this.alertVisible = true;
        console.error('Backend Error:', this.errorMessage, error.response?.data, error.response?.status);
      },
      submit () {
        if (this.isCreateMode) {
          this.createModule();
        } else {
          this.updateModule();
        }
      },
    },
  }

  class UpdateModuleRequest {
    constructor (module) {
      this.module = module;
    }
  }
</script>

<style lang="scss">
.course-management-update-module-modal {

  .close {
    position: absolute;
    right: 18px;
    top: 8px;
    z-index: 999;
    background-color: white;
    padding: 4px;
    color: #767676;
    font-size: 22px;
    opacity: .75;
    border-radius: 4px;
    cursor: pointer;
  }

  .modal-content {
    padding: 20px;
  }

  .form {
    .form-group {
      display: flex;
      align-items: center;

      label {
        width: 100px;
        text-align: right;
        margin-right: 10px;
      }

      input {
        flex-grow: 1;
      }
    }

    .disabled-input {
      background-color: #e9ecef;
      color: #495057;
      cursor: not-allowed;
    }

    .submit-button {
      background-color: #007bff;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  .v-alert {
    border-radius: 8px;
    padding: 16px;

    &.error {
      background-color: #ffebee; // Light Red
      color: #c62828; // Dark Red
      border: 1px solid #ef9a9a; // Red

      .v-icon {
        color: #c62828; // Dark Red
      }
    }

    &.success {
      background-color: #e8f5e9; // Light Green
      color: #2e7d32; // Dark Green
      border: 1px solid #a5d6a7; // Green

      .v-icon {
        color: #2e7d32; // Dark Green
      }
    }
  }
}

</style>
