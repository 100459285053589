<template>
  <div class="direct-messaging-avatar-container">
    <v-avatar :color="avatarColor(name)" :size="size" class="user-picture">
      {{ name.charAt(0).toUpperCase() }}
    </v-avatar>
    <span class="user-count"
          v-if="userCount"
          :style="{ width: badgeSize + 'px', height: badgeSize + 'px', fontSize: badgeFontSize + 'px' }">
      {{ userCount }}
    </span>
    <div class="online-status"
         :style="{ width: badgeSize + 'px', height: badgeSize + 'px', backgroundColor: onlineStatusColor }"
         v-if="shouldRenderUserOnlineStatusBadge"></div>
  </div>
</template>

<script>
import { VAvatar } from 'vuetify/lib'


export default {
  name: 'direct-messaging-avatar',
  components: {
    VAvatar,
  },
  props: {
    name: { type: String, required: true },
    size: { type: Number, default: 48 },
    fontSize: { type: Number, default: 20},
    userCount: { type: Number, required: false },
    userOnlineStatus: { type: Boolean, default: null, required: false },
  },
  data: () => ({

  }),
  mounted () {
    this.updateFontSize(this.fontSize);
  },
  destroy() {

  },
  computed: {
    badgeSize() {
      return Math.max(14, this.size * 0.3); // Badge size is 30% of the avatar size
    },
    badgeFontSize() {
      return Math.max(9, this.size * 0.15); // Font size is 15% of the avatar size
    },
    onlineStatusColor() {
      return this.userOnlineStatus ? '#90c73f' : '#e63450'; // Green for online, red for offline
    },
    shouldRenderUserOnlineStatusBadge(){
      return this.userOnlineStatus === true || this.userOnlineStatus === false;
    }
  },
  methods: {
    avatarColor(name) {
      // Simple hashing for deterministic color selection
      let hash = 0;
      for (let i = 0; i < name.length; i++) {
        hash = (hash << 5) - hash + name.charCodeAt(i);
        hash |= 0; // Convert to 32bit integer
      }
      const hue = Math.abs(hash) % 360;
      return `hsl(${hue}, 60%, 65%)`; // HSL color based on the hash
    },
    updateFontSize(fontSize) {
      this.$el.style.setProperty('--avatar-font-size', fontSize+'px');
    }
  },
  watch: {
    fontSize(newSize) {
      this.updateFontSize(newSize);
    }
  }

}

</script>

<style lang="scss" scoped>
.direct-messaging-avatar-container {
  position: relative;

  .user-picture {
    border-radius: 50%;
    font-size: var(--avatar-font-size, 20px);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user-count {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(15%, 2%);
  }

  .online-status {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid white;
    transform: translate(15%, 2%);
  }
}


</style>
