<template>
  <div class="interaction-eventMessage">
    <div class="interaction-event-row">
      <UserThumbnail :userId="event.value.sentBy.id"/>
      <div class="content">
        <div class="sent-info">
          <span class="sent-by">{{ event.value.sentByName }}</span>
          <span class="sent-at">{{ event.value.sentTimeFormatted }}</span>
        </div>
        <span v-html="event.value.body"></span>
      </div>
    </div>
  </div>
</template>

<script>
import UserThumbnail from '@components/general/UserThumbnail.vue'

  export default {
    name: 'interaction-eventMessage',
    components: {
      UserThumbnail,
    },
    props: {
      event: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .interaction-event-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .interaction-eventMessage {
    margin-bottom: 16px;
    background-color: #fff;

    .content {
      color: white;
      background-color: #573F7E;
      border-radius: 0px 16px 16px 16px;
      padding: 12px;
      display: flex;
      flex-wrap: wrap;
      max-width: 330px;
      min-width: 64px;
      margin-left: 8px;
      font-size: 1rem;
      line-height: 24px;
      text-align: left;

      span {
        width: 100%;
        word-wrap: break-word;
      }
    }

    .sent-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: center;
      width: 100%;
      margin-bottom: 4px;
    }

    .sent-by {
      font-weight: 600;
      font-size: .875rem;
      color: #DDD8E5;
      line-height: 16px;
      display: inline-block;
      text-align: left;
    }

    .sent-at {
      color: #DDD8E5;
      font-size: .75rem;
      text-align: right;
    }
  }
</style>
