<template>
  <div class="interaction-review-event">
    <div>
      <toggle-switch
        :options="myOptions"
        v-model="status"
        :disabled="inFlight"
        name="statusSwitch" />
    </div>
    <textarea :placeholder="this.$t('application.interaction_timeline.review_event.feedback')" v-model="message" :disabled="inFlight"/>
    <div>
      <button class="cancel" @click="cancel" :disabled="inFlight">{{ $t('application.common.cancel') }}</button>
      <button class="submit" @click="create" :disabled="inFlight">{{ $t('application.common.submit') }}</button>
    </div>
  </div>
</template>

<script>
  import ToggleSwitch from '@components/general/ToggleSwitch.vue'

  import { getCourseStepReviewsStoreFor } from '@stores/CourseStepReviewsStore'
  import FlashMessages from '@services/FlashMessageService'
  import { courseStepReviewStatusNumerical } from '@utils'

  export default {
    name: 'interaction-review-event',
    components: {
      ToggleSwitch,
    },
    data () {
      return {
        courseStepReviewStore: getCourseStepReviewsStoreFor(this.studentStepProgress._courseStepReviewsStoreUuid),
        status: false,
        userId: this.studentStepProgress.userId,
        message: '',
        courseStepId: this.studentStepProgress.courseStepId,
        groupId: this.studentStepProgress.groupId,
        inFlight: false,
        status: this.$t('application.common.attended'),
        myOptions: {
          layout: {
            color: '#333333',
            backgroundColor: 'lightgray',
            selectedColor: 'white',
            selectedBackgroundColor: '#3e5167',
            borderColor: '#999999',
            fontWeightSelected: 'bold',
            squareCorners: false,
            noBorder: false,
          },
          size: {
            fontSize: 0.8,
            height: 3,
            padding: 0.25,
            width: 24,
          },
          config: {
            preSelected: 'Attended',
            disabled: false,
            items: [
              // This field is unable to be translated right now. The package we
              // are using for the toggle-switch only has a "label" field which serves
              // as the displayed label AS WELL AS the returned value when selected.
              // Meaning that when we use the returned value in the "courseStepReviewStatusNumerical"
              // method below (to convert the returned value into a numerical value 0, 1, or 2),
              // we have to always have the same strings to match on.
              { name: this.$t('application.common.under_review'), value: 'Under Review', },
              { name: this.$t('application.common.questions_remaining'), value: 'Questions Remaining',  },
              { name: this.$t('application.common.attended'), value: 'Attended',  },
            ],
          },
        },
      }
    },
    methods: {
      create () {
        this.inFlight = true
        this.courseStepReviewStore.create({
          course_step_review: {
            status: courseStepReviewStatusNumerical('event', this.status),
            user_id: this.userId,
            message: this.message,
            course_step_id: this.courseStepId,
            group_id: this.groupId,
          }
        })
        .then(res => {
          this.inFlight = false
          FlashMessages.success(this.$t('application.interaction_timeline.review_event.flash.submit_success'))
          this.message = ''
          this.$emit('reviewSubmitted', res)
        })
        .catch(err => {
          this.inFlight = false
          FlashMessages.error(this.$t('application.interaction_timeline.review_event.flash.submit_error'))
        })
      },
      cancel (evt) {
        this.$emit('cancel', evt)
      },
    },
    props: {
      studentStepProgress: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style lang="scss">
.interaction-review-event {
  .toggle-switch {
    [type='radio'] {
      border-radius: initial;
      width: initial;
      height: initial;
    }

    li:first-of-type, li:last-of-type {
      label {
        line-height: 3.2;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.interaction-review-event {
  display: flex;
  flex-direction: column;
  align-items: center;

  textarea {
    flex: 1 1 auto;
    height: 120px;
    width: 100%;
    border: 1px solid #e8e8e8;
  }

  button {
      color: #fff;
      padding: 12px 24px !important;
      border-radius: 5px;
      cursor: pointer;
      margin-top: 8px;

      &.submit {
        background-color: #f3763a;
        border: 1px solid #f3763a;

        &:hover {
          background-color: #e6692e;
        }
      }

      &.cancel {
        background-color: #868e96;
        border: 1px solid #868e96;

        &:hover {
          background-color: #959da5;
        }
      }

      transition: all 200ms ease;
    }
}
</style>
