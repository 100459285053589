export default {
  classes: {
    outer: (context) => {
      if (context.type === 'checkbox') {
        return (context.isGrouped ? "mb-2" : "mb-8") + ' leading-loose text-left'
      }
      return (context.isGrouped ? "mb-2 leading-loose" : "mb-4") + ' text-left'
    },
    // groupRepeatable: 'bg-gray-200',
    // groupRepeatableRemove: 'px-4 py-2 rounded bg-red-500 text-white hover:bg-red-600',
    label: () => {
      return 'font-semibold text-lg grow mb-0'
    },
    help: 'text-xs mb-1 text-gray-600',
    error: 'text-red-700 text-xs mb-1',



    wrapper: ({ classification }) => {
      switch (classification) {
        case "box":
          return "flex";
        default:
          return "";
      }
    },
    element: ({ classification, hasValue }) => {
      switch (classification) {
        case "group":
          return "mt-2 pl-2"
        case "select":
          return !hasValue ? "text-gray-500 font-light" : ""
        default:
          return ""
      }
    },
    input: ({ classification, label }) => {
      switch (classification) {
        case "button":
          if (label && label.includes('Add')) {
            return 'cursor-pointer transition-all btn btn-primary w-full'
          } else {
            return 'cursor-pointer transition-all btn btn-primary w-full'
          }
        case "group":
          return ""
        case "box":
          return "sr-only"
        default:
          return 'border border-gray-400 rounded px-3 py-2 leading-none focus:border-blue-500 outline-none border-box w-full mb-1'
      }
    },
    decorator: ({ hasValue }) => {
      let base = "border rounded border-black inline-block w-4 h-4 mr-2"
      if (hasValue) {
        base += " bg-blue-500"
      }
      return base;
    },
  },
  rules: {
  }
}