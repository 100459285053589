<template>
  <modal
      :name="modalName"
      class="course-management-reorder-modal"
      :scrollable="true"
      height="auto"
      @closed="close">
    <div class="close" @click="close">
      <i class="fa fa-times"></i>
    </div>

    <div class="modal-content">
      <h2>Reorder Items</h2>
      <div>
        <v-alert v-model="alertVisible" type="error" dismissible>
          {{ errorMessage }}
        </v-alert>
      </div>
      <div class="draggable-wrapper">
        <draggable v-model="items" class="draggable-list">
          <div v-for="item in items" :key="item.id" class="draggable-item">
            {{ item.name }}
          </div>
        </draggable>
      </div>

      <button @click="updateItemsOrder" class="submit-button">Apply Reordering</button>

    </div>
  </modal>
</template>
<script>
  import draggable from "vuedraggable";
  import ModalMixin from '@mixins/ModalMixin.vue'
  import axios from '@dataServices/axios'
  import EventBus from '@services/GlobalEventBus'

  import { VAlert } from 'vuetify/lib'

  const baseUrl = window.location.origin
  const NAME = 'course-management-reorder-modal'

  export default {
    name: NAME,
    mixins: [ModalMixin],
    components: {
      draggable,
    },
    data() {
      return {
        modalName: NAME,
        courseId: this.params.courseId,
        moduleId: this.params.moduleId,
        stepId: this.params.stepId,
        items: [],
        updated: false,
        alertVisible: false,
        errorMessage: '',
      }
    },
    computed: {
      isModule() {
        return this.courseId != null
            && this.moduleId == null
            && this.stepId == null;
      },
      isStep() {
        return this.courseId != null
            && this.moduleId != null
            && this.stepId == null;
      },
      isElement() {
        return this.courseId != null
            && this.moduleId != null
            && this.stepId != null;
      },
      uri() {
        return this.isModule? `/backoffice/api/courses/${this.courseId}/modules`:
               this.isStep? `/backoffice/api/modules/${this.moduleId}/steps`:
                            `/backoffice/api/steps/${this.stepId}/elements`;
      },
    },
    mounted() {
      this.fetchItems();
    },
    methods: {
      close() {
        const eventPayload = { type: 'closed', updated: this.updated}
        EventBus.dispatch(NAME, eventPayload)
        this.destroyModal()
      },
      fetchItems(){
        const request = new FetchItemsRequest(this.parentType)
        const uri = this.uri;
        return axios
              .get(baseUrl + uri, request)
              .then(response => this.processFetchItemsResponse(response))
              .catch(error => this.processResponseError(error))
              ;
      },
      updateItemsOrder(){
        const request = new UpdateItemsOrder(this.parentId, this.parentType, this.items)
        const uri = this.uri;
        return axios
              .put(baseUrl + uri, request)
              .then(response => this.processUpdateItemsResponse(response))
              .catch(error => this.processResponseError(error))
              ;
      },
      processFetchItemsResponse (response) {
        this.items = response.data;
      },
      processUpdateItemsResponse (response) {
        this.updated = true;
        this.items = response.data.records;
        this.close();
      },
      processResponseError (error) {
        this.errorMessage = error.response? error.response.data.message : error.message;
        this.alertVisible = true;
        console.error('Backend Error:', this.errorMessage, error.response?.data, error.response?.status);
      },
    },
  }

  class FetchItemsRequest {
    constructor (parentType) {
      this.params = {
        parent_type: parentType,
      }
    }
  }

  class UpdateItemsOrder {
    constructor (parentId, parentType, items) {
      this.parent_id = parentId;
      this.parent_type = parentType;
      this.items_ids = items.map((item) =>  item.id );
    }

  }

</script>

<style lang="scss">
.course-management-reorder-modal {

  .close {
    position: absolute;
    right: 18px;
    top: 8px;
    z-index: 999;
    background-color: white;
    padding: 4px;
    color: #767676;
    font-size: 22px;
    opacity: .75;
    border-radius: 4px;
    cursor: pointer;
  }

  .modal-content {
    padding: 20px;
  }

  .draggable-wrapper {
    padding: 10px 0; // Vertical padding around the draggable list
    margin-bottom: 20px; // Space between the draggable list and the button
  }

  .draggable-list {
    border: 1px solid #e0e0e0; // Border around the draggable list
    border-radius: 4px; // Rounded corners
    overflow: hidden; // To ensure child item borders don't protrude
  }

  .draggable-item {
    padding: 10px 15px; // Padding for each draggable item
    border-bottom: 1px solid #e0e0e0; // Border between items
    cursor: grab; // Cursor style indicating the item can be dragged

    &:last-child {
      border-bottom: none; // Remove border for the last item
    }

    &:hover {
      background-color: #f5f5f5; // Slight grey background on hover for better UX
    }

    &:active {
      cursor: grabbing; // Cursor style when the item is being dragged
    }
  }

  .submit-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }

  .v-alert {
    border-radius: 8px;
    padding: 16px;

    &.error {
      background-color: #ffebee; // Light Red
      color: #c62828; // Dark Red
      border: 1px solid #ef9a9a; // Red

      .v-icon {
        color: #c62828; // Dark Red
      }
    }

    &.success {
      background-color: #e8f5e9; // Light Green
      color: #2e7d32; // Dark Green
      border: 1px solid #a5d6a7; // Green

      .v-icon {
        color: #2e7d32; // Dark Green
      }
    }
  }

}

</style>
