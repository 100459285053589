<template>
  <div class="gradebook-totals-item">
    <div class="points">
      {{ totals.points }}
    </div>
    <div class="percentage">{{ totals.percentage }}%</div>
  </div>
</template>

<script>
  import { filter, find, first, orderBy, sumBy } from 'lodash-es'
  import GradebookService from '@services/gradebookManager/Service'

  export default {
    name: 'starter-tracking-totals-item',
    data () {
      return {
        gradebookService: GradebookService,
      }
    },
    fromMobx: {
      grades () {
        return filter(this.gradebookService.allAssignmentResponseGrades, {
          group_id: this.groupId,
          user_id: this.userId
        })
      },
      assignments () {
        return this.gradebookService.allAssignments
      },
      assignmentResponses () {
        return filter(this.gradebookService.allAssignmentResponses, { user_id: this.userId })
      }
    },
    computed: {
      totals: function() {
        let pointsEarned = sumBy(this.assignmentResponses, (ar) => {
          const grade = first(orderBy(filter(this.grades, { assignment_response_id: ar.id }), ['id'], ['desc']))

          if (grade) {
            const assignment = find(this.assignments, { id: ar.assignment_id })
            if (assignment) {
              return assignment.point_weighting * grade.points
            }
          }
          return 0
        })

        let pointsPossible = sumBy(this.assignments, 'weighted_points')
        if (pointsPossible === 0) {
          return {
            points: pointsEarned,
            percentage: ''
          }
        } else {
          return {
            points: pointsEarned,
            percentage: Math.round(pointsEarned/pointsPossible * 100)
          }
        }
      }
    },
    props: {
      groupId: {
        type: Number,
        required: true
      },
      userId: {
        type: Number,
        required: true
      }
    }
  }

</script>

<style lang="scss" scoped>
.gradebook-totals-item {
  flex: 0 0 70px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #cccccc;
  height: 64px;
  line-height: 30px;
}

  .points, .percentage {
    flex: 0 0 64px;
    text-align: center;
    border-left: 1px solid #e8e8e8;
  }

  .inner {
    padding-right: 5px;
  }

</style>