import StoreInstances, { getStoreFor } from '@stores/StoreInstances'
import BaseArrayClass from '@stores/BaseArrayStore'
import { action, makeObservable } from 'mobx'
import DataService from '@dataServices/Assignments'

const STORE_TYPE = 'Assignments'

export function getAssignmentsStoreFor (param) {
  return getStoreFor(AssignmentsStore, STORE_TYPE, param)
}

export class AssignmentsStore extends BaseArrayClass {
  constructor () {
    super(STORE_TYPE)

    makeObservable(this, {
      _fetchOne: action,
      _fetchAll: action,
    })
  }

  _fetchOne() {
    return DataService.getById({ id: this._filter.id })
  }

  _fetchAll() {
    let payload = {
      ...this._filter,
    }
    return DataService.getAll(payload)
  }

  // @action
  // update (record) {
  //   let payload = {
  //     ...record,
  //   }
  //   return DataService.update(payload)
  //     .then(rec => StoreInstances._propagateChanges(STORE_TYPE, [ rec ]))
  // }

  // @action
  // create (record) {
  //   let payload = {
  //     ...record,
  //   }
  //   return DataService.create(payload)
  //     .then(rec => StoreInstances._propagateChanges(STORE_TYPE, [ rec ]))
  // }
}
