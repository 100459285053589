import { map } from 'lodash-es'
import axios from './axios'
import Assignment from '@models/Assignment'

const baseUrlString = '/assignments'

async function getAll (params) {
  return axios.get(baseUrlString, { params })
    .then((response) => {
      return map(response.data, assignment => initWithData(assignment) )
    })
    .catch(console.error)
}

async function getById (id) {
  return axios.get(`${baseUrlString}/${id}`)
    .then((response) => { return initWithData(response.data) })
    .catch(console.error)
}

function initWithData (data) {
  return new Assignment(data)
}

export default {
  getAll,
  getById,
  initWithData
}
