<template>
  <div>
    <h2 class="pb-2 text-xl font-semibold text-center">
      Multiple Launchers Selected {{ ids.length }} item{{ ids.length > 1 ? 's' : '' }}
    </h2>

    <FormulateForm
      @submit="submitForm"
      ref="form"
      class="max-w-lg mx-auto"
      v-model="values"
      :schema="currentSchema"
    />
  </div>
</template>
<script>
  import { getTasksStoreFor } from '@stores/TasksStore';
  import FlashMessages from '@services/FlashMessageService';
  import EventBus from '@services/GlobalEventBus';
  import axios from "@dataServices/axios";

  const NAME = "bulk-manual-engagement-form"
  const baseUrl = window.location.origin

  export default {
    name: NAME,
    mixins: [],
    components: {},
    props: ['ids'],
    data() {
      return {
        coachOptions: [],
        values: {
          coach_responsible_user_id: null,
        },
        tasksStore: getTasksStoreFor(),
      }
    },
    async created() {
      await this.fetchCoaches()
    },
    methods: {
      async fetchCoaches () {
        try {
          const response = await axios.get(baseUrl + '/api/coaching/task_management/coaches', {params: {per_page: 100}}) //defining a high perPage number for now. If necessary in the future, implement recursive fetching later to fetch everyone
          this.coachOptions = response.data.records.map(coach => ({value: coach.id, label: coach.name}));
        } catch (error) {
          console.error("Failed to fetch coaches:", error);
          FlashMessages.error("Failed to fetch coaches! " + error); //it's ok to show the real error because the coaches are the users
        }
      },
      async submitForm () {
        try {
          const request = {
            create_manual_engagement_request: {
              ...this.values,
              student_group_membership_ids: this.ids
            }
          }

          const response = axios.post(baseUrl + `/api/coaching/task_management/manual_engagement`, request)
          const tasks = response.data

          FlashMessages.success(this.$t('application.tasks.flash.task_completion_success'))
          EventBus.dispatch('refresh-task-list', tasks)
          this.$emit('resetBulkActionIds');
          this.$emit('cancel');
        } catch (error) {
          console.warn(error)
          FlashMessages.error(this.$t('application.tasks.flash.task_completion_error'))
        }
      }
    },
    computed: {
      currentSchema() {
        return [
          {
            component: 'h3',
            class: 'font-bold text-center text-xl pb-4',
            children: "Bulk Manual Engagement Request",
          },
          {
            type: 'select',
            label: 'Coach Responsible',
            name: 'coach_responsible_user_id',
            options: this.coachOptions,
            placeholder: 'Select a coach...'
          },
          {
            type: 'textarea',
            label: 'Engagement Request Description',
            name: 'message',
            placeholder: 'Enter the description here...',
            validation: 'required'
          },
          {
            type: 'submit',
            label: 'Submit'
          }
        ]
      },
    }
  }
</script>