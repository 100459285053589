<template>
  <div class="flex">
    <div v-if="!allItemsShown"
      :class="{ 'w-6': mobileView, 'w-8': !mobileView, 'rounded-l-md': !disableRoundedButtons }"
      class="h-auto flex-none bg-gray-200 hover:bg-gray-300 flex items-center justify-center text-lg text-gray-400 hover:text-gray-500 cursor-pointer"
      @click="prevPage"
    >
      <i class="fa fa-chevron-left" :class="mobileView || 'text-3xl'"></i>
    </div>
    <ul ref="content-list" role="list" class="flex overflow-x-hidden flex-auto">
      <li v-for="d in data" :key="d.id" class="flex-none mb-0" :style="thumbnailStyles">
        <div class="relative group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
          <img :src="d.presigned_thumbnail_url" alt="" class="object-cover pointer-events-none group-hover:opacity-75">
          <button type="button" class="absolute inset-0 focus:outline-none" @click="onClick(d)"></button>
          <div class="absolute top-0 left-0 right-full bottom-full flex items-center justify-center pointer-events-none">
            <div class="text-white opacity-70" style="width:30px;height:30px;"><svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg></div>
          </div>
        </div>
        <p class="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none text-ellipsis whitespace-nowrap overflow-hidden">{{ d.name }}</p>
        <p class="block text-sm font-medium text-gray-500 pointer-events-none text-ellipsis whitespace-nowrap overflow-hidden">{{ d.description_shortened }}</p>
      </li>
    </ul>
    <div v-if="!allItemsShown"
      :class="{ 'w-6': mobileView, 'w-8': !mobileView, 'rounded-r-md': !disableRoundedButtons }"
      class="h-auto flex-none bg-gray-200 hover:bg-gray-300 flex items-center justify-center text-lg text-gray-400 hover:text-gray-500 cursor-pointer"
      @click="nextPage"
    >
      <i class="fa fa-chevron-right" :class="mobileView || 'text-3xl'"></i>
    </div>
  </div>
</template>

<script>
const DEFAULT_SETTINGS = {
  MIN_THUMBNAIL_WIDTH: 170,
  MAX_THUMBNAIL_WIDTH: 210,
  MOBILE_MIN_THUMBNAIL_WIDTH: 150,
  MOBILE_MAX_THUMBNAIL_WIDTH: 200,
  MINIMUM_THUMBNAIL_COUNT: 2,
  THUMBNAIL_PADDING: 10,
}

export default {
  name: 'media-slider',
  props: {
    data: {
      type: Array,
      required: true,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      ro: null,
      dimensions: {},
      actualNumThumbnailsShown: this.configuredNumThumbnailsShown,
    }
  },
  computed: {
    itemCount () {
      return this.data.length
    },
    disableRoundedButtons () {
      return this.config.disableRoundedButtons || false
    },
    numItemsCurrentlyShowing () {
      return Math.floor(this.width / this.widthOfThumbnails)
    },
    mobileView () {
      return this.width < 600
    },
    minThumbnailWidth () {
      if (this.mobileView) {
        return this.config.minMobileThumbnailWidth || DEFAULT_SETTINGS.MOBILE_MIN_THUMBNAIL_WIDTH
      } else {
        return this.config.minThumbnailWidth || DEFAULT_SETTINGS.MIN_THUMBNAIL_WIDTH
      }
    },
    maxThumbnailWidth () {
      if (this.mobileView) {
        return this.config.maxMobileThumbnailWidth || DEFAULT_SETTINGS.MOBILE_MAX_THUMBNAIL_WIDTH
      } else {
        return this.config.maxThumbnailWidth || DEFAULT_SETTINGS.MAX_THUMBNAIL_WIDTH
      }
    },
    thumbnailPadding () {
      return this.config.thumbnailPadding || DEFAULT_SETTINGS.THUMBNAIL_PADDING
    },
    minimumThumbnailCount () {
      return this.config.minimumThumbnailCount || DEFAULT_SETTINGS.MINIMUM_THUMBNAIL_COUNT
    },
    allItemsShown () {
      return this.numItemsCurrentlyShowing >= this.itemCount
    },
    width () {
      return this.dimensions.width
    },
    height () {
      return this.dimensions.height
    },
    widthOfThumbnails () {
      const minWidth = this.minThumbnailWidth
      const maxWidth = this.maxThumbnailWidth

      const countAtMin = Math.floor(this.width / minWidth)
      const countAtMax = Math.floor(this.width / maxWidth)

      // Try to show as many as possible in the general case
      let result = Math.floor(this.width / countAtMin)

      if (countAtMax < this.minimumThumbnailCount) {
        result = Math.floor(this.width / this.minimumThumbnailCount)
      } else if (result < minWidth) {
        result = this.width / (countAtMin - 1)
      } else if (result > maxWidth) {
        result = this.width / (countAtMax + 1)
      }
      return result
    },
    thumbnailStyles () {
      return {
        width: this.widthOfThumbnails + 'px',
        padding: this.thumbnailPadding + 'px',
      }
    },
  },
  methods: {
    onClick (d) {
      this.$emit('selected', d)
    },
    onResize (evt) {
      this.dimensions = {
        width: parseInt(evt[0].contentRect.width),
        height: parseInt(evt[0].contentRect.height),
      }
    },
    nextPage () {
      const el = this.$refs['content-list']
      el.scroll({
        left: el.scrollLeft + this.width,
        behavior: 'smooth',
      })
    },
    prevPage () {
      const el = this.$refs['content-list']
      el.scroll({
        left: el.scrollLeft - this.width,
        behavior: 'smooth',
      })
    },
  },
  mounted () {
    this.ro = new ResizeObserver(this.onResize)
      .observe(this.$refs['content-list'])
  },
  beforeDestroy () {
    if (this.ro) {
      this.ro.unobserve(this.$refs['content-list'])
    }
  },
}
</script>
