<template>
  <div class="starter-table-cell" @click="open()" :class="vm.classNames">
    <div class="inner-container"></div>
  </div>
</template>

<script>
  import ModalService from '@services/ModalService'
  import TimelineServiceManager from '@services/timelineService/ServiceManager'
  import { WindowVariables } from '@utils'
  import { observable, runInAction, computed, action, makeObservable } from 'mobx'
  import { observer } from 'mobx-vue'

  class ViewModel {
    timelineService = null
    userId = null
    courseStepId = null
    groupId = null
    servicePreset = null
    sspObs = null
    ssp = null

    constructor(params) {
      makeObservable(this, {
        // ssp: observable,
        studentStepProgress: computed,
        courseStep: computed,
        sspStatuses: computed,
        sspMessages: computed,
        classNames: computed,
        hasUnreadMessage: computed,
        hasReplied: computed,
      })

      this.courseStepId = params.courseStepId
      this.groupId = params.groupId
      this.userId = params.userId
      this.servicePreset = params.servicePreset
      this.sspObs = observable.box(null)

      const serviceFilter = this.servicePreset || { groupId: this.groupId }
      this.timelineService = TimelineServiceManager.getServiceFor(`starter-table-cell-${this.groupId}-${this.userId}-${this.courseStepId}`, serviceFilter),

      this.timelineService.registerDataReadyFn(() => {
        const res = this.timelineService.getStepProgress(this.userId, this.courseStepId)
        this.sspObs.set(res)
        return !!res
      })
    }

    get studentStepProgress () {
      return this.sspObs.get()
    }

    get courseStep () {
      return this.studentStepProgress && this.studentStepProgress.courseStep
    }

    get sspStatuses () {
      return this.studentStepProgress && this.studentStepProgress.statuses
    }

    get sspMessages () {
      return (this.studentStepProgress && this.studentStepProgress.messages) || []
    }

    get classNames() {
      if (!this.sspStatuses || !this.courseStep) {
        return {}
      }

      return {
        'course-step-benchmark': this.courseStep.isBenchmark,
        'course-step-step': this.courseStep.isStep,
        'course-step-event': this.courseStep.isEvent,
        'course-step-survey': this.courseStep.isSurvey,
        'course-step-step-no-action-step': this.courseStep.isStep && !this.courseStep.hasAssignment,

        'has-attachments': this.sspStatuses.hasAttachments,
        'has-uploads': this.sspStatuses.hasUploads,
        'no-uploads': !this.sspStatuses.hasUploads,
        'has-uploads-or-attachments': this.sspStatuses.hasUploadsOrAttachments,
        'locked': this.sspStatuses.locked,
        'unlocked': this.sspStatuses.unlocked,
        'completed': this.sspStatuses.completed,
        'work-required': this.sspStatuses.workRequired && !(this.sspStatuses.needToReviewAttachment || this.sspStatuses.workRequiredWithNewUpload),
        'not-yet-reviewed': this.sspStatuses.notYetReviewed,
        'under-review': this.sspStatuses.isUnderReview,
        'work-required-with-new-upload': this.sspStatuses.workRequiredWithNewUpload,

        'has-unread-message': this.hasUnreadMessage,
        'message-read-and-responded': this.hasReplied,

        'event-or-benchmark-attachment-uploaded': this.sspStatuses.needToReviewAttachment
      }
    }

    get hasUnreadMessage () {
      if (this.sspMessages.length === 0) {
        return false
      }
      return this.studentStepProgress.conversation.conversationWaitingForCurrentUser
        && this.studentStepProgress.conversation.isOpened
    }

    get hasReplied () {
      if (this.sspMessages.length === 0) {
        return false
      }
      return !this.studentStepProgress.conversation.conversationWaitingForCurrentUser
        && this.studentStepProgress.conversation.isOpened
    }
  }

  export default observer({
    name: 'starter-table-cell',
    data () {
      return {
        vm: new ViewModel({
          groupId: this.groupId,
          userId: this.userId,
          courseStepId: this.courseStepId,
          servicePreset: this.servicePreset,
        }),
      }
    },
    methods: {
      open () {
        if (
          !this.vm.sspStatuses.locked && WindowVariables.currentUser.role === 'student' ||
          WindowVariables.currentUser.role !== 'student'
        ){
          ModalService.open('interaction-timeline-modal', {
            groupId: this.groupId,
            userId: this.userId,
            courseStepId: this.courseStepId,
            servicePreset: this.servicePreset,
          })
        }
      },
    },
    beforeDestroy () {
      TimelineServiceManager.deregister(this.vm.timelineService, `starter-table-cell-${this.groupId}-${this.userId}-${this.courseStepId}`)
    },
    props: {
      groupId: {
        type: Number,
        required: true,
      },
      courseStepId: {
        type: Number,
        required: true,
      },
      userId: {
        type: Number,
        required: true,
      },
      servicePreset: {
        required: false,
        default: null
      },
    }
  })
</script>

<style lang="scss" scoped>
%common-bg {
  height: 64px;
  width: 64px;
  background-repeat: no-repeat;
}

%common-before-after {
  content: "";
  background-repeat: no-repeat;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
}

.starter-table-cell {
  flex: 0 0 64px;
  border-left: 1px solid #888;
  border-top: 1px solid #888;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease;
  padding: 6px;

  @extend %common-bg;

  &:hover {
    box-shadow: 0px 2px 8px #c8ccd5;
  }

  /* COURSE STEP */

  &.course-step-step-no-action-step {
    background-color: #ececec !important;
  }

  /* Incomplete (No action steps uploaded, and no review made) */
  &.unlocked.no-uploads.not-yet-reviewed {
    &.course-step-step,
    &.course-step-event {
      background: white; // this is the same thing as the SVG below
    }
  }

  /* Uploaded (action step uploaded, no review made  */
  &.has-uploads.not-yet-reviewed {
    background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='64' height='64' fill='white'/%3E%3Crect y='32' width='64' height='32' fill='white'/%3E%3Crect width='64' height='32' fill='%237FC29B'/%3E%3Cpath d='M4.79038 10.0444C5.06529 10.2789 5.47766 10.2554 5.75258 10.0444L8.91409 7.20687V15.4372C8.91409 15.7655 9.189 16 9.57388 16H10.4536C10.811 16 11.1134 15.7655 11.1134 15.4372V7.20687L14.2474 10.0209C14.5223 10.2554 14.9347 10.2554 15.2096 10.0444L15.8144 9.52848C16.0619 9.29397 16.0619 8.94221 15.8144 8.73116L10.4536 4.15829C10.2062 3.94724 9.79381 3.94724 9.54639 4.15829L4.18557 8.73116C3.93814 8.94221 3.93814 9.29397 4.18557 9.52848L4.79038 10.0444Z' fill='%23263A2E'/%3E%3Crect y='31' width='64' height='2' fill='%23263A2E'/%3E%3C/svg%3E%0A");
  }

  &.has-uploads.under-review {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNjQiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8ZGVmcz48L2RlZnM+CiAgPHJlY3Qgd2lkdGg9IjY0IiBoZWlnaHQ9IjY0IiBmaWxsPSJ3aGl0ZSI+PC9yZWN0PgogIDxyZWN0IHk9IjMyIiB3aWR0aD0iNjQiIGhlaWdodD0iMzIiIGZpbGw9IndoaXRlIj48L3JlY3Q+CiAgPHJlY3Qgd2lkdGg9IjY0IiBoZWlnaHQ9IjMyIiBmaWxsPSIjN0ZDMjlCIj48L3JlY3Q+CiAgPHBhdGggZD0iTTQuNzkwMzggMTAuMDQ0NEM1LjA2NTI5IDEwLjI3ODkgNS40Nzc2NiAxMC4yNTU0IDUuNzUyNTggMTAuMDQ0NEw4LjkxNDA5IDcuMjA2ODdWMTUuNDM3MkM4LjkxNDA5IDE1Ljc2NTUgOS4xODkgMTYgOS41NzM4OCAxNkgxMC40NTM2QzEwLjgxMSAxNiAxMS4xMTM0IDE1Ljc2NTUgMTEuMTEzNCAxNS40MzcyVjcuMjA2ODdMMTQuMjQ3NCAxMC4wMjA5QzE0LjUyMjMgMTAuMjU1NCAxNC45MzQ3IDEwLjI1NTQgMTUuMjA5NiAxMC4wNDQ0TDE1LjgxNDQgOS41Mjg0OEMxNi4wNjE5IDkuMjkzOTcgMTYuMDYxOSA4Ljk0MjIxIDE1LjgxNDQgOC43MzExNkwxMC40NTM2IDQuMTU4MjlDMTAuMjA2MiAzLjk0NzI0IDkuNzkzODEgMy45NDcyNCA5LjU0NjM5IDQuMTU4MjlMNC4xODU1NyA4LjczMTE2QzMuOTM4MTQgOC45NDIyMSAzLjkzODE0IDkuMjkzOTcgNC4xODU1NyA5LjUyODQ4TDQuNzkwMzggMTAuMDQ0NFoiIHN0eWxlPSJmaWxsOiByZ2IoMzgsIDU4LCA0Nik7Ij48L3BhdGg+CiAgPHJlY3QgeT0iMzEiIHdpZHRoPSI2NCIgaGVpZ2h0PSIyIiBmaWxsPSIjMjYzQTJFIj48L3JlY3Q+CiAgPHRleHQgc3R5bGU9ImZpbGw6IHJnYigzOCwgNTgsIDQ2KTsgZm9udC1mYW1pbHk6IEFyaWFsLCBzYW5zLXNlcmlmOyBmb250LXNpemU6IDE2LjRweDsgZm9udC13ZWlnaHQ6IDcwMDsgcGFpbnQtb3JkZXI6IGZpbGw7IHdoaXRlLXNwYWNlOiBwcmU7IiB4PSIyMCIgeT0iMTUuNTc3Ij5VUjwvdGV4dD4KPC9zdmc+");
  }

  &.event-or-benchmark-attachment-uploaded.under-review {
    // Marked Important --->
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbD0ibm9uZSI+CiAgPGc+CiAgICA8cmVjdCBpZD0ic3ZnXzEiIGZpbGw9IndoaXRlIiBoZWlnaHQ9IjY0IiB3aWR0aD0iNjQiLz4KICAgIDxyZWN0IGlkPSJzdmdfMiIgZmlsbD0id2hpdGUiIGhlaWdodD0iMzIiIHdpZHRoPSI2NCIgeT0iMzIiLz4KICAgIDxyZWN0IGlkPSJzdmdfMyIgZmlsbD0iIzdGQzI5QiIgaGVpZ2h0PSIzMiIgd2lkdGg9IjY0Ii8+CiAgICA8cmVjdCBpZD0ic3ZnXzUiIGZpbGw9IiMyNjNBMkUiIGhlaWdodD0iMiIgd2lkdGg9IjY0IiB5PSIzMSIvPgogICAgPHRleHQgaWQ9InN2Z182IiBmb250LXdlaWdodD0iNzAwIiBmb250LXNpemU9IiAxNi40cHgiIGZvbnQtZmFtaWx5PSIgQXJpYWwsIHNhbnMtc2VyaWYiIGZpbGw9IiByZ2IoMzgsIDU4LCA0NikiIHk9IjE1LjU3NyIgeD0iMjAiPlVSPC90ZXh0PgogIDwvZz4KPC9zdmc+") !important;
  }

  /* Complete */
  &.has-uploads.completed {
    // green background w/ check
    background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='64' height='64' fill='white'/%3E%3Crect width='64' height='64' fill='%237FC29B'/%3E%3Cpath d='M29.4219 37.7644C29.7344 38.0785 30.2656 38.0785 30.5781 37.7644L39.7656 28.5288C40.0781 28.2147 40.0781 27.6806 39.7656 27.3665L38.6406 26.2356C38.3281 25.9215 37.8281 25.9215 37.5156 26.2356L30.0156 33.7749L26.4844 30.2565C26.1719 29.9424 25.6719 29.9424 25.3594 30.2565L24.2344 31.3874C23.9219 31.7016 23.9219 32.2356 24.2344 32.5497L29.4219 37.7644Z' fill='%23263A2E'/%3E%3C/svg%3E%0A");
  }

  &.course-step-event.completed {
    // green background w/ check
    background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='64' height='64' fill='white'/%3E%3Crect width='64' height='64' fill='%237FC29B'/%3E%3Cpath d='M29.4219 37.7644C29.7344 38.0785 30.2656 38.0785 30.5781 37.7644L39.7656 28.5288C40.0781 28.2147 40.0781 27.6806 39.7656 27.3665L38.6406 26.2356C38.3281 25.9215 37.8281 25.9215 37.5156 26.2356L30.0156 33.7749L26.4844 30.2565C26.1719 29.9424 25.6719 29.9424 25.3594 30.2565L24.2344 31.3874C23.9219 31.7016 23.9219 32.2356 24.2344 32.5497L29.4219 37.7644Z' fill='%23263A2E'/%3E%3C/svg%3E%0A");
  }

  /* Questions Remaining */
  &.has-uploads.work-required {
    background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 11.3129L11.3129 0H8.0804L0 8.0804V11.3129ZM20.4558 0H17.2233L0 17.2233V20.4558L20.4558 0ZM29.5986 0H26.3661L0 26.3661V29.5986L29.5986 0ZM38.7415 0H35.509L0 35.509V38.7415L38.7415 0ZM47.8843 0H44.6518L0 44.6518V47.8843L47.8843 0ZM57.0272 0H53.7947L0 53.7947V57.0272L57.0272 0ZM2.17004 64L64 2.17004V0H62.9375L0 62.9376V64H2.17004ZM64 11.3129V8.08041L8.08041 64H11.3129L64 11.3129ZM64 20.4558V17.2233L17.2233 64H20.4558L64 20.4558ZM64 29.5986V26.3661L26.3661 64H29.5986L64 29.5986ZM64 38.7415V35.509L35.509 64H38.7415L64 38.7415ZM64 47.8843V44.6518L44.6518 64H47.8843L64 47.8843ZM64 57.0272V53.7947L53.7947 64H57.0272L64 57.0272Z' fill='%23FFF28C'/%3E%3C/svg%3E%0A");
  }

  /* Questions Remaining */
  &.course-step-event.work-required {
    background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 11.3129L11.3129 0H8.0804L0 8.0804V11.3129ZM20.4558 0H17.2233L0 17.2233V20.4558L20.4558 0ZM29.5986 0H26.3661L0 26.3661V29.5986L29.5986 0ZM38.7415 0H35.509L0 35.509V38.7415L38.7415 0ZM47.8843 0H44.6518L0 44.6518V47.8843L47.8843 0ZM57.0272 0H53.7947L0 53.7947V57.0272L57.0272 0ZM2.17004 64L64 2.17004V0H62.9375L0 62.9376V64H2.17004ZM64 11.3129V8.08041L8.08041 64H11.3129L64 11.3129ZM64 20.4558V17.2233L17.2233 64H20.4558L64 20.4558ZM64 29.5986V26.3661L26.3661 64H29.5986L64 29.5986ZM64 38.7415V35.509L35.509 64H38.7415L64 38.7415ZM64 47.8843V44.6518L44.6518 64H47.8843L64 47.8843ZM64 57.0272V53.7947L53.7947 64H57.0272L64 57.0272Z' fill='%23FFF28C'/%3E%3C/svg%3E%0A");
  }

  &.message-read-and-responded:before {
    @extend %common-before-after;
    width: 24px;
    height: 24px;
    // this is the filled-in purple chat bubble w/ 3 white dots
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.84533 12.6971C2.84533 6.83429 8.00086 2 14.4227 2C20.7992 2 26 6.83429 26 12.6971C26 18.6114 20.7992 23.3943 14.4227 23.3943C12.6589 23.3943 11.0309 23.0343 9.53848 22.4171C8.4531 23.4457 5.36432 26 2.37953 26C2.19864 26 2.10819 25.9486 2.01774 25.7943C1.97252 25.64 2.01774 25.4343 2.10819 25.3314C2.10819 25.3241 2.16359 25.2523 2.26029 25.1271C2.84209 24.3735 4.91898 21.6833 5.42309 19.4343C3.79503 17.5829 2.84533 15.2686 2.84533 12.6971Z' fill='white' stroke='%23263A2E' stroke-width='3'/%3E%3Cpath d='M11 12.5C11 13.3284 10.3284 14 9.5 14C8.67157 14 8 13.3284 8 12.5C8 11.6716 8.67157 11 9.5 11C10.3284 11 11 11.6716 11 12.5Z' fill='%23263A2E'/%3E%3Cpath d='M16 12.5C16 13.3284 15.3284 14 14.5 14C13.6716 14 13 13.3284 13 12.5C13 11.6716 13.6716 11 14.5 11C15.3284 11 16 11.6716 16 12.5Z' fill='%23263A2E'/%3E%3Cpath d='M21 12.5C21 13.3284 20.3284 14 19.5 14C18.6716 14 18 13.3284 18 12.5C18 11.6716 18.6716 11 19.5 11C20.3284 11 21 11.6716 21 12.5Z' fill='%23263A2E'/%3E%3C/svg%3E%0A");
  }

  &.has-attachments {
    &:after {
      @extend %common-before-after;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.22606 10.2066L6.1281 10.1223L6.03021 10.2067L5.27651 10.8568L5.19072 10.9308L5.23843 11.0335C5.41283 11.4092 5.6585 11.67 5.95285 11.8274C6.24605 11.9841 6.57669 12.0325 6.91322 12.0022C7.58174 11.9421 8.29266 11.571 8.85224 11.0739L15.5173 5.36549L15.5176 5.36523C16.3137 4.6798 16.9093 3.94946 17.0918 3.19811C17.184 2.8185 17.1702 2.43508 17.0254 2.05549C16.8812 1.6775 16.6104 1.31192 16.2019 0.960198C15.3662 0.240654 14.2871 0.116275 13.2314 0.337364C12.1766 0.55827 11.1279 1.12636 10.3224 1.81987L2.42832 8.61673C0.323893 10.4286 0.323893 13.3759 2.42832 15.1878C4.5195 16.9883 7.90071 16.9883 9.9919 15.1878L16.8685 9.26698C16.9828 9.16859 17.0522 9.03878 17.0522 8.89654C17.0522 8.7543 16.9828 8.62449 16.8685 8.5261L16.2721 8.01256C16.0404 7.81308 15.7116 7.81308 15.4799 8.01256L8.60327 13.9334C7.29121 15.0631 5.129 15.0631 3.81695 13.9334C2.51814 12.8151 2.51814 10.9894 3.81695 9.87115L11.7111 3.0743C12.1599 2.68784 12.7404 2.28917 13.313 2.08284C13.8873 1.87591 14.417 1.87343 14.8133 2.21462C15.0296 2.40084 15.1344 2.5593 15.1731 2.69486C15.2104 2.82519 15.1919 2.95315 15.1196 3.09442C15.0452 3.23996 14.9164 3.39371 14.7419 3.56463C14.5834 3.71997 14.3953 3.88171 14.1891 4.05906C14.1692 4.07617 14.1492 4.09341 14.129 4.11081L7.49794 9.82017C7.2707 10.0158 7.03486 10.195 6.80847 10.2815C6.5912 10.3645 6.40086 10.3571 6.22606 10.2066Z' fill='%23263A2E' stroke='%23263A2E' stroke-width='0.3'/%3E%3C/svg%3E%0A");
      width: 16px;
      height: 16px;
      top: 2px;
      right: 2px;
    }
  }

  &.has-unread-message {
    &:before {
      @extend %common-before-after;
      // the white chat outline chat bubble. transparent center and surround
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.0092 2C7.35803 2 2.01838 7.03571 2.01838 13.1429C2.01838 15.8214 3.002 18.2321 4.6882 20.1607C4.0793 22.8929 2.11205 25.25 2.11205 25.3036C2.01838 25.4107 1.97154 25.625 2.01838 25.7857C2.11205 25.9464 2.20573 26 2.39309 26C5.48447 26 7.82642 24.3393 8.95056 23.2679C10.4963 23.9107 12.1825 24.2857 14.0092 24.2857C20.6135 24.2857 26 19.3036 26 13.1429C26 7.03571 20.6135 2 14.0092 2Z' fill='white' stroke='%23263A2E' stroke-width='3'/%3E%3C/svg%3E%0A");
      width: 24px;
      height: 24px;
    }
  }

  &.has-uploads {
    background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='64' height='64' fill='white'/%3E%3Crect y='32' width='64' height='32' fill='white'/%3E%3Crect width='64' height='32' fill='%237FC29B'/%3E%3Cpath d='M4.79038 10.9079C5.06529 11.1759 5.47766 11.1491 5.75258 10.9079L8.91409 7.66499V15.3568C8.91409 15.732 9.189 16 9.57388 16H10.4536C10.811 16 11.1134 15.732 11.1134 15.3568V7.66499L14.2474 10.8811C14.5223 11.1491 14.9347 11.1491 15.2096 10.9079L15.8144 10.3183C16.0619 10.0503 16.0619 9.64824 15.8144 9.40704L10.4536 4.1809C10.2062 3.9397 9.79381 3.9397 9.54639 4.1809L4.18557 9.40704C3.93814 9.64824 3.93814 10.0503 4.18557 10.3183L4.79038 10.9079Z' fill='%23263A2E'/%3E%3Crect y='31' width='64' height='2' fill='%23263A2E'/%3E%3C/svg%3E%0A");
  }

  &.event-or-benchmark-attachment-uploaded {
    background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' xmlns='http://www.w3.org/2000/svg' fill='none'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Crect id='svg_1' fill='white' height='64' width='64'/%3E%3Crect id='svg_2' fill='white' height='32' width='64' y='32'/%3E%3Crect id='svg_3' fill='%237FC29B' height='32' width='64'/%3E%3Crect id='svg_5' fill='%23263A2E' height='2' width='64' y='31'/%3E%3C/g%3E%3C/svg%3E");
  }

  &.work-required-with-new-upload {
    &:after {
      @extend %common-before-after;
      background-image: url("data:image/svg+xml,%3Csvg width='64' height='32' viewBox='0 0 64 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H64V32H0V0Z' fill='white'/%3E%3Cpath d='M64 32V0H0V32H64Z' fill='%237FC29B'/%3E%3Cpath d='M4.79038 10.9079C5.06529 11.1759 5.47766 11.1491 5.75258 10.9079L8.91409 7.66499V15.3568C8.91409 15.732 9.189 16 9.57388 16H10.4536C10.811 16 11.1134 15.732 11.1134 15.3568V7.66499L14.2474 10.8811C14.5223 11.1491 14.9347 11.1491 15.2096 10.9079L15.8144 10.3183C16.0619 10.0503 16.0619 9.64824 15.8144 9.40704L10.4536 4.1809C10.2062 3.9397 9.79381 3.9397 9.54639 4.1809L4.18557 9.40704C3.93814 9.64824 3.93814 10.0503 4.18557 10.3183L4.79038 10.9079Z' fill='%23263A2E'/%3E%3Crect y='30' width='64' height='2' fill='%23263A2E'/%3E%3C/svg%3E%0A");
      width: 64px;
      height: 32px;
      top: 0px;
      right: 0px;
    }
  }

  /* BENCHMARK */

  &.course-step-benchmark {
    background-color: white;
  }

  &.course-step-benchmark.completed, &.course-step-survey.completed {
    // green background w/ check
    background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='64' height='64' fill='white'/%3E%3Crect width='64' height='64' fill='%237FC29B'/%3E%3Cpath d='M29.4219 37.7644C29.7344 38.0785 30.2656 38.0785 30.5781 37.7644L39.7656 28.5288C40.0781 28.2147 40.0781 27.6806 39.7656 27.3665L38.6406 26.2356C38.3281 25.9215 37.8281 25.9215 37.5156 26.2356L30.0156 33.7749L26.4844 30.2565C26.1719 29.9424 25.6719 29.9424 25.3594 30.2565L24.2344 31.3874C23.9219 31.7016 23.9219 32.2356 24.2344 32.5497L29.4219 37.7644Z' fill='%23263A2E'/%3E%3C/svg%3E%0A");
    z-index: 999;
  }

  &.course-step-benchmark.work-required {
    background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 11.3129L11.3129 0H8.0804L0 8.0804V11.3129ZM20.4558 0H17.2233L0 17.2233V20.4558L20.4558 0ZM29.5986 0H26.3661L0 26.3661V29.5986L29.5986 0ZM38.7415 0H35.509L0 35.509V38.7415L38.7415 0ZM47.8843 0H44.6518L0 44.6518V47.8843L47.8843 0ZM57.0272 0H53.7947L0 53.7947V57.0272L57.0272 0ZM2.17004 64L64 2.17004V0H62.9375L0 62.9376V64H2.17004ZM64 11.3129V8.08041L8.08041 64H11.3129L64 11.3129ZM64 20.4558V17.2233L17.2233 64H20.4558L64 20.4558ZM64 29.5986V26.3661L26.3661 64H29.5986L64 29.5986ZM64 38.7415V35.509L35.509 64H38.7415L64 38.7415ZM64 47.8843V44.6518L44.6518 64H47.8843L64 47.8843ZM64 57.0272V53.7947L53.7947 64H57.0272L64 57.0272Z' fill='%23FFF28C'/%3E%3C/svg%3E%0A");
  }

  // &.course-step-benchmark.locked {
  &.locked {
    background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='64' height='64' fill='%23ECECEC'/%3E%3Cpath d='M37.5 31H36.75V28.75C36.75 26.1562 34.5938 24 32 24C29.375 24 27.25 26.1562 27.25 28.75V31H26.5C25.6562 31 25 31.6875 25 32.5V38.5C25 39.3438 25.6562 40 26.5 40H37.5C38.3125 40 39 39.3438 39 38.5V32.5C39 31.6875 38.3125 31 37.5 31ZM34.25 31H29.75V28.75C29.75 27.5312 30.75 26.5 32 26.5C33.2188 26.5 34.25 27.5312 34.25 28.75V31Z' fill='%23888888'/%3E%3C/svg%3E%0A");
  }
}

.inner-container {
  text-align: center;
  cursor: pointer;
}
</style>