<template>
  <div class="container pt-8 px-4" >
    <div>
      <h2 class="pb-2 text-xl font-semibold text-center">
        <a :href="`/users/${user.id}`" target="_blank">{{ user.firstLastName }}</a>
      </h2>
      <div class="text-center border-b-2 border-gray-200 pb-2 mb-2">
        <span class="" v-if="user.phone_number">
          <i class="fa fa-phone"></i>
          <a :href="'tel:' + user.phone_number.replace(/[^0-9.]/g, '')">{{ user.formattedPhoneNumber }}</a>
        </span>

        <span class="pl-4">
          <i class="fa fa-envelope"></i>
          <a :href="'mailto:' + user.email">{{ user.email }}</a>
        </span>
      </div>
    </div>
    <div v-for="tag in availableTags" :key="tag.id">
      <input :id="tag.name" type="checkbox" v-model="allTags[tag.name]" class="w-4 h-4 text-blue-600 mb-2 mr-2 ml-0.5 bg-white border-solid b-1 border-gray-400 rounded focus:ring-blue-500 focus:ring-2" />
      <label :for="tag.name">{{tag.name}}</label>
    </div>
    <button class="btn btn-primary !block mx-auto !px-16 w-full" @click="saveTags">Save</button>
  </div>
</template>

<script>
  import { getUsersStoreFor } from '@stores/UsersStore'
  import FlashMessages from '@services/FlashMessageService'
  import { WindowVariables } from '@utils'

  import { observer } from "mobx-vue"

export default observer({
  name: 'user-edit-tags',
  data () {
    const initialUserTags = this.user.tagArray
    const allTags = {}
    WindowVariables.tags.forEach(tag => {
      allTags[tag.name] = false
    })

    initialUserTags.forEach(tagName => {
      allTags[tagName] = true
    })

    return {
      allTags,
      initialUserTags,
      availableTags: WindowVariables.tags,
      usersStore: getUsersStoreFor({ id: this.user.id }),
    }
  },
  methods: {
    saveTags () {
      // TODO: 1003 fill out this data...

      const tagsToSend = []
      for ( var k in this.allTags) {
        if (this.allTags[k]) {
          tagsToSend.push(k)
        }
      }
      this.usersStore.updateTags({ tags: tagsToSend, id: this.user.id })
        .then(() => {
          FlashMessages.success({ msg: 'User tags saved successfully!'})
          if (this.onSuccessCallback) {
            this.onSuccessCallback()
          }
        })
        .catch(() => {
          FlashMessages.error({ msg: 'User tags failed to save.' })
        })
    }
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    onSuccessCallback: {
      type: Function,
      required: false,
    }
  },
})
</script>
