<template>
  <div v-bind:style="styleObject"></div>
</template>

<script>
  import { isUndefined } from 'lodash-es'
  import PDFObject from 'pdfobject'

  export default {
    mounted: function () {
      if (isUndefined(this.url)) {
        console.error('VuePDFViewer Error: no file url provided');
        return;
      }
      PDFObject.embed(this.url, this.$el);
    },
    props: [ 'height', 'url' ],
    data: function () {
      var vm = this;
      var h = '400px';
      if (!vm.height) {
        console.warn('Please specify viewer height, e.g. height="500px"');
      }
      else {
        h = vm.height;
      }
      return {
        styleObject: {
          height: h
        }
      }
    },
    watch: {
      url: function (val) {
        var vm = this;
        PDFObject.embed(val, vm.$el);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pdfobject-container {
    width: 100%;
  }
  .pdfobject {
    border: 1px solid #666;
  }
</style>
