<template>
  <div class="interaction-attach">
    <label class="file-container" @click="clearVal()">
      <button class="btn">
        <i class="fa fa-paperclip fa-2x" v-if="!creatingAttachment"></i>
        <i class="fa fa-spinner fa-spin fa-2x" v-if="creatingAttachment"></i>
      </button>
      <input
        type="file"
        name="attachment_file"
        accept="application/pdf, image/jpeg, image/png, image/x-png,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation,video/*,"
        class="pull-right"
        aria-describedby="paperclip: add-attachment-to-conversation"
        @change="filesChange($event.target.name, $event.target.files)"
      />
      <span id="add-attachment-to-conversation" class="sr-only">Add attachment to conversation</span>
    </label>
  </div>
</template>

<script>
import { getAttachmentsStoreFor } from "@stores/AttachmentsStore"
import { WindowVariables } from "@utils"
import FlashMessages from "@services/FlashMessageService"

export default {
  name: "interaction-attach",
  components: {},
  data() {
    return {
      creatingAttachment: false,
      attachmentsStore: getAttachmentsStoreFor()
    }
  },
  computed: {},
  methods: {
    removeResponse() {
      // this.attachmentsStore.remove(this.assignmentResponse.id)
      //   .then(() => {
      //     FlashMessages.success('Your Action Step was removed.')
      //   })
      //   .catch(() => {
      //     FlashMessages.error('We were unable to remove your Action Step. Please try again.')
      //   })
    },
    clearVal() {
      $("input[name=attachment_file]").val("")
    },
    save(formData) {
      const groupId = this.studentStepProgress.groupId || WindowVariables.groupId
      formData.set("attachment[group_id]", groupId)
      formData.set(
        "attachment[attachment_relation][course_step_id]",
        this.studentStepProgress.courseStepId || WindowVariables.courseStepId
      )

      // when a current_user is teacher, need to set the CourseStepProgress user_id
      // when the current_user is a student it doesnt need to be set
      if (
        WindowVariables.currentUser.teacherForGroupId(groupId) ||
        WindowVariables.currentUser.mentorForGroupId(groupId)
      ) {
        formData.set(
          "attachment[attachment_relation][user_id]",
          this.studentStepProgress.userId
        )
      }

      this.creatingAttachment = true;
      this.attachmentsStore
        .create(formData)
        .then(resp => {
          this.creatingAttachment = false
          FlashMessages.success(this.$t('application.interaction_timeline.attach.flash.upload_success'))
        })
        .catch(resp => {
          this.creatingAttachment = false
          if (resp.kind === "error") {
            let txt = resp.message
            let idx = txt.indexOf("|")
            if (idx > -1) {
              txt = txt.substring(idx + 1)
            }
            FlashMessages.error(txt)
          } else {
            FlashMessages.error(this.$t('application.interaction_timeline.attach.flash.upload_error'))
          }
        })
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData()

      if (!fileList.length) return

      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map(x => {
        formData.append(fieldName, fileList[x], fileList[x].name) // more params
      })

      // save it
      this.save(formData)
    }
  },
  props: {
    studentStepProgress: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.interaction-attach {
  .assignment-total-points {
    display: none;
  }

  .btn {
    background-color: #f8f7f9;
    padding: 4px 8px;
    cursor: pointer;
    color: #573f7e;
    border: 1px solid #573f7e;
    margin-left: 0px;
    border-radius: 5px;
    font-size: 12px;
    height: 100%;
    width: 100%;

    &:hover {
      background-color: #573f7e;
      color: #fff;
      border-radius: 8px;
    }

    transition: all 500ms ease;
  }
}

.top-bar {
  background-color: #f6f6f6;
  width: 100%;
  color: $medium-gray;
  padding: 5px 10px;

  .label {
    text-transform: uppercase;
    font-size: 1rem;
  }
}

.assignment-attachment {
  padding-right: 10px;
}

.inner {
  padding: 5px 10px;
  background-color: #f0f0f0;
  color: $medium-gray;
  width: 100%;
}

.file-container {
  overflow: hidden;
  position: relative;
  height: 48px;
  width: 48px;
}

.file-container [type="file"] {
  cursor: pointer;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}
.accepted-extensions {
  font-size: 0.7em;
}
</style>
