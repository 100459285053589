<template>
  <div class="assignment-grader">
    <div class="info-bar">
      <div class="student-name">
        <div class="label">{{ studentLabel }}</div>
        <div class="value">{{ currentUser && currentUser.last_name }}, {{ currentUser && currentUser.first_name }}</div>
      </div>
      <div class="assignment-name">
        <div class="label">Action Step</div>
        <div class="value">{{ currentAssignment.name }}</div>
      </div>
      <div class="submitted-at">
        <div class="label">Submitted</div>
        <div class="value">{{ arSubmittedAtFormatted }}</div>
      </div>
    </div>
    <div class="grading-bar">
      <div>
        <div class="grading-direction-text">Click on a button below to grade the Action Step</div>
        <div class="grading-buttons-wrapper clearfix">
          <div class="grading-button" :class="{ selected: x.selected }" v-for="x in possiblePointValues" :key="x.key" @click="assignGrade(x.val)">
            {{ x.val }}
          </div>
        </div>
      </div>

      <div class="rubric-toggler" v-if="hasRubric">
        <b-button @click="toggleRubricView()">{{ rubricToggleButtonText }}</b-button>
      </div>
    </div>

    <div class="assignment-grader-body">
      <div class="rubric-container" v-if="hasRubric" :class="{show: rubricShowing }">
        <pdf-object-viewer :url="rubricPdfUrl" height="100%"></pdf-object-viewer>
      </div>

      <div class="assignment-container">
        <div class="nav-left" @click="goBack()">
          Back
        </div>
        <div class="content">
          <pdf-object-viewer :url="pdfUrl" height="100%"></pdf-object-viewer>
        </div>
        <div class="nav-right" @click="goToNext()">
          Next
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { capitalize, findIndex, filter, first, map, range, sortBy } from 'lodash-es'
  import PdfObjectViewer from '@components/PdfObjectViewer.vue'
  import GradebookService from '@services/gradebookManager/Service'
  import FlashMessages from '@services/FlashMessageService'
  import { WindowVariables } from '@utils'

  export default {
    name: 'assignment-grader',
    components: {
      PdfObjectViewer,
    },
    data () {
      return {
        gradebookService: GradebookService,
        studentLabel: WindowVariables.group && capitalize(WindowVariables.group.user_descriptor) || 'Student',
        rubricShowing: false,
      }
    },
    created () {
      // if we pass in the assignmentResponseId then set it on the gradebook service
      if (this.assignmentResponseId) {
        this.gradebookService.setCurrentAssignmentResponseId(parseInt(this.assignmentResponsesId))
      }
    },
    fromMobx: {
      assignments () {
        return this.gradebookService.allAssignments
      },
      assignmentResponses () {
        return this.gradebookService.allAssignmentResponses
      },
      students () {
        return this.gradebookService.allStudents
      },
      currentAssignmentResponse () {
        return this.gradebookService.currentAssignmentResponse
      },
      currentAssignment () {
        return this.gradebookService.currentAssignment
      },
      currentUser () {
        return this.gradebookService.currentUser
      },
      currentGrade () {
        return this.gradebookService.currentAssignmentResponseGrade
      },
    },
    computed: {
      rubricToggleButtonText () {
        if (this.rubricShowing) {
          return 'Hide Rubric'
        } else {
          return 'Show Rubric'
        }
      },
      hasRubric () {
        return this.currentAssignment.rubrics && this.currentAssignment.rubrics.length >= 1
      },
      rubricPdfUrl () {
        return this.hasRubric && first(this.currentAssignment.rubrics).presigned_url
      },
      ar () {
        return this.currentAssignmentResponse
      },
      pdfUrl () {
        if (this.currentAssignmentResponse) {
          return this.currentAssignmentResponse.attachments[0].presigned_url
        } else {
          return null
        }
      },
      possiblePointValues () {
        if (this.currentAssignment && this.ar) {
          return map(range(this.currentAssignment.total_points + 1), (x) => {
            return {
                key: x,
                val: x,
                selected: this.currentGrade && x == this.currentGrade.points }
          })
        } else {
          return []
        }
      },
      arSubmittedAtFormatted () {
        if (this.ar && this.ar.attachments && this.ar.attachments.length > 0) {
          return `${this.ar.attachments[0].created_at.format('M/D/YY h:mma')} (${this.ar.attachments[0].created_at.fromNow()})`
        } else {
          return ''
        }
      },
      orderedAssignmentResponses() {
        // put the assignmentResponses ordered by assignments idx then by students idx
        this.assignmentResponses.forEach((ar) => {
          ar.user_idx = findIndex(this.students, { id: ar.user_id })
          ar.assignment_idx = findIndex(this.assignments, { id: ar.assignment_id })
        })

        return sortBy(filter(this.assignmentResponses, ar => { return ar.user_idx >= 0 && ar.assignment_idx >= 0 }),
          (ar) => {
            return ar.assignment_idx * 1000 + ar.user_idx
          })
      },
      nextAssignmentResponseId() {
        const currIdx = findIndex(this.orderedAssignmentResponses, { id: this.ar.id })
        let newIdx = null

        if ((this.orderedAssignmentResponses.length -1) === currIdx) {
          newIdx = 0
        } else {
          newIdx = currIdx + 1
        }

        return this.orderedAssignmentResponses[newIdx].id
      },
      previousAssignmentResponseId() {
        const currIdx = findIndex(this.orderedAssignmentResponses, { id: this.ar.id })
        let newIdx = null

        if (currIdx === 0) {
          newIdx = this.orderedAssignmentResponses.length - 1
        } else {
          newIdx = currIdx - 1
        }

        return this.orderedAssignmentResponses[newIdx].id
      }

    },
    methods: {
      toggleRubricView () {
        if (this.rubricShowing) {

        } else {

        }
        this.rubricShowing = !this.rubricShowing
      },
      goBack () {
        // should go towards "A" last names in the users list for the same assignment through assignment_responses
        // if at the beginning of the alphabet then it should loop around to the end of the alphabet of the prior assignment
        this.gradebookService.setCurrentAssignmentResponseId(this.previousAssignmentResponseId)
      },
      goToNext() {
        // should go towards "Z" last names in the users list for the same assignment through assignment_responses
        this.gradebookService.setCurrentAssignmentResponseId(this.nextAssignmentResponseId)
      },
      assignGrade(points) {
        this.gradebookService.createGrade(points)
          .then(grade => {
            FlashMessages.success('Action Step score submitted.')
          })
          .catch(() => {
            FlashMessages.error('Unable to submit Action Step score.')
          })
        this.goToNext()
      }
    },
    props: {
      groupId: {
        type: Number,
        required: true,
      },
    },
  }

</script>

<style lang="scss" scoped>
  .assignment-grader {
    height: 100%;
    display: flex;
    flex-direction: column;

    .rubric-toggler {
      position: absolute;
      right: 10px;
    }

    .rubric-container {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0%;
      right: -100%;
      z-index: 1000;

      transition-property: right;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;

      &.show {
        right: 0%;
      }
    }

    .assignment-container {
      height: 100%;
      width: 100%;
      display: flex;
    }
  }

  .assignment-grader-body {
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .nav-left, .nav-right {
    flex: 0 0 35px;
    text-align: center;
    background-color: $light-gray;
    cursor: pointer;
    font-weight: 400;
    text-orientation: sideways;
    writing-mode: vertical-lr;
    text-transform: uppercase;
    padding-left: 5px;

    &:hover {
      background-color: darken($light-gray, 5%);
      color: $dark-gray;
    }
  }

  .nav-right {
    transform: rotate(180deg);
    transform-origin: center;
  }

  .content {
    flex: 1 1 auto;
  }

  .info-bar {
    display: flex;
    background-color: $medium-gray;
    color: $white;
    font-size: 1.0rem;
    padding: 5px 5px;

    .student-name, .assignment-name, .submitted-at {
      display: flex;
      flex-direction: row;
      flex: 1 1 33.3%;
    }

    .student-name {
      text-align: left;
      justify-content: flex-start;
    }

    .assignment-name {
      text-align: center;
      justify-content: center;
    }

    .submitted-at {
      text-align: right;
      justify-content: flex-end;
    }

    .label {
      color: darken($white, 10%);
      font-size: 0.9rem;
      text-transform: uppercase;
      padding: 2px 5px;
    }
  }

  .grading-bar {
    display: flex;
    justify-content: center;
    background-color: lighten($medium-gray, 5%);
    color: darken($white, 5%);
    padding: 10px 0;
  }

  .grading-buttons-wrapper {
    margin: 0 auto;
    padding-top: 5px;
  }

  .grading-button {
    float: left;
    width: 50px;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.5;
    background-color: $light-gray;
    border-radius: 2px;
    margin: 0 5px;
    color: $black;

    &:hover {
      background-color: mix($orange, $light-gray, 50%);
      color: $dark-gray;
      cursor: pointer;
    }

    &.selected {
      background-color: $orange;
      color: $white;
      cursor: initial;
    }
  }

  .grading-direction-text {
    text-align: center;
    font-size: 1.0rem;
    line-height: 1.2;
    color: #cccccc;
  }
</style>
