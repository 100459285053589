<template>
  <div class="web-worksheet">
    <div style="display:flex;" ref="assignment-target"></div>
  </div>
</template>

<script>
import WebWorksheetsService from '@services/webWorksheets/Service'

export default {
  name: 'web-worksheet',
  data () {
    return {
      service: null,
    }
  },
  mounted () {
    this.service = new WebWorksheetsService(this.mode, {
      el: this.$refs['assignment-target'],
      courseStepId: this.courseStepId,
      userId: this.userId,
      groupId: this.groupId,
      assignmentResponse: this.assignmentResponse,
      ...this.assignmentId && { assignmentId: this.assignmentId },
      ...this.assignmentRefId && { assignmentRefId: this.assignmentRefId },
    })
    this.service.rehydrate()
  },
  beforeDestroy () {
    this.service && this.service.dispose()
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    courseStepId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    groupId: {
      type: Number,
      required: true,
    },
    assignmentId: {
      type: Number,
    },
    assignmentRefId: {
      type: String,
    },
    assignmentResponse: {
      type: Object,
    },
  },
}
</script>

<style scoped>

</style>
