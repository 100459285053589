<template>
  <div class="interaction-events">
    <component
      :event="event"
      :studentStepProgress="studentStepProgress"
      v-for="event in vm.allEvents"
      :key="event.uid"
      :is="event.component"
    />
  </div>
</template>

<script>
import InteractionEvent from "@components/interactionTimeline/InteractionEvent.vue"
import InteractionEventMessage from "@components/interactionTimeline/InteractionEventMessage.vue"
import InteractionEventReview from "@components/interactionTimeline/InteractionEventReview.vue"
import InteractionEventAttachment from "@components/interactionTimeline/InteractionEventAttachment.vue"
import InteractionEventAssignment from "@components/interactionTimeline/InteractionEventAssignment.vue"
import InteractionEventClosed from "@components/interactionTimeline/InteractionEventClosed.vue"

import { computed, makeObservable, observable } from "mobx"
import { observer } from "mobx-vue"

class ViewModel {
  studentStepProgress = null

  constructor(params) {
    makeObservable(this, {
      studentStepProgress: observable,
      allEvents: computed,
    })
    this.studentStepProgress = params.studentStepProgress
  }

  get allEvents() {
    return this.studentStepProgress.allEvents
  }
}

export default observer({
  name: "interaction-events",
  components: {
    InteractionEvent,
    InteractionEventMessage,
    InteractionEventReview,
    InteractionEventAttachment,
    InteractionEventAssignment,
    InteractionEventClosed,
  },
  data () {
    return {
      vm: new ViewModel({
        studentStepProgress: this.studentStepProgress
      })
    }
  },
  props: {
    studentStepProgress: {
      type: Object,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
.interaction-events {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  padding: 12px;
  overflow-x: hidden;
}
</style>
