import { map, orderBy } from 'lodash-es'
import axios from './axios'
import User from '@models/User'

const baseUrlString = '/users'

async function getAll (params) {
  return axios.get(baseUrlString, { params })
    .then((response) => {
      const recs = params.page ? response.data.records : response.data
      const instantiatedRecords = map(recs, initWithData)
      return params.page
        ? { records: instantiatedRecords, pagination: response.data.pagination }
        : instantiatedRecords
    }).catch(console.error)
}

async function getById (id) {
  return axios.get(`${baseUrlString}/${id}`)
    .then(response => initWithData(response.data))
    .catch(console.error)
}

async function create (data) {
  return axios.post(baseUrlString, data)
    .then(response => initWithData(response.data))
    .catch(error => Promise.reject(error.response.data))
}

async function update (data) {
  return axios.put(`${baseUrlString}/${data.id}`, data)
    .then(response => initWithData(response.data))
    .catch(error => Promise.reject(error.response.data))
}

function initWithData (data) {
  return new User(data)
}

export default {
  initWithData,
  getAll,
  create,
  update,
  getById,
}
