import { map } from 'lodash-es'
import axios from './axios'
import Task from '@models/Task'

const baseUrlString = '/bulk_actions'

async function manageEngagement(data) {
  return axios.post(`/coaching/task_management/manual_engagement`, data)
    .then(response => map(response.data.result,  r => initWithData(r)))
    .catch(error => Promise.reject(error))
}

function initWithData(data) {
  return new Task(data)
}

export default {
  manageEngagement,
}
