import NewModel from '@models/NewModel'
import User from '@models/NewUser'

class TaskAction extends NewModel {
  constructor (initialData, included) {
    super(initialData, included, {
      columns: [
        'id',
        'user_id',
        'task_id',
        'action_type',
      ],
      momentKeys: [
        'created_at',
        'updated_at',
      ],
      relationships: {
        user: {
          model: User,
        },
      }
    })
  }

  get isComplete () {
    return this.action_type === 'complete'
  }

  get createdFormatted () {
    return this.created_at.format('MMM Do YY @ H:mma')
  }
}

export default TaskAction
