import { computed, observable, reaction, makeObservable } from 'mobx'
import StudentStepProgress from '@services/timelineService/StudentStepProgress'
import { getCourseStepReviewsStoreFor } from '@stores/CourseStepReviewsStore'
import { getConversationsStoreFor } from '@stores/ConversationsStore'
import { getAttachmentsStoreFor } from '@stores/AttachmentsStore'
import { getAssignmentResponsesStoreFor } from '@stores/AssignmentResponsesStore'
import { WindowVariables } from '@utils'

class StudentProgress {
  _userId = undefined
  _parent = undefined
  _lazyLoad = false
  _courseStepReviewsStore = undefined
  _conversationsStore = undefined
  _assignmentResponsesStore = undefined
  _attachmentsStore = undefined
  _studentStepProgresses = []

  constructor(parent, userId, lazyLoad = false) {
    makeObservable(this, {
      _studentStepProgresses: observable,
      messages: computed,
      allCourseSteps: computed,
      courseStepReviews: computed,
      attachments: computed,
      assignmentResponses: computed,
      userId: computed,
      user: computed,
      groupId: computed,
      conversations: computed,
      openConversations: computed,
      indexOfLatestGatedLock: computed,
      stepProgresses: computed
    })

    this._userId = userId
    this._parent = parent
    this._lazyLoad = lazyLoad
    this._registerCourseStepsReaction()

    if (lazyLoad) {
      const baseFilter = { group_id: this.groupId, users: [ userId ] }
      const convoFilter = { ...baseFilter }

      if (!WindowVariables.currentUser.studentForGroupId(this.groupId)) {
        convoFilter.mailbox_group_id = this.groupId
      }

      this._courseStepReviewsStore = getCourseStepReviewsStoreFor(baseFilter)
      this._conversationsStore = getConversationsStoreFor(convoFilter)
      this._assignmentResponsesStore = getAssignmentResponsesStoreFor({ ...baseFilter, include_discarded: true })
      this._attachmentsStore = getAttachmentsStoreFor(baseFilter)
    }
  }

  get messages() {
    return this.conversations.map(conversation => conversation.messages).flatten()
  }

  get allCourseSteps() {
    return this._parent.allCourseSteps
  }

  get courseStepReviews() {
    return this._lazyLoad
      ? this._courseStepReviewsStore.allRecords
      : this._parent.allCourseStepReviews.filter(review => this._userId === review.user_id)
  }

  get attachments() {
    return this._lazyLoad
      ? this._attachmentsStore.allRecords
      : this._parent.allAttachments.filter(att => this._userId === att.attachment_relations[0].attachment_relatable.user_id)
  }

  get assignmentResponses() {
    return this._lazyLoad
      ? this._assignmentResponsesStore.allRecords
      : this._parent.allAssignmentResponses.filter(ar => this._userId === ar.user_id)
  }

  get userId() {
    return this._userId
  }

  get user() {
    return this._parent.allUsers.find(u => this._userId === u.id)
  }

  get groupId() {
    return this._parent.groupId
  }

  get conversations() {
    return this._lazyLoad
      ? this._conversationsStore.allRecords
      : this._parent.allConversations.filter(conv => this._userId === conv.metadata.user_id)
  }

  get openConversations() {
    return this.conversations.filter(conv => conv.isOpened)
  }

  get indexOfLatestGatedLock() {
    const gatedSteps = this._parent.allCourseSteps.filter(cs => cs.isGatedLock)
    if (gatedSteps.length === 0) {
      return this._parent.allCourseSteps.length - 1
    }

    const completedGates = gatedSteps.map(cs => {
      return this.courseStepReviews.find(csr => csr.course_step_id === cs.id && csr.isApproved)
    })

    const incompleteGate = gatedSteps[completedGates.findIndex( x => !x) || 0]

    if (!incompleteGate) {
      return this._parent.allCourseSteps.length - 1
    }

    return this._parent.allCourseSteps.findIndex(cs => cs.id === incompleteGate.id)
  }

  get stepProgresses() {
    return this._studentStepProgresses
  }

  get dataStore () {
    return this._parent.dataStore
  }

  _registerCourseStepsReaction() {

    this._initCourseStepsProgress()

    this._parent.addStopFunction(reaction(
      () => this.allCourseSteps,
      () => this._initCourseStepsProgress()
    ))
  }

  _initCourseStepsProgress() {
    const progresses = this.allCourseSteps.map((cs, index) => {
      return new StudentStepProgress(this, cs.id, index)
    })

    this._studentStepProgresses.spliceWithArray(0, this._studentStepProgresses.length, progresses)
  }
}

export default StudentProgress
