<!--TODO: DEPRECATED - delete this in the future-->
<template>
  <div class="user-engagement-summary-container pb-4 py-4 px-2">
    <h3 class="font-bold text-gray-900 text-lg text-center pb-2">Venture Update</h3>

    <dl v-if="loaded" class="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Current Phase</dt>
        <dd class="text-right text-gray-900">
          <div> {{ gmEngagementMetadata.current_sequence_name }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Current Coach</dt>
        <dd class="text-right text-gray-900">
          <div> {{ gmEngagementMetadata.current_coach_name }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Last Phase Survey</dt>
        <dd class="text-right text-gray-900">
          <div v-if="latestSurvey"> {{ latestSurvey.sequence_analytics_name }} </div>
          <div v-else> No survey submitted </div>
        </dd>
      </div>

      <div v-if="latestSurvey" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Last Phase Survey Coach</dt>
        <dd class="text-right text-gray-900">
          <div> {{ latestSurvey.current_coach_name }} </div>
        </dd>
      </div>

      <div v-if="latestSurvey" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Last Phase Survey Date</dt>
        <dd class="text-right text-gray-900">
          <div>{{ toDateTime(latestSurvey.updated_at) }}</div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Have Accountability Partner</dt>
        <dd class="text-right text-gray-900">
          <div> {{ haveAccountabilityPartner }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Have Expert/Advisor</dt>
        <dd class="text-right text-gray-900">
          <div> {{ haveExpertAdvisor }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">90-day Goal</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.venture_90_day_goal_translated }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Venture Priority</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.venture_priority }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Advisory Team Needs</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.venture_advisory_team_needs }} </div>
        </dd>
      </div>

      <!-- BELOW TODOO: should all of these come from the venture_ fields on users? or should industry and type be from the orginal "idea_industry", "business_venture_type", and "idea_description" -->
      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Idea Industry</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.idea_industry }} </div>
        </dd>
      </div>


      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Type of business/venture</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.business_venture_type }} </div>
        </dd>
      </div>


      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Biz desc.</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.venture_description }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Likely To Recommend</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.venture_how_likely_to_recommend }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">How Supported</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.venture_how_supported_did_you_feel }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Liked Best</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.venture_liked_best_about_phase }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Thing to Improve</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.venture_can_improve_in_phase }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Wins or Connections</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.venture_wins_from_phase }} </div>
        </dd>
      </div>

      <div v-if="latestSurvey" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">How confident using HTSS</dt>
        <dd class="text-right text-gray-900">
          <div> {{ latestSurvey.answers.how_confident_using_htss }} </div>
        </dd>
      </div>

      <div v-if="latestSurvey" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">How confident Curiosity Conversations</dt>
        <dd class="text-right text-gray-900">
          <div> {{ latestSurvey.answers.how_confident_curiosity_conversation }} </div>
        </dd>
      </div>

      <div v-if="latestSurvey" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500"># Advisors</dt>
        <dd class="text-right text-gray-900">
          <div> {{ latestSurvey.answers.how_many_advisors }} </div>
        </dd>
      </div>

      <div v-if="latestSurvey" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Searching for Advisor skills</dt>
        <dd class="text-right text-gray-900">
          <div> {{ latestSurvey.answers.what_skills_advisor_team }} </div>
        </dd>
      </div>

      <div v-if="latestSurvey" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Have mentor</dt>
        <dd class="text-right text-gray-900">
          <div> {{ latestSurvey.answers.have_mentor }} </div>
        </dd>
      </div>

      <div v-if="latestSurvey" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Importance Online Platform</dt>
        <dd class="text-right text-gray-900">
          <div> {{ latestSurvey.importance_online_platform }} </div>
        </dd>
      </div>

      <div v-if="latestSurvey" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Importance Videos</dt>
        <dd class="text-right text-gray-900">
          <div> {{ latestSurvey.importance_videos_on_platform }} </div>
        </dd>
      </div>

      <div v-if="latestSurvey" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Importance Steps And Roadmap</dt>
        <dd class="text-right text-gray-900">
          <div> {{ latestSurvey.importance_steps_and_roadmap }} </div>
        </dd>
      </div>

      <div v-if="latestSurvey" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Importance Journey Dashboard</dt>
        <dd class="text-right text-gray-900">
          <div> {{ latestSurvey.importance_my_journey_dashboard }} </div>
        </dd>
      </div>

      <div v-if="latestSurvey" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Importance Coach Feedback</dt>
        <dd class="text-right text-gray-900">
          <div> {{ latestSurvey.importance_feedback_support_from_coaches }} </div>
        </dd>
      </div>

      <div v-if="latestSurvey" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Importance Online Feedback</dt>
        <dd class="text-right text-gray-900">
          <div> {{ latestSurvey.importance_online_workshops }} </div>
        </dd>
      </div>

      <div v-if="latestSurvey" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Importance Online Office Hours</dt>
        <dd class="text-right text-gray-900">
          <div> {{ latestSurvey.importance_online_office_hours }} </div>
        </dd>
      </div>

      <div v-if="latestSurvey" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Importance Online Community</dt>
        <dd class="text-right text-gray-900">
          <div> {{ latestSurvey.importance_slack_community }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Prog # contacts</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.user_contacts_made_in_program }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Ph 7 # contacts</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.user_contacts_made_ph7 }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Ph 6 # contacts</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.user_contacts_made_ph6 }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Ph 5 # contacts</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.user_contacts_made_ph5 }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Ph 4 # contacts</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.user_contacts_made_ph4 }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Ph 3 # contacts</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.user_contacts_made_ph3 }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Ph 2 # contacts</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.user_contacts_made_ph2 }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Ph 1 # contacts</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.user_contacts_made_ph1 }} </div>
        </dd>
      </div>
    </dl>
  </div>
</template>

<script>
import dayjs from '@services/dayjsWrapper'
import { WindowVariables } from '@utils'
import axios from "@dataServices/axios";
import FlashMessages from "@services/FlashMessageService";

const baseUrl = window.location.origin

export default {
  props: {
    studentGroupMembershipId: {type: Number, required: true,},
  },
  data () {
    return {
      latestSurvey: null,
      userVentureData: null,
      gmEngagementMetadata: null,
      loaded: false
    }
  },
  computed: {
    haveExpertAdvisor () {
      return this.userVentureData.venture_have_expert_advisor === true ? "yes" :
             this.userVentureData.venture_have_expert_advisor === false ? "no" :
             null
    },
    haveAccountabilityPartner () {
      return this.userVentureData.venture_have_accountability_partner === true ? "yes" :
             this.userVentureData.venture_have_accountability_partner === false ? "no" :
             null
    },
  },
  async mounted () {
    const initDataResponse = await this.fetchInitData()
    console.info("initDataResponse", initDataResponse)
    this.latestSurvey = initDataResponse.latest_phase_survey
    this.gmEngagementMetadata = initDataResponse.gm_engagement_metadata
    this.userVentureData = initDataResponse.user_venture_data
    this.loaded = true
  },
  methods: {
    async fetchInitData() {
      try {
        const response = await axios.get(baseUrl + '/api/v2/bff/venture_tab/init_data', {params: {group_membership_id: this.studentGroupMembershipId}})
        return response.data;
      } catch (error) {
        console.error("Failed to fetch data:", error);
        FlashMessages.error("Failed to fetch data! " + error); //it's ok to show the real error because the coaches are the users
      }
    },
    toDateTime(dateTime){
      return dateTime ? dayjs(dateTime).tz(WindowVariables.timezone).format('M/D/YY h:mma'): ''
    }
  },
}
</script>

<style>

</style>