<template>
  <div class="user-engagement-summary-container pb-4 py-4 px-2">
    <h3 class="font-bold text-gray-900 text-lg text-center pb-2">Surveys</h3>

    <dl v-if="loaded" class="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Current Phase</dt>
        <dd class="text-right text-gray-900">
          <div> {{ gmEngagementData.current_sequence_name }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Current Coach</dt>
        <dd class="text-right text-gray-900">
          <div> {{ gmEngagementData.current_coach_name }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Have Accountability Partner</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.venture_have_accountability_partner }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Have Expert/Advisor</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.venture_have_expert_advisor }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">90-day Goal</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.venture_90_day_goal_translated }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Venture Priority</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.venture_priority }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Advisory Team Needs</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.venture_advisory_team_needs }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Idea Industry</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.idea_industry }} </div>
        </dd>
      </div>


      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Type of business/venture</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.business_venture_type }} </div>
        </dd>
      </div>


      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Biz desc.</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.venture_description }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Prog # contacts</dt>
        <dd class="text-right text-gray-900">
          <div> {{ userVentureData.user_contacts_made_in_program }} </div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Last Phase Survey</dt>
        <dd class="text-right text-gray-900">
          <div v-if="hasSubmittedAtLeastOneSurvey"> {{ userVentureData.last_ph_survey }} </div>
          <div v-else> No survey submitted </div>
        </dd>
      </div>

      <div v-if="hasSubmittedAtLeastOneSurvey" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Last Phase Survey Date</dt>
        <dd class="text-right text-gray-900">
          <div>{{ toDateTime(userVentureData.last_ph_survey_date) }}</div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Coach for {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.current_coach_name }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Likely to recommend {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.how_likely_to_recommend }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">How supported {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.how_supported_did_you_feel }} </div>
        </dd>
      </div>


      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Liked best about {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.liked_best_about_phase }} </div>
        </dd>
      </div>


      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Can improve in {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.can_improve_in_phase }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Wins in {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.wins_from_phase }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500"># contacts {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.user_contacts_made_in_phase }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">How confident using HTSS {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.answers.how_confident_using_htss }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">How confident Curiosity Conversations {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.answers.how_confident_curiosity_conversation }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Have accountability Partner {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.venture_have_accountability_partner }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500"># Advisors {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.answers.how_many_advisors }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Searching for Advisor skills {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.answers.what_skills_advisor_team }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Next goal {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.venture_90_day_goal }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Have mentor {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.answers.have_mentor }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Importance Online Platform {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.importance_online_platform }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Importance Videos {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.importance_videos_on_platform }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Importance Steps And Roadmap {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.importance_steps_and_roadmap }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Importance Journey Dashboard {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.importance_my_journey_dashboard }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Importance Coach Feedback {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.importance_feedback_support_from_coaches }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Importance Online Feedback {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.importance_online_workshops }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Importance Online Office Hours {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.importance_online_office_hours }} </div>
        </dd>
      </div>

      <div v-for="survey in surveys" class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Importance Online Community {{survey.sequence_analytics_name}}</dt>
        <dd class="text-right text-gray-900">
          <div> {{ survey.importance_slack_community }} </div>
        </dd>
      </div>
    </dl>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { WindowVariables } from '@utils'
import axios from "@dataServices/axios";
import FlashMessages from "@services/FlashMessageService";

const baseUrl = window.location.origin

export default {
  props: {
    studentGroupMembershipId: {type: Number, required: true,},
  },
  data () {
    return {
      momentNow: dayjs().tz(WindowVariables.timezone),
      surveysMostRecentAnswers: null,
      gmEngagementData: null,
      userVentureData: null,
      surveys: null,
      loaded: false,
    }
  },
  computed: {
    hasSubmittedAtLeastOneSurvey() {
      return this.userVentureData.last_ph_survey_date !== null
    },
  },
  async mounted() {
    const initData = await this.fetchInitData()
    this.surveysMostRecentAnswers = initData.surveys_most_recent_answers
    this.gmEngagementData = initData.gm_engagement_metadata
    this.userVentureData = initData.user_venture_data
    this.surveys = initData.surveys
    this.loaded = true
  },
  methods: {
    async fetchInitData() {
      try {
        const response = await axios.get(baseUrl + '/api/v2/bff/survey_tab/init_data', {params: {group_membership_id: this.studentGroupMembershipId}})
        return response.data;
      } catch (error) {
        console.error("Failed to fetch data:", error);
        FlashMessages.error("Failed to fetch data! " + error); //it's ok to show the real error because the coaches are the users
      }
    },
    toDateTime(dateTime){
      return dateTime ? dayjs(dateTime).tz(WindowVariables.timezone).format('M/D/YY h:mma'): ''
    }
  },
}
</script>

<style>

</style>