import { isArray } from 'lodash-es'
import { action, makeObservable } from 'mobx'

import BaseArrayClass from '@stores/BaseArrayStore'
import DataService from '@dataServices/Attachments'
import StoreInstances, { getStoreFor } from '@stores/StoreInstances'

const STORE_TYPE = 'Attachments'

export function getAttachmentsStoreFor(param) {
  return getStoreFor(AttachmentsStore, STORE_TYPE, param)
}

export class AttachmentsStore extends BaseArrayClass {

  constructor() {
    super(STORE_TYPE)

    makeObservable(this, {
      _fetchAll: action,
      create: action,
      remove: action,
    })
  }

  _fetchAll() {
    let payload = {
      ...this._filter,
    }
    return DataService.getAll(payload)
  }

  create(payload) {
    return DataService.create(payload)
      .then(rec => {
        if (!isArray(rec)) {
          rec = [rec]
        }

        return Promise.resolve(StoreInstances._propagateChanges(STORE_TYPE, rec))
      })
      .catch(Promise.reject)
  }

  remove(id) {
    return DataService.remove(id)
      .then(rec => StoreInstances._propagateChanges(STORE_TYPE, [rec]))
      .catch(Promise.reject)
  }
}
