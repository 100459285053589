<template>
  <div>
    <div class="flex flex-row justify-between">
      <div>
        <h2 class="text-xl">{{task.phase_name}}</h2>
        <h2 class="text-xl text-gray-500">{{task.course_step_name}}</h2>
      </div>

      <div class="text-right">
        <h2 class="text-xl">
          {{task.task_type }}
        </h2>
        <ActionModalDropdown
            :user="task.student_user_id"
            :groupId="task.group_id"
            :groupMembershipId="task.student_group_membership_id"
        />
      </div>
    </div>

    <div>
      <div class="pt-4">
        <b>Created At:</b>
        <span class="pl-4">{{ formatTimestamp(task.created_at) }}</span>
      </div>
      <div v-if="isTaskInProgress" class="pt-4">
        <b>Marked In Progress At:</b>
        <span class="pl-4">{{ formatTimestamp(task.marked_in_progress_at) }}</span>
      </div>
      <div v-if="isTaskCompleted" class="pt-4">
        <b>Completed At:</b>
        <span class="pl-4">{{ formatTimestamp(task.completed_at) }}</span>
      </div>
    </div>

    <div class="pt-4" v-if="task.in_progress_at">
      <b>Last Task In Progress At:</b>
      <span class="pl-4">{{ formatTimestamp(task.in_progress_at) }}</span>
    </div>

    <div v-if="task.isLowFeedback" class="flex flex-row pt-4">
      <b>Feedback:</b> <span class="pl-4">{{ task.metadata['course_step_feedback_message'] }}</span>
    </div>


    <div v-if="taskDescription" class="flex flex-row pt-4">
      <b>Description:</b> <span class="pl-4">{{ taskDescription }}</span>
    </div>

    <div v-if="task.metadata" class="flex flex-row pt-4">
      <b>Source:</b> <span class="pl-4">{{ task.metadata.source_type }}</span>
    </div>

    <div class="flex justify-evenly">
      <button class="btn btn-primary" @click="completeTask" :disabled="isTaskCompleted"><i class="fa fa-check"></i>&nbsp;&nbsp;Complete Task</button>
      <button class="btn btn-primary" @click="markInProgressTask" :disabled="!isTaskOpen" >Mark In Progress</button>
    </div>
  </div>
</template>

<script>
  import EventBus from "@services/GlobalEventBus";
  import FlashMessages from '@services/FlashMessageService'
  import dayjs from '@services/dayjsWrapper'

  import ActionModalDropdown from '@components/ActionModalDropdown.vue'
  import TaskSequenceIcon from '@components/tasks/TaskSequenceIcon.vue'
  import TaskTypeIcon from '@components/tasks/TaskTypeIcon.vue'

  import axios from "@dataServices/axios";
  import {WindowVariables} from "@utils";
  import TaskStatusIcon from "@components/tasks/TaskStatusIcon.vue";


  const NAME = "task-card"
  const baseUrl = window.location.origin

  export default {
    name: NAME,
    mixins: [ ],
    components: {
      TaskStatusIcon,
      TaskSequenceIcon,
      TaskTypeIcon,
      ActionModalDropdown,
    },
    props: {
      task: { type: Object, required: true },
    },
    data () {
      return {

      }
    },
    async mounted() {

    },
    computed: {
      isTaskOpen() {
        return this.task.state === 'open'
      },
      isTaskInProgress() {
        return this.task.state === 'in_progress'
      },
      isTaskCompleted() {
        return this.task.state === 'completed'
      },
      isManualEngagement() {
        return this.task.task_type === 'manual-engagement-followup'
      },
      isTriageTask() {
        return this.task.task_type === 'triage-launchers-engagement-followup'
      },
      taskDescription() {
        return this.isManualEngagement ? this.task.metadata_description :
               this.isTriageTask ? this.task.description :
               null
      },
    },
    methods: {
      formatTimestamp(timestampString) {
        return timestampString ?
                dayjs(timestampString).tz(WindowVariables.timezone).format('M/D/YY h:mma') :
                "";
      },
      async completeTask() {
        try {
          const response = await axios.post(baseUrl + `/api/v2/bff/coach_tasks_per_student/tasks/${this.task.id}/complete`)
          this.task.state = response.data.task.state
          this.task.completed_at = response.data.completed_at
          FlashMessages.success(this.$t('application.tasks.flash.task_completion_success'))
          EventBus.dispatch('refresh-task-list')

          return response.data;
        } catch (error) {
          console.error("Failed to complete task:", error);
          FlashMessages.error(this.$t('application.tasks.flash.task_completion_error'))
        }
      },
      async markInProgressTask () {
        try {
          const response = await axios.post(baseUrl + `/api/v2/bff/coach_tasks_per_student/tasks/${this.task.id}/mark_in_progress`)
          this.task.state = response.data.task.state
          this.task.marked_in_progress_at = response.data.marked_in_progress_at

          FlashMessages.success(this.$t('application.tasks.flash.task_in_progress_success'))
          EventBus.dispatch('refresh-task-list')

          return response.data;
        } catch (error) {
          console.error("Failed to mark in progress task:", error);
          FlashMessages.error(this.$t('application.tasks.flash.task_in_progress_error'))
        }
      },
    },
    watch: {
    }
  }


</script>

<style lang="scss">

</style>
