<template>
  <div class="interaction-closed">
    <div class="interaction-event-row">
      <div class="text">{{ $t('application.interaction_timeline.event_closed.conversation_was_closed_by') }}</div>
      <div class="username">{{ event.value.sentByName }} @ {{ event.value.sentTimeFormatted }}</div>
    </div>
  </div>
</template>

<script>
import UserThumbnail from '@components/general/UserThumbnail.vue'

  export default {
    name: 'interaction-closed',
    components: {
      UserThumbnail,
    },
    props: {
      event: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
.interaction-closed {
  margin: 0 -16px 16px -16px;
}

.interaction-event-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  background-color: lighten($htss-purple, 45%);
  padding-top: 3px;
  padding-bottom: 3px;
  color: black;
}

.text {
  width: 100%;
}

.username {
  margin: 0 auto;
  font-size: 0.9rem;
}
</style>
