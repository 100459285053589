<template>
  <modal
    :name="modalName"
    class="tasks-per-student-modal"
    :scrollable="true"
    width="90%"
    height="auto"
    @closed="destroyModal">
    <div class="close" @click="destroyModal" style="position: absolute; right: 18px; top: 8px; z-index: 999; background-color: white; padding: 4px; color: #767676; font-size: 22px; opacity: .75; border-radius: 4px;">
      <i class="fa fa-times"></i>
    </div>

    <div v-if="loaded" class="grid grid-cols-6 gap-4 px-4 py-4">
      <div class="col-span-3">
        <TaskUserHeader
            :user-id="user.id"
            :user-first-name="user.first_name"
            :user-last-name="user.last_name"
            :user-email="user.email"
            :user-phone-number="user.phone_number"
        />
        <div class="filter-container flex-1 pr-2 max-w-xs">
          <label for="action-types-dropdown" class="block">Task State</label>

          <multiselect
              v-model="filters.taskStates"
              track-by="type"
              label="name"
              :multiple="true"
              :options="filterOptions.taskStates">
            <template #selection="{ values, isOpen }">
              <span v-if="values.length === 1">{{ values[0].name }}</span>
              <span v-else-if="values.length > 1">{{ values.length }} selected</span>
            </template>
          </multiselect>

          <button @click="refreshTasksDebounced" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
          </button>
        </div>

        <v-row class="my-4 mx-0" justify="center">
          <v-expansion-panels accordion v-model="tasksCurrentPanel">
            <v-expansion-panel
                v-for="task in tasks"
                :key="task.id"
            >
              <v-expansion-panel-header>
                <div class="task-header">
                  <div class="w-6"><TaskTypeIcon :taskType="task.task_type"/></div>
                  <div class="w-6"><TaskStatusIcon :taskState="task.state"/></div>
                  <span><b>Created At:</b>{{ formatDate(task.created_at) }}</span>
                  <span><b>Step:</b>{{ task.course_step_name }}</span>
                  <span v-if="task.coach_user_name"><b>Coach:</b>{{ task.coach_user_name }}</span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <TaskCard :task="task" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>

      </div>

      <div class="col-span-3 border-l-2 border-gray-200">
        <TaskUserEngagementTabs
            :user="user"
            :group-id="studentGroupMembership.group_id"
            :student-group-membership-id="studentGroupMembershipId"
            :selected-task="selectedTask"
        />
      </div>
    </div>

    <div v-if="loaded">
      <StarterTracking
          :groupId="studentGroupMembership.group_id"
          :userId="studentGroupMembership.user_id"
          :showLeftColumn="false"
          :hasIconLegend="false"
      />
    </div>
  </modal>
</template>

<script>
  import EventBus from "@services/GlobalEventBus";

  import {cloneDeep, debounce} from 'lodash-es'
  import ModalMixin from '@mixins/ModalMixin.vue'
  import FlashMessages from '@services/FlashMessageService'
  import dayjs from '@services/dayjsWrapper'

  import TaskTypeIcon from '@components/tasks/TaskTypeIcon.vue'
  import StarterTracking from '@components/StarterTracking.vue'

  import { VExpansionPanel, VExpansionPanels, VExpansionPanelHeader, VExpansionPanelContent } from 'vuetify/lib'

  import axios from "@dataServices/axios";
  import {WindowVariables} from "@utils";
  import TaskStatusIcon from "@components/tasks/TaskStatusIcon.vue";
  import Multiselect from "vue-multiselect";
  import TaskCard from "@components/tasks/TaskCard.vue";
  import TaskUserHeader from "@components/tasks/TaskUserHeader.vue";
  import TaskUserEngagementTabs from "@components/tasks/TaskUserEngagementTabs.vue";

  const NAME = "tasks-per-student-modal"
  const baseUrl = window.location.origin

  export default {
    name: NAME,
    mixins: [ ModalMixin ],
    components: {
      TaskUserEngagementTabs,
      TaskUserHeader,
      TaskCard,
      Multiselect,
      TaskStatusIcon,
      TaskTypeIcon,
      VExpansionPanel,
      VExpansionPanels,
      VExpansionPanelHeader,
      VExpansionPanelContent,
      StarterTracking,
    },
    data () {
      return {
        modalName: NAME,
        studentGroupMembershipId: this.params.studentGroupMembershipId,
        selectedTaskId: this.params.selectedTaskId, //optional

        filters: {
          taskStates: null
        },
        filterOptions: {
          taskStates: [
            { type: 'open', name: 'Open' },
            { type: 'in_progress', name: 'In Progress' },
            { type: 'completed', name: 'Completed' },
          ]
        },
        user: null,
        studentGroupMembership: null,
        tasks: null,
        tasksCurrentPanel: null,

        selectedTask: null,
        loaded: false,
      }
    },
    async mounted() {
      this.filters.taskStates = this.filterOptions.taskStates.filter(t => t.type === 'open' || t.type === 'in_progress')
      await this.refreshInitData()
      await this.refreshTasks()
      this.loaded = true
    },
    computed: {

    },
    methods: {
      initSelectedTask(tasks) {
        const foundSelectedTask = tasks.find(t => t.id === this.selectedTaskId)
        const taskIdToSelect = foundSelectedTask ? foundSelectedTask.id :
                               tasks.length > 0 ? this.tasks[0].id :
                               null;
        this.selectTask(taskIdToSelect, tasks)
      },
      selectTask(taskId, tasks){
        this.selectedTask = tasks.find(t => t.id === taskId)

        this.tasksCurrentPanel = this.selectedTask ?
                                 tasks.map(t => t.id).indexOf(this.selectedTask.id) :
                                 null;

      },
      async refreshInitData() {
        const initData = await this.fetchInitData()
        this.user = initData.user
        this.studentGroupMembership = initData.student_group_membership
      },
      async refreshTasks() {
        this.tasks = await this.fetchTasks();
      },
      refreshTasksDebounced: debounce(async function() { await this.refreshTasks(); }, 250),
      async fetchInitData() {
        try {
          const request = { params: { student_group_membership_id: this.studentGroupMembershipId} }
          const response = await axios.get(baseUrl + '/api/v2/bff/coach_tasks_per_student/init_data', request)
          return response.data;
        } catch (error) {
          console.error("Failed to fetch data:", error);
          FlashMessages.error("Failed to fetch data! " + error); //it's ok to show the real error because the coaches are the users
        }
      },
      async fetchTasks() {
        try {
          const request = this.getFetchTasksRequest()
          const response = await axios.get(baseUrl + '/api/v2/bff/coach_tasks_per_student/tasks', request)
          return response.data.tasks;
        } catch (error) {
          console.error("Failed to fetch data:", error);
          FlashMessages.error("Failed to fetch data! " + error); //it's ok to show the real error because the coaches are the users
        }
      },
      getFetchTasksRequest(){
        return {
          params: {
            student_group_membership_id: this.studentGroupMembershipId,
            task_states: this.filters.taskStates.map(t => t.type)
          }
        }
      },
      formatDate(dateString) {
        return dateString ?
               dayjs(dateString).tz(WindowVariables.timezone).format('M/D/YY') :
               "";
      },
    },
    watch: {
      filters: {
        deep: true,
        handler(newVal) {
          this.refreshTasksDebounced()
        },
      },
      tasks(newVal, oldVal){
        if (newVal !== oldVal) {
          this.$nextTick(() => {
            this.initSelectedTask(newVal)
          })
        }
      },
      tasksCurrentPanel(newVal, oldVal){
        if (newVal !== oldVal) {
          const selectedTaskId = newVal != null && this.tasks.length > 0? this.tasks[newVal].id : null
          this.selectTask(selectedTaskId, this.tasks)
        }
      },
    }
  }

</script>

<style lang="scss">
  .tasks-per-student-modal .v--modal {
    height: auto;
    width: 75%;
  }

    .input :is(textarea,select,input) {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding:  12px;
    width: full;
    margin-top: 4px;
  }

  .task-header {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .step-timeline-no-task-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    span {
      font-size: 24px;
      color: gray;
    }
  }

  .filter-container {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;

    label {
      white-space: nowrap;
      margin-bottom: 0;
    }
  }


</style>
