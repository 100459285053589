<template>
  <modal
      :name="modalName"
      class="course-management-update-element-modal"
      :scrollable="true"
      height="auto"
      @closed="close">
    <div class="close" @click="close">
      <i class="fa fa-times"></i>
    </div>

    <div class="modal-content">
      <h2 v-if="isUpdateMode">Update Element</h2>
      <h2 v-if="isCreateMode">Create Element</h2>
      <div>
        <v-alert v-model="alertVisible" type="error" dismissible>
          {{ errorMessage }}
        </v-alert>
      </div>

      <form @submit.prevent="submit" class="form">
        <div class="form-group">
          <label for="id">Id:</label>
          <input type="text" id="id" v-model="formElement.id" :disabled="true" class="disabled-input" />
        </div>

        <div class="form-group">
          <label for="type">Type:</label>
          <select id="type" v-model="elementTypeSwitch" >
            <option v-for="option in typeOptions" :key="option" :value="option">{{ option }}</option>
          </select>
        </div>

        <div class="form-group" v-if="isIframeElement">
          <label for="iframe-url">Iframe Url:</label>
          <input type="text" id="iframe-url" v-model="formElement.content.iframe_url" />
        </div>

        <v-switch v-if="isVideoElement" v-model="attachmentTypeSwitch" :label="attachmentTypeSwitchLabel"></v-switch>

        <div class="form-group" v-if="isVideoElement && isUseSavedAttachment">
          <label for="attachment-id">Attachment Id:</label>
          <input type="number" id="attachment-id" v-model.number="formElement.attachment_id" />
        </div>

        <div class="form-group" v-if="isVideoElement && isCreateNewAttachment">
          <label for="new-attachment-version">Version:</label>
          <input type="number" id="new-attachment-version" v-model.number="formElement.new_attachment._v" />
        </div>

        <div class="form-group" v-if="isVideoElement && isCreateNewAttachment">
          <label for="new-attachment-base-url">Base Url:</label>
          <input type="text" id="new-attachment-base-url" v-model="formElement.new_attachment.base_url" />
        </div>

        <div class="form-group" v-if="isVideoElement && isCreateNewAttachment">
          <label for="new-attachment-path">Path:</label>
          <input type="text" id="new-attachment-path" v-model="formElement.new_attachment.path" />
        </div>

        <div class="form-group" v-if="isVideoElement && isCreateNewAttachment">
          <label for="new-attachment-file-type">File Type:</label>
          <input type="text" id="new-attachment-file-type" v-model="formElement.new_attachment.file_type" />
        </div>

        <div class="form-group" v-if="isVideoElement && isCreateNewAttachment">
          <label for="new-attachment-chapter-languages">Chapter Languages:</label>
          <input type="text" id="new-attachment-chapter-languages" v-model="formElement.new_attachment.chapter_languages" />
        </div>

        <div class="form-group" v-if="isVideoElement && isCreateNewAttachment">
          <label for="new-attachment-subtitle-languages">Subtitle Languages:</label>
          <input type="text" id="new-attachment-subtitle-languages" v-model="formElement.new_attachment.subtitle_languages" />
        </div>

        <button type="submit" class="submit-button">Submit</button>
      </form>
    </div>
  </modal>
</template>
<script>
  import ModalMixin from '@mixins/ModalMixin.vue'
  import axios from '@dataServices/axios'
  import EventBus from '@services/GlobalEventBus'

  import { VAlert, VSwitch } from 'vuetify/lib'

  const baseUrl = window.location.origin
  const NAME = 'course-management-update-element-modal'

  export default {
    name: NAME,
    mixins: [ModalMixin],
    components: {
      VAlert,
      VSwitch,
    },
    data() {
      return {
        modalName: NAME,
        stepId: this.params.stepId,
        elementId: this.params.elementId,
        typeOptions: ['Iframe', 'Video'],
        fetchedElement: {},
        formElement: new FormElement(),
        updated: false,
        alertVisible: false,
        elementTypeSwitch: 'Iframe',
        attachmentTypeSwitch: false,
        errorMessage: '',
      }
    },
    computed: {
      isCreateMode () {
        return this.elementId ? false : true
      },
      isUpdateMode () {
        return this.elementId ? true : false
      },
      isIframeElement(){
        return this.elementTypeSwitch === 'Iframe';
      },
      isVideoElement(){
        return this.elementTypeSwitch === 'Video';
      },
      isUseSavedAttachment(){
        return this.attachmentTypeSwitch === false;
      },
      isCreateNewAttachment(){
        return this.attachmentTypeSwitch === true;
      },
      attachmentTypeSwitchLabel() {
        return this.attachmentTypeSwitch? 'Create New Attachment' : 'Use Saved Attachment';
      },
    },
    created() {
    },
    mounted() {
      if (this.isUpdateMode){
        this.fetchElementData();
      }
    },
    methods: {
      close() {
        const eventPayload = { type: 'closed', updated: this.updated}
        EventBus.dispatch(NAME, eventPayload)
        this.destroyModal()
      },
      instantiateRequest(){
        return this.isIframeElement ? new IframeElementRequest(this.stepId, this.formElement) :
               this.isUseSavedAttachment ? new VideoElementSavedAttachmentRequest(this.stepId, this.formElement) :
               new VideoElementNewAttachmentRequest(this.stepId, this.formElement);
      },
      fetchElementData(){
        return axios
              .get(baseUrl + `/backoffice/api/elements/${this.elementId}`)
              .then(response => this.processFetchElementDataResponse(response))
              .catch(error => this.processResponseError(error))
      },
      createElement(){
        const request = this.instantiateRequest();
        return axios
              .post(baseUrl + `/backoffice/api/elements`, request)
              .then(response => this.processUpdateResponse(response))
              .catch(error => this.processResponseError(error))
      },
      updateElement(){
        const request = this.instantiateRequest()
        return axios
              .put(baseUrl + `/backoffice/api/elements/${this.elementId}`, request)
              .then(response => this.processUpdateResponse(response))
              .catch(error => this.processResponseError(error))
      },
      processFetchElementDataResponse (response) {
        this.fetchedElement = response.data;
        this.formElement = new FormElement(this.fetchedElement);
      },
      processUpdateResponse (response) {
        this.updated = true;
        this.step = response.data;
        this.close();
      },
      processResponseError (error) {
        this.errorMessage = error.response? error.response.data.message : error.message;
        this.alertVisible = true;
        console.error('Backend Error:', this.errorMessage, error.response?.data, error.response?.status);
      },
      submit () {
        if (this.isCreateMode) {
          this.createElement();
        } else {
          this.updateElement();
        }
      },
    },
    watch: {
      'fetchedElement.type'(newVal, oldVal){
        this.elementTypeSwitch = newVal === 'StepElementIframe' ? 'Iframe' : 'Video';
      },
    },
  }

  class FormElement {
    constructor (fetchedElement = {}) {
      this.id = fetchedElement.id;
      this.content = fetchedElement.content ? fetchedElement.content : {};
      this.attachment_id = fetchedElement.attachment?.id;
      this.new_attachment = {};
    }
  }

  class IframeElementRequest {
    constructor (stepId, formElement) {
      this.step_id = stepId;
      this.element = {
        content: {
          iframe_url: formElement.content.iframe_url,
        },
      }
    }
  }

  class VideoElementSavedAttachmentRequest {
    constructor (stepId, formElement) {
      this.stepId = stepId;
      this.element = {
        attachment_id: formElement.attachment_id,
      };
    }
  }

  class VideoElementNewAttachmentRequest {
    constructor (stepId, formElement) {
      this.stepId = stepId;
      this.element = {
        new_attachment: {
          _v: formElement.new_attachment._v,
          base_url: formElement.new_attachment.base_url,
          path: formElement.new_attachment.path,
          file_type: formElement.new_attachment.file_type,
          chapter_languages: formElement.new_attachment.chapter_languages.split(',').map(item => item.trim()),
          subtitle_languages: formElement.new_attachment.subtitle_languages.split(',').map(item => item.trim()),
        },
      };
    }
  }
</script>

<style lang="scss">
.course-management-update-element-modal {

  .close {
    position: absolute;
    right: 18px;
    top: 8px;
    z-index: 999;
    background-color: white;
    padding: 4px;
    color: #767676;
    font-size: 22px;
    opacity: .75;
    border-radius: 4px;
    cursor: pointer;
  }

  .modal-content {
    padding: 20px;
  }

  .form {
    .form-group {
      display: flex;
      align-items: center;

      label {
        width: 100px;
        text-align: right;
        margin-right: 10px;
      }

      input {
        flex-grow: 1;
      }
    }

    .disabled-input {
      background-color: #e9ecef;
      color: #495057;
      cursor: not-allowed;
    }

    .submit-button {
      background-color: #007bff;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  .v-alert {
    border-radius: 8px;
    padding: 16px;

    &.error {
      background-color: #ffebee; // Light Red
      color: #c62828; // Dark Red
      border: 1px solid #ef9a9a; // Red

      .v-icon {
        color: #c62828; // Dark Red
      }
    }

    &.success {
      background-color: #e8f5e9; // Light Green
      color: #2e7d32; // Dark Green
      border: 1px solid #a5d6a7; // Green

      .v-icon {
        color: #2e7d32; // Dark Green
      }
    }
  }
}

</style>
