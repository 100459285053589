<template>
  <modal
      :name="modalName"
      class="course-management-update-step-modal"
      :scrollable="true"
      height="auto"
      @closed="close">
    <div class="close" @click="close">
      <i class="fa fa-times"></i>
    </div>

    <div class="modal-content">
      <h2 v-if="isUpdateMode">Update Step</h2>
      <h2 v-if="isCreateMode">Create Step</h2>
      <div>
        <v-alert v-model="alertVisible" type="error" dismissible>
          {{ errorMessage }}
        </v-alert>
      </div>

      <form @submit.prevent="submit" class="form">
        <div class="form-group">
          <label for="id">Id:</label>
          <input type="text" id="id" v-model="step.id" :disabled="true" class="disabled-input" />
        </div>

        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" id="name" v-model="step.name" />
        </div>

        <div class="form-group">
          <label for="analytics">Analytics Name:</label>
          <input type="text" id="analytics" v-model="step.analytics_name"  />
        </div>

        <div class="form-group">
          <label for="type">Type:</label>
          <select id="type" v-model="step.type" :disabled="isUpdateMode" :class="{'disabled-input': isUpdateMode }" >
            <option v-for="option in typeOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="lock-type">Lock:</label>
          <select id="lock-type" v-model="step.lock_type" >
            <option v-for="option in lockOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="kpi">Is Kpi:</label>
          <select id="kpi" v-model="step.is_kpi" >
            <option :value="null" ></option>
            <option :value="true" >True</option>
            <option :value="false" >False</option>
          </select>
        </div>

        <div class="form-group">
          <label for="estimated-days">Estimated Days to Complete:</label>
          <input type="number" id="estimated-days" v-model="step.estimated_days_to_complete" step="any" />
        </div>

        <div class="form-group">
          <label for="content-tags">Content Tags:</label>
          <input type="text" id="content-tags" v-model="step.attached_content_tags" />
        </div>

        <div class="form-group" v-if="isEventStep">
          <label for="event">Event Type:</label>
          <select id="event" v-model="step.event.type" >
            <option v-for="option in eventTypeOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
          </select>
        </div>

        <div class="form-group" v-if="isEventStep">
          <label for="url">Url:</label>
          <input type="text" id="url" v-model="step.event.url" />
        </div>

        <div class="form-group" v-if="isEventStep">
          <label for="date">Date:</label>
          <input type="text" id="date" v-model="step.event.date" />
        </div>

        <div class="form-group" v-if="isEventStep">
          <label for="time">Time:</label>
          <input type="text" id="time" v-model="step.event.time" />
        </div>

        <div class="form-group" v-if="isEventStep">
          <label for="description">Description:</label>
          <input type="text" id="description" v-model="step.event.description" />
        </div>

        <div class="form-group" v-if="isBenchmarkStep">
          <label for="benchmark">Benchmark Description:</label>
          <input type="text" id="benchmark" v-model="step.benchmark.description" />
        </div>

        <div class="form-group" v-if="isSurveyStep">
          <label for="survey">Survey Type:</label>
          <select id="survey" v-model="step.survey.survey_type" >
            <option v-for="option in surveyTypeOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
          </select>
        </div>

        <button type="submit" class="submit-button">Submit</button>
      </form>
    </div>
  </modal>
</template>
<script>
  import ModalMixin from '@mixins/ModalMixin.vue'
  import axios from '@dataServices/axios'
  import EventBus from '@services/GlobalEventBus'

  import { VAlert } from 'vuetify/lib'

  const baseUrl = window.location.origin
  const NAME = 'course-management-update-step-modal'

  export default {
    name: NAME,
    mixins: [ModalMixin],
    components: {
      VAlert,
    },
    data() {
      return {
        modalName: NAME,
        moduleId: this.params.module_id,
        stepId: this.params.step_id,
        step: { event: null, benchmark: null, survey: null},
        stepEvent: {},
        stepBenchmark: {},
        stepSurvey: {},
        updated: false,
        lockOptions: [],
        typeOptions: [],
        eventTypeOptions: [],
        surveyTypeOptions: [],
        alertVisible: false,
        errorMessage: '',
      }
    },
    computed: {
      isCreateMode () {
        return this.stepId ? false : true
      },
      isUpdateMode () {
        return this.stepId ? true : false
      },
      isEventStep(){
          return this.step.type === 'CourseStep::Event';
      },
      isBenchmarkStep(){
        return this.step.type === 'CourseStep::Benchmark';
      },
      isSurveyStep(){
        return this.step.type === 'CourseStep::Survey';
      },
    },
    created() {
      this.fetchTypes();
      this.fetchLockTypes();
      this.fetchEventTypes();
      this.fetchSurveyTypes();
    },
    mounted() {
      if (this.isUpdateMode){
        this.fetchStepData();
      }
    },
    methods: {
      close() {
        const eventPayload = { type: 'closed', updated: this.updated}
        EventBus.dispatch(NAME, eventPayload)
        this.destroyModal()
      },
      fetchTypes(){
        return axios
              .get(baseUrl + '/backoffice/api/step_types')
              .then(response => this.processFetchTypesResponse(response))
              .catch(error => this.processResponseError(error))
      },
      fetchEventTypes(){
        return axios
            .get(baseUrl + '/backoffice/api/step_event_types')
            .then(response => this.processFetchEventTypesResponse(response))
            .catch(error => this.processResponseError(error))
      },
      fetchSurveyTypes(){
        return axios
            .get(baseUrl + '/backoffice/api/step_survey_types')
            .then(response => this.processFetchSurveyTypesResponse(response))
            .catch(error => this.processResponseError(error))
      },
      fetchLockTypes(){
        return axios
              .get(baseUrl + '/backoffice/api/step_lock_types')
              .then(response => this.processFetchLockTypesResponse(response))
              .catch(error => this.processResponseError(error))
      },
      fetchStepData(){
        return axios
              .get(baseUrl + `/backoffice/api/steps/${this.stepId}`)
              .then(response => this.processFetchStepDataResponse(response))
              .catch(error => this.processResponseError(error))
      },
      createStep(){
        this.step.module_id = this.moduleId
        const request = new UpdateStepRequest(this.step)
        return axios
              .post(baseUrl + `/backoffice/api/steps`, request)
              .then(response => this.processUpdateResponse(response))
              .catch(error => this.processResponseError(error))
      },
      updateStep(){
        const request = new UpdateStepRequest(this.step)
        return axios
              .put(baseUrl + `/backoffice/api/steps/${this.stepId}`, request)
              .then(response => this.processUpdateResponse(response))
              .catch(error => this.processResponseError(error))
      },
      processFetchTypesResponse (response) {
        this.typeOptions = response.data.map(type => new StepType(type))
      },
      processFetchEventTypesResponse (response) {
        this.eventTypeOptions = response.data.map(type => new EventType(type))
      },
      processFetchSurveyTypesResponse (response) {
        this.surveyTypeOptions = response.data.map(type => new SurveyType(type))
      },
      processFetchLockTypesResponse (response) {
        this.lockOptions = response.data;
      },
      processFetchStepDataResponse (response) {
        this.step = response.data;
      },
      processUpdateResponse (response) {
        this.updated = true;
        this.step = response.data;
        this.close();
      },
      processResponseError (error) {
        this.errorMessage = error.response? error.response.data.message : error.message;
        this.alertVisible = true;
        console.error('Backend Error:', this.errorMessage, error.response?.data, error.response?.status);
      },
      submit () {
        if (this.isCreateMode) {
          this.createStep();
        } else {
          this.updateStep();
        }
      },
    },
    watch: {
      'step.type'(newVal, oldVal){
        if (this.isCreateMode){
          this.step.event = this.isEventStep? {} : null;
          this.step.benchmark = this.isBenchmarkStep? {} : null;
          this.step.survey = this.isSurveyStep? {} : null;
        }
      },
    },
  }

  class UpdateStepRequest {
    constructor (step) {
      this.step = step;
    }
  }

  class StepType {
    constructor (type) {
      this.value = type;
      this.label = this.#toLabel(type);
    }

    #toLabel(type){
      switch (type){
        case 'CourseStep::Step': return 'Step';
        case 'CourseStep::Event': return 'Event';
        case 'CourseStep::Benchmark': return 'Benchmark';
        case 'CourseStep::Survey': return 'Survey';
        default: return 'Unknown Type -> '+type;
      }
    }
  }

  class EventType {
    constructor (type) {
      this.value = type;
      this.label = this.#toLabel(type);
    }

    #toLabel(type){
      switch (type){
        case 'Online': return 'Online';
        case 'Mentor': return 'Mentor';
        case 'Workshop': return 'Workshop';
        case 'Coaching': return 'Coaching';
        default: return 'Unknown Type -> '+type;
      }
    }

  }

  class SurveyType {
    constructor (type) {
      this.value = type;
      this.label = this.#toLabel(type);
    }

    #toLabel(type){
      switch (type){
        case 'venture_update': return 'Venture Update';
        case 'course': return 'Course';
        case 'phase': return 'Phase';
        default: return 'Unknown Type -> '+type;
      }
    }

  }

</script>

<style lang="scss">
.course-management-update-step-modal {

  .close {
    position: absolute;
    right: 18px;
    top: 8px;
    z-index: 999;
    background-color: white;
    padding: 4px;
    color: #767676;
    font-size: 22px;
    opacity: .75;
    border-radius: 4px;
    cursor: pointer;
  }

  .modal-content {
    padding: 20px;
  }

  .form {
    .form-group {
      display: flex;
      align-items: center;

      label {
        width: 100px;
        text-align: right;
        margin-right: 10px;
      }

      input {
        flex-grow: 1;
      }
    }

    .disabled-input {
      background-color: #e9ecef;
      color: #495057;
      cursor: not-allowed;
    }

    .submit-button {
      background-color: #007bff;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  .v-alert {
    border-radius: 8px;
    padding: 16px;

    &.error {
      background-color: #ffebee; // Light Red
      color: #c62828; // Dark Red
      border: 1px solid #ef9a9a; // Red

      .v-icon {
        color: #c62828; // Dark Red
      }
    }

    &.success {
      background-color: #e8f5e9; // Light Green
      color: #2e7d32; // Dark Green
      border: 1px solid #a5d6a7; // Green

      .v-icon {
        color: #2e7d32; // Dark Green
      }
    }
  }
}

</style>
