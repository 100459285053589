import { map, omit } from 'lodash-es'
import axios from './axios'
import Mentorship from '@models/Mentorship'

const baseUrlString = '/mentorships'

async function getAll (params) {
  return axios.get(baseUrlString, { params })
    .then(response => map(response.data, initWithData))
    .catch(console.error)
}

async function create (formData) {
  return axios.post(baseUrlString,{ mentorship: formData })
    .then(response => initWithData(response.data))
    .catch(error => Promise.reject(error.response.data))
}

async function update (formData) {
  return axios.put(`${baseUrlString}/${formData.id}`,{ mentorship: omit(formData, ['id']) })
    .then(response => initWithData(response.data))
    .catch(error => Promise.reject(error.response.data))
}

function initWithData(data) {
  return new Mentorship(data)
}

export default {
  getAll,
  create,
  update
}
