import { action, makeObservable } from 'mobx'
import { isArray } from 'lodash-es'
import StoreInstances, { getStoreFor } from '@stores/StoreInstances'
import BaseArrayClass from '@stores/BaseArrayStore'
import DataService from '@dataServices/AssignmentResponses'

const STORE_TYPE = 'AssignmentResponses'

export function getAssignmentResponsesStoreFor (param) {
  return getStoreFor(AssignmentResponsesStore, STORE_TYPE, param)
}

export class AssignmentResponsesStore extends BaseArrayClass {
  constructor () {
    super(STORE_TYPE, { keepDiscarded: true })

    makeObservable(this, {
      _fetchOne: action,
      _fetchAll: action,
      create: action,
      remove: action,
    })
  }

  _fetchOne() {
    return DataService.getById({ id: this._filter.id })
  }

  _fetchAll() {
    let payload = {
      ...this._filter,
    }
    return DataService.getAll(payload)
  }

  create(record) {
    return DataService.create(record)
      .then((rec) => {
        if (!isArray(rec)) {
          rec = [ rec ]
        }
        return StoreInstances._propagateChanges(STORE_TYPE, rec)
      })
      .catch(resp => Promise.reject(resp))
  }

  remove(id) {
    return DataService.remove(id)
      .then(rec => StoreInstances._propagateChanges(STORE_TYPE, [ rec ]))
  }

  // @action
  // update (record) {
  //   let payload = {
  //     ...record,
  //   }
  //   return DataService.update(payload)
  //     .then(rec => StoreInstances._propagateChanges(STORE_TYPE, [ rec ]))
  // }
}
