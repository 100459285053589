import { isArray } from 'lodash-es'
import { action, makeObservable } from 'mobx'
import StoreInstances, { getStoreFor } from '@stores/StoreInstances'
import BaseArrayClass from '@stores/BaseArrayStore'
import DataService from '@dataServices/AssignmentResponseGrades'

const STORE_TYPE = 'AssignmentResponseGrades'

export function getAssignmentResponseGradesStoreFor (param) {
  return getStoreFor(AssignmentResponseGradesStore, STORE_TYPE, param)
}

export class AssignmentResponseGradesStore extends BaseArrayClass {
  constructor () {
    super(STORE_TYPE)

    makeObservable(this, {
      _fetchOne: action,
      _fetchAll: action,
      create: action,
      remove: action,
    })
  }

  _fetchOne() {
    return DataService.getById({ id: this._filter.id })
  }

  _fetchAll() {
    let payload = {
      ...this._filter,
    }
    return DataService.getAll(payload)
  }

  create(record) {
    return DataService.create(record)
      .then((rec) => {
        if (!isArray(rec)) {
          rec = [ rec ]
        }
        StoreInstances._propagateChanges(STORE_TYPE, rec)
        return Promise.resolve(rec[0])
      })
  }

  remove(id) {
    return DataService.remove(id)
      .then(rec => {
        StoreInstances._propagateChanges(STORE_TYPE, [ rec ])
        return Promise.resolve(rec)
      })
  }

  // @action
  // update (record) {
  //   let payload = {
  //     ...record,
  //   }
  //   return DataService.update(payload)
  //     .then(rec => StoreInstances._propagateChanges(STORE_TYPE, [ rec ]))
  // }
}
