<template>
  <v-avatar
    size="22"
    :class="iconClasses"
    :style="iconStyle"
    class="mr-2"
    v-if="task.sequence_number"
  >
    <div class="text-xs font-bold text-white">
      {{ task.sequence_number}}
    </div>
  </v-avatar>
</template>

<script>
import { VAvatar } from 'vuetify/lib'

export default {
  name: 'task-sequence-icon',
  components: {
    VAvatar,
  },
  computed: {
    iconStyle () {
      if (this.task.sequence_number === 1) {
        return { 'background-color': '#facc15' } // yellow-400
      } else if (this.task.sequence_number === 2) {
        return { 'background-color': '#f97316'} // orange-500
      } else if (this.task.sequence_number === 3) {
        return { 'background-color': '#dc2626' } // red-600
      }

    },
    iconClasses () {
      return {}
    },
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  }
}
</script>