<template>
  <div>
    <b-dropdown id="dropdown-1" text="Actions">
      <b-dropdown-item v-for="action in actionOptionsForUserId"
        :key="action.actionType"
        @click.stop="openActionModal(action.actionType)">{{action.label}}</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { every, some } from 'lodash-es'
import ModalService from '@services/ModalService'
import { WindowVariables } from '@utils'

const rawActions = [
  { actionType: 'openInteractionTimelineModal', label: 'Open Step', permitted: ['er_admin', 'gm_teacher', 'partner_admin'], requiredProps: ['courseStepId'] },
  { actionType: 'openStarterTracker', label: 'Open Tracker', permitted: ['er_admin', 'gm_teacher', 'partner_admin'] },
  { actionType: 'openTasks', label: 'Open Tasks', permitted: ['er_admin', 'gm_teacher'], requiredProps: ['groupMembershipId'] },
  { actionType: 'viewActionHistory', label: 'View Action History', permitted: ['er_admin', 'partner_admin', 'gm_teacher', 'gm_mentor'] },
  { actionType: 'addLauncherUpdateInternal', label: 'Add Launcher Update', permitted: ['er_admin', 'gm_teacher', 'gm_mentor'], exclude: ['partner_admin'] },
  { actionType: 'addLauncherUpdatePartner', label: 'Add Launcher Update', permitted: ['partner_admin'] },
  { actionType: 'addPhoneCall', label: 'Add Call Notes', permitted: ['er_admin', 'gm_teacher', 'gm_mentor'] },
  { actionType: 'markDiscontinued', label: 'Mark Discontinued', permitted: ['er_admin'] },
  { actionType: 'recordAssignedMentorGroup', label: 'Record Assigned Mentor Group', permitted: ['er_admin'] },
  { actionType: 'addDisengagementNotes', label: 'Add Disengagement Notes', permitted: ['er_admin'] },
  { actionType: 'updateUserPhoneNumber', label: 'Update Phone Number', permitted: ['er_admin'] },
  { actionType: 'editUserTags', label: 'Edit Tags', permitted: ['er_admin', 'gm_teacher'] },
  { actionType: 'createManualEngagementTask', label: 'Manual Eng. Req', permitted: ['er_admin', 'gm_teacher'] },
  { actionType: 'reviewTriageRequest', label: 'Review Triage Req', permitted: ['er_admin', 'gm_teacher'], requiredProps: ['triageRequestIds'] },
]

export default {
  name: 'action-modal-dropdown',
  props: {
    user: {required: true,},
    groupId: {type: Number,required: true,}, //student group id
    groupMembershipId: {type: Number,required: true,},
    courseStepId: {type: Number,required: false,},
    triageRequestIds: {type: Array, required: false, default: [], validator(array) { return array.length > 0 && array.every(item => typeof item === 'number'); }}
  },
  data () {
    return {}
  },
  computed: {
    isPartnerAdmin () {
      return WindowVariables.currentUserRoles.find(r => r.name === 'partner_admin')
    },
    isERAdmin () {
      return WindowVariables.currentUserRoles.find(r => r.name === 'er_admin')
    },
    hasTeacherGroupMembership () {
      return some(WindowVariables.currentUser.group_memberships, gm => gm.group_id === this.groupId && gm.role === 'teacher' && gm.state === 1)
    },
    hasMentorGroupMembership () {
      return some(WindowVariables.currentUser.group_memberships, gm => gm.group_id === this.groupId && gm.role === 'mentor' && gm.state === 1)
    },
    computedUserId(){
      return typeof this.user === 'object' ? this.user.id : this.user;
    },
    actionOptionsForUserId () {

      const excludePredicate = (excludeRole) =>
          (excludeRole === 'er_admin' && !this.isERAdmin)
          || (excludeRole === 'partner_admin' && !this.isPartnerAdmin)
          || (excludeRole === 'gm_teacher' && !this.hasTeacherGroupMembership)
          || (excludeRole === 'gm_mentor' && !this.hasMentorGroupMembership)

      const permittedPredicate = (permittedRole) =>
          (permittedRole === 'er_admin' && this.isERAdmin)
          || (permittedRole === 'partner_admin' && this.isPartnerAdmin)
          || (permittedRole === 'gm_teacher' && this.hasTeacherGroupMembership)
          || (permittedRole === 'gm_mentor' && this.hasMentorGroupMembership)

      return rawActions
             .filter(action => every(action.exclude, excludePredicate))
             .filter(action => some(action.permitted, permittedPredicate))
             .filter(action => every(action.requiredProps, v => this[v]))
    },
  },
  methods: {
    openActionModal (actionType) {
      if (actionType === 'openStarterTracker') {
        ModalService.open('starter-tracking-modal', {
          userId: this.computedUserId,
          groupId: this.groupId,
        })
      }
      else if (actionType === 'openInteractionTimelineModal') {
        ModalService.open('interaction-timeline-modal', {
            groupId: this.groupId,
            userId: this.computedUserId,
            courseStepId: this.courseStepId,
            servicePreset: {
              groupId: this.groupId,
              userId: this.userId,
            },
          })
      }
      else if (actionType === 'openTasks') {
        const payload = { studentGroupMembershipId: this.groupMembershipId, selectedTaskId: null }
        ModalService.open('tasks-per-student-modal', payload)
      }
      else if (actionType === 'editUserTags') {
        ModalService.open('user-edit-tags-modal', {
          userId: this.computedUserId,
        })
      }
      else if (actionType === 'createManualEngagementTask') {
        ModalService.open('create-manual-engagement-task-modal', {
          userId: this.computedUserId,
          groupId: this.groupId,
          groupMembershipId: this.groupMembershipId,
        })
      }
      else if (actionType === 'reviewTriageRequest') {
        ModalService.open('triage-engagement-request-modal', {
          userId: this.computedUserId,
          triageRequestIds: this.triageRequestIds,
        })
      }
      else {
        ModalService.open(actionType === 'viewActionHistory' ?  'group-membership-action-history-modal' : 'group-membership-actions-modal', {
          actionType,
          data: {
            user: this.user,
            groupId: this.groupId,
            groupMembershipId: this.groupMembershipId,
          },
        })
      }
    },
  }
}
</script>