import { action, makeObservable } from 'mobx'
import { getStoreFor } from '@stores/StoreInstances'
import BaseArrayClass from '@stores/BaseArrayStore'
import { getStepProgress } from '@dataServices/CourseStepProgresses'

const STORE_TYPE = 'CourseStepProgresses'

export function getCourseStepProgressesStoreFor (param) {
  return getStoreFor(CourseStepProgressesStore, STORE_TYPE, param)
}

export class CourseStepProgressesStore extends BaseArrayClass {
  constructor () {
    super(STORE_TYPE)

    makeObservable(this, {
      _fetchAll: action,
    })
  }

  _fetchAll() {
    return getStepProgress(
      this._filter.group_id,
      this._filter.user_id,
      this._filter.course_step_id
    )
  }
}
