<template>
  <div class="interaction-actions" v-if="showAssignmentSubmitter || showCloseConversation">
    <!-- <h5 class="interaction-actions-header" v-if="showAssignmentSubmitter">Uploads:</h5>
    <h5 class="interaction-actions-header">Attachments:</h5>-->
    <InteractionAssignmentSubmit
      :studentStepProgress="studentStepProgress"
      v-if="showAssignmentSubmitter"
    />

    <InteractionCloseConversation
      :studentStepProgress="studentStepProgress"
      v-if="showCloseConversation"
    />
    <!-- <div class="help-text">
      If you need help <a href="#" target="_blank">click here</a>.
    </div>-->
  </div>
</template>

<script>
import { WindowVariables } from '@utils'
import InteractionAssignmentSubmit from './InteractionAssignmentSubmit.vue'
import InteractionCloseConversation from './InteractionCloseConversation.vue'
import { observer } from "mobx-vue"

export default observer({
  name: 'interaction-actions',
  components: {
    InteractionAssignmentSubmit,
    InteractionCloseConversation,
  },
  data() {
    return {}
  },
  computed: {
    showAssignmentSubmitter() {
      return (
        WindowVariables.currentUser.studentForGroupId(
          this.studentStepProgress.groupId
        ) && this.canAttachActionStepsToStep
      );
    },
    showCloseConversation () {
      return this.studentStepProgress.conversation && this.studentStepProgress.conversation.isOpened &&
        !WindowVariables.currentUser.studentForGroupId(this.studentStepProgress.groupId)
    },
    canAttachActionStepsToStep() {
      return !!this.studentStepProgress.courseStep.assignment_id && this.studentStepProgress.courseStep.assignment_type === 'Assignment::Downloadable'
    }
  },
  props: {
    studentStepProgress: {
      type: Object,
      required: true,
    }
  }
})
</script>

<style lang="scss" scoped>
.interaction-actions {
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  text-align: left;
  background-color: #f8f7f9;
  padding: 1px 16px;
  border: 1px solid #eeebf2;
  border-radius: 2px;

  .help-text {
    font-size: 1rem;
    color: $medium-gray;
    margin: auto 0;
  }
}

.interaction-actions-header {
  font-size: 1rem;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}
</style>
