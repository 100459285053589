import { action, computed, reaction, observable, makeObservable } from 'mobx'
import { findIndex, reverse, sortBy } from 'lodash-es'
import StudentProgress from '@services/timelineService/StudentProgress'

class TimelineModalService {
  _currentStudentId = null
  _currentCourseStepId = null
  _currentAssignmentResponseIdx = null

  _stopFunctions = []

  constructor (timelineService, studentId, courseStepId) {
    makeObservable(this, {
      _currentStudentId: observable,
      _currentCourseStepId: observable,
      _currentAssignmentResponseIdx: observable,
      setCurrentAssignmentResponseId: action,
      currentStudentId: computed,
      setCurrentStudentId: action,
      setCurrentCourseStepId: action,
      currentCourseStepId: computed,
      currentStudentProgress: computed,
      currentStudentStepProgress: computed,
      currentAssignmentResponse: computed,
      renderObject: computed,
    })

    this._timelineService = timelineService
    this._currentStudentId = studentId
    this._currentCourseStepId = courseStepId
    this._currentAssignmentResponseIdx = this.currentStudentStepProgress
      ? this.currentStudentStepProgress.assignmentResponses.length - 1
      : 0

    this._reactionStop = reaction(
      () => this.currentStudentStepProgress && this.currentStudentStepProgress._uuid,
      (val) => {
        if (this.currentStudentStepProgress) {
          this._currentAssignmentResponseIdx = this.currentStudentStepProgress.assignmentResponses.length - 1
        }
      }
    )
  }

  dispose () {
    this._stopFunctions.forEach(stop => stop())
    this._stopFunctions = []
  }

  get currentStudentId() {
    return this._currentStudentId
  }

  get currentCourseStepId() {
    return this._currentCourseStepId
  }

  setCurrentStudentId (id) {
    this._currentStudentId = id

    if (!this._timelineService.getStudentProgress(id)) {
      const unpaginatedStudentProgress = new StudentProgress(this._timelineService, id, true)
      this._timelineService.studentProgressRecords.push(unpaginatedStudentProgress)
    }
  }

  setCurrentCourseStepId (id) {
    this._currentCourseStepId = id
  }

  setCurrentAssignmentResponseId (id) {
    if (this.currentStudentStepProgress) {
      this._currentAssignmentResponseIdx = findIndex(this.currentStudentStepProgress.assignmentResponses, { id })
    }
  }

  get currentStudentProgress() {
    return this._timelineService.getStudentProgress(this.currentStudentId)
  }

  get currentStudentStepProgress() {
    return this._timelineService.getStepProgress(this.currentStudentId, this.currentCourseStepId)
  }

  get currentAssignmentResponse() {
    if (this.currentStudentStepProgress) {
      return this.currentStudentStepProgress.assignmentResponses[this._currentAssignmentResponseIdx]
    }
  }

  get renderObject() {
    return {
      assignment_type: this.currentAssignmentResponse && this.currentAssignmentResponse.assignment_type || 'no-response',
      type: this.currentAssignmentResponse
        ? this.currentAssignmentResponse.file_identifier || 'pdf'
        : 'no-response',
      assignmentId: this.currentAssignmentResponse && this.currentAssignmentResponse.assignment_id,
      response: this.currentAssignmentResponse,
      responseOptions: this.currentStudentStepProgress
        ? reverse(sortBy(this.currentStudentStepProgress.assignmentResponses, ar => ar.submitted_at)).map(ar => {
          return { id: ar.id, label: ar.submitted_at ? ar.submitted_at.format('MM/DD/YY @ hh:mm') : 'Unsubmitted' }
        })
        : []
    }
  }
}

export default TimelineModalService
