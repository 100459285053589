<template>
  <div class="interaction-timeline">
    <!-- <template v-if="vm.studentStepProgress"> -->
    <template v-if="vm.ssp">
      <div v-if="!showReviewForm">
        <InteractionStep
          :studentStepProgress="vm.studentStepProgress"
          :courseStepProgress="vm.courseStepProgress"
          :groupMembership="vm.groupMembership"
          :showCoachPacingRisk="vm.canMakeReview"
          :showActionsButton="vm.canMakeReview"
          :showVideoProgress="vm.canMakeReview"
        />

        <v-tabs v-model="currentTb" :prev-icon="null" v-if="vm.canMakeReview">
          <v-tab tab-value="messaging">Step<br/>Timeline</v-tab>
          <v-tab tab-value="user-engagement-summary">Engagement<br/>Summary</v-tab>
          <v-tab tab-value="survey-tab">Surveys</v-tab>
          <v-tab tab-value="venture-update">Venture<br/>Update</v-tab>
          <v-tab tab-value="gm-history">Action<br/>History</v-tab>
          <v-tab tab-value="events">Activity<br/>History</v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentTb">
          <v-tab-item value="messaging">
            <InteractionActions :studentStepProgress="vm.studentStepProgress" />
            <div class="interaction-events-container" ref="scrollable">
              <InteractionEvents :studentStepProgress="vm.studentStepProgress" />
            </div>
            <InteractionInput :studentStepProgress="vm.studentStepProgress" @conversationCreated="onMessageSent" />
            <button
              v-if="vm.canMakeReview"
              class="review-primary"
              @click="setReviewFormDisplay(true)"
            >
              <span v-text="$t(vm.reviewButtonKey)"></span>&nbsp;&nbsp;<i class="fa fa-arrow-right"></i>
            </button>
          </v-tab-item>

          <v-tab-item value="user-engagement-summary">
            <UserEngagementSummary :studentGroupMembershipId="vm.groupMembership.id"/>
          </v-tab-item>

          <v-tab-item value="survey-tab">
            <div class="interaction-events-container" ref="scrollable">
              <SurveyAnswersTab :studentGroupMembershipId="vm.groupMembership.id"/>
            </div>
          </v-tab-item>

          <v-tab-item value="venture-update">
            <div class="interaction-events-container" ref="scrollable">
              <VentureUpdate :studentGroupMembershipId="vm.groupMembership.id"/>
            </div>
          </v-tab-item>

          <v-tab-item value="gm-history">
            <group-membership-action-history :hideHeader="true" :groupMembershipId="vm.groupMembership.id" :user="vm.studentStepProgress.user"/>
          </v-tab-item>

          <v-tab-item value="events">
            <user-analytics-events v-if="vm.studentStepProgress.user && vm.studentStepProgress.user.id" :user="vm.studentStepProgress.user.id"/>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div v-if="showReviewForm && vm.canMakeReview">
        <InteractionReview
          :studentStepProgress="vm.studentStepProgress"
          @reviewSubmitted="setReviewFormDisplay(false)"
          @cancel="setReviewFormDisplay(false)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { computed, makeObservable, observable, runInAction } from "mobx"
import { observer } from "mobx-vue"
import { find, last } from 'lodash-es'

import InteractionInput from "@components/interactionTimeline/InteractionInput.vue"
import InteractionEvents from "@components/interactionTimeline/InteractionEvents.vue"
import InteractionStep from "@components/interactionTimeline/InteractionStep.vue"
import InteractionReview from "@components/interactionTimeline/InteractionReview.vue"
import InteractionActions from "@components/interactionTimeline/InteractionActions.vue"
import InteractionAssignmentSubmit from "@components/interactionTimeline/InteractionAssignmentSubmit.vue"
import UserAnalyticsEvents from '@components/UserAnalyticsEvents.vue'
import VentureUpdate from '@components/VentureUpdate.vue'
import SurveyAnswersTab from "@components/SurveyAnswersTab.vue";
import UserEngagementSummary from '@components/UserEngagementSummary.vue'
import TimelineServiceManager from '@services/timelineService/ServiceManager'
import GroupMembershipActionHistory from '@components/GroupMembershipActionHistory.vue'
import { WindowVariables } from "@utils"

import { VTab, VTabItem, VTabsItems, VTabs } from 'vuetify/lib'

class ViewModel {
  timelineService = null
  userId = null
  courseStepId = null
  groupId = null
  servicePreset = null
  groupMembership = null
  sspObs = null
  ssp = null

  constructor(params) {
    makeObservable(this, {
      studentStepProgress: computed,
      courseStep: computed,
      assignmentResponses: computed,
      courseStepProgress: computed,
      groupMembership: observable,
      ssp: observable,
    })

    this.courseStepId = params.courseStepId
    this.groupId = params.groupId
    this.userId = params.userId
    this.servicePreset = params.servicePreset
    this.sspObs = observable.box(null)

    this.timelineService = TimelineServiceManager.getServiceFor('interaction-timeline', this.servicePreset)

    this.timelineService.registerDataReadyFn(() => {

      // TODO: what about making this a hash to access here instead of this workaround?
      const res = this.timelineService.getStepProgress(this.userId, this.courseStepId)
      if (res) {
        runInAction(() => {
          this.groupMembership = find(res.user.group_memberships, gm => gm.group_id === res.groupId)
          this.sspObs.set(res)
          this.ssp = res
        })
      }

      return !!res
    })
  }

  get studentStepProgress () {
    return this.sspObs.get()
  }

  get courseStep () {
    return this.studentStepProgress && this.studentStepProgress.courseStep
  }

  get assignmentResponses () {
    return this.studentStepProgress && this.studentStepProgress.assignmentResponses
  }

  get courseStepProgress() {
    return this.timelineService.courseStepProgress
  }

  get reviewButtonKey () {
    if (this.courseStep.isEvent) {
      return 'application.common.mark_attendance'
    } else {
      return 'application.common.review'
    }
  }

  get canMakeReview () {
    return this.studentStepProgress && (
      ((this.courseStep.hasAssignment && this.courseStep.isStep) || this.courseStep.isBenchmark || this.courseStep.isEvent) &&
      (WindowVariables.currentUser.teacherForGroupId(this.groupId) ||
        WindowVariables.currentUser.mentorForGroupId(this.groupId))
    )
  }

  get currentAssignmentResponse () {
    return this.assignmentResponses && last(this.assignmentResponses)
  }

  get viewerUrl () {
    if (
      this.currentAssignmentResponse &&
      this.currentAssignmentResponse.getRelation('attachments') &&
      this.currentAssignmentResponse.getRelation('attachments').length > 0
    ) {
      return this.currentAssignmentResponse.getRelation('attachments')[0].presigned_url
    } else {
      return null
    }
  }
}

export default observer({
  name: "interaction-timeline",
  components: {
    VTab,
    VTabs,
    VTabItem,
    VTabsItems,
    InteractionEvents,
    InteractionInput,
    InteractionStep,
    InteractionReview,
    InteractionActions,
    InteractionAssignmentSubmit,
    UserAnalyticsEvents,
    UserEngagementSummary,
    GroupMembershipActionHistory,
    VentureUpdate,
    SurveyAnswersTab,
  },
  data () {
    const serviceFilter = this.servicePreset || {
      groupId: this.groupId,
      userId: this.userId,
    }

    return {
      vm: new ViewModel({
        groupId: this.groupId,
        userId: this.userId,
        courseStepId: this.courseStepId,
        servicePreset: serviceFilter
      }),
      currentTb: 'messaging',
      showReviewForm: false
    }
  },
  methods: {
    onMessageSent() {
      this.$refs.scrollable.scrollTop = 0
    },
    setReviewFormDisplay(state) {
      this.showReviewForm = state
    },
  },
  watch: {
    // viewerUrl(newVal, oldVal) {
    //   this.$emit("viewerUrlChanged", this.viewerUrl)
    // },
    showReviewForm(newVal) {
      this.$forceUpdate()
    },
    currentTb() {
      this.$forceUpdate()
    },
  },
  created() {
    this.$watch('vm.viewerUrl', () => {
      this.$emit('viewerUrlChanged', this.vm.viewerUrl)
    })
  },
  //   if (this.dataReady) {
  //     this.fetchStudentStepProgress()
  //   } else {
  //     const dereg = this.$watch("dataReady", (newVal, oldVal) => {
  //       if (!oldVal && newVal) {
  //         setTimeout(() => {
  //           this.fetchStudentStepProgress()
  //           dereg()
  //         }, 250)
  //       }
  //     })
  //   }
  // },
  beforeDestroy () {
    TimelineServiceManager.deregister(this.vm.timelineService, 'interaction-timeline')
  },
  props: {
    groupId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    courseStepId: {
      type: Number,
      required: true,
    },
    servicePreset: {
      required: false,
    },
  },
})
</script>

<style lang="scss">
.interaction-timeline {
  .v-window__container {
    height: 100%;

    .v-window-item {
      height: inherit;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>

<style lang="scss" scoped>
.interaction-timeline {
  display: flex;
  min-height: 0;
  flex-grow: 1;
  padding-top: 0.5rem;

  > div {
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
  }
}

.interaction-events-container {
  scroll-behavior: smooth; // this is experimental and not yet on... will be cool when it turns on!
  overflow-y: scroll;
  max-height: 675px;
  flex: 1 1 auto;
}


.review-primary {
  background-color: #f3763a;
  color: #fff;
  padding: 12px 24px;
  border-radius: 5px;
  border: 1px solid #f3763a;
  cursor: pointer;
  flex: 0 0 48px;
  margin-top: 8px;

  &:hover {
    background-color: #e6692e;
    color: #fff;
    border-radius: 8px;
  }

  transition: all 500ms ease;
}
</style>
