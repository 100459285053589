<template>
  <div class="interaction-event">
    <InteractionEventMessage v-if="isMessage" :message="event.value"/>
    <InteractionEventReview v-if="isCourseStepReview" :review="event.value"/>
    <InteractionEventAttachment v-if="isAttachment" :attachment="event.value"/>
    <InteractionEventAssignment v-if="isAssignment" :assignment="event.value"/>
  </div>
</template>

<script>

import InteractionEventMessage from '@components/interactionTimeline/InteractionEventMessage.vue'
import InteractionEventReview from '@components/interactionTimeline/InteractionEventReview.vue'
import InteractionEventAttachment from '@components/interactionTimeline/InteractionEventAttachment.vue'
import InteractionEventAssignment from '@components/interactionTimeline/InteractionEventAssignment.vue'

  export default {
    name: 'interaction-event',
    components: {
        InteractionEventMessage,
        InteractionEventReview,
        InteractionEventAttachment,
        InteractionEventAssignment,
    },
    data () {
      return {
      }
    },
    computed: {
      isMessage() {
        return this.event.type === 'message'
      },
      isCourseStepReview() {
        return this.event.type === 'courseStepReview'
      },
      isAttachment() {
        return this.event.type === 'attachment'
      },
      isAssignment() {
        return this.event.type === 'assignmentResponse'
      },
    },
    props: {
      event: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .interaction-event {
  }
</style>
