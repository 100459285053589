import { map, sortBy } from 'lodash-es'

import Message from '@models/ConversationMessage'
import NewModel from '@models/NewModel'
import { WindowVariables } from '@utils'

class Conversation extends NewModel {
  constructor (initialData, included) {
    // initialData.messages = sortBy(map(initialData.messages, (msg) => {
    //   return msg
    //   // return new Message(msg)
    // }, 'created_at'))

    super(initialData, included, {
      columns: [
        'id',
        'metadata',
      ],
      momentKeys: [
        'created_at',
        // 'updated_at', // turn off for perf. unused
      ],
      relationships: {
        messages: {
          model: Message,
          collection: true,
        },
      },
    })
  }

  get messages () {
    return this.getRelation('messages')
  }

  get isOpened () {
    return !this.isClosed
  }

  get isClosed () {
    return this.metadata.closed
  }

  get conversationWaitingForCurrentUser () {
    return this.metadata.last_message_by_role !== WindowVariables.currentUser.role
  }
}

export default Conversation
