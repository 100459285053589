import { map } from 'lodash-es'
import axios from './axios'
import Attachment from '@models/Attachment'

async function getAll(params) {
  return axios.get('/attachments', { params })
    .then(response => map(response.data.data, r => initWithData(r, response.data.included)))
    .catch(resp => Promise.reject(resp))
}

async function create(data) {
  return axios.post('/attachments', data)
    .then(response => initWithData(response.data.data, response.data.included))
    .catch(console.error)
}

async function remove(id) {
  return axios['delete'](`/attachments/${id}`, id)
    .then(response => initWithData(response.data.data, response.data.included))
    .catch(console.error)
}

function initWithData(data, included) {
  return new Attachment(data, included)
}

export default {
  getAll,
  create,
  remove,
}
