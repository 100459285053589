import StoreInstances, { getStoreFor } from '@stores/StoreInstances'
import { action, makeObservable } from 'mobx'
import { isArray } from 'lodash-es'
import BaseArrayClass from '@stores/BaseArrayStore'
import DataService from '@dataServices/Mentorship'

const STORE_TYPE = 'Mentorships'

export function getMentorshipsStoreFor (param) {
  return getStoreFor(MentorshipsStore, STORE_TYPE, param)
}

export class MentorshipsStore extends BaseArrayClass {
  constructor () {
    super(STORE_TYPE)

    makeObservable(this, {
      _fetchAll: action,
      create: action,
      update: action,
    })
  }

  _fetchAll() {
    let payload = {
      ...this._filter,
    }
    return DataService.getAll(payload)
  }

  create(record) {
    return DataService.create(record)
      .then((rec) => {
        if (!isArray(rec)) {
          rec = [ rec ]
        }
        return StoreInstances._propagateChanges(STORE_TYPE, rec)
      })
      .catch(resp => Promise.reject(resp))
  }

  update(record) {
    return DataService.update(record)
      .then((rec) => {
        if (!isArray(rec)) {
          rec = [ rec ]
        }
        return StoreInstances._propagateChanges(STORE_TYPE, rec)
      })
      .catch(resp => Promise.reject(resp))
  }
}
