import { computed, observable, makeObservable, reaction } from 'mobx'
import { sortBy } from 'lodash-es'
import { ConversationsStore, getConversationsStoreFor } from '@stores/ConversationsStore'
import { UsersStore, getUsersStoreFor } from '@stores/UsersStore'
import { CourseStepsStore, getCourseStepsStoreFor } from '@stores/CourseStepsStore'
import { CourseStepReviewsStore, getCourseStepReviewsStoreFor } from '@stores/CourseStepReviewsStore'
import { CourseStepProgressesStore, getCourseStepProgressesStoreFor } from '@stores/CourseStepProgressesStore'
import { AttachmentsStore, getAttachmentsStoreFor } from '@stores/AttachmentsStore'
import { AssignmentResponsesStore, getAssignmentResponsesStoreFor } from '@stores/AssignmentResponsesStore'
import { WindowVariables } from '@utils'

class TimelineServiceDataStore {
  /**
   * @type {ConversationsStore}
   */
  _conversationsStore = getConversationsStoreFor()

  /**
   * @type {UsersStore}
   */
  _usersStore = getUsersStoreFor()

  /**
   * @type {CourseStepsStore}
   */
  _courseStepsStore = getCourseStepsStoreFor()

  /**
   * @type {CourseStepProgressesStore}
   */
  _courseStepProgressesStore = getCourseStepProgressesStoreFor()

  /**
   * @type {CourseStepReviewsStore}
   */
  _courseStepReviewsStore = getCourseStepReviewsStoreFor()

  /**
   * @type {AttachmentsStore}
   */
  _attachmentsStore = getAttachmentsStoreFor()

  /**
   * @type {AssignmentResponsesStore}
   */
  _assignmentResponsesStore = getAssignmentResponsesStoreFor()
  _groupId = null;

  _stopFunctions = []

  get allConversations() {
    return this._conversationsStore && this._conversationsStore.allRecords
  }

  get allUsers() {
    return this._usersStore && this._usersStore.allRecords
  }

  get allCourseSteps() {
    return this._courseStepsStore && this._courseStepsStore.allRecords
  }

  get allCourseStepReviews() {
    return this._courseStepReviewsStore && this._courseStepReviewsStore.allRecords
  }

  get allAttachments() {
    return this._attachmentsStore && this._attachmentsStore.allRecords
  }

  get allAssignmentResponses() {
    return this._assignmentResponsesStore && sortBy(this._assignmentResponsesStore.allRecords, 'id')
  }

  get attachmentsStore() {
    return this._attachmentsStore
  }

  get assignmentResponsesStore() {
    return this._assignmentResponsesStore
  }

  get courseStepReviewsStore() {
    return this._courseStepReviewsStore
  }

  get courseStepsStore() {
    return this._courseStepsStore
  }

  get courseStepProgressesStore() {
    return this._courseStepProgressesStore
  }

  get allCourseStepProgresses() {
    return this._courseStepProgressesStore && this._courseStepProgressesStore.allRecords
  }

  get conversationsStore() {
    return this._conversationsStore
  }

  get loading() {
    return this._usersStore.loading && this._courseStepsStore.loading && this._courseStepProgressesStore.loading
  }

  get groupId() {
    return this._groupId
  }

  set groupId(groupId) {
    this._groupId = groupId
  }

  constructor (opts = {}) {
    makeObservable(this, {
      _groupId: observable,
      allConversations: computed,
      allUsers: computed,
      allCourseSteps: computed,
      allCourseStepReviews: computed,
      allAttachments: computed,
      allAssignmentResponses: computed,
      attachmentsStore: computed,
      assignmentResponsesStore: computed,
      courseStepReviewsStore: computed,
      courseStepsStore: computed,
      courseStepProgressesStore: computed,
      allCourseStepProgresses: computed,
      conversationsStore: computed,
      loading: computed,
      groupId: computed
    });

    this.groupId = opts.groupId

    const convoFilter = { group_id: this.groupId }
    if (!WindowVariables.currentUser.studentForGroupId(this.groupId)) {
      convoFilter.mailbox_group_id = this.groupId
    }

    let usersFilter = { group_id: this.groupId, role: 'student', state: 1 }
    if (opts.userId) {
      usersFilter = { id: opts.userId }
    }

    this._usersStore.setFilter(usersFilter)
    this._courseStepsStore.setFilter({ group_id: this.groupId })

    if (opts.groupId && opts.userId && opts.courseStepId) {
      this._courseStepProgressesStore.setFilter({
        group_id: opts.groupId,
        user_id: opts.userId,
        course_step_id: opts.courseStepId,
      })
    }


    if (opts.pagination) {
      const stop = reaction(() => opts.pagination.filteredItems, (e) => {
          // Get updated list of paginated users
          const users = e.object.map(student => student.id)

          // Dispose existing stores
          this._conversationsStore.dispose()
          this._courseStepReviewsStore.dispose()
          this._assignmentResponsesStore.dispose()
          this._attachmentsStore.dispose()
          this._courseStepProgressesStore.dispose()

          // Load resources based on updated list of users
          this._conversationsStore.setFilter({ ...convoFilter, users })
          this._courseStepReviewsStore.setFilter({ group_id: this.groupId, users })
          this._assignmentResponsesStore.setFilter({ group_id: this.groupId, include_discarded: true, users })
          this._attachmentsStore.setFilter({ group_id: this.groupId, users })
      })

      this._stopFunctions.push(stop)

      // this._stopFunctions.push(
      //   opts.pagination.filteredItems.observe((e) => {
      //     console.log('STOP method executed in DataStore', this.groupId)
      //     // Get updated list of paginated users
      //     const users = e.object.map(student => student.id)

      //     // Dispose existing stores
      //     this._conversationsStore.dispose()
      //     this._courseStepReviewsStore.dispose()
      //     this._assignmentResponsesStore.dispose()
      //     this._attachmentsStore.dispose()
      //     this._courseStepProgressesStore.dispose()

      //     // Load resources based on updated list of users
      //     this._conversationsStore.setFilter({ ...convoFilter, users })
      //     this._courseStepReviewsStore.setFilter({ group_id: this.groupId, users })
      //     this._assignmentResponsesStore.setFilter({ group_id: this.groupId, include_discarded: true, users })
      //     this._attachmentsStore.setFilter({ group_id: this.groupId, users })
      //   })
      // )
    }
  }

  dispose () {
    this._stopFunctions.forEach(stop => stop())
    this._stopFunctions = []
  }
}

export default TimelineServiceDataStore
