import Service from '@services/timelineService/Service'

class TimelineServiceManager {
  _services = {}
  _enableDebug = false

  getServiceFor (obsv, filterset, opts = {}) {
    this._debug('Requesting instance for observer:', filterset, obsv)
    let keys = Object.keys(filterset).sort()
    let uid = opts.identifier || keys.map(k => `${k}-${filterset[k]}`).join('|')
    if (!this._services[uid]) {
      this._debug('Instance not found. Creating instance with observer:', filterset, obsv)
      this._services[uid] = {
        observers: [obsv],
        service: new Service(filterset)
      }
    } else {
      this._debug('Instance found. Adding observer:', filterset, obsv)
      this._services[uid].observers.push(obsv)
    }
    return this._services[uid].service
  }

  deregister (inst, obsv) {
    this._debug('Requesting deregistration from:', inst.presetFilter, obsv)
    const uid = this._generateUid(inst.presetFilter)
    const rec = this._services[uid]
    if (rec) {
      this._debug('Located instance. Deregistering observer:', obsv)
      const idx = rec.observers.indexOf(obsv)
      rec.observers.splice(idx, 1)

      this._debug('Checking observers', rec.observers, rec.observers.length)
      if (rec.observers.length === 0) {
        this._debug('No observers. Disposing of instance:', rec.service.presetFilter)
        rec.service.dispose()
        delete this._services[uid]
      }
    }
  }

  _generateUid (filterset) {
    let keys = Object.keys(filterset).sort()
    return keys.map(k => `${k}-${filterset[k]}`).join('|')
  }

  _debug () {
    if (this._enableDebug) {
      console.log('[Timeline Service Manager]', ...arguments)
    }
  }
}

const s = new TimelineServiceManager()
window.s = s
export default s
