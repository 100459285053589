<template>
  <div class="interaction-timeline-content-wrapper">
    <div class="wrapper-header" v-if="!noVersions">
      <div class="assignment-title"></div>
      <div style="display:flex;">
        <div class="label">{{ $t('application.interaction_timeline.content_wrapper.viewing') }}</div>
        <select v-model="versionId">
          <option v-for="v in versions" :value="v.id" :key="v.id">{{ v.label }}</option>
        </select>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'interaction-timeline-content-wrapper',
  components: {
  },
  data() {
    return {
      versionId: this.selected,
      activateWatcher: false,
    }
  },
  computed: {
    title () {
      const version = this.versions.find(v => v.id === this.versionId)
      return version ? version.label : ''
    },
    noVersions () {
      return (!this.versions || this.versions.length === 0) || this.hideVersions
    },
  },
  watch: {
    versionId (newVal, oldVal) {
      if (newVal && this.activateWatcher) {
        this.$emit('versionChanged', newVal)
      }
    },
    versions (newVal, oldVal) {
      // Helps with lazy loaded `versions`.
      // Will pick the first `versionId` in the array if `versions` were still loading when modal opened.
      if (oldVal.length === 0 && newVal.length >= 1) {
        const newVersion = newVal[0]
        this.$emit('versionChanged', newVersion.id)
      }
    }
  },
  created () {
    if (!this.selected) {
      const dereg = this.$watch('selected', (newVal, oldVal) => {
        if (newVal) {
          this.versionId = newVal
          this.activateWatcher = true
          dereg()
        }
      })
    } else {
      this.activateWatcher = true
    }
  },
  props: {
    selected: {
      type: Number,
    },
    versions: {
      type: Array,
      required: true,
    },
    hideVersions: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="scss" scoped>
.interaction-timeline-content-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .wrapper-header {
    flex: 0 0 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 7px;
    padding-right: 7px;
    background-color: $htss-green;
    width: 100%;

    .assignment-title {
      font-size: 1.2rem;
      color: black;
    }

    .label {
      margin-right: 7px;
      color: black;
    }
  }
}
</style>
