<template>
  <div class="thumbnail-user">
    {{ initials }}
  </div>
</template>

<script>
import { getUsersStoreFor } from '@stores/UsersStore'

import { observer } from "mobx-vue"

export default observer({
  name: 'thumbnail-user',
  components: {},
  data () {
    return {
      usersStore: getUsersStoreFor({ id: this.userId }),
    }
  },
  computed: {
    user() {
      return this.usersStore.allRecords.find(user => user && user.id === this.userId)
    },
    initials() {
      return this.user && this.user.first_name.charAt(0).concat(this.user.last_name.charAt(0))
    },
  },
  methods: {},
  props: {
    userId: {
      type: Number,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
.thumbnail-user {
  width: 32px;
  height: 32px;
  background-color: #EEEBF2;
  color: #221932;
  border-radius: 8px 0px 8px 8px;
  font-size: 12px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.2px;
}
</style>
