<template>
  <div class="interaction-event-attachment">
    <div class="interaction-event-row">
      <UserThumbnail :userId="parseInt(createdBy.id)"/>
      <div class="content">
        <div class="sent-info">
          <span class="sent-by">{{ createdBy.first_name }} {{ createdBy.last_name }}</span>
          <span class="sent-at">{{ event.value.sentTimeFormatted }}</span>
        </div>
        <div class="attachment-icon-container">
          <span class="attachment-status">{{ $t('application.interaction_timeline.event_attachment.attachment_uploaded') }}</span>
            <div class="attachment-data">
            <a :href="event.value.presigned_url" target=”_blank” class="attachment-download-link">
              <div class="filename">{{ event.value.file_name }}</div>
              <i class="fa fa-download"></i>
            </a>
            <div @click="remove()" v-if="showDeleteIcon" class="attachment-icon-remove">
              <i class="fa fa-trash"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FlashMessages from '@services/FlashMessageService'
  import { getAttachmentsStoreFor } from "@stores/AttachmentsStore"
  import { WindowVariables } from '@utils'
  import UserThumbnail from '@components/general/UserThumbnail.vue'

  export default {
    name: 'interaction-event-attachment',
    data: () => {
      return {
        attachmentsStore: getAttachmentsStoreFor()
      }
    },
    components: {
      UserThumbnail,
    },
    computed: {
      showDeleteIcon() {
        return this.event.value.created_by_id === WindowVariables.currentUser.id || WindowVariables.currentUser.teacherForGroupId(WindowVariables.groupId)
      },
      createdBy() {
        return this.event.value.getRelation('created_by')
      }
    },
    methods: {
      remove() {
        this.attachmentsStore.remove(this.event.value.id)
          .then(() => {
            FlashMessages.success(this.$t('application.interaction_timeline.event_attachment.flash.attachment_deleted'))
          })
          .catch(() => {
            FlashMessages.error(this.$t('application.interaction_timeline.event_attachment.flash.attachment_deletion_failed'))
          })
      },
    },
    props: {
      event: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .interaction-event-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .interaction-event-attachment {
    // background-color: #F0EBF5;
    // padding: 12px;
    font-size: .875rem;
    font-weight: 500;
    color: #333;
    border-radius: 5px;
    margin-bottom: 16px;

    .content {
      color: #5F5934;
      background-color: #FDFAE6;
      border-radius: 0px 16px 16px 16px;
      padding: 12px;
      display: flex;
      flex-wrap: wrap;
      max-width: 269px;
      min-width: 64px;
      margin-left: 8px;
      font-size: 1rem;
      line-height: 24px;
      text-align: left;
    }
  }

  .filename {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    width: 160px;
    display: inline-block;
    line-height: 32px;
  }

  .sent-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    margin-bottom: 4px;
  }

  .sent-by {
    font-weight: 600;
    font-size: .875rem;
    color: #5F5934;
    line-height: 16px;
    display: inline-block;
    text-align: left;
  }

  .sent-at {
    color: #7A744D;
    font-size: .75rem;
    text-align: right;
  }

  .attachment-icon-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex-shrink: 0;
    font-size: 0.9rem;
    overflow: hidden;
    white-space: wrap;
    text-overflow: wrap;
    text-align: left;
    width: 100%;

    .fa-download {
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: #E2DAA2;
      color: #5B552B;
      border-radius: 4px;
      font-size: 1rem;
      width: 32px;
      height: 32px;
      position: relative;
      margin-left: 8px;
    }
  }

  .attachment-icon-remove {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #E2DAA2;
      color: #5B552B;
      border-radius: 4px;
      font-size: 1rem;
      width: 32px;
      height: 32px;
      margin-left: 8px;
  }

  .attachment-download-link {
    display: inline-flex;
    font-size: .875rem;
    font-weight: 700;
    color: #5F5934;
    margin-bottom: 4px;
    width: 196px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: space-between;

    &:hover {
      text-decoration: none;
    }
  }

  .thumbnail-user {
      color: #5F5934;
      background-color: #FDFAE6;
  }

  .attachment-action-icon-container {
    display: flex;
    flex-direction: row;
  }

  .attachment-status {
    width: 100%;
  }

  .attachment-data {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
</style>
