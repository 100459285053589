<template>
  <div class="interaction-close-conversation">
    <button class="btn btn-primary" @click="closeConversation">
      {{ $t('application.interaction_timeline.close_conversation.close_conversation') }}
    </button>
  </div>
</template>

<script>
import { getConversationsStoreFor } from '@stores/ConversationsStore'

export default {
  name: 'interaction-close-conversation',
  data () {
    return {
      conversationsStore: getConversationsStoreFor(this.studentStepProgress._conversationsStoreUuid),
    }
  },
  methods: {
    closeConversation () {
      return this.conversationsStore.close(this.studentStepProgress.conversation.id)
    },
  },
  props: {
    studentStepProgress: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.interaction-close-conversation {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .btn {
    flex: 0 0 38px;
  }
}

</style>
