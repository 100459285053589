import NewModel from '@models/NewModel'

class ConversationMessage extends NewModel {
  constructor (initialData, included) {
    super(initialData, included, {
      columns: [
        'id',
        'body',
        'conversation_id',
        'metadata',
      ],
      momentKeys: [
        'created_at',
        // 'updated_at',  //turn off for perf. unused.
      ],
    })
  }

  get sentTimeFormatted () {
    return this.created_at.format('MMM Do YY @ H:mma')
  }

  get sentBy () {
    return this.metadata.user || (console.log('sentBy Here!') && false) || this.sender
  }

  get sentByName () {
    return this.sentBy.name ? this.sentBy.name : `${this.sentBy.first_name} ${this.sentBy.last_name}`
  }

  get isAttachmentNotification () {
    return this.body === '---ATTACHMENT UPLOADED---'
  }

  get isCloseNotification () {
    return this.body === '---CLOSED---'
  }
}

export default ConversationMessage
