<template>
  <div class="user-engagement-summary-container pb-4 py-4 px-2">
    <h3 class="font-bold text-gray-900 text-lg text-center pb-2">Engagement Summary</h3>
    <dl v-if="loaded === true" class="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">

      <div class="flex justify-between py-2 text-sm font-medium" v-if="gmEngagementMetadata.last_engagement_timestamp">
        <dt class="whitespace-nowrap px-2 text-gray-500">Last Engaged</dt>
        <dd class="text-right text-gray-900">
          {{ toDateTime(gmEngagementMetadata.last_engagement_timestamp) }} <span :class="lastEngagedClasses">({{lastEngagedDays}}d)</span>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Last Workshop</dt>
        <dd class="text-right text-gray-900">
          <template v-if="gmEngagementMetadata.last_workshop_attended_at">
            {{toDateTime(gmEngagementMetadata.last_workshop_attended_at)}} <span :class="lastWorkshopAttendedClasses">({{lastWorkshopAttendedDays}}d)</span>
          </template>
          <template v-else>
            <span :class="lastWorkshopAttendedClasses">Never Attended</span>
          </template>

        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium">
        <dt class="whitespace-nowrap px-2 text-gray-500">Last Office Hours</dt>
        <dd class="text-right text-gray-900">
          <template v-if="gmEngagementMetadata.last_office_hours_attended_at">
            {{toDateTime(gmEngagementMetadata.last_office_hours_attended_at)}} <span :class="lastOfficeHoursAttendedClasses">({{lastOfficeHoursAttendedDays}}d)</span>
          </template>
          <template v-else>
            <span :class="lastOfficeHoursAttendedClasses">Never Attended</span>
          </template>

        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium" v-if="gmEngagementMetadata.last_page_load_event_timestamp">
        <dt class="whitespace-nowrap px-2 text-gray-500">Last Page Load</dt>
        <dd class="text-right text-gray-900">
          {{toDateTime(gmEngagementMetadata.last_page_load_event_timestamp)}} <span :class="lastPageLoadClasses">({{lastPageLoadDays}}d)</span>
          <div class="italic text-xs text-gray-700">{{gmEngagementMetadata.last_page_load_event_step_name}}</div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium" v-if="gmEngagementMetadata.last_er_staff_engagement_timestamp">
        <dt class="whitespace-nowrap px-2 text-gray-500">Last ER Staff Engagement</dt>
        <dd class="text-right text-gray-900">
          {{toDateTime(gmEngagementMetadata.last_er_staff_engagement_timestamp)}} <span :class="lastERStaffEngagementClasses">({{lastERStaffEngagementDays}}d)</span>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium" v-if="gmEngagementMetadata.last_action_step_submitted_timestamp">
        <dt class="whitespace-nowrap px-2 text-gray-500">Last Action Step</dt>
        <dd class="text-right text-gray-900">
          <div>{{toDateTime(gmEngagementMetadata.last_action_step_submitted_timestamp)}} <span :class="lastActionStepSubmittedClasses">({{lastActionStepSubmittedDays}}d)</span></div>
          <div class="italic text-xs text-gray-700">{{gmEngagementMetadata.last_action_step_submitted_step_name}}</div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium" v-if="gmEngagementMetadata.current_sequence_name">
        <dt class="whitespace-nowrap px-2 text-gray-500">Current Phase</dt>
        <dd class="text-right text-gray-900">
          <div>{{gmEngagementMetadata.current_sequence_name}}</div>
          <div class="italic text-xs text-gray-700">({{gmEngagementMetadata.num_days_in_current_module}} days in phase)</div>
        </dd>
      </div>



      <div class="flex justify-between py-2 text-sm font-medium" v-if="gmEngagementMetadata.completed_phase_buffer_days">
        <dt class="whitespace-nowrap px-2 text-gray-500">Buffer Days</dt>
        <dd class="text-right text-gray-900">
          <div>{{gmEngagementMetadata.completed_phase_buffer_days}} days</div>
        </dd>
      </div>

      <div class="flex justify-between py-2 text-sm font-medium" v-if="gmEngagementMetadata.completed_pace_days">
        <dt class="whitespace-nowrap px-2 text-gray-500">Relative Pace</dt>
        <dd class="text-right text-gray-900">
          <div>{{gmEngagementMetadata.completed_pace_days}} days</div>
        </dd>
      </div>
      <div class="flex justify-between py-2 text-sm font-medium" v-if="numOpenedTasks">
        <dt class="whitespace-nowrap px-2 text-gray-500">Number of Opened Coach Tasks</dt>
        <dd class="text-right text-gray-900">
          <div>{{numOpenedTasks}}</div>
        </dd>
      </div>
    </dl>
  </div>
</template>

<script>
import dayjs from '@services/dayjsWrapper'
import { WindowVariables } from '@utils'
import axios from "@dataServices/axios";
import FlashMessages from "@services/FlashMessageService";

const baseUrl = window.location.origin

export default {
  props: {
    studentGroupMembershipId: { type: Number, required: true, },
  },
  data () {
    return {
      gmEngagementMetadata: null,
      numOpenedTasks: null,
      momentNow: dayjs().tz(WindowVariables.timezone),
      loaded: false,
    }
  },
  computed: {
    lastEngagedClasses () {

    },
    lastEngagedDays () {
      if (this.gmEngagementMetadata.last_engagement_timestamp) {
        return this.momentNow.diff(this.gmEngagementMetadata.last_engagement_timestamp, 'days')
      } else {
        return null
      }
    },
    lastPageLoadClasses () {

    },
    lastPageLoadDays () {
      if (this.gmEngagementMetadata.last_page_load_event_timestamp) {
        return this.momentNow.diff(this.gmEngagementMetadata.last_page_load_event_timestamp, 'days')
      } else {
        return null
      }
    },
    lastActionStepSubmittedClasses () {
       if (this.gmEngagementMetadata.last_action_step_submitted_timestamp) {
        return {
          'text-red-500': this.momentNow.diff(this.gmEngagementMetadata.last_action_step_submitted_timestamp, 'days') >= 7,
          'text-amber-400': this.momentNow.diff(this.gmEngagementMetadata.last_action_step_submitted_timestamp, 'days') >= 3 && this.momentNow.diff(this.gmEngagementMetadata.last_action_step_submitted_timestamp, 'days') < 7,
          'text-emerald-500': this.momentNow.diff(this.gmEngagementMetadata.last_action_step_submitted_timestamp, 'days') < 3,
        }
      } else {
        return {
          'text-red-500': true,
        }
      }
    },
    lastActionStepSubmittedDays () {
      if (this.gmEngagementMetadata.last_action_step_submitted_timestamp) {
        return this.momentNow.diff(this.gmEngagementMetadata.last_action_step_submitted_timestamp, 'days')
      } else {
        return null
      }
    },
    lastERStaffEngagementClasses () {

    },
    lastERStaffEngagementDays () {
      if (this.gmEngagementMetadata.last_er_staff_engagement_timestamp) {
        return this.momentNow.diff(this.gmEngagementMetadata.last_er_staff_engagement_timestamp, 'days')
      } else {
        return null
      }
    },
    lastWorkshopAttendedClasses () {
      if (this.gmEngagementMetadata.last_workshop_attended_at) {
        return {
          'text-red-500': this.momentNow.diff(this.gmEngagementMetadata.last_workshop_attended_at, 'days') >= 14,
          'text-amber-400': this.momentNow.diff(this.gmEngagementMetadata.last_workshop_attended_at, 'days') >= 7 && this.momentNow.diff(this.gmEngagementMetadata.last_workshop_attended_at, 'days') < 14,
          'text-emerald-500': this.momentNow.diff(this.gmEngagementMetadata.last_workshop_attended_at, 'days') < 7,
        }
      } else {
        return {
          'text-red-500': true,
        }
      }
    },
    lastWorkshopAttendedDays () {
      if (this.gmEngagementMetadata.last_workshop_attended_at) {
        return this.momentNow.diff(this.gmEngagementMetadata.last_workshop_attended_at, 'days')
      } else {
        return null
      }
    },
    lastOfficeHoursAttendedClasses () {
      if (this.gmEngagementMetadata.last_office_hours_attended_at) {
        return {
          'text-red-500': this.momentNow.diff(this.gmEngagementMetadata.last_office_hours_attended_at, 'days') >= 14,
          'text-amber-400': this.momentNow.diff(this.gmEngagementMetadata.last_office_hours_attended_at, 'days') >= 7 && this.momentNow.diff(this.gmEngagementMetadata.last_office_hours_attended_at, 'days') < 14,
          'text-emerald-500': this.momentNow.diff(this.gmEngagementMetadata.last_office_hours_attended_at, 'days') < 7,
        }
      } else {
        return {
          'text-red-500': true,
        }
      }
    },
    lastOfficeHoursAttendedDays () {
      if (this.gmEngagementMetadata.last_office_hours_attended_at) {
        return this.momentNow.diff(this.gmEngagementMetadata.last_office_hours_attended_at, 'days')
      } else {
        return null
      }
    },
  },
  async mounted () {
    const initData = await this.fetchInitData()
    this.gmEngagementMetadata = initData.gm_engagement_metadata
    this.numOpenedTasks = initData.num_opened_tasks
    console.info('gmEngagementMetadata', this.gmEngagementMetadata, initData)
    this.loaded = true
  },
  methods: {
    async fetchInitData() {
      try {
        const response = await axios.get(baseUrl + '/api/v2/bff/user_summaries_tab/init_data', {params: {group_membership_id: this.studentGroupMembershipId}})
        return response.data;
      } catch (error) {
        console.error("Failed to fetch data:", error);
        FlashMessages.error("Failed to fetch data! " + error); //it's ok to show the real error because the coaches are the users
      }
    },
    toDateTime(dateTime){
      return dateTime ? dayjs(dateTime).tz(WindowVariables.timezone).format('M/D/YY h:mma'): ''
    }
  }
}
</script>

<style>

</style>