<template>
  <div>
    <div class="legend" v-if="hasIconLegend">
      <b-dropdown id="dropdown-right" :text="$t('application.starter_tracking.icon_legend')" class="m-md-2" no-flip>
        <b-dropdown-item disabled>
          <span class="legend-item">
            <span class="legend-item-icon">
              <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" fill="white" />
                <rect x="0.5" y="0.5" width="31" height="31" fill="white" stroke="#888888" />
              </svg>
            </span>

            <span class="legend-item-text">{{ $t('application.common.incomplete') }}</span>
          </span>
        </b-dropdown-item>
        <b-dropdown-item disabled>
          <span class="legend-item">
            <span class="legend-item-icon">
              <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" fill="white" />
                <rect x="0.5" y="15.5" width="31" height="16" fill="white" stroke="#888888" />
                <rect width="32" height="17" fill="#7FC29B" />
                <path
                    d="M2.52692 6.60525C2.71019 6.78392 2.98511 6.76605 3.16838 6.60525L5.27606 4.44333V9.57119C5.27606 9.82133 5.45934 10 5.71592 10H6.30241C6.54066 10 6.74227 9.82133 6.74227 9.57119V4.44333L8.83162 6.58738C9.01489 6.76605 9.28981 6.76605 9.47308 6.60525L9.87629 6.21217C10.0412 6.0335 10.0412 5.76549 9.87629 5.60469L6.30241 2.1206C6.13746 1.9598 5.86254 1.9598 5.69759 2.1206L2.12371 5.60469C1.95876 5.76549 1.95876 6.0335 2.12371 6.21217L2.52692 6.60525Z"
                    fill="#263A2E"
                />
                <rect y="15" width="32" height="2" fill="#263A2E" />
              </svg>
            </span>

            <span class="legend-item-text">{{ $t('application.common.submitted') }}</span>
          </span>
        </b-dropdown-item>

        <b-dropdown-item disabled>
          <span class="legend-item">
            <span class="legend-item-icon">
              <svg style="border: 1px solid #888888; display: inline" width="32" height="32" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs></defs>
                <rect width="64" height="64" fill="white"></rect>
                <rect y="32" width="64" height="32" fill="white"></rect>
                <rect width="64" height="32" fill="#7FC29B"></rect>
                <path d="M4.79038 10.0444C5.06529 10.2789 5.47766 10.2554 5.75258 10.0444L8.91409 7.20687V15.4372C8.91409 15.7655 9.189 16 9.57388 16H10.4536C10.811 16 11.1134 15.7655 11.1134 15.4372V7.20687L14.2474 10.0209C14.5223 10.2554 14.9347 10.2554 15.2096 10.0444L15.8144 9.52848C16.0619 9.29397 16.0619 8.94221 15.8144 8.73116L10.4536 4.15829C10.2062 3.94724 9.79381 3.94724 9.54639 4.15829L4.18557 8.73116C3.93814 8.94221 3.93814 9.29397 4.18557 9.52848L4.79038 10.0444Z" style="fill: rgb(38, 58, 46);"></path>
                <rect y="31" width="64" height="2" fill="#263A2E"></rect>
                <text style="fill: rgb(38, 58, 46); font-family: Arial, sans-serif; font-size: 16.4px; font-weight: 700; paint-order: fill; white-space: pre;" x="20" y="15.577">UR</text>
              </svg>
            </span>

            <span class="legend-item-text">{{ $t('application.common.under_review') }}</span>
          </span>
          <!-- <span class="text-black" style="padding-left: 0.7rem; font-size: 13px;">{{ $t('application.common.under_review') }}</span> -->
        </b-dropdown-item>

        <b-dropdown-item disabled>
          <span class="legend-item">
            <span class="legend-item-icon">
              <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" fill="#7FC29B" />
                <path
                    d="M13.4219 21.7644C13.7344 22.0785 14.2656 22.0785 14.5781 21.7644L23.7656 12.5288C24.0781 12.2147 24.0781 11.6806 23.7656 11.3665L22.6406 10.2356C22.3281 9.92147 21.8281 9.92147 21.5156 10.2356L14.0156 17.7749L10.4844 14.2565C10.1719 13.9424 9.67188 13.9424 9.35938 14.2565L8.23438 15.3874C7.92188 15.7016 7.92188 16.2356 8.23438 16.5497L13.4219 21.7644Z"
                    fill="#263A2E"
                />
              </svg>
            </span>

            <span class="legend-item-text">{{ $t('application.common.completed') }}</span>
          </span>

        </b-dropdown-item>
        <b-dropdown-item disabled>
          <span class="legend-item">
            <span class="legend-item-icon">
              <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" fill="white" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 5.65644L5.65644 0H4.0402L0 4.0402V5.65644ZM10.2279 0H8.61163L0 8.61163V10.2279L10.2279 0ZM14.7993 0H13.1831L0 13.1831V14.7993L14.7993 0ZM19.3707 0H17.7545L0 17.7545V19.3707L19.3707 0ZM23.9422 0H22.3259L0 22.3259V23.9422L23.9422 0ZM28.5136 0H26.8973L0 26.8973V28.5136L28.5136 0ZM1.08501 32L32 1.08502V0H31.4688L0 31.4688V32H1.08501ZM32 5.65645V4.0402L4.04019 32H5.65644L32 5.65645ZM32 10.2279V8.61163L8.61163 32H10.2279L32 10.2279ZM32 14.7993V13.1831L13.1831 32H14.7993L32 14.7993ZM32 19.3707V17.7545L17.7545 32H19.3707L32 19.3707ZM32 23.9422V22.3259L22.3259 32H23.9422L32 23.9422ZM32 28.5136V26.8973L26.8973 32H28.5136L32 28.5136Z"
                    fill="#FFF28C"
                />
              </svg>
            </span>

            <span class="legend-item-text">{{ $t('application.common.questions_remaining') }}</span>
          </span>
        </b-dropdown-item>
        <b-dropdown-item disabled>
          <span class="legend-item">
            <span class="legend-item-icon">
              <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" fill="white" />
                <path d="M0 0H32V32H0V0Z" fill="white" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 5.65644L5.65644 0H4.0402L0 4.0402V5.65644ZM10.2279 0H8.61163L0 8.61163V10.2279L10.2279 0ZM14.7993 0H13.1831L0 13.1831V14.7993L14.7993 0ZM19.3707 0H17.7545L0 17.7545V19.3707L19.3707 0ZM23.9422 0H22.3259L0 22.3259V23.9422L23.9422 0ZM28.5136 0H26.8973L0 26.8973V28.5136L28.5136 0ZM1.08501 32L32 1.08502V0H31.4688L0 31.4688V32H1.08501ZM32 5.65645V4.0402L4.04019 32H5.65644L32 5.65645ZM32 10.2279V8.61163L8.61163 32H10.2279L32 10.2279ZM32 14.7993V13.1831L13.1831 32H14.7993L32 14.7993ZM32 19.3707V17.7545L17.7545 32H19.3707L32 19.3707ZM32 23.9422V22.3259L22.3259 32H23.9422L32 23.9422ZM32 28.5136V26.8973L26.8973 32H28.5136L32 28.5136Z"
                    fill="#FFF28C"
                />
                <path d="M32 16V0H0V16H32Z" fill="#7FC29B" />
                <rect y="15.5" width="32" height="1" fill="#263A2E" />
              </svg>
            </span>

            <span class="legend-item-text">{{ $t('application.common.questions_responded_to') }}</span>
          </span>
        </b-dropdown-item>
        <b-dropdown-item disabled>
          <span class="legend-item">
            <span class="legend-item-icon">
              <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" fill="white" />
                <path
                    d="M12.5636 15.1314C12.5636 11.2229 16.0006 8 20.2818 8C24.5328 8 28 11.2229 28 15.1314C28 19.0743 24.5328 22.2629 20.2818 22.2629C19.106 22.2629 18.0206 22.0229 17.0257 21.6114C16.3021 22.2971 14.2429 24 12.253 24C12.1324 24 12.0721 23.9657 12.0118 23.8629C11.9817 23.76 12.0118 23.6229 12.0721 23.5543C12.0721 23.5494 12.1091 23.5016 12.1735 23.4181C12.5614 22.9157 13.946 21.1222 14.2821 19.6229C13.1967 18.3886 12.5636 16.8457 12.5636 15.1314Z"
                    fill="white"
                    stroke="#263A2E"
                    stroke-width="3"
                />
                <path
                    d="M18 15C18 15.5523 17.5523 16 17 16C16.4477 16 16 15.5523 16 15C16 14.4477 16.4477 14 17 14C17.5523 14 18 14.4477 18 15Z"
                    fill="#263A2E"
                />
                <path
                    d="M21.3333 15C21.3333 15.5523 20.8856 16 20.3333 16C19.781 16 19.3333 15.5523 19.3333 15C19.3333 14.4477 19.781 14 20.3333 14C20.8856 14 21.3333 14.4477 21.3333 15Z"
                    fill="#263A2E"
                />
                <path
                    d="M24.6667 15C24.6667 15.5523 24.219 16 23.6667 16C23.1144 16 22.6667 15.5523 22.6667 15C22.6667 14.4477 23.1144 14 23.6667 14C24.219 14 24.6667 14.4477 24.6667 15Z"
                    fill="#263A2E"
                />
              </svg>
            </span>

            <span class="legend-item-text">{{ $t('application.common.message_responded_to') }}</span>
          </span>
        </b-dropdown-item>
        <b-dropdown-item disabled>
          <span class="legend-item">
            <span class="legend-item-icon">
              <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" fill="white" />
                <path
                    d="M12.5636 15.1314C12.5636 11.2229 16.0006 8 20.2818 8C24.5328 8 28 11.2229 28 15.1314C28 19.0743 24.5328 22.2629 20.2818 22.2629C19.106 22.2629 18.0206 22.0229 17.0257 21.6114C16.3021 22.2971 14.2429 24 12.253 24C12.1324 24 12.0721 23.9657 12.0118 23.8629C11.9817 23.76 12.0118 23.6229 12.0721 23.5543C12.0721 23.5494 12.1091 23.5016 12.1735 23.4181C12.5614 22.9157 13.946 21.1222 14.2821 19.6229C13.1967 18.3886 12.5636 16.8457 12.5636 15.1314Z"
                    fill="white"
                    stroke="#263A2E"
                    stroke-width="3"
                />
              </svg>
            </span>

            <span class="legend-item-text">{{ $t('application.common.unread_message') }}</span>
          </span>
        </b-dropdown-item>
        <b-dropdown-item disabled>
          <span class="legend-item">
            <span class="legend-item-icon">
              <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" fill="white" />
                <path
                    d="M26.5173 12.9773L26.5176 12.9771C27.3137 12.2916 27.9093 11.5613 28.0918 10.8099C28.184 10.4303 28.1702 10.0469 28.0254 9.66731C27.8812 9.28932 27.6104 8.92373 27.2019 8.57201C26.3662 7.85247 25.2871 7.72809 24.2314 7.94918C23.1766 8.17009 22.1279 8.73817 21.3224 9.43169L13.4283 16.2285C11.3239 18.0405 11.3239 20.9877 13.4283 22.7996C15.5195 24.6001 18.9007 24.6001 20.9919 22.7996L27.8685 16.8788C27.9828 16.7804 28.0522 16.6506 28.0522 16.5084C28.0522 16.3661 27.9828 16.2363 27.8685 16.1379L27.2721 15.6244C27.0404 15.4249 26.7116 15.4249 26.4799 15.6244L19.6033 21.5452C18.2912 22.6749 16.129 22.6749 14.817 21.5452C13.5181 20.4269 13.5181 18.6012 14.817 17.483L22.7111 10.6861C23.1599 10.2997 23.7404 9.90098 24.313 9.69466C24.8873 9.48772 25.417 9.48525 25.8133 9.82644C26.0296 10.0127 26.1344 10.1711 26.1731 10.3067C26.2104 10.437 26.1919 10.565 26.1196 10.7062C26.0452 10.8518 25.9164 11.0055 25.7419 11.1764C25.5834 11.3318 25.3953 11.4935 25.1891 11.6709C25.1692 11.688 25.1492 11.7052 25.129 11.7226L18.4979 17.432C18.2707 17.6276 18.0349 17.8068 17.8085 17.8933C17.5912 17.9763 17.4009 17.9689 17.2261 17.8184L17.1281 17.7341L17.0302 17.8185L16.2765 18.4686L16.1907 18.5426L16.2384 18.6454C16.4128 19.021 16.6585 19.2818 16.9529 19.4392C17.2461 19.5959 17.5767 19.6443 17.9132 19.614C18.5817 19.5539 19.2927 19.1828 19.8522 18.6858L26.5173 12.9773Z"
                    fill="#263A2E"
                    stroke="#263A2E"
                    stroke-width="0.3"
                />
              </svg>
            </span>

            <span class="legend-item-text">{{ $t('application.common.attachment') }}</span>
          </span>
        </b-dropdown-item>
        <b-dropdown-item disabled>
          <span class="legend-item">
            <span class="legend-item-icon">
              <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 64" style="border: 1px solid rgb(136, 136, 136);">
                <g>
                  <title>Layer 1</title>
                  <rect id="svg_1" fill="white" height="64" width="64"/>
                  <rect id="svg_2" fill="white" height="32" width="64" y="32"/>
                  <rect id="svg_3" fill="#7FC29B" height="32" width="64"/>
                  <rect id="svg_5" fill="#263A2E" height="2" width="64" y="31"/>
                  <path stroke-width="0.3" stroke="#263A2E" fill="#263A2E" d="m58.5173,9.9773l0.0003,-0.0002c0.7961,-0.6855 1.3917,-1.4158 1.5742,-2.1672c0.0922,-0.3796 0.0784,-0.763 -0.0664,-1.14259c-0.1442,-0.37799 -0.415,-0.74358 -0.8235,-1.0953c-0.8357,-0.71954 -1.9148,-0.84392 -2.9705,-0.62283c-1.0548,0.22091 -2.1035,0.78899 -2.909,1.48251l-7.8941,6.79681c-2.1044,1.812 -2.1044,4.7592 0,6.5711c2.0912,1.8005 5.4724,1.8005 7.5636,0l6.8766,-5.9208c0.1143,-0.0984 0.1837,-0.2282 0.1837,-0.3704c0,-0.1423 -0.0694,-0.2721 -0.1837,-0.3705l-0.5964,-0.5135c-0.2317,-0.1995 -0.5605,-0.1995 -0.7922,0l-6.8766,5.9208c-1.3121,1.1297 -3.4743,1.1297 -4.7863,0c-1.2989,-1.1183 -1.2989,-2.944 0,-4.0622l7.8941,-6.7969c0.4488,-0.3864 1.0293,-0.78512 1.6019,-0.99144c0.5743,-0.20694 1.104,-0.20941 1.5003,0.13178c0.2163,0.18626 0.3211,0.34466 0.3598,0.48026c0.0373,0.1303 0.0188,0.2583 -0.0535,0.3995c-0.0744,0.1456 -0.2032,0.2993 -0.3777,0.4702c-0.1585,0.1554 -0.3466,0.3171 -0.5528,0.4945c-0.0199,0.0171 -0.0399,0.0343 -0.0601,0.0517l-6.6311,5.7094c-0.2272,0.1956 -0.463,0.3748 -0.6894,0.4613c-0.2173,0.083 -0.4076,0.0756 -0.5824,-0.0749l-0.098,-0.0843l-0.0979,0.0844l-0.7537,0.6501l-0.0858,0.07401l0.0477,0.1028c0.1744,0.3756 0.4201,0.6364 0.7145,0.7938c0.2932,0.1567 0.6238,0.2051 0.9603,0.1748c0.6685,-0.0601 1.3795,-0.4312 1.939,-0.9282l6.6651,-5.7085l0,-0.00001z" id="svg_8"/>
                </g>
              </svg>
            </span>

            <span class="legend-item-text">{{ $t('application.common.submitted_with_attachment_for_event_benchmark') }}</span>
          </span>
        </b-dropdown-item>
        <b-dropdown-item disabled>
          <span class="legend-item">
            <span class="legend-item-icon">
              <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" fill="white" />
                <path
                    d="M25.5 15H24.75V12.75C24.75 10.1562 22.5938 8 20 8C17.375 8 15.25 10.1562 15.25 12.75V15H14.5C13.6562 15 13 15.6875 13 16.5V22.5C13 23.3438 13.6562 24 14.5 24H25.5C26.3125 24 27 23.3438 27 22.5V16.5C27 15.6875 26.3125 15 25.5 15ZM22.25 15H17.75V12.75C17.75 11.5312 18.75 10.5 20 10.5C21.2188 10.5 22.25 11.5312 22.25 12.75V15Z"
                    fill="#FFBA49"
                />
              </svg>
            </span>

            <span class="legend-item-text">{{ $t('application.common.benchmark') }}</span>
          </span>
        </b-dropdown-item>
        <b-dropdown-item disabled>
          <span class="legend-item">
            <span class="legend-item-icon">
              <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" fill="white" />
                <path
                    d="M13 22.5C13 23.3438 13.6562 24 14.5 24H25.5C26.3125 24 27 23.3438 27 22.5V14H13V22.5ZM23 16.375C23 16.1875 23.1562 16 23.375 16H24.625C24.8125 16 25 16.1875 25 16.375V17.625C25 17.8438 24.8125 18 24.625 18H23.375C23.1562 18 23 17.8438 23 17.625V16.375ZM23 20.375C23 20.1875 23.1562 20 23.375 20H24.625C24.8125 20 25 20.1875 25 20.375V21.625C25 21.8438 24.8125 22 24.625 22H23.375C23.1562 22 23 21.8438 23 21.625V20.375ZM19 16.375C19 16.1875 19.1562 16 19.375 16H20.625C20.8125 16 21 16.1875 21 16.375V17.625C21 17.8438 20.8125 18 20.625 18H19.375C19.1562 18 19 17.8438 19 17.625V16.375ZM19 20.375C19 20.1875 19.1562 20 19.375 20H20.625C20.8125 20 21 20.1875 21 20.375V21.625C21 21.8438 20.8125 22 20.625 22H19.375C19.1562 22 19 21.8438 19 21.625V20.375ZM15 16.375C15 16.1875 15.1562 16 15.375 16H16.625C16.8125 16 17 16.1875 17 16.375V17.625C17 17.8438 16.8125 18 16.625 18H15.375C15.1562 18 15 17.8438 15 17.625V16.375ZM15 20.375C15 20.1875 15.1562 20 15.375 20H16.625C16.8125 20 17 20.1875 17 20.375V21.625C17 21.8438 16.8125 22 16.625 22H15.375C15.1562 22 15 21.8438 15 21.625V20.375ZM25.5 10H24V8.5C24 8.25 23.75 8 23.5 8H22.5C22.2188 8 22 8.25 22 8.5V10H18V8.5C18 8.25 17.75 8 17.5 8H16.5C16.2188 8 16 8.25 16 8.5V10H14.5C13.6562 10 13 10.6875 13 11.5V13H27V11.5C27 10.6875 26.3125 10 25.5 10Z"
                    fill="#20A39E"
                />
              </svg>
            </span>

            <span class="legend-item-text">{{ $t('application.common.event') }}</span>
          </span>
        </b-dropdown-item>
        <b-dropdown-item disabled>
          <span class="legend-item">
            <span class="legend-item-icon">
              <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" fill="white" />
                <path
                    d="M27.5 8H12.5C11.6562 8 11 8.6875 11 9.5V19.5C11 20.3438 11.6562 21 12.5 21H18.5L18 22.5H15.75C15.3125 22.5 15 22.8438 15 23.25C15 23.6875 15.3125 24 15.75 24H24.25C24.6562 24 25 23.6875 25 23.25C25 22.8438 24.6562 22.5 24.25 22.5H22L21.5 21H27.5C28.3125 21 29 20.3438 29 19.5V9.5C29 8.6875 28.3125 8 27.5 8ZM27 19H13V10H27V19Z"
                    fill="#573F7E"
                />
              </svg>
            </span>

            <span class="legend-item-text">{{ $t('application.common.action_step') }}</span>
          </span>
        </b-dropdown-item>

        <b-dropdown-item disabled>
          <span class="legend-item">
            <span class="legend-item-icon survey">
              <i class="fa fa-question-circle"></i>
            </span>

            <span class="legend-item-text">{{ $t('application.common.survey') }}</span>
          </span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div id="group_starter_tracking">
      <div class="left-column" :class="{ hidden: !showLeftColumn }">
        <div class="categories-top">&nbsp;</div>
        <div class="course-steps-top">
          <div class="student-filter-wrapper" v-if="!vm.forSingleStudent && hasSearchBox">
            <input class="w-full sm:text-sm sm:leading-5 w-full h-[43px] px-3 border-solid border-1 border-[#e8e8e8] placeholder-[#adadad] bg-white rounded-md transition duration-150 ease-in-out" type="text" v-model="studentFilterText" :placeholder="$t('application.starter_tracking.filter_students')" />
          </div>
          <div v-if="!vm.forSingleStudent && hasPagination">
            <b-pagination
                v-if="vm.pagination.shouldShowPagination"
                v-model="vm.pagination.currentPage"
                :total-rows="vm.pagination.totalRows"
                :per-page="vm.pagination.itemsPerPage"
                aria-controls="my-table"
                align="fill"
            ></b-pagination>
          </div>
        </div>
        <div class="students-list-container" v-if="vm.dataReady">
          <template v-for="student in vm.studentsToDisplay">
            <div
                class="student-item"
                :key="student.id"
                :class="{'has-not-logged-in': student.sign_in_count === 0, 'has-logged-in': student.sign_in_count > 0}"
            >
              <div class="stacked-status" v-show="!currentUserIsStudent">
                <div class="num-open-conversations">
                  {{ vm.statsByUserId[student.id] && vm.statsByUserId[student.id].numOpenConversationsNeedingReply }}
                </div>
                <div class="num-items-needing-review">
                  {{ vm.statsByUserId[student.id] && vm.statsByUserId[student.id].numItemsNeedingReview }}
                </div>
              </div>
              <div class="name-container">
                <a :href="`/users/${student.id}`" target="_blank">
                  <span class="last-name">{{ student.last_name }}</span>,
                  <span class="first-name">&nbsp;{{student.first_name}}</span>
                </a>
              </div>

            </div>
          </template>
        </div>
      </div>
      <div class="scrolling-column">
        <div class="categories-top">
          <template v-for="catObj in vm.courseStepsToShowByCategory">
            <div
                class="category-item"
                :style="{ 'flex-basis': 64 * catObj.items.length + 'px', width: 64 * catObj.items.length + 'px' }"
                :key="catObj.category.id"
            >{{ catObj.category.name }}</div>
          </template>
        </div>

        <div class="course-steps-top">
          <template v-for="courseStep in vm.linearCourseStepsToShow">
            <div
                class="course-step-container"
                :key="courseStep.uniqueId"
                :class="{
            'course-step-benchmark': courseStep.isBenchmark,
            'course-step-step': courseStep.isStep,
            'course-step-event': courseStep.isEvent,
            'course-step-survey': courseStep.isSurvey,
            'event-type-coaching': courseStep.isEvent && courseStep.isCoachingEventType,
            'event-type-phone': courseStep.isEvent && courseStep.isPhoneEventType,
            'event-type-workshop': courseStep.isEvent && courseStep.isWorkshopEventType,
            'event-type-online': courseStep.isEvent && courseStep.isOnlineEventType,
            'event-type-mentor': courseStep.isEvent && courseStep.isMentorEventType,
          }"
            >
              <div class="assignment-name vertical-label">
                {{ courseStep.displayName }}
                <!-- {{ courseStep.type_substring[0].toUpperCase() }} {{ courseStep.displayName }} -->
              </div>
              <i v-if="courseStep.faIcon" :class="{fa: true, [courseStep.faIcon]: true}">
              <span
                  class="sr-only"
              >{{ courseStep.faIcon }} icon for {{ courseStep.displayName }} assignment</span>
              </i>
            </div>
          </template>
        </div>
        <div class="course-steps-list-container">
          <div
              class="course-step-student-container"
              v-for="student in vm.studentsToDisplay"
              :key="student.id"
              tabindex="0"
          >
            <template v-for="courseStep in vm.linearCourseStepsToShow">
              <starter-table-cell
                  :key="courseStep.uniqueId"
                  :groupId="derivedGroupId"
                  :courseStepId="courseStep.id"
                  :userId="student.id"
                  role="img"
                  :title="$t('application.starter_tracking.current_status_of_assignment')"
                  :servicePreset="servicePreset"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import StarterTableCell from '@components/starterTracking/StarterTableCell.vue'
import TotalsItem from '@components/starterTracking/TotalsItem.vue'

import TimelineServiceManager from '@services/timelineService/ServiceManager'
import { WindowVariables } from '@utils'

import StepType from '@components/general/StepType.vue'

import { observer } from "mobx-vue"
import { makeObservable, computed, action } from 'mobx'

class ViewModel {
  groupId = null
  hideCourseStepIds = []
  onlyShowCourseStepIds = []
  servicePreset = []
  userId = null
  timelineService = null

  constructor(params) {
    makeObservable(this, {
      dataReady: computed,
      allStudents: computed,
      courseStepsByCategory: computed,
      courseStepsToShowByCategory: computed,
      linearCourseStepsToShow: computed,
      courseStepCategories: computed,
      statsByUserId: computed,
      pagination: computed,
      studentsToDisplay: computed,
      forSingleStudent: computed,
    })

    this.groupId = params.groupId
    this.userId = params.userId
    this.servicePreset = params.servicePreset
    this.onlyShowCourseStepIds = params.onlyShowCourseStepIds
    this.hideCourseStepIds = params.hideCourseStepIds

    this.timelineService = TimelineServiceManager.getServiceFor('starter-tracking', this.servicePreset, {
      identifier: `group=${this.groupId}|user=${this.userId}|hide=${this.hideCourseStepIds.join('-')}|show=${this.onlyShowCourseStepIds.join('-')}`
    })

    if (this.hideCourseStepIds.length > 0) {
      this.timelineService.hideCourseStepIds = this.hideCourseStepIds
    }

    if (this.onlyShowCourseStepIds.length > 0) {
      this.timelineService.onlyShowCourseStepIds = this.onlyShowCourseStepIds
    }
  }

  get dataReady () {
    return this.timelineService.dataReady
  }

  get allStudents () {
    if (this.userId) {
      return this.timelineService.allUsers.filter(us => us.id === this.userId)
    } else {
      return this.timelineService.allUsers
    }
  }

  get courseStepsByCategory () {
    // this returns an array of dictionaries w/ two keys
    //   { category: { name: 'Week 1', id: 1 },
    //     items: [ { id: 1, type: 'CourseStep::Step', name: 'My Money', ...} ] }
    return this.timelineService.courseStepsByCategory
  }

  get courseStepsToShowByCategory () {
    return this.timelineService.courseStepsToShowByCategory
  }

  get linearCourseStepsToShow () {
    // this is an array of coursesteps flattened down
    return this.timelineService.courseStepsToShow
  }

  get courseStepCategories () {
    // the courseStep categories/modules/weeks in order
    return this.timelineService.courseStepCategories
  }

  get statsByUserId () {
    return this.timelineService.statsByUserId
  }

  get pagination () {
    return this.timelineService.pagination
  }

  get studentsToDisplay () {
    return this.forSingleStudent
      ? this.allStudents // This method does the filter for you
      : this.pagination.filteredItems
  }

  get forSingleStudent () {
    return !!this.userId
  }
}

export default observer({
  name: 'starter-tracking-dashboard',
  components: {
    StarterTableCell,
    TotalsItem,
    StepType,
  },
  data () {
    const derivedGroupId = this.groupId || WindowVariables.groupId
    const servicePreset = { groupId: derivedGroupId, userId: this.userId }
    return {
      vm: new ViewModel({
        servicePreset,
        groupId: derivedGroupId,
        userId: this.userId,
        onlyShowCourseStepIds: this.onlyShowCourseStepIds,
        hideCourseStepIds: this.hideCourseStepIds,
      }),
      derivedGroupId: derivedGroupId,
      studentFilterText: '',
      filteredStudentIds: [],
      servicePreset: servicePreset,
    }
  },
  computed: {
    currentUserIsStudent () {
      return WindowVariables.currentUser.studentForGroupId(this.derivedGroupId)
    }
  },
  beforeDestroy () {
    TimelineServiceManager.deregister(this.vm.timelineService, 'starter-tracking')
  },
  methods: {
    filteredStudents () {
      if (!this.vm.timelineService.dataReady) {
        return []
      }
      let result = []
      if (this.studentFilterText.length === 0) {
        result = this.vm.allStudents
      } else {
        result = this.vm.allStudents.filter(stud => this.filteredStudentIds.includes(stud.id))
      }
      return result
    }
  },
  props: {
    userId: {
      type: Number,
    },
    groupId: {
      type: Number,
    },
    hideCourseStepIds: {
      type: Array,
      default: () => [],
    },
    onlyShowCourseStepIds: {
      type: Array,
      default: () => [],
    },
    hasPagination: {
      type: Boolean,
      default: true,
    },
    hasSearchBox: {
      type: Boolean,
      default: true,
    },
    hasIconLegend: {
      type: Boolean,
      default: true,
    },
    showLeftColumn: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    studentFilterText (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.filteredStudentIds = this.vm.allStudents
          .filter(stud => stud.firstLastName.toLowerCase().includes(newVal.toLowerCase()))
          .map(stud => stud.id)

        // Reset pagination items after filter text
        this.vm.pagination.allItems = this.filteredStudents()
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import 'vars';
.categories-top {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 56px;
  border-top: 1px solid #e8e8e8;
  padding-left: 201px;
}

.category-item {
  flex-grow: 0;
  flex-shrink: 0;
  border-left: 1px solid #888;
  border-bottom: 1px solid #888;
  text-overflow: ellipsis;
  word-wrap: normal;
  padding: 18px 4px 18px 4px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: #333333;
  overflow: hidden;
}

#group_starter_tracking {
  // margin-top: 24px;
  display: flex;
  background-color: white;
  box-shadow: 0 2px 8px #c8ccd5;
  flex-direction: row;
  min-height: 330px;
}

.course-steps-top {
  height: 200px;
  display: flex;
  box-sizing: border-box;
}

.student-filter-wrapper {
  margin-bottom: 5px;
  padding-left: 3px;
  padding-right: 3px;
}

.course-step-student-container {
  display: flex;
  width: 100%;
  height: 64px;
  line-height: 64px;
  // border-top: 1px solid #cccccc;

  div:focus {
    border: 2px solid red;
  }
}

.student-item {
  height: 64px;
  border-top: 1px solid #888;
  color: #333333;
  padding: 1px 24px 1px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-right: -1px;

  &.has-not-logged-in {
    color: rgb(192, 5, 5);
  }
}

.stacked-status {
  display: flex;
  flex-direction: column;
  flex: 0 34px;
  text-align: center;
  border-right: 1px solid hsl(0, 0%, 70%);
  height: 100%;
  justify-content: center;

  .num-open-conversations {
    border-bottom: 1px solid hsl(0, 0%, 70%);
}
}

.name-container {
  padding-left: 8px;
}

.footer-left-label {
  padding-bottom: 7px;
  color: lighten($medium-gray, 20%);
}

.students-list-container {
  background-color: white;

  &.for-single-student {
    .num-open-conversations, .num-items-needing-review {
      display: none;
    }
  }
}

.course-steps-list-container {
  margin-left: 1px;
}

.course-step-container {
  flex: 0 0 46px;
  border-left: 1px solid #888;
  padding-top: 4px;
  padding-bottom: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333333;
  height: 232px;
  font-size: 14px;
  font-weight: 600;
  bottom: 100px;
  left: 64px;
  text-align: left;
  transform: translate(calc(100% - 1px / 2), 1px) rotate(45deg);
  margin-right: 18px;
  min-height: 400px;

  .course-step-name {
    position: relative;
    text-align: center;
    flex: 1 1 auto; // so that the course-step name will fill the space above total points
  }

  .course-step-footer-left {
    flex: 0 0 25px; // last number is height of total points box
    width: 100%;
    text-align: center;
    font-size: 1rem;
    color: lighten($medium-gray, 20%);
  }
}

.course-step-container.course-step-step {
  flex: 0 0 46px;
  background-color: #EEEBF2;
  border-left: 1px solid #888;
  padding-top: 4px;
  padding-bottom: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #573F7E;
  height: 200px;
  font-size: 14px;
  font-weight: 600;
}

.vertical-label {
  text-orientation: sideways;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  margin-bottom: 16px;
  max-height: 198px;

    // white-space: normal;
  font-size: 13px;
  line-height: 1.1;
}

.left-column {
  flex: 1 0 224px;
  background-color: white;

  .course-steps-top {
    flex-direction: column;
    justify-content: flex-end;
    font-size: 1rem;
  }
}

.scrolling-column {
  flex: 1 1 auto;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 9px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

.scrolling-column,
.right-column {
  .course-steps-top {
    background-color: white;
    clip-path: inset(0px -12000px 0px 0px);
  }

  .course-step-container .inner {
    flex: 1 1 auto;
    text-align: left;
  }
}

.right-column {
  flex: 1 0 70px;
}

.course-step-event i {
  background-color: #20a39e;
  padding: 6px;
  color: white;
  font-size: 16px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transform: rotate(-90deg);
  position: absolute;
  bottom: 80px;
}

.course-step-container.course-step-survey {
  background-color: #cbdcec;
  i {
    background-color: #2064a3;
    padding: 6px;
    color: white;
    font-size: 16px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transform: rotate(-90deg);
    position: absolute;
    bottom: 80px;
  }
}

.course-step-step {
  color: #333;
  i {
    background-color: #786597;
    padding: 6px;
    color: #fff;
    font-size: 16px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transform: rotate(-90deg);
    position: absolute;
    bottom: 80px;
  }
}

.course-step-container.course-step-benchmark {
  /* background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='64' height='64' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.33887 0H0.106376L0 0.106376V3.33887L3.33887 0ZM0 12.4817V9.24923L9.24923 0H12.4817L0 12.4817ZM0 21.6246V18.3921L18.3921 0H21.6246L0 21.6246ZM0 30.7674V27.5349L27.5349 0H30.7674L0 30.7674ZM7.9103 32H4.67781L36.6778 0H39.9103L7.9103 32ZM17.0532 32H13.8207L45.8207 0H49.0532L17.0532 32ZM26.196 32H22.9635L54.9635 0H58.196L26.196 32ZM35.3389 32H32.1064L64 0.106377V3.33887L35.3389 32ZM44.4817 32H41.2492L64 9.24923V12.4817L44.4817 32ZM53.6246 32H50.3921L64 18.3921V21.6246L53.6246 32ZM62.7674 32H59.535L64 27.535V30.7674L62.7674 32Z' fill='%23F5D49F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.33887 64H0.106376L0 63.8936V60.6611L3.33887 64ZM0 51.5183V54.7508L9.24923 64H12.4817L0 51.5183ZM0 42.3754V45.6079L18.3921 64H21.6246L0 42.3754ZM0 33.2326V36.4651L27.5349 64H30.7674L0 33.2326ZM7.9103 32H4.67781L36.6778 64H39.9103L7.9103 32ZM17.0532 32H13.8207L45.8207 64H49.0532L17.0532 32ZM26.196 32H22.9635L54.9635 64H58.196L26.196 32ZM35.3389 32H32.1064L64 63.8936V60.6611L35.3389 32ZM44.4817 32H41.2492L64 54.7508V51.5183L44.4817 32ZM53.6246 32H50.3921L64 45.6079V42.3754L53.6246 32ZM62.7674 32H59.535L64 36.465V33.2326L62.7674 32Z' fill='%23F5D49F'/%3E%3C/svg%3E"); */
  background-color: #FFF1DA;
  border-left: 1px solid #FFBA49;
  border-right: 1px solid #FFBA49;
  background-repeat: repeat;

  i {
    background-color: #ffba49;
    padding: 6px;
    color: white;
    font-size: 16px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transform: rotate(-90deg);
    position: absolute;
    bottom: 80px;
  }
}

.legend {

}

.legend-item {
  display: flex;
  align-items: center;
}

.legend-item-icon {
  margin-right: 12px;

  &.survey {
    color: #2064a3;
    width: 32px;
    text-align: right;
    padding-right: 3px;
    font-size: 22px;
  }
}

.legend-item-text {
  color: black;
  font-size: 13px;
}
</style>

<style lang="scss">
ul.pagination.b-pagination li {
  margin-bottom: 0;
}

ul.pagination.b-pagination {
  margin-bottom: 0;
}

li.page-item a {
  margin-bottom: 0;
}


</style>
