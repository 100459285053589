import { map } from 'lodash-es'
import axios from './axios'
import CourseStepReview from '@models/CourseStepReview'

const baseUrlString = '/course_step_reviews'

async function create(data) {
  return axios.post(baseUrlString, data)
    .then(response => initWithData(response.data))
    .catch(error => Promise.reject(error.response.data))
}

async function getAll(params) {
  return axios.get(baseUrlString, { params })
    .then(response => map(response.data, initWithData))
    .catch(console.error)
}

function initWithData(data) {
  return new CourseStepReview(data)
}

export default {
  getAll,
  create
}
