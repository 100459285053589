import { action, computed, observable, makeObservable } from 'mobx'
import BaseArrayClass from '@stores/BaseArrayStore'

class StudentPagination {
  _currentPage = 1
  _itemsPerPage = 10
  _numberOfPages = 1
  _totalRows = 0

  _allItems = []

  _filteredItems = []

  constructor() {
    makeObservable(this, {
      _currentPage: observable,
      _itemsPerPage: observable,
      _numberOfPages: observable,
      _totalRows: observable,
      _allItems: observable,
      _filteredItems: observable,
      nextPage: action,
      previousPage: action,
      disablePreviousPage: computed,
      disableNextPage: computed,
      shouldShowPagination: computed,
      itemsPerPage: computed,
      allItems: computed,
      currentPage: computed,
      totalRows: computed,
      filteredItems: computed,
      _reconfigure: action,
    })
  }

  nextPage() {
    this.currentPage++
  }

  previousPage() {
    this.currentPage--
  }

  get disablePreviousPage() {
    return this._currentPage === 1
  }

  get disableNextPage() {
    return this._currentPage > (this._filteredItems.length / this._itemsPerPage)
  }

  get shouldShowPagination() {
    return this._numberOfPages > 1
  }

  get itemsPerPage() {
    return this._itemsPerPage
  }

  get allItems() {
    return this._allItems
  }

  set allItems(allItems) {
    this._allItems = allItems
    this._currentPage = 1
    this._reconfigure()
  }

  get currentPage() {
    return this._currentPage
  }

  get totalRows() {
    return this._totalRows
  }

  get filteredItems() {
    return this._filteredItems
  }

  /**
   * @param {number} currentPage
   */
  set currentPage(currentPage) {
    // Keep it at first page if we go below
    if (currentPage < 1) {
      currentPage = 1
    }

    // Keep it at last page if we go over it
    if (currentPage > this._numberOfPages) {
      currentPage = this._numberOfPages
    }

    this._currentPage = currentPage
    this._reconfigure()
  }

  _reconfigure() {
    this._numberOfPages = this._calculateTotalNumberOfPages()
    this._filteredItems.spliceWithArray(0, this._filteredItems.length, this._calculateFilteredList())
    this._totalRows = this._allItems.length
  }

  _calculateTotalNumberOfPages() {
    return Math.ceil(this._allItems.length / this._itemsPerPage)
  }

  _calculateFilteredList() {
    const firstItemIndex = (this._currentPage - 1) * this._itemsPerPage
    const lastItemIndex = this._currentPage * this._itemsPerPage

    return this._allItems.slice(firstItemIndex, lastItemIndex)
  }
}

export default StudentPagination
