<template>
  <div>
    <v-tabs v-model="currentTab" :prev-icon="null">
      <v-tab key="messaging">Step Timeline</v-tab>
      <v-tab key="user-engagement-summary">Engagement<br/>Summary</v-tab>
      <v-tab key="survey-tab">Surveys</v-tab>
      <v-tab key="venture-update">Venture<br/>Update</v-tab>
      <v-tab key="gm-history">Action<br/>History</v-tab>
      <v-tab key="events">Activity<br/> History</v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item key="messaging">
        <TasksInteractionTimeline
            v-if="selectedTask"
            :groupId="groupId"
            :userId="userId"
            :courseStepId="selectedTask.course_step_id"
            :key="uniqueKey"
            :servicePreset="servicePreset"
        />
        <div v-else class="step-timeline-no-task-container"><span>No Task Selected!</span></div>
      </v-tab-item>

      <v-tab-item key="user-engagement-summary">
        <UserEngagementSummary :studentGroupMembershipId="studentGroupMembershipId" />
      </v-tab-item>

      <v-tab-item key="survey-tab">
        <div class="scroll-smooth overflow-y-scroll flex-auto" style="max-height: 500px;" ref="scrollable">
          <SurveyAnswersTab :studentGroupMembershipId="studentGroupMembershipId"/>
        </div>
      </v-tab-item>

      <v-tab-item key="venture-update">
        <div class="scroll-smooth overflow-y-scroll flex-auto" style="max-height: 500px;" ref="scrollable">
          <VentureUpdate :studentGroupMembershipId="studentGroupMembershipId"/>
        </div>
      </v-tab-item>

      <v-tab-item key="gm-history">
        <group-membership-action-history :hideHeader="true" :user="user" :groupMembershipId="studentGroupMembershipId"></group-membership-action-history>
      </v-tab-item>

      <v-tab-item key="events">
        <user-analytics-events :user="userId"/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import TasksInteractionTimeline from '@components/interactionTimeline/TasksInteractionTimeline.vue'
  import UserEngagementSummary from '@components/UserEngagementSummary.vue'
  import UserAnalyticsEvents from '@components/UserAnalyticsEvents.vue'
  import GroupMembershipActionHistory from '@components/GroupMembershipActionHistory.vue'
  import VentureUpdate from '@components/VentureUpdate.vue'
  import SurveyAnswersTab from "@components/SurveyAnswersTab.vue";
  import StarterTracking from '@components/StarterTracking.vue'

  import { VTab, VTabItem, VTabsItems, VTabs, } from 'vuetify/lib'

  const NAME = "task-user-engagement-tabs"

  export default {
    name: NAME,
    mixins: [  ],
    components: {
      TasksInteractionTimeline,
      UserEngagementSummary,
      GroupMembershipActionHistory,
      UserAnalyticsEvents,
      StarterTracking,
      VentureUpdate,
      SurveyAnswersTab,
      VTab,
      VTabItem,
      VTabsItems,
      VTabs,
    },
    props: {
      studentGroupMembershipId: {type: Number, required: true},
      groupId: {type: Number, required: true},
      user: {type: Object, required: true},
      selectedTask: {type: Object, required: false},
    },
    data () {
      return {
        currentTab: null,
      }
    },
    async mounted() {

    },
    computed: {
      userId(){
        return this.user.id
      },
      servicePreset () { //TODO: find a way to get rid of this
        return {
          groupId: this.groupId,
          userId: this.userId,
          courseStepId: this.selectedTask.course_step_id,
        }
      },
      uniqueKey () { //TODO: find a way to get rid of this
        return this.userId + "-" + this.selectedTask.course_step_id
      },
    },
    methods: {

    },
    watch: {

    }
  }

</script>

<style lang="scss">
  .step-timeline-no-task-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    span {
      font-size: 24px;
      color: gray;
    }
  }
</style>
