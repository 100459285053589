import axios from './axios'
import AssignmentResponseGrade from '@models/AssignmentResponseGrade'

const baseUrlString = '/assignment_response_grades'

async function getAll (params) {
  return axios.get(baseUrlString, { params })
    .then(response => {
      return response.data.map(initWithData)
    }).catch(console.error)
}

async function getById (id) {
  return axios.get(`${baseUrlString}/${id}`)
    .then(response => initWithData(response.data))
    .catch(console.error)
}

async function create (data) {
  return axios.post(baseUrlString, {
    assignment_response_grade: data
  })
    .then(response => initWithData(response.data))
    .catch(console.error)
}

function initWithData (data) {
  return new AssignmentResponseGrade(data)
}

export default {
  getAll,
  getById,
  create,
  initWithData
}
