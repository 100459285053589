<template>
  <modal
    :name="modalName"
    class="mentorship-modal"
    :scrollable="true"
    height="500"
    @closed="destroyModal">
    <div class="close" @click="closeAssignMentor" style="position: absolute; right: 18px; top: 8px; z-index: 999; background-color: white; padding: 4px; color: #767676; font-size: 22px; opacity: .75; border-radius: 4px;">
      <i class="fa fa-times"></i>
    </div>
    <div class="container">
      <h2 class="title">Assign Mentor to {{ currentStudent.first_name }} {{ currentStudent.last_name }} </h2>
      <p>Select a mentor to assign to {{ currentStudent.first_name }} and add a description for them. </p>

      <div class="mentor-list">
        <div class="mentor-list-entry" v-for="record in mentorRecords" :key="record.user.id">
          <div class="mentor-list-entry-header" :class="{ exists: record.mentorshipRecord, 'not-exists': !record.mentorshipRecord }"
            v-b-toggle="`collapse-${record.user.id}`">
            <div class="mentor-name">{{ record.user.first_name }} {{ record.user.last_name }}</div>
            <div class="mentor-description" v-if="record.mentorshipRecord">{{record.mentorshipRecord.description}}</div>
            <div class="add-icon" v-if="!record.mentorshipRecord"><i class="fa fa-plus-circle" /></div>
          </div>

          <b-collapse :id="`collapse-${record.user.id}`" class="mt-1" :key="`c${record.user.id}`">
            <div class="mentor-form-card">
              <div class="mentor-form-card-inner">
                <textarea class="form-control" placeholder="Mentorship description..." id="exampleFormControlTextarea1" rows="2" v-model="mentorshipDescription" />
                <button class="btn btn-primary btn-block" @click="assignMentor(record.user.id)">Assign Mentor</button>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import { WindowVariables } from '@utils'
  import ModalMixin from '@mixins/ModalMixin.vue'
  import FlashMessages from '@services/FlashMessageService'
  import { getUsersStoreFor } from '@stores/UsersStore'
  import { filter, find } from 'lodash-es'
  import { getMentorshipsStoreFor} from '@stores/MentorshipsStore'

  const NAME = "mentorship-modal"

  export default {
    name: 'mentorship-modal',
    mixins: [ ModalMixin ],
    components: {
    },
    data() {
      return {
        usersStore: getUsersStoreFor({
          group_id: this.params.groupId,
          state: 1,
        }),
        mentorshipsStore: getMentorshipsStoreFor(),
        modalName: NAME,
        groupId: this.params.groupId,
        userId: this.params.userId,
        mentorshipDescription: '',
        creatingMentorship: false,
      }
    },
    fromMobx: {
      users () {
        return this.usersStore.allRecords
      },
    },
    methods: {
      assignMentor (userId) {
        if (this.creatingMentorship) { return }
        this.creatingMentorship = true
        this.mentorshipsStore.create({
          mentee_id: this.params.userId,// which row did you click on the roster?,
          mentor_id: userId,  // which mentor row did they click on just now
          group_id: this.groupId,
          description: this.mentorshipDescription,
        })
          .then(u => {
            this.creatingMentorship = false
            FlashMessages.success('Mentor assigned successfully!')
            this.usersStore._reload()
            this.destroyModal()
            // this.$emit({userId: userId, studentId: })
          })
          .catch(resp => {
            console.error(resp)
            this.creatingMentorship = false
            FlashMessages.error('Error, could not assign mentor.')
          })
          .finally(() => {
          })
      },
      showMentorDetails (userId) {},
      closeAssignMentor () {
        this.destroyModal()
      },
    },
    computed: {
      mentorRecords () {
        return filter(this.users, u => {
          const gm = find(u.group_memberships, { group_id: this.groupId })
          return gm && gm.role === 'mentor'
        }).map(mentor => {
          const matchingGm = this.currentStudent.group_memberships.find(gm => gm.group_id === this.groupId)
          return {
            user: mentor,
            mentorshipRecord: matchingGm.mentorships.find(mnt => mnt.group_id === this.groupId && mentor.id === mnt.mentor_id && !mnt.inactive_at)
          }
        })
      },
      currentStudent () {
        return find(this.users, { id: this.userId })
      },
    },
  }
</script>

<style lang="scss">
  .title {
    padding-top: 20px;
  }

  .mentorship-modal .v--modal {
    height: auto;
    width: 75%;
  }

  .mentor-list {
    overflow-y: auto;
    height: 320px;
  }

  .mentor-list-entry {
    .mentor-list-entry-header {
      display: flex;
      flex-direction: row;
      padding: 12px;
      border-bottom: 2px solid #e8e8e8;
      color: #333;
      font-weight: 400;
      transition: all 200ms ease;

      background-color: #fff;
      &.not-exists {
        cursor: pointer;

        &:hover {
          background-color: #F6F6F6;
          font-weight: 500;
        }
      }


      &.exists {
        cursor: initial;
        background-color: #dddddd;
        color: #333333;
        pointer-events: none;
        width: 100%;
      }

      .mentor-name {
        flex: 1 0 100px;
      }

      .mentor-description {
        flex: 4 0 auto;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .add-icon {
        flex: 0 0 30px;
        align-content: flex-end;
        padding-left: 25px;
        color: #333;
      }
    }

    textarea.form-control {
      margin-bottom: 10px;
    }
  }

  .mentor-form-card {
    padding: 0px 8px 4px 8px;

  .mentor-form-card-inner {
      padding: 12px;
      background-color: #F6F6F6;

      button {
        background-color: #f3763a;
        color: #fff;
        padding: 12px 24px;
        border: none;

        &:hover {
          background-color: #e6692e;
        }
      }
    }
  }
</style>
