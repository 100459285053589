import Model from '@models/Model'
import { v4 as uuid } from 'uuid'

export const LOCK_TYPE = {
  'gated': 'gated',
  'non-gated': 'non-gated',
  'never': 'never',
}

class CourseStep extends Model {
  constructor (initialData) {
    super(initialData, {
      momentKeys: [],
      uniqueId: uuid(),
    })
  }

  get hasAssignment () {
    return !!this.assignment_id
  }

  get isStep () {
    return this.type === 'CourseStep::Step'
  }

  get isBenchmark () {
    return this.type === 'CourseStep::Benchmark'
  }

  get isEvent () {
    return this.type === 'CourseStep::Event'
  }

  get isSurvey () {
    return this.type === 'CourseStep::Survey'
  }

  get displayName () {
    return this.name
  }

  // LEGACY
  // get customProperties () {
  //   return this.custom_descriptors ? merge({}, this.base_descriptors || {}, this.custom_descriptors || {}) : this.base_descriptors || {}
  // }

  get isCoachingEventType () {
    return this.event_type.toLowerCase() === 'coaching'
  }

  get isPhoneEventType () {
    return this.event_type.toLowerCase() === 'phone'
  }

  get isWorkshopEventType () {
    return this.event_type.toLowerCase() === 'workshop'
  }

  get isOnlineEventType () {
    return this.event_type.toLowerCase() === 'online'
  }

  get isMentorEventType () {
    return this.event_type.toLowerCase() === 'mentor'
  }

  get lockType () {
    return this.lock_type
  }

  get isGatedLock () {
    return this.lockType === LOCK_TYPE['gated']
  }

  get isNonGatedLock () {
    return this.lockType === LOCK_TYPE['non-gated']
  }

  get isNeverLocked () {
    return this.lockType === LOCK_TYPE['never']
  }

  get faIcon () {
    if (this.isEvent) {
      return (
        'fa-calendar'
      )
    } else if (this.isBenchmark) {
      return 'fa-lock'
    } else if (this.isStep) {
      return 'fa-desktop'
    } else if (this.isSurvey) {
      return 'fa-question-circle'
    }
  }
}

export default CourseStep
