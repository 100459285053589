import EventBus from '@services/GlobalEventBus'

export default {
  open (componentName, params) {
    EventBus.dispatch('spawning-modal', { componentName, params })
  },

  close (modalId) {
    EventBus.dispatch('destroying-modal', modalId)
  }
}
