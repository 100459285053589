import { computed, makeObservable } from 'mobx'
import { concat, last, sortBy } from 'lodash-es'
import { v4 as uuid } from 'uuid'

class StudentStepProgress {
  _parent = undefined
  _courseStepId = undefined
  _courseStepIndex= undefined

  constructor (parent, courseStepId, index) {
    makeObservable(this, {
      conversation: computed,
      messages: computed,
      courseStepId: computed,
      courseStep: computed,
      courseStepReviews: computed,
      latestCourseStepReview: computed,
      latestAssignmentResponse: computed,
      latestAttachment: computed,
      isReviewApproved: computed,
      isUnderReview: computed,
      isQuestionsRemaining: computed,
      attachments: computed,
      assignmentResponses: computed,
      userId: computed,
      user: computed,
      groupId: computed,
      allEvents: computed,
      _messageEvents: computed,
      _closedEvents: computed,
      _courseStepReviewEvents: computed,
      _attachmentsEvents: computed,
      _assignmentResponseEvents: computed,
      isLocked: computed,
      statuses: computed
    })

    this._parent = parent
    this._courseStepId = courseStepId
    this._courseStepIndex = index
    this._uuid = uuid()
  }

  get conversation() {
    return this._parent.conversations.find(conv => this._courseStepId === conv.metadata.course_step_id)
  }

  get messages() {
    return this.conversation ? sortBy(this.conversation.messages, ['id']) : []
  }

  get courseStepId() {
    return this._courseStepId
  }

  get courseStep() {
    return this._parent.allCourseSteps.find(cs => this._courseStepId === cs.id)
  }

  get courseStepReviews() {
    return this._parent.courseStepReviews.filter(review => this._courseStepId === review.course_step_id)
  }

  get latestCourseStepReview() {
    return last(sortBy(this.courseStepReviews, 'id'))
  }

  get latestAssignmentResponse() {
    return last(sortBy(this.assignmentResponses, 'submitted_at'))
  }

  get latestAttachment() {
    return last(sortBy(this.attachments, 'id'))
  }

  get isReviewApproved() {
    return this.latestCourseStepReview &&
      this.latestCourseStepReview.isApproved &&
      (
        (this.latestAssignmentResponse && this.latestAssignmentResponse.submitted_at && this.latestAssignmentResponse.submitted_at.unix() < this.latestCourseStepReview.created_at.unix()) ||

        // if Review is approved and isEvent or isBenchmark, it's Approved.
        (this.courseStep.isEvent || this.courseStep.isBenchmark) ||

        // if this is now a step and doesn't have an AR because they used an assignment upload, have it be reviewed.
        (this.courseStep.isStep && !this.latestAssignmentResponse) ||

        // An approved CSR is good enough in this case
        this.courseStep.isSurvey
      )
  }

  get isUnderReview() {
    return this.latestCourseStepReview &&
      this.latestCourseStepReview.isUnderReview &&
      (
        (this.latestAssignmentResponse && this.latestAssignmentResponse.submitted_at && this.latestAssignmentResponse.submitted_at.unix() < this.latestCourseStepReview.created_at.unix()) ||

        // if Review is under review and isEvent or isBenchmark, it's under review without an assignment response.
        (this.courseStep.isEvent || this.courseStep.isBenchmark) ||

        // if this is now a step and doesn't have an AR because they used an assignment upload, have it be under review.
        (this.courseStep.isStep && !this.latestAssignmentResponse)
      )
  }

  get isQuestionsRemaining() {
    return this.latestCourseStepReview &&
      this.latestCourseStepReview.isQuestionsRemaining &&
      (
        (this.latestAssignmentResponse && this.latestAssignmentResponse.submitted_at && this.latestAssignmentResponse.submitted_at.unix() < this.latestCourseStepReview.created_at.unix()) ||

        // if Review is under review and isEvent or isBenchmark, it's under review without an assignment response.
        (this.courseStep.isEvent || this.courseStep.isBenchmark) ||

        // if this is now a step and doesn't have an AR because they used an assignment upload, have it be under review.
        (this.courseStep.isStep && !this.latestAssignmentResponse)
      )
  }

  get attachments() {
    return this._parent.attachments.filter(attachment => {
      const attachRel = attachment.attachment_relations[0]
      return attachRel.attachment_relatable_type === 'CourseStepProgress' && attachRel.attachment_relatable.course_step_id === this._courseStepId
    })
  }

  get assignmentResponses() {
    return this._parent.assignmentResponses.filter(ar => this.courseStep.assignment_id === ar.assignment_id)
  }

  get userId() {
    return this._parent.userId
  }

  get user() {
    return this._parent.user
  }

  get groupId() {
    return this._parent.groupId
  }

  get allEvents() {
    return sortBy(concat(this._attachmentsEvents, this._courseStepReviewEvents, this._messageEvents, this._assignmentResponseEvents, this._closedEvents), ['sort'])
  }

  get _messageEvents() {
    return this.messages
      .filter(m => !m.isCloseNotification && !m.isAttachmentNotification)
      .map(message => this._createUnified(message, 'message'))
  }

  get _closedEvents() {
    return this.messages
      .filter(m => m.isCloseNotification)
      .map(message => this._createUnified(message, 'closed'))
  }

  get _courseStepReviewEvents() {
    return this.courseStepReviews.map(review => this._createUnified(review, 'courseStepReview'))
  }

  get _attachmentsEvents() {
    return this.attachments.map(attachment => this._createUnified(attachment, 'attachment'))
  }

  get _assignmentResponseEvents() {
    // don't show unsubmitted ARs in the student's timeline
    return this.assignmentResponses.filter(ar => ar.isSubmitted).map(ar => this._createUnified(ar, 'assignmentResponse', 'submitted_at'))
  }

  // To-Do: Remove getter, create better way to access conversations store
  get _conversationsStoreUuid () {
    return this.dataStore._conversationsStore.uuid
  }

  get _courseStepReviewsStoreUuid () {
    return this.dataStore._courseStepReviewsStore.uuid
  }

  get isLocked() {
    if (this.courseStep.isNeverLocked) {
      return false
    } else {
      return this._parent.indexOfLatestGatedLock < this._courseStepIndex
    }
  }

  get statuses() {
    return {
      hasAttachments: this.attachments.length > 0,
      hasUploads: this.assignmentResponses.length > 0,
      hasUploadsOrAttachments: this.attachments.length > 0 || this.assignmentResponses.length > 0,
      locked: this.isLocked,
      unlocked: !this.isLocked,
      completed: this.isReviewApproved,
      workRequired: this.latestCourseStepReview && !this.isReviewApproved && !this.isUnderReview,
      notYetReviewed: !this.latestCourseStepReview || this.latestCourseStepReview.isUnderReview,
      isUnderReview: this.isUnderReview,
      workRequiredWithNewUpload: this.assignmentResponses.length > 0 &&
        this.latestCourseStepReview &&
        !this.isReviewApproved &&
        this.latestAssignmentResponse.isSubmitted &&
        this.latestAssignmentResponse.submitted_at.unix() > this.latestCourseStepReview.created_at.unix(),
      needToReviewAttachment: this.attachments.length > 0 &&
        (this.courseStep.isBenchmark || this.courseStep.isEvent) && (
          ( // there is an approved review and an attachment came in after the review
            this.latestCourseStepReview &&
            this.isReviewApproved &&
            this.latestAttachment.created_at.unix() > this.latestCourseStepReview.created_at.unix()
          ) ||
          ( // there is a questions remaining or under review state
            this.latestCourseStepReview &&
            this.isUnderReview
          ) ||
          !this.latestCourseStepReview // there is no course step review and we have attachments
        )
    }
  }

  get dataStore () {
    return this._parent.dataStore
  }

  _typeParse(type) {
    return ({
      message: 'InteractionEventMessage',
      attachment: 'InteractionEventAttachment',
      courseStepReview: 'InteractionEventReview',
      assignmentResponse: 'InteractionEventAssignment',
      closed: 'InteractionEventClosed',
    })[type]

  }

  _createUnified (record, type, sortColumn = 'created_at') {
    return {
      userId: this._parent.userId,
      uid: `${type}-${record.id}`,
      type: type,
      component: this._typeParse(type),
      value: record,
      sort: record[sortColumn] * -1,
    }
  }
}

export default StudentStepProgress
