<template>
  <modal
      :name="modalName"
      class="course-management-update-course-modal"
      :scrollable="true"
      height="auto"
      @closed="close">
    <div class="close" @click="close">
      <i class="fa fa-times"></i>
    </div>

    <div class="modal-content">
      <h2 v-if="isUpdateMode">Update Course</h2>
      <h2 v-if="isCreateMode">Create Course</h2>
      <div>
        <v-alert v-model="alertVisible" type="error" dismissible>
          {{ errorMessage }}
        </v-alert>
      </div>

      <form @submit.prevent="submit" class="form">
        <div class="form-group">
          <label for="id">Id:</label>
          <input type="text" id="id" v-model="course.id" :disabled="true" class="disabled-input" />
        </div>

        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" id="name" v-model="course.name" />
        </div>

        <div class="form-group">
          <label for="analytics">Analytics Name:</label>
          <input type="text" id="analytics" v-model="course.analytics_name" :disabled="isUpdateMode" :class="{'disabled-input': isUpdateMode }" />
        </div>

        <div class="form-group">
          <label for="partner-id">Partner Id:</label>
          <input type="number" id="partner-id" v-model.number="course.partner_id" />
        </div>

        <div class="form-group">
          <label for="classes">Class Names:</label>
          <input type="text" id="classes" v-model="course.class_names" />
        </div>

        <div class="form-group">
          <label for="language">Language:</label>
          <select id="language" v-model="course.language_code" >
            <option value=""></option>
            <option v-for="option in languageOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
          </select>
        </div>

        <button type="submit" class="submit-button">Submit</button>
      </form>
    </div>
  </modal>
</template>
<script>
  import ModalMixin from '@mixins/ModalMixin.vue'
  import axios from '@dataServices/axios'
  import EventBus from '@services/GlobalEventBus'

  import { VAlert } from 'vuetify/lib'

  const baseUrl = window.location.origin
  const NAME = 'course-management-update-course-modal'

  export default {
    name: NAME,
    mixins: [ModalMixin],
    components: {
      VAlert,
    },
    data() {
      return {
        modalName: NAME,
        courseId: this.params.course_id,
        course: {},
        languageOptions: [],
        updated: false,
        alertVisible: false,
        errorMessage: '',
      }
    },
    mounted() {
      if (this.isUpdateMode) {
        this.fetchCourseData()
      }
      this.fetchLanguages()
    },
    methods: {
      close() {
        const eventPayload = { type: 'closed', updated: this.updated}
        EventBus.dispatch(NAME, eventPayload)
        this.destroyModal()
      },
      fetchCourseData(){
        return axios
              .get(baseUrl + `/backoffice/api/courses/${this.courseId}`)
              .then(response => this.processFetchResponse(response))
              .catch(error => this.processResponseError(error))
              ;
      },
      fetchLanguages(){
        return axios
              .get(baseUrl + `/backoffice/api/course_languages`)
              .then(response => this.processFetchLanguagesResponse(response))
              .catch(error => this.processResponseError(error))
              ;
      },
      createCourse(){
        const request = new UpdateCourseRequest(this.course);
        return axios
            .post(baseUrl + `/backoffice/api/courses`, request)
            .then(response => this.processUpdateResponse(response))
            .catch(error => this.processResponseError(error))
            ;
      },
      updateCourse(){
        const request = new UpdateCourseRequest(this.course);
        return axios
              .put(baseUrl + `/backoffice/api/courses/${this.courseId}`, request)
              .then(response => this.processUpdateResponse(response))
              .catch(error => this.processResponseError(error))
              ;
      },
      submit () {
        if (this.isCreateMode) {
          this.createCourse();
        } else {
          this.updateCourse();
        }
      },
      processFetchResponse (response) {
        this.course = response.data;
      },
      processFetchLanguagesResponse (response){
        this.languageOptions = response.data.map(language => new Language(language));
      },
      processUpdateResponse (response) {
        this.updated = true;
        this.course = response.data;
        this.close();
      },
      processResponseError (error) {
        this.errorMessage = error.response? error.response.data.message : error.message;
        this.alertVisible = true;
        console.error('Backend Error:', this.errorMessage, error.response?.data, error.response?.status);
      },
    },
    computed: {
      isCreateMode () {
        return this.courseId ? false : true
      },
      isUpdateMode () {
        return this.courseId ? true : false
      },
    },
    created () {

    },
  }

  class UpdateCourseRequest {
    constructor (course) {
      this.course = course;
    }

  }

  class Language {
    constructor (value) {
      this.value = value;
      this.label = this.#toLabel(value);
    }

    #toLabel(value){
      switch (value){
        case 'en': return 'English';
        case 'es': return 'Spanish';
        default: return 'Unknown Language -> '+value;
      }
    }

  }
</script>

<style lang="scss">
.course-management-update-course-modal {

  .close {
    position: absolute;
    right: 18px;
    top: 8px;
    z-index: 999;
    background-color: white;
    padding: 4px;
    color: #767676;
    font-size: 22px;
    opacity: .75;
    border-radius: 4px;
    cursor: pointer;
  }

  .modal-content {
    padding: 20px;
  }

  .form {
    .form-group {
      display: flex;
      align-items: center;

      label {
        width: 100px;
        text-align: right;
        margin-right: 10px;
      }

      input {
        flex-grow: 1;
      }
    }

    .disabled-input {
      background-color: #e9ecef;
      color: #495057;
      cursor: not-allowed;
    }

    .submit-button {
      background-color: #007bff;
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  .v-alert {
    border-radius: 8px;
    padding: 16px;

    &.error {
      background-color: #ffebee; // Light Red
      color: #c62828; // Dark Red
      border: 1px solid #ef9a9a; // Red

      .v-icon {
        color: #c62828; // Dark Red
      }
    }

    &.success {
      background-color: #e8f5e9; // Light Green
      color: #2e7d32; // Dark Green
      border: 1px solid #a5d6a7; // Green

      .v-icon {
        color: #2e7d32; // Dark Green
      }
    }
  }
}

</style>
