<template>
  <modal :name="modalName" height="90%" width="90%" :scrollable="true">
    <grader :groupId="params.groupId"></grader>
  </modal>
</template>

<script>

const NAME = 'starter-tracking-grader-dialog'

import Grader from '@components/starterTracking/Grader.vue'
import ModalMixin from '@mixins/ModalMixin.vue'

export default {
  name: NAME,
  mixins: [ ModalMixin ],
  components: {
    Grader,
  },
  data () {
    return {
      modalName: NAME,
    }
  },
}
</script>