<template>
  <div class="interaction-event-assignment">
    <div class="interaction-event-row">
      <UserThumbnail :userId="event.value.user.id"/>
      <div class="content">
        <div class="sent-info">
          <span class="sent-by">{{ event.value.uploaderName }}</span>
          <span class="sent-at">{{ event.value.sentTimeFormatted }}</span>
        </div>
        <div class="attachment-icon-container" v-if="event.value.assignment_type === 'Assignment::Downloadable'">
          {{ $t('application.interaction_timeline.event_assignment.step_uploaded') }}
          <a :href="event.value.attachmentUrl" target=”_blank” class="attachment-download-link">
            <div class="filename">{{ event.value.filename }}</div><i class="fa fa-download"></i>
          </a>
        </div>
        <div class="attachment-icon-container" v-if="event.value.assignment_type === 'Assignment::Web'">
          {{ $t('application.interaction_timeline.event_assignment.step_submitted_via_web') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import UserThumbnail from '@components/general/UserThumbnail.vue'

  export default {
    name: 'interaction-event-assignment',
    components: {
      UserThumbnail,
    },
    props: {
      event: {
        type: Object,
        required: true,
      },
    },
    created () {
    }
  }
</script>

<style lang="scss" scoped>
  .interaction-event-assignment {
    // background-color: #F0EBF5;
    font-size: .875rem;
    font-weight: 500;
    color: #333;
    border-radius: 5px;
    margin-bottom: 16px;

    .content {
      color: #5F5934;
      background-color: #FDFAE6;
      border-radius: 0px 16px 16px 16px;
      padding: 12px;
      display: flex;
      flex-wrap: wrap;
      max-width: 269px;
      min-width: 64px;
      margin-left: 8px;
      font-size: 1rem;
      line-height: 24px;
      text-align: left;
    }
  }

  .interaction-event-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .filename {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sent-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    margin-bottom: 4px;
  }

  .sent-by {
    font-weight: 600;
    font-size: .875rem;
    color: #5F5934;
    line-height: 16px;
    display: inline-block;
    text-align: left;
  }

  .sent-at {
    color: #7A744D;
    font-size: .75rem;
    text-align: right;
  }

  .attachment-icon-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-size: 0.9rem;
    overflow: hidden;
    white-space: wrap;
    text-overflow: wrap;
    text-align: left;

    .fa-download {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #E2DAA2;
      color: #5B552B;
      border-radius: 4px;
      font-size: 1rem;
      width: 32px;
      height: 32px;
    }
  }

  .attachment-download-link {
    display: inline-flex;
    flex-direction: column;
    font-size: .875rem;
    font-weight: 700;
    color: #5F5934;
    margin-bottom: 4px;
    width: 100%;

    &:hover {
      text-decoration: none;
    }
  }

  .thumbnail-user {
      color: #5F5934;
      background-color: #FDFAE6;
  }

  .attachment-action-icon-container {
    display: flex;
    flex-direction: row;
  }
</style>
