<template>
  <div class="interaction-assignment-submit">
    <form enc-type="multipart/form-data" novalidate class>
      <label class="file-container pull-right" @click="clearVal()">
        <button class="btn pull-right" :class="{ resubmit: hasAssignmentUploaded }">
          <span v-if="!submitting && !hasAssignmentUploaded">{{ $t('application.interaction_timeline.assignment_submit.submit_action_step') }}</span>
          <span v-if="!submitting && hasAssignmentUploaded">{{ $t('application.interaction_timeline.assignment_submit.resubmit_action_step') }}</span>
          <span v-if="submitting">{{ $t('application.interaction_timeline.assignment_submit.uploading') }}</span>
        </button>
        <input
          type="file"
          name="attachment_file"
          accept="application/pdf"
          class="pull-right"
          aria-describedby="add-attachment-to-step"
          @change="filesChange($event.target.name, $event.target.files)"
        />
        <span id="add-attachment-to-step" class="sr-only">Add attachment to step</span>
      </label>
    </form>
  </div>
</template>

<script>
import { find } from 'lodash-es'
import { getAssignmentResponsesStoreFor } from "@stores/AssignmentResponsesStore"
import { getAssignmentsStoreFor } from "@stores/AssignmentsStore"
import { WindowVariables } from "@utils"
import FlashMessages from "@services/FlashMessageService"
import ModalService from "@services/ModalService"

import { observer } from "mobx-vue"

export default observer({
  name: "interaction-assignment-submit",
  components: {},
  data () {
    const assignmentId = this.studentStepProgress.courseStep.assignment_id
    return {
      assignmentId,
      submitting: false,
      assignmentsStore: getAssignmentsStoreFor({
        groupId: this.studentStepProgress.groupId,
        courseStepId: this.studentStepProgress.courseStepId
      }),
      assignmentResponsesStore: getAssignmentResponsesStoreFor({
        assignmentId: assignmentId,
        groupId: this.studentStepProgress.groupId
      }),
    }
  },
  computed: {
    assignmentResponse() {
      return find(this.assignmentResponsesStore.allRecords, {
        assignment_id: parseInt(this.assignmentId),
        user_id: this.studentStepProgress.userId,
      })
    },
    hasAssignmentUploaded() {
      return !!this.assignmentResponse
    },
    firstUpload() {
      return this.assignment.uploads[0]
    },
    assignment() {
      return find(this.assignmentsStore.allRecords, {
        id: parseInt(this.assignmentId)
      })
    },
  },
  methods: {
    clearVal() {
      $("input[name=attachment_file]").val("")
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData()

      if (!fileList.length) return

      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map(x => {
        formData.append(fieldName, fileList[x], fileList[x].name)
      })

      // save it
      this.save(formData)
    },
    save(formData) {
      formData.set("assignment_response[assignment_id]", this.assignmentId)
      formData.set(
        "assignment_response[group_id]",
        this.studentStepProgress.groupId
      )
      formData.set("assignment_response[file_type]", "PDF")
      formData.set(
        "assignment_response[file_identifier]",
        this.firstUpload.file_identifier
      )

      this.submitting = true
      this.assignmentResponsesStore
        .create(formData)
        .then(resp => {
          this.submitting = false;
          FlashMessages.success(this.$t('application.interaction_timeline.assignment_submit.flash.submission_success'))
        })
        .catch(resp => {
          this.submitting = false
          if (resp.kind === "error") {
            let txt = resp.message
            let idx = txt.indexOf("|")
            if (idx > -1) {
              txt = txt.substring(idx + 1)
            }
            FlashMessages.error(txt)
            ModalService.open('action-step-upload-error-modal', { groupId: this.groupId })
          } else {
            FlashMessages.error(this.$t('application.interaction_timeline.assignment_submit.flash.submission_error'))
          }
        })
    }
  },
  props: {
    studentStepProgress: {
      type: Object,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
.interaction-assignment-submit {

  .assignment-total-points {
    display: none;
  }

  .btn {
    background-color: $htss-purple;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 16px 48px;

    &:hover {
      background-color: lighten(#573f7e, 5%);
    }

    &.resubmit {
      font-size: 1.0rem;
      padding: 6px 12px;
      background-color: #767676;

      &:hover {
        background-color: lighten(#767676, 5%);
      }
    }
  }
}

.assignment-attachment {
  padding-right: 10px;
}

.file-container {
  overflow: hidden;
  position: relative;
}

.file-container [type="file"] {
  cursor: pointer;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}
</style>
