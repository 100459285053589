import NewModel from '@models/NewModel'
import TaskAction from '@models/TaskAction'
import User from '@models/NewUser'
import { find } from 'lodash-es'

class Task extends NewModel {
  constructor (initialData, included) {
    super(initialData, included, {
      columns: [
        'id',
        'description',
        'course_step_name',
        'group_name',
        'sequence_name',
        'course_step_id',
        'group_id',
        'student_user_id',
        'coach_user_id',
        'state',
        'metadata',
        'student_group_membership_id',
        'sequence_number',
        'sequence_subject',
      ],
      momentKeys: [
        'created_at',
        'updated_at',
        'in_progress_at',
      ],
      relationships: {
        task_actions: {
          model: TaskAction,
          collection: true,
        },
        coach_user: {
          model: User,
        },
        student_user: {
          model: User,
        },
      }
    })
  }

  get isOpen () {
    return this.state === 'open'
  }

  get isCompleted () {
    return this.state === 'completed'
  }

  get taskCompletedAt () {
    const taskAction = find(this.getRelation('task_actions'), ta => ta.isComplete)

    return taskAction && taskAction.created_at || null
  }

  get isEngagementConcern () {
    return this.task_type === 'engagement-concern-followup'
  }

  get isLowFeedback () {
    return this.task_type === 'low-feedback-score-followup'
  }

  get isQuestionsRemaining () {
    return this.task_type === 'questions-remaining-followup'
  }

  get isPhaseCompletion () {
    return this.task_type === 'phase-completion-followup'
  }

  get isManualEngagement () {
    return this.task_type === 'manual-engagement-followup'
  }
  get isPhaseSurveySubmitted () {
    return this.task_type === 'phase-survey-followup'
  }

  get isTriageTask () {
    return this.task_type === 'triage-launchers-engagement-followup'
  }
  get humanSourceType () {
    if (this.isPhaseCompletion) {
      if (this.metadata['source_type'] === 'launcher_reassigned') {
        return 'Launcher Reassigned'
      } else if (this.metadata['source_type'] === 'course_started') {
        return 'Course Started'
      } else if (this.metadata['source_type'] === 'phase_complete') {
        return 'Phase Complete'
      } else {
        return null
      }
    } else {
      return null
    }
  }

  get taskTypeFormatted () {
    if (this.task_type === 'engagement-concern-followup') {
      return 'Engagement Concern'
    } else if (this.task_type === 'low-feedback-score-followup') {
      return 'Low Feedback Score'
    } else if (this.task_type === 'questions-remaining-followup') {
      return 'Questions Remaining'
    } else if (this.task_type === 'manual-engagement-followup') {
      return 'Manual Engagement Request'
    } else if (this.task_type === 'phase-survey-followup') {
      return 'Phase Survey Submitted'
    } else if (this.task_type === 'phase-completion-followup') {
      return 'New Launcher'
    }
  }

  get createdFormatted () {
    return this.created_at.format('MMM Do YY @ H:mma')
  }
}

export default Task
