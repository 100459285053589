import { isArray } from 'lodash-es'
import { action, makeObservable } from 'mobx'

import BaseArrayClass from '@stores/BaseArrayStore'
import DataService from '@dataServices/Users'
import StoreInstances, { getStoreFor } from '@stores/StoreInstances'

const STORE_TYPE = 'Users'

export function getUsersStoreFor (param, opts = {}) {
  return getStoreFor(UsersStore, STORE_TYPE, param, opts)
}

export class UsersStore extends BaseArrayClass {
  constructor (opts) {
    super(STORE_TYPE, opts)

    makeObservable(this, {
      _fetchOne: action,
      _fetchAll: action,
      create: action,
      updateTags: action,
    })
  }

  _fetchOne() {
    return DataService.getById(this._filter.id)
  }

  _fetchAll(payload) {
    return DataService.getAll(payload || { ...this._filter })
  }

  create(payload) {
    return DataService.create(payload)
      .then(rec => {
        if (!isArray(rec)) {
          rec = [ rec ]
        }
        return Promise.resolve(StoreInstances._propagateChanges(STORE_TYPE, rec))
      })
      .catch(Promise.reject)
  }

  updateTags(payload) {
    return DataService.update({
      id: payload.id,
      tags: payload.tags.sort().join(',')
    })
      .then(rec => {
        if (!isArray(rec)) {
          rec = [ rec ]
        }
        return Promise.resolve(StoreInstances._propagateChanges(STORE_TYPE, rec))
      })
      .catch(Promise.reject)
  }
}
