<template>
  <div class="interaction-step">
    <div class="interaction-header">
      <div class="user-row flex flex-row">
        <div class="users-name">
          <a :href="`/users/${vm.currentUser.id}`" target="_blank">{{ vm.currentUser.firstLastName }}</a>
        </div>

        <div class="pacing-wrapper pl-4" v-if="showCoachPacingRisk">
          <CoachPacingRisk :pacingRisk="groupMembership.engagement_metadata.completed_pace_risk" :bufferDays="groupMembership.engagement_metadata.completed_phase_buffer_days"></CoachPacingRisk>
        </div>
      </div>

      <div class="text-left pt-1 pb-1" v-if="showActionsButton">
        <ActionModalDropdown
          :user="groupMembership.user_id"
          :groupMembershipId="groupMembership.id"
          :groupId="groupMembership.group_id"
        />
      </div>

      <div class="step-name">
        <i
          :class="{fa: true, [vm.currentStep.faIcon]: true}"
          style="padding-right: 5px"
          v-if="vm.currentStep.faIcon"
        />
        <span>{{ vm.currentStep.displayName }}</span>&nbsp;&nbsp;
        <span
          style="vertical-align: top;"
          class="badge badge-pill badge-secondary" v-if="stepReviewStatusText"
        >{{ stepReviewStatusText }}</span>
      </div>
    </div>

    <div class="interaction-progress" v-if="isStep && showVideoProgress">
      <i class="fa fa-video-camera interaction-progress-icon"></i>
      <strong>{{ $t('application.common.video_progress') }}:</strong>

      <span v-if="hasPlayedVideo">
         {{ videoProgressInWords }}
      </span>

      <span v-else>
        <em>{{ $t('application.common.not_started') }}</em>
      </span>
    </div>
  </div>
</template>

<script>
import ActionModalDropdown from "@components/ActionModalDropdown.vue"
import CoachPacingRisk from '@components/CoachPacingRisk.vue'
import { computed, makeObservable } from 'mobx'
import { observer } from 'mobx-vue'

class ViewModel {
  studentStepProgress = null

  constructor(params) {
    makeObservable(this, {
      currentStep: computed,
      currentUser: computed,
    })

    this.studentStepProgress = params.studentStepProgress
  }

  get currentStep () {
    return this.studentStepProgress.courseStep
  }

  get currentUser () {
    return this.studentStepProgress.user
  }
}

export default observer({
  name: "interaction-step",
  components: {
    ActionModalDropdown,
    CoachPacingRisk,
  },
  data () {
    return {
      vm: new ViewModel({
        studentStepProgress: this.studentStepProgress,
      }),
    }
  },
  computed: {
    stepReviewStatusText() {
      if (this.studentStepProgress.courseStep.isEvent) {
        if (this.studentStepProgress.isReviewApproved) {
          return this.$t('application.common.attended')
        } else if (this.studentStepProgress.isUnderReview) {
          return this.$t('application.common.under_review')
        } else if (this.studentStepProgress.isQuestionsRemaining) {
          return this.$t('application.common.questions_remaining')
        } else {
          return null
        }
      } else if (
        this.studentStepProgress.courseStep.isBenchmark &&
          this.studentStepProgress.latestCourseStepReview
      ) {
        if (this.studentStepProgress.isReviewApproved) {
          return this.$t('application.common.achieved')
        } else if (this.studentStepProgress.isUnderReview) {
          return this.$t('application.common.under_review')
        } else {
          return this.$t('application.common.did_not_achieve')
        }
      } else if (
        this.studentStepProgress.courseStep.isStep &&
          this.studentStepProgress.latestCourseStepReview
      ) {
        if (this.studentStepProgress.isReviewApproved) {
          return this.$t('application.common.completed')
        } else if (this.studentStepProgress.isUnderReview) {
          return this.$t('application.common.under_review')
        } else if (this.studentStepProgress.isQuestionsRemaining) {
          return this.$t('application.common.questions_remaining')
        } else {
          return null
        }
      }
    },
    isStep () {
      return this.studentStepProgress.courseStep.isStep
    },
    hasPlayedVideo() {
      let playedVideo = this.courseStepProgress && this.courseStepProgress.has_played_video
      return playedVideo
    },
    videoProgressInWords() {
      let progressInWords = ''

      if (this.courseStepProgress) {
        progressInWords = this.courseStepProgress.video_progress_in_words
      }

      return progressInWords
    }
  },
  props: {
    studentStepProgress: {
      type: Object,
      required: true
    },
    courseStepProgress: {
      type: Object,
      default: () => { return null }
    },
    groupMembership: {
      type: Object,
      default: () => { return null }
    },
    showCoachPacingRisk: {
      type: Boolean,
      default: () => { return true }
    },
    showActionsButton: {
      type: Boolean,
      default: () => { return true }
    },
    showVideoProgress: {
      type: Boolean,
      default: () => { return false }
    },
  }
})
</script>

<style lang="scss">
  .v-slide-group__prev.v-slide-group__prev--disabled {
    flex-basis: initial;
    min-width: 0px;
  }

  .interaction-timeline .v-tabs {
    flex-grow: 0;
  }
</style>

<style lang="scss" scoped>
.interaction-step {
  flex: 0 0 85px;

  .users-name {
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: #333;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", Arial, sans-serif;
    margin-bottom: 4px;
    text-align: left;
  }

  .step-name {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #767676;
    text-align: left;
    min-height: 48px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", Arial, sans-serif;
  }
}

.interaction-header {
  padding: 0px 16px 8px 16px;
  border-bottom: 1px solid #e8e8e8;
  background-color: #fff;
  width: inherit;

  @media (max-width: 767px) {
    padding-left: 0px;
    border-bottom: none;
  }
}

.interaction-progress {
  padding: 0.5rem 0;
}

.interaction-progress-icon {
  margin-right: .5rem;
}
</style>
