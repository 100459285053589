import { isArray } from 'lodash-es'

import StoreInstances, { getStoreFor } from '@stores/StoreInstances'
import BaseArrayClass from '@stores/BaseArrayStore'
import { action, makeObservable } from 'mobx'
import DataService from '@dataServices/Conversations'

const STORE_TYPE = 'Conversations'

export function getConversationsStoreFor (param) {
  return getStoreFor(ConversationsStore, STORE_TYPE, param)
}

export class ConversationsStore extends BaseArrayClass {
  constructor () {
    super(STORE_TYPE)

    makeObservable(this, {
      _fetchOne: action,
      _fetchAll: action,
      create: action,
      reply: action,
      close: action,
      reopen: action,
    })
  }

  _fetchOne() {
    return DataService.getById({ id: this._filter.id })
  }

  _fetchAll() {
    let payload = {
      ...this._filter,
    }
    return DataService.getAll(payload)
  }

  create({ groupId, courseStepId, message, recipients, userId }) {
    return DataService.create(recipients, groupId, courseStepId, userId, message)
      .then(rec => {
        if (!isArray(rec)) {
          rec = [ rec ]
        }
        return Promise.resolve(StoreInstances._propagateChanges(STORE_TYPE, rec))
      })
      .catch(Promise.reject)
  }

  reply(params) {
    return DataService.replyToConversation(params)
      .then(rec => {
        if (!isArray(rec)) {
          rec = [ rec ]
        }
        return Promise.resolve(StoreInstances._propagateChanges(STORE_TYPE, rec))
      })
      .catch(Promise.reject)
  }

  close(id) {
    return DataService.close(id)
      .then(rec => Promise.resolve(StoreInstances._propagateChanges(STORE_TYPE, [rec])))
      .catch(Promise.reject)
  }

  reopen(id) {
    return DataService.reopen(id)
      .then(rec => Promise.resolve(StoreInstances._propagateChanges(STORE_TYPE, [rec])))
      .catch(Promise.reject)
  }
}
