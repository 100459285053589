import { map } from 'lodash-es'
import axios from './axios'
import Task from '@models/Task'

const baseUrlString = '/tasks'

async function complete(data) {
  return axios.post(`${baseUrlString}/${data.id}/complete`, {})
    .then(response => initWithData(response.data.data, response.data.included))
    .catch(error => Promise.reject(error.response.data))
}

async function markInProgress(data) {
  return axios.post(`${baseUrlString}/${data.id}/mark_in_progress`, {})
    .then(response => initWithData(response.data.data, response.data.included))
    .catch(error => Promise.reject(error.response.data))
}

async function sendMessage(data) {
  return axios.post(`${baseUrlString}/${data.id}/send_message`, data)
    .then(response => initWithData(response.data.data, response.data.included))
    .catch(error => Promise.reject(error.response.data))
}

async function create(data) {
  return axios.post(`${baseUrlString}`, data)
    .then(response => initWithData(response.data.data, response.data.included))
    .catch(error => Promise.reject(error.response.data))
}

async function getById (id) {
  return axios.get(`${baseUrlString}/${id}`)
    .then(response => initWithData(response.data.data, response.data.included))
    .catch(console.error)
}

async function getAll(params) {
  return axios.get(baseUrlString, { params })
    .then(response => ({
      records: map(response.data.data,  r => initWithData(r, response.data.included )),
      pagination: response.data.meta,
    }))
    .catch(console.error)
}

function initWithData(data, included) {
  return new Task(data, included)
}

export default {
  getById,
  getAll,
  complete,
  markInProgress,
  sendMessage,
  create,
  initWithData,
}
