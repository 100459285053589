<template>
  <v-avatar
    tile
    size="22"
    :style="iconStyle"
    class="mr-2"
  >
    <div class="text-xs font-bold text-white">
      {{ iconText }}
    </div>
  </v-avatar>
</template>

<script>
import { VAvatar } from 'vuetify/lib'

export default {
  name: 'task-type-icon',
  components: {
    VAvatar,
  },
  props: {
    taskState: { type: String, required: true,},
  },
  computed: {
    iconStyle() {
      switch (this.taskState) {
        case 'open': return { 'background-color': '#a855f7', }
        case 'completed': return { 'background-color': '#06b6d4', }
        case 'inactive': return { 'background-color': '#ec4899', }
        case 'in_progress': return { 'background-color': '#84cc16', }
        default: return { 'background-color': '#333' }
      }
    },
    iconText() {
      switch (this.taskState) {
        case 'open': return 'OP'
        case 'completed': return 'CP'
        case 'inactive': return 'IA'
        case 'in_progress': return 'IP'
        default: return ''
      }
    }
  },

}
</script>
