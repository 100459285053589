<template>
  <modal
    :name="modalName"
    class="meeting-upsert-modal"
    :scrollable="true"
    height="auto"
    @closed="destroyModal">

    <div>
      <div class="modal-header-container">
        <v-btn icon @click="destroyModal">
          <VIcon>mdi-close</VIcon>
        </v-btn>
      </div>

      <v-card v-if="meetingUpdateData">
        <v-card-title>
          <span>Meeting</span>
        </v-card-title>
        <v-card-text>
          <div>
            <v-alert v-model="alertVisible" type="error" dismissible>
              {{ errorMessage }}
            </v-alert>
          </div>

          <VForm ref="updateForm" v-model="validForm">
            <v-text-field
                v-model="meetingUpdateData.name"
                label="Name"
                :rules="[validationRules.required]"
                required
            ></v-text-field>

            <v-select
                v-model="meetingUpdateData.meetingType"
                :items="meetingTypeOptions"
                item-value="value"
                item-text="label"
                label="Type"
                :rules="[validationRules.required]"
                required
            ></v-select>

            <VRow>
              <VCol cols="auto">
                <v-checkbox
                    v-model="meetingUpdateData.isRecurring"
                    label="Recurring"
                    :disabled="isUpdateMode"
                />
              </VCol>
            </VRow>

            <div v-if="meetingUpdateData.isRecurring">
              <v-select
                  v-model="meetingUpdateData.recurrency.type"
                  :items="recurringTypeOptions"
                  item-value="value"
                  item-text="label"
                  label="Recurrance"
                  :rules="[validationRules.required]"
                  required
              />

              <div style="display: flex">
                <v-menu
                    v-model="firstOccurrenceMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="meetingUpdateData.recurrency.firstOccurrenceDate"
                        label="First Occurrence"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="meetingUpdateData.recurrency.firstOccurrenceDate"
                      :allowed-dates="recurrencyMeetingsAllowedStartDates"
                      @input="firstOccurrenceMenu = false"
                      :rules="[validationRules.required]"
                      required
                  ></v-date-picker>

                </v-menu>

                <v-select
                    v-model="meetingUpdateData.recurrency.firstOccurrenceTime"
                    :items="startTimeOptions"
                    item-value="value"
                    item-text="label"
                    :rules="[validationRules.required]"
                    required
                ></v-select>

                <!--              TODO: for now, duration is always disabled - need to update zoom api calls to include duration-->
                <v-select
                    v-model="meetingUpdateData.recurrency.duration"
                    :items="durationOptions"
                    item-value="value"
                    item-text="label"
                    :disabled="true"
                    :rules="[validationRules.required]"
                    required
                ></v-select>
              </div>

              <v-select v-if="meetingUpdateData.recurrency.type === 'weekly'"
                        v-model="meetingUpdateData.recurrency.weekDays"
                        :items="recurringWeekDayOptions"
                        item-value="value"
                        item-text="label"
                        label="Week Days"
                        multiple
                        :item-disabled="isWeekdayDisabled"
              />

              <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="meetingUpdateData.recurrency.endDate"
                      label="End Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="meetingUpdateData.recurrency.endDate"
                    :allowed-dates="recurrencyMeetingsAllowedEndDates"
                    @input="endDateMenu = false"
                    :rules="[validationRules.required]"
                    required
                ></v-date-picker>

              </v-menu>
            </div>

            <div v-else>
              <div style="display: flex">
                <v-menu
                    v-model="startTimeMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="meetingUpdateData.startDate"
                        label="Start Time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="meetingUpdateData.startDate"
                      :allowed-dates="specificMeetingAllowedDates"
                      @input="startTimeMenu = false"
                      :rules="[validationRules.required]"
                      required
                  ></v-date-picker>

                </v-menu>

                <v-select
                    v-model="meetingUpdateData.startTime"
                    :items="startTimeOptions"
                    item-value="value"
                    item-text="label"
                    :rules="[validationRules.required]"
                    required
                ></v-select>

                <!--              TODO: for now, duration is always disabled - need to update zoom api calls to include duration-->
                <v-select
                    v-model="meetingUpdateData.duration"
                    :items="durationOptions"
                    item-value="value"
                    item-text="label"
                    :disabled="true"
                    :rules="[validationRules.required]"
                    required
                ></v-select>
              </div>
            </div>

            <v-text-field
                label="Add Breakout Room"
                v-model="meetingUpdateData.breakoutRooms"
                :disabled="isUpdateMode"
            ></v-text-field>

            <v-select
                v-model="meetingUpdateData.broadcastType"
                :items="broadcastTypeOptions"
                item-value="value"
                item-text="label"
                label="Broadcast Type"
                :rules="[validationRules.required]"
                :disabled="true"
                required
            ></v-select>

            <div v-if="showDelegationsSection">
              <v-alert v-if="delegationError" type="error">
                {{ delegationError }}
              </v-alert>

              <div v-for="(delegation, index) in meetingUpdateData.delegations" :key="delegation.program_id">

                <div style="display: flex; justify-content: end">
                  <v-btn icon @click="removeDelegation(index)"><VIcon>mdi-close</VIcon></v-btn>
                </div>

                <VCard>
                  <VCardText>
                    <v-select
                        v-model="meetingUpdateData.delegations[index].program_id"
                        :items="availableDelegationOptions(index)"
                        item-value="program_id"
                        item-text="program_name"
                        label="Program"
                        :rules="[validationRules.required]"
                    ></v-select>

                    <v-select
                        v-model="meetingUpdateData.delegations[index].module_ids"
                        :items="getModuleOptions(delegation.program_id)"
                        item-value="module_id"
                        item-text="module_name"
                        label="Modules"
                        multiple
                    ></v-select>
                  </VCardText>
                </VCard>
              </div>

              <v-btn @click="addDelegation">Add Delegation</v-btn>
            </div>

          </VForm>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="validateAndUpdate">Save</v-btn>
          <v-btn @click="destroyModal">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </modal>
</template>

<script>
  import ModalMixin from '@mixins/ModalMixin.vue'
  import FlashMessages from '@services/FlashMessageService'
  import axios from "@dataServices/axios";
  import { VRow, VCol, VCheckbox, VAlert, VCard, VCardTitle,VCardText,VCardActions,VBtn,VIcon,VTextField,VSelect,VMenu,VDatePicker,VForm,VSwitch} from 'vuetify/lib';

  import dayjs from "dayjs";
  import EventBus from "@services/GlobalEventBus";

  const NAME = "meeting-upsert-modal"
  const baseUrl = window.location.origin

  export default {
    name: NAME,
    mixins: [ ModalMixin ],
    components: {
      VRow,
      VCol,
      VCheckbox,
      VAlert,
      VCard,
      VCardTitle,
      VCardText,
      VCardActions,
      VBtn,
      VIcon,
      VTextField,
      VSelect,
      VMenu,
      VDatePicker,
      VForm,
      VSwitch,
    },
    data() {
      return {
        modalName: NAME,
        recurrentMeeting: this.params.recurrentMeeting,
        meeting: this.params.meeting,
        delegations: this.params.delegations,
        meetingUpdateData: null,
        meetingTypeOptions: [],
        broadcastTypeOptions: [],
        delegationOptions: [],
        startTimeOptions: [],
        durationOptions: [],
        recurringTypeOptions: [],
        recurringWeekDayOptions: [],
        startTimeMenu: false,
        firstOccurrenceMenu: false,
        endDateMenu: false,
        loading: true,
        dateFormat: 'YYYY-MM-DD',
        weekdayFormat: 'dddd',
        timeFormat: 'h:mma',
        validForm: false,
        validationRules: {
          required: (value) => !!value || 'Required.',
        },
        alertVisible: false,
        errorMessage: '',
        delegationError: '',
      }
    },
    async mounted () {
      this.loading = true;
      this.startTimeOptions = this.generateStartTimeOptions()
      this.durationOptions = this.generateDurationOptions(this.startTimeOptions[0].value)
      await this.fetchInitData()
      this.resetMeetingUpdateData()
      this.loading = false;
    },
    computed: {
      isUpdateMode(){
        return this.meeting ? true : false;
      },
      isCreateMode() {
        return !this.isUpdateMode;
      },
      showDelegationsSection() {
        const isUpdateWithDelegations = this.isUpdateMode && this.delegations && this.delegations.length > 0;
        const isCreateWithDelegationBroadcastType = this.isCreateMode && this.meetingUpdateData.broadcastType === 'delegated';
        return isCreateWithDelegationBroadcastType || isUpdateWithDelegations;
      },
      availableDelegationOptions() {
        return (currentIndex) => {
          // Get the list of currently selected program_ids except the current one being edited
          const selectedProgramIds = this.meetingUpdateData.delegations
              .filter((_, index) => index !== currentIndex)
              .map(delegation => delegation.program_id);

          // Filter the available delegation options to exclude already selected programs
          return this.delegationOptions.filter(
              option => !selectedProgramIds.includes(option.program_id)
          );
        };
      },
    },
    methods: {
      specificMeetingAllowedDates(date) {
        const today = dayjs().format(this.dateFormat);
        const selectedDate = dayjs(date).format(this.dateFormat);
        return dayjs(selectedDate).isSame(today) || dayjs(selectedDate).isAfter(today);
      },
      recurrencyMeetingsAllowedStartDates(date) {
        const nextWeek = dayjs().add(8, 'day').format(this.dateFormat);
        const selectedDate = dayjs(date).format(this.dateFormat);
        return dayjs(selectedDate).isSame(nextWeek) || dayjs(selectedDate).isAfter(nextWeek);
      },
      recurrencyMeetingsAllowedEndDates(date) {
        const firstOccurrence = dayjs(this.meetingUpdateData.recurrency.firstOccurrenceDate)
        const oneWeekAfterFirstOccurrence = firstOccurrence.add(1, 'week').format(this.dateFormat);
        const selectedDate = dayjs(date).format(this.dateFormat);
        return dayjs(selectedDate).isSame(oneWeekAfterFirstOccurrence) || dayjs(selectedDate).isAfter(oneWeekAfterFirstOccurrence);
      },
      resetMeetingUpdateData(){
        this.meetingUpdateData = this.meeting ? this.toMeetingUpdateData(this.recurrentMeeting, this.meeting, this.delegations) : this.getDefaultMeetingUpdateData();
      },
      getDefaultMeetingUpdateData(){
        return {
            id: null,
            name: null,
            startDate: dayjs().format(this.dateFormat),
            startTime: this.startTimeOptions[0].value,
            duration: this.durationOptions[0].value,
            meetingType: null,
            broadcastType: 'delegated',
            breakoutRooms: null,
            delegations: [],
            isRecurring: false,
            recurrency: this.getDefaultRecurrencyMeetingUpdateData()
        }
      },
      getDefaultRecurrencyMeetingUpdateData() {
        return {
          type: this.recurringTypeOptions[0].value,
          firstOccurrenceDate: dayjs().add(8, 'day').format(this.dateFormat),
          firstOccurrenceTime: this.startTimeOptions[0].value,
          endDate: dayjs().add(2, 'week').format(this.dateFormat),
          duration: this.durationOptions[0].value,
          weekDays: []
        }
      },
      toMeetingUpdateData(recurrentMeeting, meeting, delegations){
        const startTime = dayjs(meeting.start_time).format(this.timeFormat);
        const durationInMinutes = meeting.length_hours * 60;
        const matchedStartTimeOption = this.startTimeOptions.find(option => option.label === startTime);
        const matchedDurationOption = this.durationOptions.find(option => option.durationInTime.diff(dayjs().startOf('day'), 'minute') === durationInMinutes);

        return {
          id: meeting.id,
          name: meeting.name,
          startDate: dayjs(meeting.start_time).format(this.dateFormat),
          startTime: matchedStartTimeOption ? matchedStartTimeOption.value : this.startTimeOptions[0].value,  // Default to first option if no match
          duration: matchedDurationOption ? matchedDurationOption.value : this.durationOptions[0].value,  // Default to first option if no match
          meetingType: meeting.sub_type,
          broadcastType: meeting.broadcast_type,
          breakoutRooms: meeting.settings.breakout_rooms?.join(', '),
          delegations: this.toFrontEndDelegations(delegations),
          isRecurring: recurrentMeeting != null,
          recurrency: null
        }
      },
      async fetchInitData(){
        try {
          const response = await axios.get(baseUrl + `/api/v2/bff/meeting_management/meeting_upsert/init_data`);
          this.meetingTypeOptions = response.data.meeting_type_options;
          this.broadcastTypeOptions = response.data.broadcast_type_options;
          this.delegationOptions = response.data.delegation_options;
          this.recurringTypeOptions = response.data.recurring_type_options;
          this.recurringWeekDayOptions = response.data.recurring_week_day_options;
        } catch (error) {
          console.error("Failed to fetch meeting data:", error);
          FlashMessages.error("Failed to fetch meeting data! " + error); //it's ok to show the real error because the coaches are the users
        }
      },
      async upsertMeeting () {
        const request = this.getUpsertMeetingRequest()
        return axios
              .post(baseUrl + `/api/v2/bff/meeting_management/meeting_upsert`, request)
              .then(response => this.processUpdateResponse(response))
              .catch(error => this.processResponseError(error))
      },
      validateAndUpdate() {
        this.$refs.updateForm.validate();
        const validDelegations = this.validateDelegations();
        if (this.validForm && validDelegations) {
          this.upsertMeeting();
        }
      },
      validateDelegations() {
        this.delegationError = '';

        if (this.meetingUpdateData.broadcastType === 'delegated') {
          if (this.meetingUpdateData.delegations.length === 0) {
            this.delegationError = 'At least one delegation is required for delegated meetings.';
            return false;
          }
        }

        return true;
      },
      processUpdateResponse (response) {
        EventBus.dispatch("meeting-upserted", { ids: response.data.upserted_meetings.map(meeting => meeting.id) })
        this.destroyModal();
      },
      processResponseError (error) {
        this.errorMessage = error.response? error.response.data.message : error.message;
        this.alertVisible = true;
        console.error("Failed to upserting meeting:", error);
      },
      getUpsertMeetingRequest(){
        const params = {
          start_time: this.toBackendStartTime(this.meetingUpdateData.startDate, this.meetingUpdateData.startTime),
          duration_in_hours: this.meetingUpdateData.duration,
          type: this.meetingUpdateData.meetingType,
          name: this.meetingUpdateData.name,
          broadcast_type: this.isCreateMode ? this.meetingUpdateData.broadcastType : null,
          breakout_room_names: this.isCreateMode ? this.meetingUpdateData.breakoutRooms?.split(",")?.map(room => room.trim())?.filter(room => room.length > 0) : null,
          meeting_id: this.meetingUpdateData.id,
          delegations: this.meetingUpdateData.broadcastType === 'delegated' ? this.meetingUpdateData.delegations.filter(delegation => delegation.program_id != null) : null,
          recurrency: this.meetingUpdateData.isRecurring ? this.getUpsertRecurrencyRequest() : null
        }

        const filteredParams = Object.fromEntries(
            Object.entries(params).filter(([key, value]) => value != null)
        )

        return { meeting_upsert: filteredParams }
      },
      getUpsertRecurrencyRequest(){
        const params = {
          type: this.meetingUpdateData.recurrency.type,
          first_occurrence: this.toBackendStartTime(this.meetingUpdateData.recurrency.firstOccurrenceDate, this.meetingUpdateData.recurrency.firstOccurrenceTime),
          end_date: this.meetingUpdateData.recurrency.endDate,
          duration: this.meetingUpdateData.recurrency.duration,
          week_days: this.meetingUpdateData.recurrency.type === 'weekly' ? this.meetingUpdateData.recurrency.weekDays : null
        }

        return Object.fromEntries(
            Object.entries(params).filter(([key, value]) => value != null)
        )
      },
      toFrontEndDelegations(delegations){
        const groupedDelegations = {};

        delegations.forEach(delegation => {
          const { program_id, program_name, module_id, module_name } = delegation;

          // If the program_id is not yet a key in the groupedDelegations object, initialize it
          if (!groupedDelegations[program_id]) {
            groupedDelegations[program_id] = {
              program_id: program_id,
              program_name: program_name,
              module_ids: [],
              module_names: []
            };
          }

          // Add the module details if not already added
          if (!groupedDelegations[program_id].module_ids.includes(module_id)) {
            groupedDelegations[program_id].module_ids.push(module_id);
            groupedDelegations[program_id].module_names.push(module_name);
          }
        });

        // Convert the groupedDelegations object into an array of delegations
        return Object.values(groupedDelegations);
      },
      toBackendStartTime(startDateIsoString, startTime){
        return dayjs(startDateIsoString)
              .hour(startTime.hour())
              .minute(startTime.minute())
              .toISOString()
              ;
      },
      generateStartTimeOptions(){
        const timeOptions = [];
        const startOfDay = dayjs().startOf('day');

        for (let i = 0; i < 24 * 4; i++) {  // 24 hours * 4 (15-minute intervals per hour)
          const time = startOfDay.add(i * 15, 'minute')
          const label = time.format(this.timeFormat);
          const value = time.format('HH:mm');

          timeOptions.push({ value: time, label: label });
        }

        return timeOptions;
      },
      generateDurationOptions(durationStart) {
        const durationOptions = [];

        const maxDurationInMinutes = 24 * 60;  // 24 hours in minutes
        const intervalInMinutes = 15;

        // Start from 15 minutes after the selected start time
        for (let i = 1; i <= maxDurationInMinutes / intervalInMinutes; i++) {
          const minutes = i * intervalInMinutes;
          let durationLabel;

          if (minutes < 60) {
            durationLabel = `${minutes} min`;
          } else {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            durationLabel = `${hours} hour${hours > 1 ? 's' : ''}` + (remainingMinutes > 0 ? ` ${remainingMinutes} min` : '');
          }

          const durationInTime = durationStart.add(minutes, 'minute');
          const durationInHours = minutes / 60;

          const label = `${durationInTime.format(this.timeFormat)} (${durationLabel})`;

          durationOptions.push({
            value: durationInHours,
            label: label,
            durationInTime: durationInTime
          });
        }

        return durationOptions;
      },
      addDelegation() {
        this.meetingUpdateData.delegations.push({ program_id: null, module_ids: [] });
      },
      removeDelegation(index) {
        this.meetingUpdateData.delegations.splice(index, 1);
      },
      getModuleOptions(programId) {
        const delegation = this.delegationOptions.find(option => option.program_id === programId);
        return delegation ? delegation.module_options : [];
      },
      isWeekdayDisabled(weekdayOption) {
        return dayjs(this.meetingUpdateData.recurrency.firstOccurrenceDate).format(this.weekdayFormat) === weekdayOption.value;
      },
    },
    watch: {
      'meetingUpdateData.startTime'(newVal, oldVal){
        if (newVal !== oldVal && this.loading === false) {
          this.durationOptions = this.generateDurationOptions(newVal);
          this.meetingUpdateData.duration = this.durationOptions[3].value;
        }
      },
      'meetingUpdateData.recurrency.firstOccurrenceTime'(newVal, oldVal){
        if (newVal !== oldVal && this.loading === false) {
          this.durationOptions = this.generateDurationOptions(newVal);
          this.meetingUpdateData.recurrency.duration = this.durationOptions[3].value;
        }
      },
      'meetingUpdateData.recurrency.firstOccurrenceDate'(newVal, oldVal){
        if (newVal !== oldVal && this.loading === false) {
          if (!this.meetingUpdateData.recurrency.weekDays.includes(dayjs(newVal).format(this.weekdayFormat))) {
            this.meetingUpdateData.recurrency.weekDays.push(dayjs(newVal).format(this.weekdayFormat));
          }

          const endDate = dayjs(this.meetingUpdateData.recurrency.endDate);
          const oneWeekAfterFirstOccurrence = dayjs(newVal).add(1, 'week').format(this.dateFormat);
          if (endDate.isBefore(oneWeekAfterFirstOccurrence)) {
            this.meetingUpdateData.recurrency.endDate = oneWeekAfterFirstOccurrence;
          }
        }
      },
      'meetingUpdateData.isRecurring'(newVal, oldVal){
        if (this.loading === false) {
          if (newVal === true && oldVal === false) {
            this.durationOptions = this.generateDurationOptions(this.meetingUpdateData.recurrency.firstOccurrenceTime);
            this.meetingUpdateData.recurrency.duration = this.durationOptions[3].value;
          } else if (newVal === false && oldVal === true) {
            this.durationOptions = this.generateDurationOptions(this.meetingUpdateData.startTime);
            this.meetingUpdateData.duration = this.durationOptions[3].value;
          }
        }
      },
      'meetingUpdateData.recurrency.type'(newVal, oldVal){
        if (newVal !== oldVal && this.loading === false) {
          if (newVal === 'weekly') {
            this.meetingUpdateData.recurrency.weekDays = [dayjs(this.meetingUpdateData.recurrency.firstOccurrenceDate).format(this.weekdayFormat)];
          }
        }
      },
    },
  }
</script>

<style lang="scss">
.v-label { // for some reason, without this the v-select labels are not visible
  z-index: 1;
}

</style>
