<template>
  <modal
    :name="modalName"
    class="starter-tracking-modal"
    :scrollable="true"
    height="auto"
    width="90%"
    @closed="destroyModal">
    <div class="close" @click="destroyModal" style="position: absolute; right: 18px; top: 8px; z-index: 999; background-color: white; padding: 4px; color: #767676; font-size: 22px; opacity: .75; border-radius: 4px;">
      <i class="fa fa-times"></i>
    </div>
      <!-- <h2 class="title">Assign Mentor to {{ currentStudent.first_name }} {{ currentStudent.last_name }} </h2> -->
      <!-- <p>Select a mentor to assign to {{ currentStudent.first_name }} and add a description for them. </p> -->

    <StarterTracking :groupId="groupId" :userId="userId" />
  </modal>
</template>

<script>
  import StarterTracking from '@components/StarterTracking.vue'
  import ModalMixin from '@mixins/ModalMixin.vue'

  const NAME = 'starter-tracking-modal'

  export default {
    name: NAME,
    mixins: [ModalMixin],
    components: {
      StarterTracking,
    },
    data() {
      return {
        modalName: NAME,
        groupId: this.params.groupId,
        userId: this.params.userId,
      }
    },
    methods: {
    },
  }
</script>

<style lang="scss">
  .title {
    padding-top: 20px;
  }

  .starter-tracking-modal .v--modal {
    overflow: initial !important;
  }
</style>
