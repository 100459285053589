<template>
  <div>
    <h3 v-if="vm.courseStep" class="font-bold text-gray-900 text-lg text-center pb-2">{{ vm.courseStep.name }}</h3>
    <div class="interaction-timeline h-full">
      <template v-if="vm.ssp">
        <div class="h-full">
          <div class="interaction-events-container" ref="scrollable">
            <InteractionEvents :studentStepProgress="vm.studentStepProgress" />
          </div>
          <InteractionInput :relatedTask="relatedTask" @messageSent="onMessageSent($event)" :studentStepProgress="vm.studentStepProgress" @conversationCreated="onMessageSent" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { computed, makeObservable, observable, runInAction } from "mobx"
import { observer } from "mobx-vue"

import InteractionInput from "@components/interactionTimeline/InteractionInput.vue"
import InteractionEvents from "@components/interactionTimeline/InteractionEvents.vue"
import InteractionActions from "@components/interactionTimeline/InteractionActions.vue"
import TimelineServiceManager from "@services/timelineService/ServiceManager"
import { WindowVariables } from "@utils"
import { getConversationsStoreFor } from '@stores/ConversationsStore'

class ViewModel {
  timelineService = null
  userId = null
  courseStepId = null
  courseStepName = null
  groupId = null
  servicePreset = null
  groupMembership = null
  sspObs = null
  ssp = null
  conversationStore = null

  constructor(params) {
    makeObservable(this, {
      studentStepProgress: computed,
      courseStep: computed,
      assignmentResponses: computed,
      courseStepProgress: computed,
      groupMembership: observable,
      ssp: observable,
    })

    this.courseStepId = params.courseStepId
    this.groupId = params.groupId
    this.userId = params.userId
    this.servicePreset = params.servicePreset
    this.sspObs = observable.box(null)

    this.timelineService = TimelineServiceManager.getServiceFor('interaction-timeline', this.servicePreset)

    this.timelineService.registerDataReadyFn(() => {

      // TODO: what about making this a hash to access here instead of this workaround?
      const res = this.timelineService.getStepProgress(this.userId, this.courseStepId)
      if (res) {
        runInAction(() => {
          this.groupMembership = find(res.user.group_memberships, gm => gm.group_id === res.groupId)
          this.sspObs.set(res)
          this.ssp = res
          this.conversationStore = getConversationsStoreFor(this.studentStepProgress._conversationsStoreUuid)
        })
      }

      return !!res
    })
  }

  get studentStepProgress () {
    return this.sspObs.get()
  }

  get courseStep () {
    return this.studentStepProgress && this.studentStepProgress.courseStep
  }

  get assignmentResponses () {
    return this.studentStepProgress && this.studentStepProgress.assignmentResponses
  }

  get courseStepProgress() {
    return this.timelineService.courseStepProgress
  } 
}

export default observer({
  name: "tasks-interaction-timeline",
  components: {
    InteractionEvents,
    InteractionInput,
    InteractionActions,
  },
  data() {
    const serviceFilter = this.servicePreset || {
      groupId: this.groupId,
      userId: this.userId,
    }
    return {
      vm: new ViewModel({
        groupId: this.groupId,
        userId: this.userId,
        courseStepId: this.courseStepId,
        servicePreset: serviceFilter,
      }),
    }
  },
  methods: {
    onMessageSent() {
      this.vm.conversationStore._reload()
      this.$refs.scrollable.scrollTop = 0
    },
  },
  beforeDestroy () {
    TimelineServiceManager.deregister(this.vm.timelineService, 'interaction-timeline')
  },
  props: {
    groupId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    courseStepId: {
      type: Number,
      required: true,
    },
    servicePreset: {
      required: false,
    },
    relatedTask: {
      required: false,
    }
  },
})
</script>

<style lang="scss" scoped>
.interaction-timeline {
  display: flex;
  min-height: 0;
  flex-grow: 1;

  > div {
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
  }
}

.interaction-events-container {
  scroll-behavior: smooth; // this is experimental and not yet on... will be cool when it turns on!
  overflow-y: scroll;
  max-height: 450px;
  flex: 1 1 auto;
}


.review-primary {
  background-color: #f3763a;
  color: #fff;
  padding: 12px 24px;
  border-radius: 5px;
  border: 1px solid #f3763a;
  cursor: pointer;
  flex: 0 0 48px;
  margin-top: 8px;

  &:hover {
    background-color: #e6692e;
    color: #fff;
    border-radius: 8px;
  }

  transition: all 500ms ease;
}
</style>
