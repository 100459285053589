import axios from './axios'

const baseUrlString = '/course_step_progresses'

export async function getStepProgress (groupId, userId, courseStepId) {
  const url = `${baseUrlString}/${groupId}/${userId}/${courseStepId}`

  try {
    const response = await axios.get(url)
    return response.data
  } catch (err) {
    console.error(
      `[API getStepProgress] Error while loading: ${url}`,
      err
    );
  }
}
