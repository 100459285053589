import axios from './axios'
import GroupMembership from '@models/GroupMembership'

import { isArray } from 'lodash-es'

const basePath = '/group_memberships'

async function update(data) {
  return axios.put(`${basePath}/${data.id}`, data)
    .then(response => initWithData(response.data))
    .catch(resp => Promise.reject(resp))
}

async function getActionHistoryById(groupMembershipId) {
  return axios.get(`${basePath}/${groupMembershipId}/action_history`)
    .then(response => response.data)
    .catch(resp => Promise.reject(resp))
}

async function getBy(data) {
  // have to know groupMembershipId --OR-- userId AND groupId
  let req = null

  if (data.groupMembershipId) {
    req = axios.get(`${basePath}/${data.groupMembershipId}`)
  } else if (data.userId && data.groupId) {
    req = axios.get(`${basePath}`, { params: {
      group_id: data.groupId,
      user_id: data.userId,
    }})
  } else {
    throw('did not define proper params for GroupMembership#getBy')
  }

  return req
    .then(response => {
      if (isArray(response.data)) {
        return response.data.map(initWithData)
      } else {
        return [initWithData(response.data)]
      }
    })
    .catch(resp => Promise.reject(resp))
}

function initWithData(data) {
  return new GroupMembership(data)
}

export default {
  getBy,
  update,
  getActionHistoryById,
}
