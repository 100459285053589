<template>
  <modal
      :name="modalName"
      class="direct-messaging-group-chat-info-container"
      :scrollable="true"
      height="auto"
      @closed="close">
    <div class="close" @click="close">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 18L18 6M6 6L18 18" stroke="#111826" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>  
    </div>

    <div class="modal-content">
      <h2 class="modal-title">Conversation members</h2>
      <div class="conversation-members">
        <v-list>
          <v-list-item
              v-for="(member, index) in allMembers"
              :key="index"
              :value="member"
          >
            <v-list-item-title v-text="member.username"></v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </modal>
</template>
<script>
  import ModalMixin from '@mixins/ModalMixin.vue'
  import EventBus from '@services/GlobalEventBus'
  import DirectMessagingAvatar from "@components/directMessaging/DirectMessagingAvatar.vue";
  import {VList, VListItem, VListItemContent, VListItemGroup, VListItemTitle} from 'vuetify/lib'

  const NAME = 'direct-messaging-group-chat-info-modal'

  export default {
    name: NAME,
    mixins: [ModalMixin],
    components: {
      DirectMessagingAvatar,
      VList,
      VListItem,
      VListItemGroup,
      VListItemContent,
      VListItemTitle,
    },
    data() {
      return {
        modalName: NAME,
        conversation: this.params.conversation,
        allMembers: [],
      }
    },
    mounted() {
      this.allMembers = this.conversation.allMembers;
      this.allMembers.sort((a, b) => a.username.localeCompare(b.username))
    },
    methods: {
      close() {
        const eventPayload = { type: 'closed', updated: this.updated}
        EventBus.dispatch(NAME, eventPayload)
        this.destroyModal()
      },

    },
    computed: {

    },
    created () {

    },
  }


</script>

<style lang="scss">
.direct-messaging-group-chat-info-container {
  .close {
    position: absolute;
    right: 24px;
    top: 28px;
    z-index: 999;
    color: #111826;
    cursor: pointer;
  }

  .modal-content {
    max-height: 90vh;
    padding: 24px;
    border-radius: 8px;
  }

  .modal-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: #030712;
    margin-bottom: 8px;
  }

  .conversation-members div div div {
    overflow-y: auto;
    color: #030712;
    font-size: 16px;
    border-bottom: 1px solid #E5E7EB;
    padding: 12px 0px;
  }

  .conversation-members div div{
    min-height: 0px;
    padding: 0px;
  }

}

</style>
