import NewModel from '@models/NewModel'
import GroupMembership from '@models/GroupMembership'

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return phoneNumberString
}

class NewUser extends NewModel {
  constructor (initialData, included) {
    if (initialData.group_memberships) {
      initialData.group_memberships = initialData.group_memberships.map(gm => (new GroupMembership(gm)))
    }

    super(initialData, included, {
      momentKeys: [
        'created_at',
        'updated_at',
      ],
    })
  }

  get firstLastName () {
    return `${this.first_name} ${this.last_name}`
  }

  get formattedPhoneNumber () {
    return formatPhoneNumber(this.phone_number)
  }

  $isInGroup (groupId) {
    return !!this.$membershipForGroupId(groupId)
  }

  $membershipForGroupId (groupId) {
    return (this.group_memberships || []).find(gm => gm.group_id === parseInt(groupId))
  }

  teacherForGroupId (groupId) {
    const gm = this.$membershipForGroupId(groupId)
    return gm && gm.isTeacher && gm.isActive
  }

  mentorForGroupId(groupId) {
    const gm = this.$membershipForGroupId(groupId)
    return gm && gm.isMentor && gm.isActive
  }

  studentForGroupId(groupId) {
    const gm = this.$membershipForGroupId(groupId)
    return gm && gm.isStudent && gm.isActive
  }
}

export default NewUser
