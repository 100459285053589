import axios from './axios'
import { map, orderBy } from 'lodash-es'

import Conversation from '@models/Conversation'

const BASE_URL = '/conversations'

async function getById (id) {
  return axios.get(`${BASE_URL}/${id}`)
    .then(response => initWithData(response.data.data, response.data.included))
    .catch(console.error)
}

async function getAll (opts) {
  return axios.get(BASE_URL, { params: opts })
    .then(resp => {
      return orderBy(map(resp.data.data, r => initWithData(r, resp.data.included)), ['last_name', 'first_name'])
    })
    .catch(console.error)
}

async function create (recipients, groupId, courseStepId, userId, message) {
  return axios.post(BASE_URL, {
    recipients: recipients,
    course_step_id: courseStepId,
    group_id: groupId,
    user_id: userId,
    conversation: {
      body: message,
      subject: 'Automatic'
    }
  })
    .then(response => initWithData(response.data.data, response.data.included))
    .catch(console.error)
}

async function close (conversationId) {
  return axios.post(`${BASE_URL}/${conversationId}/close`)
    .then(response => initWithData(response.data.data, response.data.included))
    .catch(console.error)
}

async function reopen (conversationId) {
  return axios.post(`${BASE_URL}/${conversationId}/reopen`)
    .then(response => initWithData(response.data.data, response.data.included))
    .catch(console.error)
}

async function replyToConversation({ conversationId, message, mailboxGroupId }) {
  let params = {
    ...mailboxGroupId && { mailbox_group_id: mailboxGroupId },
    conversation: { body: message }
  }
  return axios.post(`${BASE_URL}/${conversationId}/reply`, params)
    .then(response => initWithData(response.data.data, response.data.included))
    .catch(console.error)
}

function initWithData (data, included) {
  return new Conversation(data, included)
}

export default {
  getById,
  getAll,
  create,
  close,
  reopen,
  replyToConversation,
  initWithData,
}
