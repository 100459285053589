import { each, find, filter, isEqual, some } from 'lodash-es'
import { action, makeObservable } from 'mobx'

export function getStoreFor (LocalStore, type, param, opts = {}) {
  // If it's an object as a param, we want the `type` param ONLY in the lookup.
  // If we attach it directly to the param object, it'll be set in the filter below as well.
  let lookupParam = (param && typeof param === 'object') ? { type, filter: param } : param
  let store = globalStoreInstance.lookupStore(LocalStore, lookupParam)
  if (!store) {
    store = new LocalStore(opts)
    if (param && typeof param === 'object') {
      store.setFilter(param)
    }
  }
  store.reloadIfCacheInvalid()
  return store
}

class StoreInstances {
  stores = {}

  constructor() {
    makeObservable(this, {
      _propagateChanges: action,
    })
  }

  lookupStore (localStore, param) {
    let store = null
    if (typeof param === 'string') {
      store = this.stores[param]
    } else if (param && typeof param === 'object') {
      let typeStores = filter(this.stores, { _type: param.type })
      store = find(typeStores, st => isEqual(st._filter, param.filter))

      // If we don't find a store with the exact filter we searched for, check if the
      // filter has an `id` on it. If so, search for a store with that record and use that store.
      if (!store && param.filter.hasOwnProperty('id')) {
        // const containingStore = find(typeStores, st => some(st._records.peek(), rec => rec.id === param.filter.id))
        const containingStore = find(typeStores, st => some(st._records, rec => rec.id === param.filter.id))
        if (containingStore) {
          let record = containingStore.getRecord(param.filter.id)
          store = new localStore({
            data: record,
            filter: { id: param.filter.id },
          })
        }
      }
    }
    return store
  }

  findRecordByTypeAndId (type, id) {
    const typeStores = filter(this.stores, { _type: type })
    let matchingRecord = null
    each(typeStores, store => {
      matchingRecord = store.allRecords.find(rec => rec.id === id)
      if (matchingRecord) {
        return false
      }
    })
    return matchingRecord
  }

  registerStore (instance) {
    this.stores[instance.uuid] = instance
  }

  disposeStore (uuid) {
    this.stores[uuid].dispose()
    delete this.stores[uuid]
  }

  /**
   * Private Methods
   */
  _propagateChanges(storeType, changedRecords, action) {
    each(filter(this.stores, { _type: storeType }), store => {
      store._propagateChanges(changedRecords, action)
    })
    return changedRecords
  }
}

const globalStoreInstance = new StoreInstances()
export default globalStoreInstance
