<template>
  <div class="stepType">{{ stepType }}</div>
</template>

<script>
import { getCourseStepsStoreFor } from "@stores/CourseStepsStore"

import { observer } from "mobx-vue"

export default observer({
  name: "stepType",
  components: {},
  data() {
    return {
      courseStepsStore: getCourseStepsStoreFor({ id: this.courseStepId })
    };
  },
  computed: {
    courseStep() {
      return this.courseStepsStore.allRecords.find(courseStep => courseStep.id === this.courseStepId)
    },
    stepType() {
      if (this.courseStep.type_substring === 'step') {
        return 'S'
      }
      if (this.courseStep.type_substring === 'event') {
        return 'E'
      }
      if (this.courseStep.type_substring === 'benchmark') {
        return 'B'
      }
      return '?'
    }
  },
  methods: {},
  props: {
    courseStepId: {
      id: Number,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
.stepType {
  color: rgba(0,0,0,.5);
}
</style>
