<template>
  <modal
    :name="modalName"
    class="course-step-content-modal flex flex-col bg-color-black"
    :scrollable="true"
    :width="widthSetting"
    :height="heightSetting"
    @closed="destroyModal">
    <div class="close" @click="destroyModal" style="position: absolute; right: 18px; top: 8px; z-index: 999; background-color: white; padding: 4px; color: #767676; font-size: 22px; opacity: .75; border-radius: 4px;">
      <i class="fa fa-times"></i>
    </div>

    <template v-if="contents">
      <div ref="media-container" class="shrink overflow-y-hidden">
        <template v-if="contentType === 'm3u8'">
          <video ref="video-js-content-player"
            class="video-js vjs-default-skin vjs-16-9" style="margin: 0 auto;"
            controls :data-course-step-content-id="this.selectedContentId"
          >
            <p class="vjs-no-js">
              {{ $t('application.common.video_enable_js_prefix') }}
              <a href="http://videojs.com/html5-video-support/" target="_blank">{{ $t('application.common.video_enable_js_postfix') }}</a>
            </p>
          </video>
        </template>
      </div>

      <div class="px-3 pt-2 pb-1 bg-white">
        <div class="text-xl">{{ selectedContent.name }}</div>
        <div class="text-sm text-gray-500">{{ selectedContent.description }}</div>
      </div>

      <media-slider class="bg-white"
        :data="contents"
        :config="{ disableRoundedButtons: true }"
        @selected="selectContent"
      />
    </template>
  </modal>
</template>

<script>
import ModalMixin from '@mixins/ModalMixin.vue'
import MediaSlider from '@components/MediaSlider.vue'

const NAME = 'course-step-content-modal'

export default {
  name: NAME,
  mixins: [ModalMixin],
  components: {
    MediaSlider,
  },
  data () {
    return {
      widthSetting: window.innerWidth < 600 ? '100%' : '90%',
      heightSetting: window.innerHeight > window.innerWidth ? 'auto' : window.innerHeight * 0.9,
      modalName: NAME,
      contents: this.params.contents,
      selectedContentId: this.params.initialSelectionId,
      vjsPlayer: null,
      dimensions: {},
    }
  },
  computed: {
    selectedContent () {
      return this.contents.find(c => c.id === this.selectedContentId)
    },
    contentType () {
      return this.selectedContent.content_type
    },
    contentUrl () {
      return this.selectedContent.presigned_content_url
    },
    thumbnailUrl () {
      return this.selectedContent.presigned_thumbnail_url
    },
  },
  methods: {
    selectContent (content) {
      this.selectedContentId = content.id
      this.initVjsPlayer()
    },
    initVjsPlayer () {
      if (this.contentType === 'm3u8') {
        if (!this.vjsPlayer) {
          this.vjsPlayer = videojs(this.$refs['video-js-content-player'], {
            fluid: true,
            controls: true,
            preload: 'auto',
            playbackRates: [0.5, 1, 1.5, 2],
          })
        }
        this.vjsPlayer.poster(this.thumbnailUrl)
        this.vjsPlayer.src({ src: this.contentUrl, type: 'application/x-mpegURL' })
      }
    },
    destroyVjsPlayer () {
      if (this.vjsPlayer) {
        this.vjsPlayer.dispose()
        this.vjsPlayer = null
      }
    },
    calculateSizeOfMedia () {
      const el = this.$refs['media-container']
      const width = el.clientWidth
      const height = el.clientHeight
      const ratio = parseFloat(width) / parseFloat(height)

      if (!el) {
        return false
      }

      if (ratio === 16.0 / 9.0) {
        el.style.width = width + 'px'
        el.style.height = height + 'px'
      } else if (ratio < 16.0 / 9.0) {
        el.style.width = width + 'px'
        el.style.height = (9 * width) / 16 + 'px'
      } else {
        el.style.height = height + 'px'
        el.style.width = (16 * height) / 9 + 'px'
      }
      el.style.margin = 'auto'
    }
  },
  mounted () {
    const intv = setInterval(() => {
      if (this.$refs['video-js-content-player'] && this.$refs['media-container']) {
        this.initVjsPlayer()
        this.calculateSizeOfMedia()
        clearInterval(intv)
      }
    }, 50)
  },
  beforeDestroy () {
    this.destroyVjsPlayer()
  },
}
</script>

<style>
.course-step-content-modal .v--modal {
  display: flex;
  flex-direction: column;
  background-color: black;
  justify-content: flex-end;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder::before {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
