import { isArray } from 'lodash-es'
import { action, makeObservable } from 'mobx'

import BaseArrayClass from '@stores/BaseArrayStore'
import DataService from '@dataServices/Tasks'
import BulkDataService from '@dataServices/BulkActions'
import StoreInstances, { getStoreFor } from '@stores/StoreInstances'

const STORE_TYPE = 'Tasks'

export function getTasksStoreFor (param, opts = {}) {
  return getStoreFor(TasksStore, STORE_TYPE, param, opts)
}

export class TasksStore extends BaseArrayClass {
  constructor (opts) {
    super(STORE_TYPE, opts)

    makeObservable(this, {
      _fetchAll: action,
      _fetchOne: action,
      complete: action,
      markInProgress: action,
      create: action,
      sendMessage: action,
      bulkCreate: action,
    })
  }

  _fetchAll(payload) {
    return DataService.getAll(payload || { ...this._filter })
  }

  _fetchOne() {
    return DataService.getById(this._filter.id)
  }

  complete(payload) {
    return DataService.complete(payload)
      .then(rec => {
        if (!isArray(rec)) {
          rec = [ rec ]
        }
        return Promise.resolve(StoreInstances._propagateChanges(STORE_TYPE, rec))
      })
      .catch(Promise.reject)
  }

  markInProgress(payload) {
    return DataService.markInProgress(payload)
      .then(rec => {
        if (!isArray(rec)) {
          rec = [ rec ]
        }
        return Promise.resolve(StoreInstances._propagateChanges(STORE_TYPE, rec))
      })
      .catch(Promise.reject)
  }

  // TODO: 1003 CREATE `markInProgress` method like complete

  create(payload) {
    return DataService.create(payload)
      .then(rec => {
        if (!isArray(rec)) {
          rec = [rec];
        }
        return Promise.resolve(StoreInstances._propagateChanges(STORE_TYPE, rec));
      })
      .catch(Promise.reject);
  }

  sendMessage(payload) {
    return DataService.sendMessage(payload)
      .then(rec => {
        if (!isArray(rec)) {
          rec = [ rec ]
        }
        return Promise.resolve(StoreInstances._propagateChanges(STORE_TYPE, rec))
      })
      .catch(Promise.reject)
  }

  bulkCreate(payload) {
    return BulkDataService.manageEngagement(payload)
      .then(rec => {
        if (!isArray(rec)) {
          rec = [rec];
        }

        return Promise.resolve(StoreInstances._propagateChanges(STORE_TYPE, rec))
      })
      .catch(Promise.reject)
  }
}
