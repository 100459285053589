import { map } from 'lodash-es'
import axios from './axios'
import CourseStep from '@models/CourseStep'

const baseUrlString = '/course_steps'

async function getAll (params) {
  return axios.get(baseUrlString, { params })
    .then((response) => {
      return map(response.data, initWithData)
    })
    .catch(console.error)
}

async function getById (id) {
  return axios.get(`${baseUrlString}/${id}`)
    .then((response) => { return initWithData(response.data) })
    .catch(console.error)
}

function initWithData (data) {
  return new CourseStep(data)
}

export default {
  getAll,
  getById,
  initWithData
}
