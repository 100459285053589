<template>
  <div class="interaction-review">
    <InteractionReviewStep v-if="isStep" :studentStepProgress="studentStepProgress" @reviewSubmitted="onReviewSubmit" @cancel="onCancel" />
    <InteractionReviewEvent v-if="isEvent" :studentStepProgress="studentStepProgress" @reviewSubmitted="onReviewSubmit" @cancel="onCancel" />
    <InteractionReviewBenchmark v-if="isBenchmark" :studentStepProgress="studentStepProgress" @reviewSubmitted="onReviewSubmit" @cancel="onCancel" />
  </div>
</template>

<script>
  import InteractionReviewStep from '@components/interactionTimeline/InteractionReviewStep.vue'
  import InteractionReviewEvent from '@components/interactionTimeline/InteractionReviewEvent.vue'
  import InteractionReviewBenchmark from '@components/interactionTimeline/InteractionReviewBenchmark.vue'

  export default {
    name: 'interaction-review',
    components: {
      InteractionReviewStep,
      InteractionReviewEvent,
      InteractionReviewBenchmark,
    },
    computed: {
      isStep () {
        return this.studentStepProgress.courseStep.type == 'CourseStep::Step'
      },
      isBenchmark () {
        return this.studentStepProgress.courseStep.type == 'CourseStep::Benchmark'
      },
      isEvent () {
        return this.studentStepProgress.courseStep.type == 'CourseStep::Event'
      },
    },
    methods: {
      onReviewSubmit (evt) {
        this.$emit('reviewSubmitted', evt)
      },
      onCancel (evt) {
        this.$emit('cancel', evt)
      },
    },
    props: {
      studentStepProgress: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .interaction-review {
    flex: 0 0 100px;
    margin-top: 32px;
  }
</style>
