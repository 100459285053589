<template>
  <div class="interaction-input flex flex-row px-1" id="interaction-input">
    <template v-if="!vm.conversationIsClosed">
      <textarea
        class="textbox interaction-timeline-textarea w-full"
        ref="textarea"
        rows="3"

        v-model="message"
        :placeholder="vm.inputPlaceholder"
        :title="this.$t('application.interaction_timeline.input.send_a_message_placeholder')"
      />
      <div class="flex flex-col pl-1" style="flex-basis: 45px">
        <InteractionAttach class="attach-component" :studentStepProgress="studentStepProgress" />
        <button aria-describedby="send-message" class="send-message grow mt-1" :class="{ 'with-text': message.length > 0 && !sendingMessage }" @click="sendMessage">
          <i class="fa fa-paper-plane-o"></i>
        </button>
        <span id="send-message" class="sr-only">Send message</span>
      </div>
    </template>
    <template v-else>
      <div class="closed-conversation">
        <button class="btn btn-primary" @click="reopenConversation">{{ $t('application.interaction_timeline.input.reopen_conversation') }}</button>
      </div>
    </template>
  </div>
</template>

<script>
import autosize from "autosize"

import InteractionAttach from "@components/interactionTimeline/InteractionAttach.vue"
import FlashMessages from "@services/FlashMessageService"
import { getConversationsStoreFor } from "@stores/ConversationsStore"
import { getTasksStoreFor } from "@stores/TasksStore"
import { WindowVariables } from "@utils"
import { config as i18n } from '@utils/i18n'
import { action, computed, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-vue'

class ViewModel {
  conversationsStore = null
  tasksStore = null
  studentStepProgress = null

  constructor(params) {
    makeObservable(this, {
      boundTask: computed,
      conversation: computed,
    })

    this.studentStepProgress = params.studentStepProgress

    this.conversationsStore = getConversationsStoreFor(
      this.studentStepProgress._conversationsStoreUuid
    )

    this.tasksStore = getTasksStoreFor()
  }

  get conversation () {
    return this.studentStepProgress.conversation
  }

  get boundTask () {
    return this.tasksStore.allRecords[0]
  }

  get hasConversation () {
    return !!this.conversation
  }
  
  get inputPlaceholder () {
    return this.hasConversation
      ? i18n.t('application.interaction_timeline.input.reply')
      : i18n.t('application.interaction_timeline.input.start_a_conversation')
  }
  
  get conversationIsClosed () {
    return this.hasConversation && this.conversation.isClosed
  }
}

export default observer({
  name: "interaction-input",
  components: {
    InteractionAttach,
  },
  data() {
    return {
      vm: new ViewModel({
        studentStepProgress: this.studentStepProgress,
      }),
      message: '',
      sendingMessage: false,
    }
  },
  methods: {
    sendMessage() {
      // don't send empty messages
      if (this.message.replace(/\s/g, "").length === 0) {
        return
      }

      if (this.sendingMessage) {
        return
      }
      this.sendingMessage = true

      if (this.relatedTask) {
        this.vm.tasksStore.sendMessage({ id: this.vm.boundTask.id, task: { message: data.message }})
          .then(conv => {
            this.vm.conversationsStore._reload()
            FlashMessages.success(this.$t('application.tasks.flash.task_message_sent_success'))
          })
          .catch(resp => {
            FlashMessages.error(this.$t('application.tasks.flash.task_message_sent_error'))
          })
          .finally(() => {
            this.clearTextBox()
            this.sendingMessage = false
          })
      } else {
        if (this.studentStepProgress.conversation) {
          let params = {
            conversationId: this.studentStepProgress.conversation.id,
            message: this.message
          }

          if (!WindowVariables.currentUser.studentForGroupId(this.studentStepProgress.groupId)) {
            params.mailboxGroupId = this.studentStepProgress.groupId
          }

          this.vm.conversationsStore
            .reply(params)
            .then(conv => {
              FlashMessages.success(this.$t('application.interaction_timeline.input.flash.reply_sent'))
              this.clearTextBox()
            })
            .catch(resp => {
              FlashMessages.error(this.$t('application.interaction_timeline.input.flash.reply_error'))
            })
            .finally(() => {
              this.sendingMessage = false
            })
        } else {
          const recipients = `Group-${this.studentStepProgress.groupId},User-${this.studentStepProgress.userId}`
          this.vm.conversationsStore
            .create({
              recipients: recipients,
              groupId: this.studentStepProgress.groupId,
              courseStepId: this.studentStepProgress.courseStepId,
              userId: this.studentStepProgress.userId,
              message: this.message
            })
            .then(conv => {
              if (WindowVariables.currentUser.studentForGroupId(this.studentStepProgress.groupId)) {
                FlashMessages.success(this.$t('application.interaction_timeline.input.flash.conversation_created'))
              } else if (
                WindowVariables.currentUser.teacherForGroupId(this.studentStepProgress.groupId) ||
                  WindowVariables.currentUser.mentorForGroupId(this.studentStepProgress.groupId)) {
                FlashMessages.success(`${this.$t('application.interaction_timeline.input.flash.message_sent_to')} ${this.studentStepProgress.user.firstLastName}.`)
              }
              this.$emit('conversationCreated')
              this.clearTextBox()
            })
            .catch(resp => {
              console.error(resp)
              FlashMessages.error(this.$t('application.interaction_timeline.input.flash.conversation_error'))
            })
            .finally(() => {
              this.sendingMessage = false
            })
        }
      }
    },
    clearTextBox() {
      this.message = ''
      setTimeout(() => {
        autosize.update(this.$refs.textarea)
      }, 50)
    },
    reopenConversation () {
      return this.vm.conversationsStore.reopen(this.vm.conversation.id)
    },
  },
  mounted () {
    autosize(this.$refs.textarea)
  },
  created () {
    if (this.relatedTask && this.relatedTask.id) {
      this.vm.tasksStore.setFilter({ id: this.relatedTask.id })
    }
  },
  beforeDestroy () {
    autosize.destroy(this.$refs.textarea)
  },
  props: {
    studentStepProgress: {
      type: Object,
      required: true,
    },
    relatedTask: {
      type: Object,
      required: false,
    },
  }
})
</script>

<style lang="scss" scoped>
.interaction-input {
  padding-top: 2px;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #fff;

  textarea {
    max-height: 400px;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #3F3F3F;
      font-weight: 500;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #3F3F3F;
      font-weight: 500;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #3F3F3F;
      font-weight: 500;
    }
  }

  .send-message {
    background-color: lightgray;
    color: #fff;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    border: none;
    cursor: pointer;

    &.with-text {
      background-color: #573f7e;

      &:hover {
        background-color: hsl(263, 34%, 45%);
        transition: all 250ms ease;
      }
    }
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.textbox {
  background-color: #fff;
  padding: 8px;
  color: #333;
  border-radius: 8px;
  border: 1px solid #e8e8e8;

  &:focus {
    background-color: #fff;
    border-color: 1px solid #573f7e;
  }
}

.attach-component {
  // margin-left: 3px;
  height: 48px;
}
</style>
