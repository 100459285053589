<template>
  <modal
    :name="modalName"
    class="interaction-timeline-modal"
    height="90%"
    width="90%"
    :scrollable="true"
    @closed="destroyModal"
  >
    <div
      class="close"
      @click="destroyModal"
      style="position: absolute; right: 18px; top: 8px; z-index: 999; background-color: white; padding: 4px; color: #767676; font-size: 22px; opacity: .75; border-radius: 4px;"
    >
      <i class="fa fa-times"></i>
    </div>
    <vue-confirm-dialog></vue-confirm-dialog>
    <div class="assignment-content">
      <interaction-timeline-content-wrapper
        :selected="vm.responseRenderObj.response && vm.responseRenderObj.response.id"
        :hideVersions="vm.responseRenderObj.assignment_type === 'Assignment::Downloadable' || isStudent"
        :versions="vm.responseRenderObj.responseOptions" @versionChanged="vm.modalService.setCurrentAssignmentResponseId($event)">
        <template v-if="vm.responseRenderObj.assignment_type === 'Assignment::Downloadable'">
          <pdf-object-viewer :url="viewerUrl" height="100%" v-if="viewerUrl" />
        </template>
        <template v-else-if="vm.responseRenderObj.assignment_type === 'Assignment::Web'">
          <div class="web-worksheet-wrapper">
            <web-worksheet
              mode="timeline-modal"
              :assignmentId="vm.responseRenderObj.assignmentId"
              :assignmentResponse="vm.responseRenderObj.response"
              :courseStepId="vm.currentCourseStepId"
              :userId="vm.selectedStudentId"
              :groupId="groupId"
              :key="vm.responseRenderObj.assignmentId + vm.selectedStudentId + vm.responseRenderObj.response && vm.responseRenderObj.response.id"
            />
          </div>
        </template>
        <template v-else>
          <div class="empty-state-container">
            <img class="empty-state" src="../../../assets/images/missingUploadEmptyState.svg" />
            <h3 class="empty-state-title">{{ $t('application.interaction_timeline.timeline_modal.no_file_uploaded') }}</h3>
          </div>
        </template>
      </interaction-timeline-content-wrapper>
    </div>
    <div class="interaction-timeline-modal-container">
      <interaction-timeline
        :groupId="groupId"
        :userId="vm.selectedStudentId"
        :courseStepId="vm.currentCourseStepId"
        :key="vm.uniqueKey"
        :servicePreset="vm.servicePreset"
        @viewerUrlChanged="viewerUrl = $event"
      />
      <div class="interaction-timeline-modal-container-footer" v-if="enableNavigation">
        <button class="timeline-buttons" @click="prev()" :disabled="!vm.canGoPrev">
          <i class="fa fa-arrow-left"></i>&nbsp;&nbsp;{{ $t('application.common.previous') }}
        </button>
        <button class="timeline-buttons" @click="next()" :disabled="!vm.canGoNext">
          {{ $t('application.common.next') }}&nbsp;&nbsp;<i class="fa fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
const NAME = "interaction-timeline-modal"

import InteractionTimelineContentWrapper from '@components/interactionTimeline/InteractionTimelineContentWrapper.vue'
import InteractionTimeline from '@components/interactionTimeline/InteractionTimeline.vue'
import WebWorksheet from "@components/webWorksheets/WebWorksheet.vue"
import TimelineModalService from '@services/timelineService/TimelineModalService'
import ModalMixin from '@mixins/ModalMixin.vue'
import TimelineServiceManager from '@services/timelineService/ServiceManager'
import EventBus from '@services/GlobalEventBus'
import PdfObjectViewer from '@components/PdfObjectViewer.vue'
import { WindowVariables } from '@utils'
import { action, computed, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-vue'

class ViewModel {
  serviceFilter = null
  timelineService = null
  currentCourseStepId = null
  selectedStudentId = null

  constructor(params) {
    makeObservable(this, {
      allCourseSteps: computed,
      students: computed,
      allAssignmentResponses: computed,
      responseRenderObj: computed,
      dispose: action,
      currentCourseStepId: observable,
      selectedStudentId: observable,
      selectedStudent: computed,
      setCurrentStudentId: action,
      currentCourseStepIndex: computed,

    })
    this.currentCourseStepId = params.courseStepId
    this.selectedStudentId = params.userId
    this.serviceFilter = params.serviceFilter
    this.timelineService = TimelineServiceManager.getServiceFor('timeline-modal', this.serviceFilter)
    this.modalService = new TimelineModalService(this.timelineService, this.serviceFilter.userId, this.serviceFilter.courseStepId)
  }

  get allCourseSteps() {
    return this.timelineService.allCourseSteps || []
  }
  get students() {
    return this.timelineService.allUsers
  }

  get allAssignmentResponses () {
    return this.timelineService.allAssignmentResponses
  }

  get responseRenderObj () {
    return this.modalService.renderObject
  }

  get selectedStudent() {
    return (
      (this.students &&
        this.students.find((s) => s.id === this.selectedStudentId)) ||
      {}
    )
  }

  dispose () {
    this.modalService.dispose()
  }

  setCurrentStudentId(studentId) {
    this.selectedStudentId = studentId
  }

  setCurrentCourseStepId(csId) {
    this.currentCourseStepId = csId
    this.modalService.setCurrentCourseStepId(csId)
  }

  get currentCourseStepIndex() {
    return this.allCourseSteps.map((e) => e.id).indexOf(this.currentCourseStepId)
  }

  get uniqueKey() {
      return this.selectedStudentId + "-" + this.currentCourseStepId
  }

  get canGoNext() {
    return this.currentCourseStepIndex < this.allCourseSteps.length - 1
  }

  get canGoPrev() {
    return this.currentCourseStepIndex !== 0
  }

  get servicePreset() {
    return {
      groupId: this.serviceFilter.groupId,
      userId: this.selectedStudentId,
      courseStepId: this.currentCourseStepId,
    }
  }
}

export default observer({
  name: NAME,
  mixins: [ModalMixin],
  components: {
    InteractionTimelineContentWrapper,
    InteractionTimeline,
    WebWorksheet,
    PdfObjectViewer,
  },
  data() {
    const serviceFilter = {
      groupId: this.params.groupId,
      userId: this.params.userId,
      courseStepId: this.params.courseStepId,
    }
    return {
      modalName: NAME,
      vm: new ViewModel({
        serviceFilter,
        ...serviceFilter,
      }),
      groupId: this.params.groupId,
      userId: this.params.userId,
      courseStepId: this.params.courseStepId,
      hideNavigation: this.params.hideNavigation || false,
      viewerUrl: null,
    }
  },
  methods: {
    prev() {
      this.viewerUrl = ""
      const previousCourseStep = this.vm.allCourseSteps[this.vm.currentCourseStepIndex - 1]
      this.vm.setCurrentCourseStepId(previousCourseStep.id)
    },
    next() {
      this.viewerUrl = ""
      const nextCourseStep = this.vm.allCourseSteps[this.vm.currentCourseStepIndex + 1]
      this.vm.setCurrentCourseStepId(nextCourseStep.id)
    },
    beforeDestroyModal () {
      EventBus.dispatch('interaction-timeline-modal-closing', {
        groupId: this.groupId,
        courseStepId: this.vm.currentCourseStepId,
        userId: this.vm.selectedStudentId,
      })
    },
  },
  computed: {
    isStudent() {
      return WindowVariables.currentUser.studentForGroupId(this.groupId)
    },
    enableNavigation () {
      return !this.hideNavigation
    },
  },
  watch: {
    selectedStudentId (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.vm.setCurrentStudentId(newVal)
      }
    },
    courseStepId (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.vm.setCurrentCourseStepId(newVal)
      }
    },
  },
  beforeDestroy () {
    TimelineServiceManager.deregister(this.vm.timelineService, 'timeline-modal')
    this.vm.dispose()
  },
})
</script>

<style lang="scss">
// This style tag cannot be "scoped" or flexbox won't get applied to v--modal.

.interaction-timeline-modal {
  .v--modal {
    display: flex;
    flex-direction: row;
  }

  .assignment-content {
    height: 100%;
    width: calc(100% - 400px);
  }
}

.assignment-container {
  height: 100%;
  width: 100%;
  display: flex;
}

.web-worksheet-wrapper {
  padding: 20px;
  overflow-y: auto;
}

.interaction-timeline-modal-container {
  width: 399px;
  flex: 0 0 400px; // doing this removes the X scrollbar but hey it works.
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e8e8e8;
  overflow-y: hidden;
  position: relative;

  .user-select-wrapper-div {
    padding: 8px 16px;
    background-color: white;
    width: inherit;
    flex: 0 0 41px;
  }

  .user-selected {
    color: #767676;
    font-size: 0.75rem;
  }

  .timeline-buttons {
    background-color: #fff;
    padding: 8px 16px;
    border: none;
    color: #767676;

    &:hover {
      color: #333;
      cursor: pointer;
    }
  }

  .interaction-timeline-modal-container-footer {
    display: flex;
    justify-content: space-between;
    background-color: white;
    flex: 0 0 auto;
    margin-top: 0.5rem;
  }
}

.empty-state-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.empty-state {
  width: 368px;
  margin-bottom: 24px;
}
</style>
