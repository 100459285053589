<template>
  <div class="events-container p-2 overflow-y-auto" style="max-height: 500px;">
    <div class="mb-2 flex flex-row border-b border-gray-400 pb-2 px-1" v-for="event in allEvents" :key="event.id">
      <div class="text-gray-500 flex flex-column">
        <div class="text-xs font-bold text-left">{{event.created_at.format('M/D/YY h:mma')}}</div>
        <div class="text-gray-900 text-sm text-left">{{ titleCase(event.event_name) }}</div>
      </div>
      <div class="text-gray-700 text-sm text-right">
        {{event.sequence_analytics_name}} - <span class="italic">{{event.course_step_analytics_name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { camelCase, map, startCase } from 'lodash-es'
import dayjs from '@services/dayjsWrapper'
import { WindowVariables } from '@utils'

import AnalyticEvents from '@dataServices/AnalyticEvents'

export default {
  data () {
    return {
      allEvents: [],
    }
  },
  computed: {
    givenUserIdOnly () {
      return typeof this.user !== 'object'
    },
  },
  methods: {
    titleCase (str) {
      return startCase(camelCase(str))
    },
  },
  created () {
    AnalyticEvents.getAll({ user_id: this.givenUserIdOnly ? this.user : this.user.id })
      .then(events => {
        this.allEvents = map(events, event => {
          event.created_at = dayjs(event.created_at).tz(WindowVariables.timezone)
          return event
        })
      }, resp => console.error(resp))
  },
  props: {
    user: {
      required: true,
    }
  }
}
</script>

<style lang="sass">

</style>