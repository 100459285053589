
import { filter, find, first, orderBy } from 'lodash-es'
import { observable, computed, action, makeObservable } from 'mobx'
import GMDS from '@services/gradebookManager/DataStore'

class GradebookManagerService {
  dataStore = new GMDS()
  _groupId = null
  _userId = null

  _currentAssignmentResponseId = null

  constructor() {
    makeObservable(this, {
      _groupId: observable,
      _userId: observable,
      _currentAssignmentResponseId: observable,
      setCurrentAssignmentResponseId: action,
      createGrade: action,
      allStudents: computed,
      allAssignments: computed,
      allAssignmentResponses: computed,
      currentAssignmentResponse: computed,
      currentAssignment: computed,
      allAssignmentResponseGrades: computed,
      currentAssignmentResponseGrade: computed,
      currentUser: computed
    });
  }

  set setFilter ({ groupId, userId }) {
    this._groupId = groupId
    this._userId = userId || null

    this.dataStore.setFilter({ groupId: this._groupId, userId: this._userId })
  }

  setCurrentAssignmentResponseId (id) {
    this._currentAssignmentResponseId = id
  }

  createGrade(points) {
    return this.dataStore.assignmentResponseGradesStore.create({
      assignment_response_id: this._currentAssignmentResponseId,
      points: points
    })
  }

  get allStudents() {
    return this.dataStore.allStudents
  }

  get allAssignments() {
    return this.dataStore.allAssignments
  }

  get allAssignmentResponses() {
    return this.dataStore.allAssignmentResponses
  }

  get currentAssignmentResponse() {
    return find(this.allAssignmentResponses, { id: this._currentAssignmentResponseId })
  }

  get currentAssignment() {
    const aid = this.currentAssignmentResponse && this.currentAssignmentResponse.assignment_id
    return find(this.allAssignments, { id: aid })
  }

  get allAssignmentResponseGrades() {
    return this.dataStore.allAssignmentResponseGrades
  }

  get currentAssignmentResponseGrade() {
    return first(orderBy(filter(this.dataStore.allAssignmentResponseGrades, { assignment_response_id: this._currentAssignmentResponseId }), ['id'], ['desc']))
  }

  get currentUser() {
    if (this._userId) {
      return find(this.allStudents, { id: this._userId })
    } else if (this.currentAssignmentResponse) {
      return find(this.allStudents, { id: this.currentAssignmentResponse.user_id })
    } else {
      return null
    }
  }
}

export default new GradebookManagerService()