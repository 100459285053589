import axios from './axios'

const baseUrlString = '/analytic_events'

async function getAll(filter) {
  return axios.get(`${baseUrlString}/`, { params: filter })
    .then(function (response) {
      return response.data
    }).catch(function (error) {
      console.error('Error', error.message)
    })
}

async function track(groupId, courseStepId, courseId, eventName, metadata) {
  var data = {
    analytic_event: {
      group_id: groupId,
      course_step_id: courseStepId,
      course_id: courseId,
      event_name: eventName,
      metadata: metadata || {}
    }
  }
  return axios.post(`${baseUrlString}/track`, data)
      .then(function (response) {
        return response.data
      }).catch(function (error) {
        console.error('Error', error.message)
      })
}

async function trackStepLoad(groupId, courseStepId, courseId) {
  return track(groupId, courseStepId, courseId, 'step-loaded')
}

async function trackVideoPlay(groupId, courseStepId, courseId, currentTimeInSeconds, attachmentId) {
  return track(groupId, courseStepId, courseId, 'video-play', { current_time: currentTimeInSeconds, attachment_id: attachmentId })
}

async function trackVideoPause(groupId, courseStepId, courseId, currentTimeInSeconds, attachmentId) {
  return track(groupId, courseStepId, courseId, 'video-pause', { current_time: currentTimeInSeconds, attachment_id: attachmentId })
}

async function trackVideoEnded(groupId, courseStepId, courseId, currentTimeInSeconds, attachmentId) {
  return track(groupId, courseStepId, courseId, 'video-end', { current_time: currentTimeInSeconds, attachment_id: attachmentId })
}

async function trackVideoSeeked(groupId, courseStepId, courseId, currentTimeInSeconds, attachmentId) {
  return track(groupId, courseStepId, courseId, 'video-seeked', { current_time: currentTimeInSeconds, attachment_id: attachmentId })
}

async function trackVideoProgress(groupId, courseStepId, courseId, currentTimeInSeconds, attachmentId) {
  return track(groupId, courseStepId, courseId, 'video-progress', { current_time: currentTimeInSeconds, attachment_id: attachmentId })
}

async function trackActionStepDownload(groupId, courseStepId, courseId, attachmentId) {
  return track(groupId, courseStepId, courseId, 'download-action-step', { attachment_id: attachmentId })
}

export default {
  getAll,
  track,
  trackStepLoad,
  trackVideoEnded,
  trackVideoPause,
  trackVideoPlay,
  trackVideoProgress,
  trackVideoSeeked,
  trackActionStepDownload
}
