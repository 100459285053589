<template>
  <div>
    <h2 class="pb-2 text-xl font-semibold text-center">
      <a :href="`/users/${userId}`" target="_blank">{{ userFullName }}</a>
    </h2>
    <div class="text-center border-b-2 border-gray-200 pb-2 mb-2">
      <span class="" v-if="userPhoneNumber">
        <i class="fa fa-phone"></i>
        <a :href="formattedHrefPhoneNumber()">{{ formattedPhoneNumber() }}</a>
      </span>

      <span class="pl-4">
        <i class="fa fa-envelope"></i>
        <a :href="formattedHrefEmailTo">{{ userEmail }}</a>
      </span>
    </div>
  </div>
</template>

<script>

  const NAME = "task-user-header"

  export default {
    name: NAME,
    mixins: [  ],
    components: {

    },
    props: {
      userId: {type: Number,required: true,},
      userFirstName: {type: String,required: true,},
      userLastName: {type: String,required: true,},
      userEmail: {type: String,required: false,},
      userPhoneNumber: {type: String,required: false,},
    },
    data () {
      return {

      }
    },
    async mounted() {

    },
    computed: {
      userFullName() {
        return this.userFirstName + ' ' + this.userLastName
      },
    },
    methods: {
      formattedPhoneNumber() {
        const phoneNumberString = this.userPhoneNumber

        if (phoneNumberString == null) {
          return ''
        }

        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '')
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return phoneNumberString
      },
      formattedHrefPhoneNumber(){
        return 'tel:' + this.userPhoneNumber.replace(/[^0-9.]/g, '')
      },
      formattedHrefEmailTo(){
        return 'mailto:' + this.userEmail
      }
    },
    watch: {

    }
  }



</script>

<style lang="scss">

</style>
