
<template>
  <v-avatar
    tile
    size="22"
    :style="iconStyle"
    class="mr-2"
  >
    <div class="text-xs font-bold text-white">
      {{ iconText }}
    </div>
  </v-avatar>
</template>

<script>
import { VAvatar } from 'vuetify/lib'

export default {
  name: 'task-type-icon',
  components: {
    VAvatar,
  },
  props: {
    taskType: { type: Object, required: true, },
  },
  computed: {
    isQuestionsRemaining() {
      return this.taskType === 'questions-remaining-followup'
    },
    isEngagementConcern() {
      return this.taskType === 'engagement-concern-followup'
    },
    isLowFeedback() {
      return this.taskType === 'low-feedback-score-followup'
    },
    isPhaseCompletion() {
      return this.taskType === 'phase-completion-followup'
    },
    isManualEngagement() {
      return this.taskType === 'manual-engagement-followup'
    },
    isPhaseSurveySubmitted() {
      return this.taskType === 'phase-survey-followup'
    },
    isTriageTask() {
      return this.taskType === 'triage-launchers-engagement-followup'
    },
    iconStyle () {
      if (this.isQuestionsRemaining) { return { 'background-color': '#a855f7', } }
      else if (this.isEngagementConcern) { return { 'background-color': '#06b6d4', } }
      else if (this.isLowFeedback) { return { 'background-color': '#ec4899', } }
      else if (this.isPhaseCompletion) { return { 'background-color': '#84cc16', } } // phase-completion-followup
      else if (this.isManualEngagement) { return { 'background-color': '#333' } }
      else if (this.isPhaseSurveySubmitted) { return { 'background-color': '#f97316' } }
      else if (this.isTriageTask) { return { 'background-color': '#ee0707' } }
      else {return ''}
    },
    iconText () {
      if (this.isQuestionsRemaining) { return 'QR' }
      else if (this.isEngagementConcern) { return 'EC' }
      else if (this.isLowFeedback) { return 'LF' }
      else if (this.isPhaseCompletion) { return 'NL' } // phase-completion-followup // New Launcher label change
      else if (this.isManualEngagement) { return 'ME' }
      else if (this.isPhaseSurveySubmitted) { return 'PS' }
      else if (this.isTriageTask) { return 'TR' }
      else { return '' }
    }
  },


}
</script>