<template>
  <div class="container" :class="{'pt-8': !hideHeader}">
    <template v-if="!hideHeader">
      <h2 class="pb-2 text-xl font-semibold text-center">
        <a :href="`/users/${user.id}`" target="_blank">{{ user.firstLastName }}</a>
      </h2>
      <div class="text-center border-b-2 border-gray-200 pb-2 mb-2">
        <span class="" v-if="user.phone_number">
          <i class="fa fa-phone"></i>
          <a :href="'tel:' + user.phone_number.replace(/[^0-9.]/g, '')">{{ user.formattedPhoneNumber }}</a>
        </span>

        <span class="pl-4">
          <i class="fa fa-envelope"></i>
          <a :href="'mailto:' + user.email">{{ user.email }}</a>
        </span>
      </div>
    </template>

    <div class="action-history-list-container overflow-y-scroll" style="max-height: 600px">
      <div>
        <div class="divide-y divide-gray-200">
          <div class="pb-2" v-if="!hideHeader">
            <h2 id="activity-title" class="text-lg font-medium text-center text-gray-900">Action History</h2>
          </div>
          <div class="pt-2">
            <!-- Activity feed-->
            <div class="flow-root">
              <ul class="-mb-8">
                <li class="text-center font-semibold mb-10" v-if="actionHistoryItems.length === 0">
                  No action history items available.
                </li>
                <li class="action-history-item text-left" v-for="item in actionHistoryItems" :key="item.version_id">
                  <template v-if="item.action_type === 'group_membership:add_internal_user_update'">
                    <div class="relative pb-4">
                      <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                      <div class="relative flex items-start space-x-3">
                        <div class="relative">
                          <img class="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" :src="`https://ui-avatars.com/api/?name=${item.created_by_user.first_name[0]}+${item.created_by_user.last_name[0]}`" :alt="`${item.created_by_user.first_name} ${item.created_by_user.last_name}`">
                        </div>
                        <div class="min-w-0 flex-1 flex flex-col">
                          <div class="min-w-0 flex-1 flex flex-row space-between">
                            <div class="flex-1">
                              <div class="text-sm">
                                <span class="font-medium text-gray-900">{{item.created_by_user.first_name}} {{item.created_by_user.last_name}}</span>
                              </div>
                              <p class="mt-0.5 font-semibold text-gray-800 text-md">Added ER Starter Notes</p>
                            </div>
                            <div class="flex-1">
                              <p class="text-sm text-gray-500 text-right pr-4">
                                {{ item.created_at.format('M/D/YY h:mma') }}
                              </p>
                            </div>
                          </div>
                          <p>
                            {{ item.internal_user_update }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="item.action_type === 'group_membership:add_partner_user_update'">
                    <div class="relative pb-4">
                      <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                      <div class="relative flex items-start space-x-3">
                        <div class="relative">
                          <img class="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" :src="`https://ui-avatars.com/api/?name=${item.created_by_user.first_name[0]}+${item.created_by_user.last_name[0]}`" :alt="`${item.created_by_user.first_name} ${item.created_by_user.last_name}`">
                        </div>
                        <div class="min-w-0 flex-1 flex flex-col">
                          <div class="min-w-0 flex-1 flex flex-row space-between">
                            <div class="flex-1">
                              <div class="text-sm">
                                <span class="font-medium text-gray-900">{{item.created_by_user.first_name}} {{item.created_by_user.last_name}}</span>
                              </div>
                              <p class="mt-0.5 font-semibold text-gray-800 text-md">Added Partner Starter Notes</p>
                            </div>
                            <div class="flex-1">
                              <p class="text-sm text-gray-500 text-right pr-4">
                                {{ item.created_at.format('M/D/YY h:mma') }}
                              </p>
                            </div>
                          </div>
                          <p>
                            {{ item.partner_user_update }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="item.action_type === 'group_membership:add_internal_phone_call_notes'">
                    <div class="relative pb-4">
                      <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                      <div class="relative flex items-start space-x-3">
                        <div class="relative">
                          <img class="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" :src="`https://ui-avatars.com/api/?name=${item.created_by_user.first_name[0]}+${item.created_by_user.last_name[0]}`" :alt="`${item.created_by_user.first_name} ${item.created_by_user.last_name}`">
                        </div>
                        <div class="min-w-0 flex-1 flex flex-col">
                          <div class="min-w-0 flex-1 flex flex-row space-between">
                            <div class="flex-1">
                              <div class="text-sm">
                                <span class="font-medium text-gray-900">{{item.created_by_user.first_name}} {{item.created_by_user.last_name}}</span>
                              </div>
                              <p class="mt-0.5 font-semibold text-gray-800 text-md">Added ER Phone Call Notes</p>
                            </div>
                            <div class="flex-1">
                              <p class="text-sm text-gray-500 text-right pr-4">
                                {{ item.created_at.format('M/D/YY h:mma') }}
                              </p>
                            </div>
                          </div>
                          <p>
                            {{ item.internal_phone_call_notes }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="item.action_type === 'group_membership:mark_discontinued'">
                    <div class="relative pb-4">
                      <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                      <div class="relative flex items-start space-x-3">
                        <div class="relative">
                          <img class="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" :src="`https://ui-avatars.com/api/?name=${item.created_by_user.first_name[0]}+${item.created_by_user.last_name[0]}`" :alt="`${item.created_by_user.first_name} ${item.created_by_user.last_name}`">
                        </div>
                        <div class="min-w-0 flex-1 flex flex-col">
                          <div class="min-w-0 flex-1 flex flex-row space-between">
                            <div class="flex-1">
                              <div class="text-sm">
                                <span class="font-medium text-gray-900">{{item.created_by_user.first_name}} {{item.created_by_user.last_name}}</span>
                              </div>
                              <p class="mt-0.5 font-semibold text-gray-800 text-md">Marked Discontinued</p>
                            </div>
                            <div class="flex-1">
                              <p class="text-sm text-gray-500 text-right pr-4">
                                {{ item.created_at.format('M/D/YY h:mma') }}
                              </p>
                            </div>
                          </div>
                          <p class="">
                              <span class="font-semibold">Disengagement Category</span>
                              {{ item.disengagement_category }}
                            </p>
                            <p class="">
                              <span class="font-semibold">Disengagement Note</span>
                              {{ item.disengagement_note }}
                            </p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="item.action_type === 'group_membership:record_assigned_mentor_group'">
                    <div class="relative pb-4">
                      <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                      <div class="relative flex items-start space-x-3">
                        <div class="relative">
                          <img class="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" :src="`https://ui-avatars.com/api/?name=${item.created_by_user.first_name[0]}+${item.created_by_user.last_name[0]}`" :alt="`${item.created_by_user.first_name} ${item.created_by_user.last_name}`">
                        </div>
                        <div class="min-w-0 flex-1 flex flex-col">
                          <div class="min-w-0 flex-1 flex flex-row space-between">
                            <div class="flex-1">
                              <div class="text-sm">
                                <span class="font-medium text-gray-900">{{item.created_by_user.first_name}} {{item.created_by_user.last_name}}</span>
                              </div>
                              <p class="mt-0.5 font-semibold text-gray-800 text-md">Recorded Assigned Mentor Group</p>
                            </div>
                            <div class="flex-1">
                              <p class="text-sm text-gray-500 text-right pr-4">
                                {{ item.created_at.format('M/D/YY h:mma') }}
                              </p>
                            </div>
                          </div>
                          <p class="">
                              <span class="font-semibold">Group Name</span>
                              {{ item.assigned_mentor_group }}
                            </p>
                            <p class="">
                              <span class="font-semibold">Group Assigned</span>
                              {{ item.assigned_mentor_group_date }}
                            </p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="item.action_type === 'group_membership:add_coaching_call'">
                    <div class="relative pb-4">
                      <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                      <div class="relative flex items-start space-x-3">
                        <div class="relative">
                          <img class="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" :src="`https://ui-avatars.com/api/?name=${item.created_by_user.first_name[0]}+${item.created_by_user.last_name[0]}`" :alt="`${item.created_by_user.first_name} ${item.created_by_user.last_name}`">
                        </div>
                        <div class="min-w-0 flex-1 flex flex-col">
                          <div class="min-w-0 flex-1 flex flex-row space-between">
                            <div class="flex-1">
                              <div class="text-sm">
                                <span class="font-medium text-gray-900">{{item.created_by_user.first_name}} {{item.created_by_user.last_name}}</span>
                              </div>
                              <p class="mt-0.5 font-semibold text-gray-800 text-md">Add Coaching Call</p>
                            </div>
                            <div class="flex-1">
                              <p class="text-sm text-gray-500 text-right pr-4">
                                {{ item.created_at.format('M/D/YY h:mma') }}
                              </p>
                            </div>
                          </div>
                          <p class="">
                            <span class="font-semibold">Phase</span>
                            {{ item.coaching_call_phase }}
                          </p>
                          <p class="">
                            <span class="font-semibold">Coaching Call Date</span>
                            {{ item.coaching_call_date }}
                          </p>
                          <p class="">
                            <span class="font-semibold">Mentor/Coach Name</span>
                            {{ item.coaching_call_coach_name }}
                          </p>
                          <p class="">
                            <span class="font-semibold">Notes</span>
                            {{ item.coaching_call_notes }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="item.action_type === 'group_membership:add_phone_call'">
                    <div class="relative pb-4">
                      <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                      <div class="relative flex items-start space-x-3">
                        <div class="relative">
                          <img class="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" :src="`https://ui-avatars.com/api/?name=${item.created_by_user.first_name[0]}+${item.created_by_user.last_name[0]}`" :alt="`${item.created_by_user.first_name} ${item.created_by_user.last_name}`">
                        </div>
                        <div class="min-w-0 flex-1 flex flex-col">
                          <div class="min-w-0 flex-1 flex flex-row space-between">
                            <div class="flex-1">
                              <div class="text-sm">
                                <span class="font-medium text-gray-900">{{item.created_by_user.first_name}} {{item.created_by_user.last_name}}</span>
                              </div>
                              <p class="mt-0.5 font-semibold text-gray-800 text-md">Add Coaching Call</p>
                            </div>
                            <div class="flex-1">
                              <p class="text-sm text-gray-500 text-right pr-4">
                                {{ item.created_at.format('M/D/YY h:mma') }}
                              </p>
                            </div>
                          </div>
                          <p class="">
                            <span class="font-semibold">Type</span>
                            {{ item.phone_call_type }}
                          </p>
                          <p class="">
                            <span class="font-semibold">Phase</span>
                            {{ item.phone_call_course_phase }}
                          </p>
                          <p class="">
                            <span class="font-semibold">Phone Call Date</span>
                            {{ item.phone_call_date }}
                          </p>
                          <p class="">
                            <span class="font-semibold">Staff/Mentor/Coach Name</span>
                            {{ item.phone_call_staff_name }}
                          </p>
                          <p class="">
                            <span class="font-semibold">Notes</span>
                            {{ item.phone_call_notes }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="item.action_type === 'group_membership:add_disengagement_notes'">
                    <div class="relative pb-4">
                      <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                      <div class="relative flex items-start space-x-3">
                        <div class="relative">
                          <img class="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" :src="`https://ui-avatars.com/api/?name=${item.created_by_user.first_name[0]}+${item.created_by_user.last_name[0]}`" :alt="`${item.created_by_user.first_name} ${item.created_by_user.last_name}`">
                        </div>
                        <div class="min-w-0 flex-1 flex flex-col">
                          <div class="min-w-0 flex-1 flex flex-row space-between">
                            <div class="flex-1">
                              <div class="text-sm">
                                <span class="font-medium text-gray-900">{{item.created_by_user.first_name}} {{item.created_by_user.last_name}}</span>
                              </div>
                              <p class="mt-0.5 font-semibold text-gray-800 text-md">Added Disengagement Notes</p>
                            </div>
                            <div class="flex-1">
                              <p class="text-sm text-gray-500 text-right pr-4">
                                {{ item.created_at.format('M/D/YY h:mma') }}
                              </p>
                            </div>
                          </div>
                          <p>
                            {{ item.disengagement_status }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { sortBy } from 'lodash-es'
  import GroupMembership from '@dataServices/GroupMembership'
  import dayjs from '@services/dayjsWrapper'
  import { WindowVariables } from '@utils'
  import EventBus from "@services/GlobalEventBus";

  export default {
    name: 'group-membership-action-history',
    props: {
      user: {
        type: Object,
        required: true,
      },
      groupMembershipId: {
        type: Number,
        required: true,
      },
      hideHeader: {
        type: Boolean,
        required: false,
        default: false,
      }
    },
    data () {
      return {
        actionHistoryItems: [],
        unsubscribeListenerFunctions: [],
      }
    },
    mounted () {
      this.refreshData()
      this.unsubscribeListenerFunctions = [
        EventBus.subscribe('group-membership-actions-modal', (event) => this.consumeGroupMembershipActionsModalEvent(event)),
      ]

    },
    async beforeDestroy () {
      this.unsubscribeListenerFunctions.forEach(unsubscribe => unsubscribe());
    },
    methods: {
      consumeGroupMembershipActionsModalEvent(event){
        if (event.update) {
          this.refreshData()
        }
      },
      refreshData(){
        GroupMembership
          .getActionHistoryById(this.groupMembershipId)
          .then(actions => {
            this.actionHistoryItems = sortBy(actions.map(a => {
              a.created_at = dayjs(a.created_at).tz(WindowVariables.timezone)
              return a
            }), i => i.created_at.unix() * -1)
          }, resp => {
            console.error(resp)
          })
      }
    },
  }
</script>

<style lang="scss">
</style>
