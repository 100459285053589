<template>
  <modal
    :name="modalName"
    class="bulk-action-modal"
    :scrollable="true"
    height="auto"
    @closed="destroyModal">
    <div class="close" @click="destroyModal" style="position: absolute; right: 18px; top: 8px; z-index: 999; background-color: white; padding: 4px; color: #767676; font-size: 22px; opacity: .75; border-radius: 4px;">
      <i class="fa fa-times"></i>
    </div>

    <div class="container pt-8">
      <template v-if="noSelection">
        <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
            <div class="ml-4 mt-4">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                No Selection
              </h3>
              <p class="mt-1 text-sm leading-5 text-gray-500">
                {{noSelectionMessage}}
              </p>
            </div>
            <div class="ml-4 mt-4 flex-shrink-0">
              <span class="inline-flex rounded-md shadow-sm">
                <button type="button" @click="destroyModal" class=" btn btn-primary">Close</button>
              </span>
            </div>
          </div>
        </div>
      </template>
      <template v-if="!noSelection">
        <component :is="componentName" :ids="ids" @cancel="destroyModal" @resetBulkActionIds="resetBulkActionIds"></component>
      </template>
    </div>
  </modal>
</template>

<script>
  import ModalMixin from '@mixins/ModalMixin.vue'
  import BulkManualEngagementForm from './bulkActionForms/BulkManualEngagementForm.vue'

  const NAME = "bulk-action-modal"

  export default {
    name: NAME,
    mixins: [ ModalMixin ],
    components: {
      BulkManualEngagementForm
    },
    data() {
      return {
        modalName: NAME,
        ids: this.params.ids,
        bulkAction: this.params.bulkAction,
      }
    },
    methods: {
      resetBulkActionIds () {
        const selectAllCheckbox = document.getElementById('select_all');
        selectAllCheckbox.checked = false
      }
    },
    computed: {
      componentName () {
        return `${this.bulkAction}-form`;
      },
      minSelLen () {
        return 1;
      },
      noSelection () {
        return !this.ids || this.ids.length < this.minSelLen;
      }
    }
  }
</script>

<style lang="scss">
  .bulk-action-modal .v--modal {
    height: auto;
    width: 75%;
  }
</style>
