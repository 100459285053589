<template>
  <modal
    :name="modalName"
    class="create-manual-engagement-task-modal"
    :scrollable="true"
    height="auto"
    @closed="destroyModal">
    <div class="close" @click="destroyModal" style="position: absolute; right: 18px; top: 8px; z-index: 999; background-color: white; padding: 4px; color: #767676; font-size: 22px; opacity: .75; border-radius: 4px;">
      <i class="fa fa-times"></i>
    </div>

    <div class="container pt-8">
      <template v-if="!!user">
        <h2 class="pb-2 text-xl font-semibold text-center">
          <a :href="`/users/${user.id}`" target="_blank">{{ user.firstLastName }}</a>
        </h2>
        <div class="text-center border-b-2 border-gray-200 pb-2 mb-2">
          <span class="" v-if="user.phone_number">
            <i class="fa fa-phone"></i>
            <a :href="'tel:' + user.phone_number.replace(/[^0-9.]/g, '')">{{ user.formattedPhoneNumber }}</a>
          </span>

          <span class="pl-4">
            <i class="fa fa-envelope"></i>
            <a :href="'mailto:' + user.email">{{ user.email }}</a>
          </span>
        </div>
        <FormulateForm
          @submit="submitForm"
          ref="form"
          class="max-w-lg mx-auto"
          v-model="values"
          :schema="currentSchema"
        />
      </template>
    </div>
  </modal>
</template>

<script>
  import ModalMixin from '@mixins/ModalMixin.vue'
  import { getTasksStoreFor } from '@stores/TasksStore'
  import FlashMessages from '@services/FlashMessageService'
  import EventBus from '@services/GlobalEventBus'
  import Users from '@dataServices/Users'
  import axios from "@dataServices/axios";

  const NAME = "create-manual-engagement-task-modal"
  const baseUrl = window.location.origin

  export default {
    name: NAME,
    mixins: [ ModalMixin ],
    components: {

    },
    data() {
      return {
        modalName: NAME,
        coachOptions: [],
        values: {
          coach_responsible_user_id: null,
        },
        user: null,
        userId: this.params.userId,
        groupMembershipId: this.params.groupMembershipId,
        tasksStore: getTasksStoreFor(),
      }
    },
    async created () {
      Users.getById(this.userId).then(u => { this.user = u })
      await this.fetchCoaches()
    },
    methods: {
      async fetchCoaches () {
        try {
          const response = await axios.get(baseUrl + '/api/coaching/task_management/coaches', {params: {per_page: 100}}) //defining a high perPage number for now. If necessary in the future, implement recursive fetching later to fetch everyone
          this.coachOptions = response.data.records.map(coach => ({value: coach.id, label: coach.name}));
        } catch (error) {
          console.error("Failed to fetch coaches:", error);
          FlashMessages.error("Failed to fetch coaches! " + error); //it's ok to show the real error because the coaches are the users
        }
      },
      async submitForm () {
        try {
          const request = {
            create_manual_engagement_request: {
              ...this.values,
              student_group_membership_ids: [this.groupMembershipId]
            }
          }

          const response = axios.post(baseUrl + `/api/coaching/task_management/manual_engagement`, request)
          const tasks = response.data

          FlashMessages.success(this.$t('application.tasks.flash.task_completion_success'))
          EventBus.dispatch('refresh-task-list', tasks)
          this.destroyModal()
        } catch (error) {
          console.warn(error)
          FlashMessages.error(this.$t('application.tasks.flash.task_completion_error'))
        }
      }
    },
    computed: {
      currentSchema() {
        return [
          {
            component: 'h3',
            class: 'font-bold text-center text-xl pb-4',
            children: "New Manual Engagement Request",
          },
          {
            type: 'select',
            label: 'Coach Responsible',
            name: 'coach_responsible_user_id',
            options: this.coachOptions,
            placeholder: 'Select a coach...'
          },
          {
            type: 'textarea',
            rows: 5,
            label: 'Engagement Request Description',
            class: 'input',
            name: 'message',
            placeholder: 'Enter the description here...',
            validation: 'required'
          },
          {
            type: 'submit',
            label: 'Submit'
          }
        ]
      },
    },
  }
</script>

<style lang="scss">
  .create-manual-engagement-task-modal .v--modal {
    height: auto;
    width: 75%;
  }

  .input :is(textarea,select,input) {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding:  12px;
    width: full;
    margin-top: 4px;
  }
</style>
