
import { filter, find, first, map, orderBy, uniqBy } from 'lodash-es'
import { observable, computed, action, makeObservable } from 'mobx'
import DataStore from '@services/starterTracking/DataStore'

class StarterTrackingManagerService {
  dataStore = new DataStore()
  _groupId = null
  _userId = null
  _currentAssignmentResponseId = null

  constructor() {
    makeObservable(this, {
      _groupId: observable,
      _userId: observable,
      _currentAssignmentResponseId: observable,
      setCurrentAssignmentResponseId: action,
      setFilter: action,
      createGrade: action,
      allStudents: computed,
      allAssignments: computed,
      allAssignmentResponses: computed,
      allCourseSteps: computed,
      currentAssignmentResponse: computed,
      currentAssignment: computed,
      allAssignmentResponseGrades: computed,
      currentAssignmentResponseGrade: computed,
      courseStepsWithAssignments: computed,
      courseSteps: computed,
      courseStepCategories: computed,
      courseStepCategoriesWithAssignments: computed,
      courseStepsByCategory: computed,
      courseStepsWithAssignmentByCategory: computed,
      assignmentByCourseStepId: computed,
      currentUser: computed
    })
  }

  setFilter ({ groupId, userId }) {
    this._groupId = groupId
    this._userId = userId || null

    this.dataStore.setFilter({ groupId: this._groupId, userId: this._userId })
  }

  setCurrentAssignmentResponseId (id) {
    this._currentAssignmentResponseId = id
  }

  createGrade(points) {
    return this.dataStore.assignmentResponseGradesStore.create({
      assignment_response_id: this._currentAssignmentResponseId,
      points: points
    })
  }

  get allStudents() {
    return this.dataStore.allStudents
  }

  get allAssignments() {
    return this.dataStore.allAssignments
  }

  get allAssignmentResponses() {
    return this.dataStore.allAssignmentResponses
  }

  get allCourseSteps() {
    return this.dataStore.allCourseSteps
  }

  get currentAssignmentResponse() {
    return find(this.allAssignmentResponses, { id: this._currentAssignmentResponseId })
  }

  get currentAssignment() {
    const aid = this.currentAssignmentResponse && this.currentAssignmentResponse.assignment_id
    return find(this.allAssignments, { id: aid })
  }

  get allAssignmentResponseGrades() {
    return this.dataStore.allAssignmentResponseGrades
  }

  get currentAssignmentResponseGrade() {
    return first(orderBy(filter(this.dataStore.allAssignmentResponseGrades, { assignment_response_id: this._currentAssignmentResponseId }), ['id'], ['desc']))
  }

  get courseStepsWithAssignments() {
    return filter(this.dataStore.allCourseSteps, cs => !!cs.assignment_id)
  }

  get courseSteps() {
    return this.dataStore.allCourseSteps
  }

  get courseStepCategories() {
    return uniqBy(map(this.dataStore.allCourseSteps, 'category'), 'id')
  }

  get courseStepCategoriesWithAssignments() {
    return uniqBy(map(this.courseStepsWithAssignments, 'category'), 'id')
  }

  get courseStepsByCategory() {
    return map(this.courseStepCategories, cat => ({
      category: cat,
      items: filter(this.dataStore.allCourseSteps, cs => cs.category.id === cat.id)
    }))
  }

  get courseStepsWithAssignmentByCategory() {
    return map(this.courseStepCategoriesWithAssignments, cat => ({
      category: cat,
      items: filter(this.courseStepsWithAssignments, cs => cs.category.id === cat.id)
    }))
  }

  get assignmentByCourseStepId() {
    if (this.dataStore.allAssignments) {
      return reduce(this.courseStepsWithAssignments, (result, cs) => {
        const asn = find(this.dataStore.allAssignments, { id: cs.assignment_id })
        if (asn) {
          result[cs.id] = asn
        }
      }, {})
    } else {
      return {}
    }
  }

  get currentUser() {
    if (this._userId) {
      return find(this.allStudents, { id: this._userId })
    } else if (this.currentAssignmentResponse) {
      return find(this.allStudents, { id: this.currentAssignmentResponse.user_id })
    } else {
      return null
    }
  }
}

export default new StarterTrackingManagerService()