<template>
  <modal
    :name="modalName"
    class="group-membership-actions-modal"
    :scrollable="true"
    height="auto"
    @closed="destroyModal">
    <div class="close" @click="destroyModal" style="position: absolute; right: 18px; top: 8px; z-index: 999; background-color: white; padding: 4px; color: #767676; font-size: 22px; opacity: .75; border-radius: 4px;">
      <i class="fa fa-times"></i>
    </div>

    <template v-if="!givenUserIdOnly">
      <group-membership-action-history
        :user="user"
        :groupMembershipId="groupMembershipId"
      />
    </template>
  </modal>
</template>

<script>
  import { sortBy } from 'lodash-es'
  import ModalMixin from '@mixins/ModalMixin.vue'
  import GroupMembership from '@dataServices/GroupMembership'
  import dayjs from '@services/dayjsWrapper'
  import GroupMembershipActionHistory from '@components/GroupMembershipActionHistory.vue'
  import Users from '@dataServices/Users'
  import User from '@models/User'
import { WindowVariables } from '@utils'

  const NAME = "group-membership-action-history-modal"

  export default {
    name: NAME,
    mixins: [ ModalMixin ],
    components: {
      GroupMembershipActionHistory,
    },
    data () {
      return {
        modalName: NAME,
        user: this.params.data.user,
        groupMembershipId: this.params.data.groupMembershipId,
        actionHistoryItems: [],
      }
    },
    computed: {
      givenUserIdOnly () {
        return typeof this.user !== 'object'
      },
    },
    created () {
      if (this.givenUserIdOnly) {
        Users.getById(this.user).then(u => { this.user = u })
      } else {
        if (!this.user._isModel) {
          this.user = new User(this.user)
        }
      }

      GroupMembership.getActionHistoryById(this.groupMembershipId)
        .then(actions => {
          this.actionHistoryItems = sortBy(actions.map(a => {
            a.created_at = dayjs(a.created_at).tz(WindowVariables.timezone)
            return a
          }), i => i.created_at.unix() * -1)
        }, resp => {
          console.error(resp)
        })
    },
  }
</script>

<style lang="scss">
  .group-membership-actions-modal .v--modal {
    height: auto;
    width: 75%;
  }
</style>
