<template>
  <modal
    :name="modalName"
    class="bulk-action-modal"
    :scrollable="true"
    height="auto"
    @closed="destroyModal">
    <div class="close" @click="destroyModal" style="position: absolute; right: 18px; top: 8px; z-index: 999; background-color: white; padding: 4px; color: #767676; font-size: 22px; opacity: .75; border-radius: 4px;">
      <i class="fa fa-times"></i>
    </div>

    <div class="container pt-8">
      <h2 class="pb-2 text-xl font-semibold text-center">
        Selected {{ triageRequestIds.length }} Launcher{{ triageRequestIds.length > 1 ? 's' : '' }}
      </h2>
      <template v-if="atLeastOneStudentSelected" >

        <FormulateForm
            @submit="submit"
            ref="form"
            class="max-w-lg mx-auto"
            v-model="values"
            :schema="formSchema"
        />
      </template>

      <template v-else >
        <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
            <div class="ml-4 mt-4">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                No Student Selected
              </h3>
            </div>
          </div>
        </div>
      </template>

    </div>
  </modal>
</template>

<script>
  import ModalMixin from '@mixins/ModalMixin.vue'
  import BulkManualEngagementForm from './bulkActionForms/BulkManualEngagementForm.vue'
  import axios from "@dataServices/axios";
  import FlashMessages from "@services/FlashMessageService";
  import EventBus from "@services/GlobalEventBus";
  import Multiselect from "vue-multiselect";


  const NAME = "triage-engagement-request-modal"
  const baseUrl = window.location.origin

  export default {
    name: NAME,
    mixins: [ ModalMixin ],
    components: {
      Multiselect,
      BulkManualEngagementForm
    },
    data() {
      return {
        modalName: NAME,
        userId: this.params.userId,
        triageRequestIds: this.params.triageRequestIds,
        coachOptions: [],
        updated: false,
        values: {
          create_triage_task: true,
        },
      }
    },
    async created() {
      this.coachOptions = await this.fetchCoaches()
    },
    methods: {
      async fetchCoaches () {
        try {
          const response = await axios.get(baseUrl + '/api/coaching/task_management/coaches', {params: {per_page: 100}}) //defining a high perPage number for now. If necessary in the future, implement recursive fetching later to fetch everyone
          return response.data.records.map(coach => ({value: coach.id, label: coach.name}));
        } catch (error) {
          console.error("Failed to fetch coaches:", error);
          FlashMessages.error("Failed to fetch coaches! " + error); //it's ok to show the real error because the coaches are the users
        }
      },
      submit () {
        this.reviewTriageRequests()

      },
      close() {
        const eventPayload = { type: 'closed', updated: this.updated}
        EventBus.dispatch(NAME, eventPayload)
        this.destroyModal()
      },
      async reviewTriageRequests () {
        try {
          const request = this.instantiateRequest()
          const response = await axios.post(baseUrl + `/api/coaching/dashboard/triage_students/review`, request)
          const tasks = response.data

          this.updated = true
          this.close();
          EventBus.dispatch('refresh-task-list', tasks)
          FlashMessages.success(this.$t('application.tasks.flash.task_completion_success'))
        } catch (error) {
          console.warn(error)
          FlashMessages.error(this.$t('application.tasks.flash.task_completion_error'))
        }
      },
      instantiateRequest() {
        return this.disableInputs === false ?
            { triage_request_ids: this.triageRequestIds, review_triage_request: { reviewer_user_id: this.userId, ...this.values } } :
            { triage_request_ids: this.triageRequestIds, review_triage_request: { reviewer_user_id: this.userId, } }
      },
    },
    computed: {
      atLeastOneStudentSelected() {
        return this.triageRequestIds?.length > 0
      },
      disableInputs() {
        return this.values.create_triage_task === false
      },
      formSchema() {
        return [
          {
            type: 'checkbox',
            label: 'Create Triage Tasks?',
            name: 'create_triage_task',
          },
          {
            component: 'h3',
            class: 'font-bold text-center text-xl pb-4',
            children: "Bulk Create Triage Tasks",
          },
          {
            type: 'select',
            label: 'Coach Responsible',
            name: 'triage_task_coach_id',
            options: this.coachOptions,
            disabled: this.disableInputs,
            inputClass: this.disableInputs ? 'disabled-field' : '',
            placeholder: 'Select a coach...'
          },
          {
            type: 'textarea',
            label: 'Description',
            name: 'triage_task_description',
            placeholder: 'Enter the description here...',
            disabled: this.disableInputs,
            inputClass: this.disableInputs ? 'disabled-field' : '',
            validation: this.disableInputs ? 'optional' : 'required'
          },
          {
            type: 'submit',
            label: 'Create Tasks'
          }
        ]
      },
    }
  }
</script>

<style lang="scss">
  .bulk-action-modal .v--modal {
    height: auto;
    width: 75%;
  }

  .disabled-field {
    background-color: #f5f5f5;
    color: #a9a9a9;
    opacity: 0.6;
    pointer-events: none;
  }
</style>
