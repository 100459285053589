import Model from '@models/Model'

class Assignment extends Model {
  constructor (initialData) {
    initialData.point_weighting = Number(initialData.point_weighting)
    initialData.weighted_points = initialData.point_weighting * initialData.total_points

    super(initialData, {
      momentKeys: [
        'created_at',
        'updated_at',
      ],
    })
  }

  get isWebWorksheet () {
    return this.type === 'worksheet'
  }
}

export default Assignment
