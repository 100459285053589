<template>
  <modal
    :name="modalName"
    class="user-edit-tags-modal"
    :scrollable="true"
    height="auto"
    @closed="destroyModal">
    <div class="close" @click="destroyModal" style="position: absolute; right: 18px; top: 8px; z-index: 999; background-color: white; padding: 4px; color: #767676; font-size: 22px; opacity: .75; border-radius: 4px;">
      <i class="fa fa-times"></i>
    </div>

    <template v-if="!!user">
      <user-edit-tags
        :user="user"
        :onSuccessCallback="destroyModal"
      />
    </template>
  </modal>
</template>

<script>
  import ModalMixin from '@mixins/ModalMixin.vue'
  import Users from '@dataServices/Users'
  import UserEditTags from '@components/general/UserEditTags.vue'

  const NAME = "user-edit-tags-modal"

  export default {
    name: NAME,
    mixins: [ ModalMixin ],
    components: {
      UserEditTags,
    },
    data () {
      return {
        user: null,
        modalName: NAME,
        userId: this.params.userId,
      }
    },
    created () {
      Users.getById(this.params.userId).then(u => { this.user = u })
    },
  }
</script>

<style lang="scss">
  .user-edit-tags-modal .v--modal {
    height: auto;
    width: 75%;
  }
</style>
